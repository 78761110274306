import React, {Component, useContext} from 'react';

import NavBar from '../layout/NavBar';
import Footer from '../layout/Footer';
import SideNavBar from '../layout/SideNavBar';

import axios from 'axios';

class HomeExpress extends Component 
{
    constructor(props) {

        super(props);

        this.state = {

            completePercentage: 0,

        }

    }

    componentDidMount() {

        this.getCompletedPercentage();

    }

    getCompletedPercentage = () => {

        axios({
            method: 'post',
            url: 'https://laffnetdev.website/api/onboarding/index.php',
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json' 
            },
            data: {
                action: 'GET_COMPLETED_PERCENTAGE',              

            }
        })
        .then(result => {
                        
            
            this.setState({completePercentage: result.data.percentage});
            
            
        })
        .catch(error => 
            console.log("ERROR: "  + JSON.stringify(error) ) 
        );

    }


    continueSetup = () => {

        this.props.history.push("/onboarding");

    }

    render() {

        
        return (
            <div >


                

                <NavBar />


                <div className="container">

                        <br /><br />

                        <div className="text-center" onClick={this.continueSetup} style={{backgroundColor:'#2BCB0A', width: '90%', maxWidth:'600px', marginLeft: 'auto', marginRight:'auto', padding:'10px'}}>
                            Your setup is {this.state.completePercentage}% Complete.<br />
                            Click here to continue setup.
                        </div>


                </div>

                <Footer />

                
            </div>
        );
    }


}



export default HomeExpress;