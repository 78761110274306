import React, {Component} from 'react';

import { Button, Input, Table , Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import axios from 'axios';

import { faPencilAlt, faTimesCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Board from './Board';
import Card from './Card';
import CardDetails from './CardDetails';
import ActivityList from './ActivityList';
import NewOpportunity from './NewOpportunity';

import NavBar from '../layout/NavBar';
import Footer from '../layout/Footer';
import SideNavBar from '../layout/SideNavBar';

class Pipeline extends Component 
{
    constructor(props) {

        super(props);
        
        this.state = {            
            items: [],
            showDetails: false,
            currentCard: null,
            showCreateOpportunity: false,

            showActivities: false,
            currentStage: null,

            destinationStage: null,
            showNotification: false,
		}            
    }

    componentDidMount() {
        this.loadCards();
    }

    loadCards = () => {

        axios({
            method: 'post',
            url: 'https://laffnetdev.website/api/funnel/index.php',
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json' 
			},
			data: {
				action: 'GET_ITEMS_LIST',
			}
        })
        .then(result => {
			
			//console.log(result.data.items);
			

			this.setState({items: result.data.items});
        })
        .catch(error => {
            console.log("ERROR: "  + JSON.stringify(error) ) 
        });
        
    }


    stageChanged = (card_id) => {
        
        console.log("changed: " + card_id);
        

        //console.log(this.state.items);

        let newStageCards;

        for (var i = 0; i < this.state.items.length; i++) {
            newStageCards = this.state.items[i].cards;

            for (var j = 0; j < newStageCards.length; j++) {
                if (newStageCards[j].id == "card-"+card_id) {
                
                    this.setState( {destinationStage: this.state.items[i]} );                    

                    break;
                }
            }
        }

        this.openCardDetails(card_id);

        //this.setState( {showNotification:true} );
        
    }

    /*
    toggleNotification = () => {
        this.setState( {showNotification: !this.state.showNotification} );
    }
    */

    addCardToBoard = (target_id, card_id) => {
      
        //console.log("addCardToBoard: " + target_id + " " + card_id);
        const tempItems = [ ...this.state.items ]; 
        

        if (target_id == "")
        {
            //console.log("target is unknown");
        }
        else if (target_id.substring( 0, 5) == "board") // target is a board
        {
            

            var movedCard;
        
            // removing the card from existing board
            for (var i = 0; i < tempItems.length; i++)
            {
                for (var j = 0; j < tempItems[i].cards.length; j++)
                {
                    if (tempItems[i].cards[j].id == card_id) 
                    {
                        movedCard = tempItems[i].cards[j];
                        tempItems[i].cards.splice(j , 1);
                        tempItems[i].total = tempItems[i].total - movedCard.revenue;
                        break;
                    }
                }
            }

            if (movedCard)
            {
                // adding card to the new board
                for (var i = 0; i < tempItems.length; i++)
                {
                    if (tempItems[i].id == target_id)
                    {
                        //movedCard.status = tempItems[i].status;
                        movedCard.board = tempItems[i].id;
                        tempItems[i].cards.push( movedCard );
                        tempItems[i].total = tempItems[i].total + movedCard.revenue;
                        this.setState({items: tempItems});
                                
                        document.getElementById(card_id).style.display = 'block';
                        
                        break;
                        
                        
                    }
                }
            }
        }
        else if (target_id.substring( 0, 4) == "card") // target is a card
        {
            //console.log("target is a card");            
        }


        
        
        setTimeout(() => {
            //console.log(this.state.items);

            //document.getElementById(movedCard.id).style.display = 'block';

            // set the opacity of the target cards to 1.0                        
            //document.getElementById(target_id).style.opacity = '1.0';



            document.getElementById(card_id).style.opacity = '1.0'; /********************** */
            
            
            this.stageChanged( card_id.substring(5) ); // extracting the numeric part of the id

        },10);

    }

    // triggerred when a card is being dragged over a card
    onDragOverCard = (dragging, target) => {   // dragging card id, target card id

        //console.log("onDragOverCard " + dragging + " " + target);

        const draggingCard = document.getElementById ( dragging );
        const targetCard = document.getElementById ( target );
        
        
            

        const draggingCardBoard = draggingCard.getAttribute("board");
        const targetCardBoard = targetCard.getAttribute("board");

        const tempItems = [ ...this.state.items ]; 
        
        

        if (draggingCardBoard == targetCardBoard) // same board
        {
            var draggingIndex;
            var targetIndex;

            for (var i = 0; i < tempItems.length; i++)
            {
                if ( tempItems[i].id == draggingCardBoard)
                {
                    for (var j = 0; j < tempItems[i].cards.length; j++)
                    {
                        if (tempItems[i].cards[j].id == dragging)
                        {    
                            draggingIndex = j;
                        }

                        if (tempItems[i].cards[j].id == target)
                        {    
                            targetIndex = j;
                        }
                        
                    }


                    const tempCard = tempItems[i].cards[draggingIndex];

                    tempItems[i].cards[draggingIndex] = tempItems[i].cards[targetIndex];
                    tempItems[i].cards[targetIndex] = tempCard;

                    
                    this.setState({items: tempItems});

                    

                    setTimeout(() => {
                        //console.log(tempItems[i].cards[targetIndex].id);
                        document.getElementById(tempItems[i].cards[targetIndex].id).style.display = 'block';

                        // set the opacity of the target cards to 0.3                        
                        document.getElementById(dragging).style.opacity = '0.3';
                        //document.getElementById(dragging).style.transform = 'rotate(0deg)';

                        // resetting the dragging id
                        document.getElementById( "dragging-card-id" ).value = dragging;
                        
                    },10);

                    break;
                }
            }

        }
        else // hover over a different board
        {
            var movedCard;

            // removing the card from existing board
            for (var i = 0; i < tempItems.length; i++)
            {
                for (var j = 0; j < tempItems[i].cards.length; j++)
                {
                    if (tempItems[i].cards[j].id == dragging) 
                    {
                        movedCard = tempItems[i].cards[j];
                        tempItems[i].cards.splice(j , 1);

                        tempItems[i].total = tempItems[i].total - movedCard.revenue;
                        break;
                    }
                }
            }

            if (movedCard)
            {
                // adding card to the new board
                for (var i = 0; i < tempItems.length; i++)
                {
                    for (var j = 0; j < tempItems[i].cards.length; j++)
                    {
                        if (tempItems[i].cards[j].id == target) 
                        {
                            movedCard.board = tempItems[i].id;

                            // insert into  tempItems[i] at j
                            /*
                            
                            tempItems[i].cards.push( movedCard );

                            

                            

                            
                            */

                            tempItems[i].cards.splice(j, 0, movedCard);

                            tempItems[i].total = tempItems[i].total + movedCard.revenue;

                            this.setState({items: tempItems});

                            // set the opacity of the target cards to 0.3                        
                            document.getElementById(dragging).style.opacity = '0.3';
                            //document.getElementById(dragging).style.transform = 'rotate(0deg)';

                            setTimeout(() => {

                                // resetting the dragging id
                                document.getElementById( "dragging-card-id" ).value = dragging;

                            },10);

                        break;
                        }

                        
                    }
                }
            }
        }

        
        



    }

    // triggered when a board/card is being over a board
    onDragOverBoard = (dragging, target) => {

        
        if ( dragging.substring(0,5) == "board" ) // dragging a board
        {
            if ( target.substring(0,5) != "board" )
            {
                if ( target.substring(0,4) == "card" )
                {
                    var bID = this.getBoardIDForCard(target);
                    if (bID != "")
                    {
                        target = bID;
                    }
                    else
                    {
                        return;
                    }
                }
                else
                {
                    return;
                }
                
            } 


            const draggingBoard = document.getElementById ( dragging );
            const targetBoard = document.getElementById ( target );

            var draggingIndex;
            var targetIndex;


            
            if (draggingBoard == targetBoard)
            {
                return;
            }
            else
            {
                const tempItems = [ ...this.state.items ]; 

                for (var i = 0; i < tempItems.length; i++)
                {
                    if (tempItems[i].id == dragging)
                    {
                        draggingIndex = i;
                    }

                    if (tempItems[i].id == target)
                    {
                        targetIndex = i;
                    }
                }


                const tempBoard = tempItems[draggingIndex];

                tempItems[draggingIndex] = tempItems[targetIndex];
                tempItems[targetIndex] = tempBoard

                this.setState({items: tempItems});

                // resetting the dragging id
                setTimeout(() => {
                    document.getElementById( "dragging-board-id" ).value = dragging;
                },10);

            }
        }
        else if ( dragging.substring(0,4) == "card" ) // dragging a card
        {
            


            const draggingCard = document.getElementById ( dragging );            
            
            if (draggingCard)
            {
                const draggingCardBoard = draggingCard.getAttribute("board");
                const targetCardBoard = target;

                const tempItems = [ ...this.state.items ]; 
                
                //console.log(targetCardBoard + " " + draggingCardBoard);
                
                if (draggingCardBoard == targetCardBoard) // same board
                {
                    
                    /*
                    console.log("Dragging Over Same Board");

                    var draggingIndex;
                    var targetIndex;

                    

                    for (var i = 0; i < tempItems.length; i++)
                    {
                        

                        if ( tempItems[i].id == draggingCardBoard)
                        {
                            for (var j = 0; j < tempItems[i].cards.length; j++)
                            {
                                if (tempItems[i].cards[j].id == dragging)
                                {    
                                    draggingIndex = j;
                                }                            
                            }
                            targetIndex = tempItems[i].cards.length - 1;


                            const tempCard = tempItems[i].cards[draggingIndex];

                            tempItems[i].cards[draggingIndex] = tempItems[i].cards[targetIndex];
                            tempItems[i].cards[targetIndex] = tempCard;

                            
                            this.setState({items: tempItems});

                            
                            setTimeout(() => {
                                //console.log(tempItems[i].cards[targetIndex].id);
                                document.getElementById(tempItems[i].cards[targetIndex].id).style.display = 'block';

                                // set the opacity of the target cards to 0.3                        
                                document.getElementById(dragging).style.opacity = '0.3';
                                
                            },10);

                            break;
                        }
                    }
                    */

                }
                else // hover over a different board
                {

                    /*
                    console.log("Dragging Over Different Board");

                    var movedCard;

                    
                    // removing the card from existing board
                    for (var i = 0; i < tempItems.length; i++)
                    {
                        for (var j = 0; j < tempItems[i].cards.length; j++)
                        {
                            if (tempItems[i].cards[j].id == dragging) 
                            {
                                movedCard = tempItems[i].cards[j];
                                tempItems[i].cards.splice(j , 1);

                                tempItems[i].total = tempItems[i].total - movedCard.revenue;
                                break;
                            }
                        }
                    }

                    if (movedCard)
                    {
                        // adding card to the new board
                        for (var i = 0; i < tempItems.length; i++)
                        {
                            
                            if (tempItems[i].id == target) 
                            {
                                movedCard.board = tempItems[i].id;

                                

                                tempItems[i].cards.splice( tempItems[i].cards.length-1 , 0, movedCard );

                                tempItems[i].total = tempItems[i].total + movedCard.revenue;

                                this.setState({items: tempItems});

                                // set the opacity of the target cards to 0.3                        
                                document.getElementById(dragging).style.opacity = '0.3';

                                setTimeout(() => {

                                    // resetting the dragging id
                                    document.getElementById( "dragging-card-id" ).value = dragging;
    
                                },10);

                                

                            
                            }

                                
                            
                        }
                    }
                    */
                    
                }

            }
         

            
        }




        
    }
    
    // returns the id of the board that the provided card belong to
    getBoardIDForCard = (card_id) => {
        
        for (var i = 0; i < this.state.items.length; i++)
        {
            for (var j = 0; j < this.state.items[i].cards.length; j++)
            {
                if (this.state.items[i].cards[j].id == card_id) 
                {
                    return this.state.items[i].id;
                }
            }
        }

        return "";

    }

    drop = (e) => {
        //console.log("dropped on pipeline");

        e.preventDefault();
        const card_id = e.dataTransfer.getData('card_id');
        const board_id = e.dataTransfer.getData('board_id');

        if ( card_id )
        {
            
            //this.addCardToBoard("", card_id);

        }
        else if ( board_id )
        {
            //this.addBoardToPipeline(board_id);
        }
        else
        {
            //console.log("board does not exist");
        }

    }

    // save the stage order after the dropping
    saveStageOrder = () => {

        const tempItems = [ ...this.state.items ]; 

        axios({
            method: 'post',
            url: 'https://laffnetdev.website/api/funnel/index.php',
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json' 
			},
			data: {
                action: 'SAVE_STAGE_ORDER',
                list: tempItems,
			}
        })
        .then(result => {
            
            console.log(result.data);
			
        })
        .catch(error => {
            console.log("ERROR: "  + JSON.stringify(error) ) 
        });

    }

    // save the card order after the dropping
    saveCardOrder = () => {

        //console.log(this.state.items);
        const tempItems = [ ...this.state.items ]; 

        axios({
            method: 'post',
            url: 'https://laffnetdev.website/api/funnel/index.php',
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json' 
			},
			data: {
                action: 'SAVE_CARD_ORDER',
                list: tempItems,
			}
        })
        .then(result => {
            
            //console.log(result.data);
			
        })
        .catch(error => {
            console.log("ERROR: "  + JSON.stringify(error) ) 
        });

    }

    openCardDetails = (card_id) => {
    
        this.setState( {currentCard: card_id} );
     
        setTimeout(() => {
            console.log("Opening " + this.state.currentCard);
            this.setState( {showDetails:true} );

        },200);
        
    }

    onCloseDetails = () => {
        
        this.setState( {currentCard: null} );
        this.setState( {showDetails:false} );
    }

    onCloseActivities = () => {

        this.setState( {currentStage: null} );
        this.setState( {showActivities:false} );
    }

    openActivities = (board_id) => {

        this.setState( {currentStage: board_id} );
     
        setTimeout(() => {

            this.setState( {showActivities:true} );

        },200);
        
    }

    onCloseNewOpportunity = () => {
        this.setState( { showCreateOpportunity: false } );
    }
    
    createOpportunity = () => {
        
        const tempItems = [ ...this.state.items ];

        if (tempItems.length == 0)
        {
            alert("Please create a Stage first.");
        }
        else
        {
            this.setState( { showCreateOpportunity: true } );
            
        }
        
    }

    createStage = () => {
        
        const tempItems = [ ...this.state.items ];

        axios({
            method: 'post',
            url: 'https://laffnetdev.website/api/funnel/index.php',
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json' 
			},
			data: {
                action: 'CREATE_NEW_STAGE',
			}
        })
        .then(result => {
            
            //console.log(result.data.stage);
            const stage = result.data.stage;
            tempItems.push(stage);        
            this.setState( {items: tempItems} );

            setTimeout(() => {

                var elmt = document.getElementById("pipeline-scroll-area");
                elmt.scrollLeft = elmt.clientWidth;
    
            },200);
			
        })
        .catch(error => {
            console.log("ERROR: "  + JSON.stringify(error) ) 
        });
       
        
    }

    onNewOpportunityCreated = (newCard) => {

        const tempItems = [ ...this.state.items ];

        //console.log("Existing Card at 0");
        //console.log(tempItems[0].cards[0]);


        
        tempItems[0].cards.unshift(newCard);
        this.setState( {items: tempItems} );

        this.setState( { showCreateOpportunity: false } );

        

        setTimeout(() => {
            //console.log(newCard);
            this.openCardDetails(newCard.row_id);

        },200);
        
    }

    

    render() {

        const totalScrollWidth = 300 * this.state.items.length;   // 285 = rough widh of a board

        const boards = this.state.items
		
			.map((item,i)=> (
				<div key={item.id} className="float-left"> {/*  style={{display: 'inline-block', whiteSpace: 'nowrap'}} */}

                    
                    
                    <Board
                        row={item.row_id}
                        id={item.id} 
                        name={item.name} 
                        total={item.total}
                        draggable="false" /*Make it not draggable since stages are static*/
                        addCardToBoard={this.addCardToBoard} 
                        //addBoardToPipeline={this.addBoardToPipeline}
                        onDragOverBoard={this.onDragOverBoard}
                        onReorderStages={this.saveStageOrder}
                        onReorderCards={this.saveCardOrder}

                        

                        openActivities={this.openActivities}
                    >
                        

                        {item.cards.map((card, i) =>
                            <Card key={card.id} card={card} draggable="true" onDragOverCard={this.onDragOverCard} onClickCard={this.openCardDetails} >
                                
                                <div className="clearfix">

                                    <div className="float-left">
                                        <div>{card.contact}</div>
                                        <div className="grayText"><i>{card.company}</i></div>
                                    </div>
                                    <div className="float-right text-right">
                                        <div>${card.revenue.toFixed(2)}</div>
                                        <div className="grayText"><i>{card.nextDate}</i></div>
                                    </div>
                                    
                                </div>
                                
                                
                            </Card>
                        )} 

                    </Board>
                    <br /><br />
                    
				</div>
            ));
            

    
        return (

            <div  > {/* className="laff-bg"   style={backgroundStyle} */}
                
                <NavBar />

                <SideNavBar />

                <div className="page-main-container">

                    <div className="secondary-menu">
                        <div className="container-fluid">
                            <Button className="btn-laff-sm" onClick={ () => this.createOpportunity() }> + Opportunity </Button>&nbsp;
                            {/*
                            <Button className="btn-laff-sm" onClick={ () => this.createStage() }> + Stage</Button>
                            */}
                        </div>  
                    </div>

                    <input type="hidden" id="dragging-board-id" value=""/>
                    <input type="hidden" id="dragging-card-id" value=""/>

                

                    <div>
                        <div className="container-fluid page-content" >
                            
                            {/* 
                            <div id="pipeline-scroll-area" style={{paddingBottom:'15px', height:'575px', width:'100%', overflow: 'auto'}} onDrop={this.drop}> 
                            */}

                            <div id="pipeline-scroll-area" style={{paddingBottom:'15px',  height:'calc(100vh - 200px)', width:'100%', overflow: 'auto'}} onDrop={this.drop}> 
                                

                                <div style={{ width: totalScrollWidth }}>
                                    
                                    {boards}

                                    
                                    
                                </div>
                                
                                
                            </div>
                        </div>
                    </div>

                </div>

                
                

                <CardDetails 
                    show={this.state.showDetails} 
                    card={this.state.currentCard}  
                    onClose={this.onCloseDetails}
                    moveCard={this.addCardToBoard}
                    onReorderCards={this.saveCardOrder}
                /> 

                <ActivityList show={this.state.showActivities} stage={this.state.currentStage} onClose={this.onCloseActivities}/>

                <NewOpportunity 
                    show={this.state.showCreateOpportunity}
                    onClose={this.onCloseNewOpportunity}
                    created={this.onNewOpportunityCreated}
                />


                {/* 
                <Modal isOpen={this.state.showNotification} size="lg">
                    
                    <ModalBody className="laff-modal">

                        <div className="row">
                            <div className="col-10">
                                <h5>...</h5>
                            </div>
                            <div className="col-2 text-right" style={{paddingBottom:'10px'}}>
                                <Button className="laff-blue rounded-circle" size="sm" onClick={this.toggleNotification} aria-label="Close"><span>&nbsp;&times;&nbsp;</span></Button>                                                
                            </div>
                        </div>

                        <br />
                        You just moved your gig to "{this.state.destinationStage ? this.state.destinationStage.name : ''}"
                       

                    </ModalBody>
                    
                        
                    
                </Modal>
                */}

                <Footer />

            </div>
        );
    }

}

const backgroundStyle = {
    width:'100%',  
    height:'100%',
    backgroundImage: `url(./img/bg/70.png)`, /* Location of the image */
	
	
	backgroundRepeat: 'repeat', 
	
	backgroundColor: '#a8b4ba' /* 838C91 Set a background color that will be displayed while the background image is loading */
}


export default Pipeline;