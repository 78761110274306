import React, {Component, useContext} from 'react';
import { Pagination, PaginationItem, PaginationLink, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Typeahead } from 'react-bootstrap-typeahead';

import axios from 'axios';

import 'react-bootstrap-typeahead/css/Typeahead.css';

import './ImageGallery.css';

class ImageSearchGallery extends Component 
{
    constructor(props) {

        super(props);

        this.state = {

            images: [],
            currPage: 1,
            pageCount: 1,
            imageCount: 0,


            imagesPerPage: 4,
            showEnlargedImage: false,
            zoomedImage:null,
            imageTags: [], // All Tags

            filteredTags: [],

        }
        
    }

    componentDidMount() {

        //this.generateTags();

        this.getAllImageTags();
        
        this.getImageTotalCount();

    }

    getAllImageTags =() => {

        var self = this;

        axios({
            method: 'post',
            url: 'https://laffnetdev.website/api/onboarding/index.php',
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json' 
            },
            data: {
                action: 'GET_ALL_TAGS',
                              

            }
        })
        .then(result => {
                        
            //console.log("---- GETTING TAGS ----");   
            //console.log(result.data.tags);

            self.setState({imageTags: result.data.tags});
                        
        })
        .catch(error => 
            console.log("ERROR: "  + JSON.stringify(error) ) 
        );

    }


    generateTags = () => {

        axios({
            method: 'post',
            url: 'https://laffnetdev.website/api/onboarding/index.php',
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json' 
            },
            data: {
                action: 'GENERATE_TAGS',
                              

            }
        })
        .then(result => {
                        
            console.log("---- GETTING TAGS ----");   
            console.log(result.data);
                        
        })
        .catch(error => 
            console.log("ERROR: "  + JSON.stringify(error) ) 
        );

    }


    getImageTotalCount = () => {

        axios({
            method: 'post',
            url: 'https://laffnetdev.website/api/onboarding/index.php',
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json' 
            },
            data: {
                action: 'GET_TOTAL_SEARCH_IMAGE_COUNT',
                perPage: this.state.imagesPerPage,
                tags: this.state.filteredTags,              

            }
        })
        .then(result => {
                        
            console.log("---- GETTING SEARCHED COUNT ----");   
            console.log(result.data);
            this.setState({pageCount: result.data.pageCount});
            this.setState({imageCount: result.data.imageCount});
            
            
            this.loadImages();
            
        })
        .catch(error => 
            console.log("ERROR: "  + JSON.stringify(error) ) 
        );

    }

    loadImages = () => {
        
        axios({
            method: 'post',
            url: 'https://laffnetdev.website/api/onboarding/index.php',
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json' 
            },
            data: {
                action: 'GET_SEARCH_GALLERY_IMAGES',
                perPage: this.state.imagesPerPage,
                page: this.state.currPage,
                tags: this.state.filteredTags

            }
        })
        .then(result => {
                       
            console.log("---- GETTING SEARCHED IMAGES ----");            
            console.log(result.data);   
            
            this.setState({images: result.data.images});
            
        })
        .catch(error => 
            console.log("ERROR: "  + JSON.stringify(error) ) 
        );

    }

    gotoPage = (pageNum) => {
        var self = this;
        self.setState({currPage: pageNum});

        setTimeout(function(){ 
            self.loadImages();
        }, 200);
        
    }


    zoomImage = (img) => {

        this.setState({showEnlargedImage: true});
        this.setState({zoomedImage: img});

    }


    closeZoomImage = () => {

        this.setState({showEnlargedImage: false});
        this.setState({zoomedImage: null});

    }

    choosePicForWebsite = () => {

        var filename = this.state.zoomedImage.filename;

        this.closeZoomImage();
        this.props.onChoosePic(filename);
    }

    render() {
        

        var galleryImages = this.state.images.map((img,i)=> (
            <div key={i} className="col-md-3 mb-4">
                <div>
                    <img src={"img/gallery/thumbnail/" + img.thumb} style={galleryImg} onClick={()=>this.zoomImage(img)}/>
                    <br />

                    <div className="" style={{color:'#fff', fontSize:'12px', paddingTop:'10px'}}>{img.image_title_short}</div> 
                    
                </div>
                
            </div>
        ));

        var paginationStart = 1;
        var paginationEnd = 1;

        if (this.state.pageCount <= 5) {
            paginationStart = 1;
            paginationEnd = this.state.pageCount;
        } else {
            paginationStart = this.state.currPage - 2;
            if (paginationStart < 1) {
                paginationStart = 1;
            }
            paginationEnd = paginationStart + 4;
            if (paginationEnd > this.state.pageCount) {
                paginationEnd = this.state.pageCount;
            }
        }

        

        var paginationArray = [];
        for (var j = paginationStart; j <= paginationEnd; j++ ) {
            paginationArray.push(j);
        }


        var paginationLinks = paginationArray.map((pg,i)=> {

            if (pg == this.state.currPage ) {
                return <PaginationItem active key={i}> <PaginationLink href="#">{pg}</PaginationLink></PaginationItem>;  
            } else {
                return <PaginationItem key={i}> <PaginationLink href="#" onClick={() => this.gotoPage(pg)}>{pg}</PaginationLink></PaginationItem>;  
            }

                      
            
        });

        var imageFoundStatus = "";

        if (this.state.imageCount == 0) {
            //imageFoundStatus = this.state.imageCount + " Images found. Remove some tags or <span className='laff-blue'>Upload</span> your own image.";

            imageFoundStatus = <span>{this.state.imageCount} Images found. Remove some tags or <span className='laff-blue-txt' style={{cursor:'pointer'}} onClick={this.props.onClickUpload}>Upload</span> your own image.</span>;
        } else if (this.state.imageCount == 1) {
            imageFoundStatus = <span>{this.state.imageCount} Image found.</span>;
        } else if (this.state.imageCount > 1) {
            imageFoundStatus = <span>{this.state.imageCount} Images found.</span>;
        }


       
        

        return (
            <div>

                <Typeahead
                    multiple
                    minLength='2'
                    placeholder='Enter Keywords'
                    onChange={(selected) => {

                        
                        
                        var self = this;
                        

                        var selectedArr = [];
                        for (var i = 0; i<selected.length; i++) {
                            console.log("***** " + selected[i].id + " " + selected[i].tag);
                            selectedArr.push(selected[i].id);
                        }
                        self.setState({filteredTags: selectedArr});
                        self.setState({currPage: 1});




                        setTimeout(function(){ 
                            self.getImageTotalCount();
                        }, 200);
                        
                        //console.log("***** " + JSON.stringify(selected) + " ****");
                    }}
                    

                    
                    labelKey="tag"
                    options={this.state.imageTags}
                    
                    /*
                    options={[ 
                        'Alaska',
                        'Arizona',
                        'Arkansas',
                        'California',
                        'Colorado'

                        
                    ]}
                    */
                   /*
                    labelKey="lastName"
                    options={[
                        {id: '1', lastName: 'Blakey'},
                        {id: '2', lastName: 'Cobb'},
                        {id: '3', lastName: 'Jones'},
                        {id: '4', lastName: 'Roach'},
                        {id: '5', lastName: 'Williams'},
                    ]}
                    */
                    
                />

                <br />

                {/* 
                <p style={{color: '#fff', display: (this.state.pageCount > 0 ? 'none' : 'block' )}}>{this.state.imageCount} Images found.<br /></p>
                */}


                <p style={{color: '#fff'}}>
                    {imageFoundStatus}                    
                </p>


                

                

                <div className="row">
                    
                    {galleryImages}

                </div>

                <div className="text-right">
                    <Pagination aria-label="Page navigation example">
                        <PaginationItem disabled={this.state.currPage <= 1}>
                            <PaginationLink first href="#" onClick={() => this.gotoPage(1)}/>
                        </PaginationItem>
                        <PaginationItem disabled={this.state.currPage <= 1}>
                            <PaginationLink previous href="#" onClick={() => this.gotoPage(this.state.currPage - 1)}/>
                        </PaginationItem>

                        {paginationLinks}

                        <PaginationItem disabled={this.state.currPage >= this.state.pageCount}>
                            <PaginationLink next href="#" onClick={() => this.gotoPage(this.state.currPage + 1)}/>
                        </PaginationItem>
                        <PaginationItem disabled={this.state.currPage >= this.state.pageCount}>
                            <PaginationLink last href="#" onClick={() => this.gotoPage(this.state.pageCount)}/>
                        </PaginationItem>
                    </Pagination>
                </div>



                <Modal
                        isOpen={this.state.showEnlargedImage}
                        size="lg"
                        backdrop={false}
                        centered
                    >
                    
                    <ModalBody style={{backgroundColor:'#000000'}}>                        

                        <span style={{
                            position:'absolute', 
                            top:'20px', 
                            right: '20px',  
                            border: '1px solid #fff', 
                            borderRadius:'50%', 
                            color: '#fff', 
                            backgroundColor:'#000',
                            padding: '1px 9px',
                            cursor: 'pointer'
                            }}
                            onClick={()=>this.closeZoomImage()}
                            >x</span>
                        <img src={'img/gallery/'+ (this.state.zoomedImage ? this.state.zoomedImage.filename : '')} style={{width:'100%', height:'auto'}} /> 
                        <br /><br />

                        
                        <div className="float-left" style={{color:'#fff', fontSize:'12px', paddingTop:'10px'}}>{this.state.zoomedImage ? this.state.zoomedImage.image_title : ''}</div>          
                        <div className="float-Right text-right">
                            <button className="btn btn-sm btn-laff-sm-blue mr-2" onClick={()=>this.closeZoomImage()}>Choose Another Image</button>
                            <button className="btn btn-sm btn-laff-sm-blue" onClick={()=>this.choosePicForWebsite()}>Accept</button>
                        </div>

                        <div className="clearfix"></div>

                    </ModalBody>
                    
                </Modal>


            </div>
        )
    }

}

const galleryImg = {

    width: '100%',
    height: 'auto',
    maxHeight: '100%',
    cursor: 'zoom-in'
}

export default ImageSearchGallery;