import React, {Component} from 'react';

import axios from 'axios';

import { Button, CustomInput } from 'reactstrap';

import NavBar from '../layout/NavBar';
import Footer from '../layout/Footer';
import SideNavBar from '../layout/SideNavBar';

class SelectEmailContacts extends Component {

    constructor(props) {

        super(props);
        
        this.state = {
            //templateID: this.props.match.params.templateid,
            contactList: [],
            showConfirmList:false
            
		}
            
    }

    componentDidMount() {

        console.log(this.state.templateID);
		// loading contacts
		
		console.log("Loading");
        axios({
            method: 'post',
            url: 'https://laffnetdev.website/api/emails/index.php',
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json' 
			},
			data: {
				action: 'GET_EMAIL_CONTACTS'
			}
            
        })
        .then(result => {
			
            this.setState({contactList: result.data.contacts});
            
            //console.log(result.data.contacts.length);
			
        })
		.catch(error => console.log("ERROR: "  + JSON.stringify(error) ) );
		

		


		console.log("Loaded");
		
    }

    // selecting all contacts
    contactSelectAll(buttonName, e) {

        var self = this;
        console.log(e.target.checked + " " + buttonName);

        

        const tempList = [ ...this.state.contactList ];

        if (e.target.checked) // checked all
        {
            for (var i = 0; i < tempList.length; i++)
            {
                tempList[i].checked='1';
            }
        }
        else // unchecked all
        {
            for (var i = 0; i < tempList.length; i++)
            {
                tempList[i].checked='0';
            }

        }
        
        console.log(tempList[0].checked);
        this.setState({contactList: tempList});

        setTimeout(function(){ 
			//console.log(self.state.contactList);
		}, 1500);
        

        //let newStr = `we received ${e.target.checked} for ${buttonName}...`;
        //console.log(newStr);
        //let newLog = [...this.state.log, newStr];
        //this.setState({ log: newLog });
    }

    // select a contact
    
    
    
    contactSelect(buttonName, i, e) {
        //console.log(e.target.checked + " " + buttonName + " " + i);


        const tempList = [ ...this.state.contactList ];

        if (e.target.checked) // checked 
        {            
            tempList[i].checked='1';
        }
        else
        {
            tempList[i].checked='0';
        }
        this.setState({contactList: tempList});

    }
    
    confirmEmailContacts = () => {
        var count = 0;
        for (var i = 0; i < this.state.contactList.length; i++)
        {
            //console.log("****: " + this.state.contactList[i].checked);
            if (this.state.contactList[i].checked == '1')
            {
                count++;
                break; // at least one contact has been selected
            }
        }
    
        if (count == 0)
        {
            alert("Select Contact First");
            return false;
        }
        this.setState( {showConfirmList:true} );
    }

    backToSelection = () => {
        this.setState( {showConfirmList:false} );
    }
  
    onPreview = () => {
        // /preview-newsletter/

        //contact-select
        //var allContacts = document.getElementsByClassName('orange juice');

        var selectedContacts = [];

        for (var i = 0; i < this.state.contactList.length; i++)
        {
            if (this.state.contactList[i].checked == '1')
            {
                //console.log(this.state.contactList[i].id + " " + this.state.contactList[i].email);

                selectedContacts.push( {id: this.state.contactList[i].id, email: this.state.contactList[i].email} );
            }
        }


        /*******************************************************************/

        axios({
            method: 'post',
            url: 'https://laffnetdev.website/api/emails/index.php',
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json' 
			},
			data: {
                action: 'CREATE_EMAIL_BATCH',
                templateID: this.state.templateID,
                contacts: selectedContacts,
			}
            
        })
        .then(result => {
                     
            console.log(result.data.batchID);
            console.log("SAVED BATCH");
            this.props.history.push('/preview-newsletter/' + result.data.batchID);
            
        })
		.catch(error => console.log("ERROR: "  + JSON.stringify(error) ) );
        
        /*******************************************************************/

    }

    render() {

        const contactList = this.state.contactList
					
			.map((contact,i)=> (
				
				<div key={contact.id} className="row" style={contactRow}>

                    <div className="col-md-3">
                        <div className="row">
                            <div className="col-auto pr-0">
                            
                                <CustomInput 
                                    className="contact-select"
                                    type="switch" 
                                    /*
                                    id={'email-contact-' + contact.id}
                                    */
                                    id={contact.id}
                                    name={'email-contact-' + contact.id} 
                                    label=""   
                                    /* 
                                    defaultChecked={ contact.checked=='1' }  
                                    */
                                    checked={contact.checked=='1'}
                                    
                                    onChange={this.contactSelect.bind(this, "contact", i)} 
                                />
                            
                            </div>
                            <div className="col-auto pl-0">
                                {contact.firstname} {contact.lastname}                                    
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        {contact.company}<br />
                        {contact.work_city}
                    </div>
                    <div className="col-md-3">
                        <div>
                            {contact.isBooker === "Y" ? <span className='badge badge-pill badge-secondary' style={{padding:'8px 16px', marginRight:'2px', marginBottom:'2px', fontWeight:'400'}}>Booker</span> : ""}
                            {contact.isPromoter === "Y" ? <span className='badge badge-pill badge-secondary' style={{padding:'8px 16px', marginRight:'2px', marginBottom:'2px',fontWeight:'400'}}>Promoter</span>  : ""}
                            {contact.isFan === "Y" ? <span className='badge badge-pill badge-secondary' style={{padding:'8px 16px', marginRight:'2px', marginBottom:'2px',fontWeight:'400'}}>Fan</span>  : ""}    
                        </div>
                    </div>
                    <div className="col-md-3">
                        {contact.email}
                    </div>
                    
				</div>


            ));
            
        // getting the selected list 
        const selectedList = this.state.contactList
            .filter ((contact,i)=> {
                // remove contact that do not match the current filter
                return  ( 
                    contact.checked == '1'
                );
            })		
			.map((contact,i)=> (
				
				<div key={contact.id} className="row" style={contactRow}>

                    <div className="col-md-3">                            
                        {contact.firstname} {contact.lastname}                        
                    </div>
                    <div className="col-md-3">
                        {contact.company}<br />
                        {contact.work_city}
                    </div>
                    <div className="col-md-3">
                        <div>
                            {contact.isBooker === "Y" ? <span className='badge badge-pill badge-secondary' style={{padding:'8px 16px', marginRight:'2px', marginBottom:'2px', fontWeight:'400'}}>Booker</span> : ""}
                            {contact.isPromoter === "Y" ? <span className='badge badge-pill badge-secondary' style={{padding:'8px 16px', marginRight:'2px', marginBottom:'2px',fontWeight:'400'}}>Promoter</span>  : ""}
                            {contact.isFan === "Y" ? <span className='badge badge-pill badge-secondary' style={{padding:'8px 16px', marginRight:'2px', marginBottom:'2px',fontWeight:'400'}}>Fan</span>  : ""}    
                        </div>
                    </div>
                    <div className="col-md-3">
                        {contact.email}
                        {/*
                        <div className="col-md-auto pr-0"><CustomInput type="switch" id={'email-pref-' + contact.id} name={'email-other-' + contact.id} label=""/></div>
                        <div className="col-md-auto pl-0">{contact.email_other}</div>
                        */}
                    </div>
                    
				</div>


			));    

        return(
            <div>
                {/*
                <NavBar />
                <br />
                */}
                <NavBar />

                <SideNavBar />

                <div className="page-main-container">

                    <div className="container" id="select-email-contacts" style={this.state.showConfirmList ? {display:'none'}:{display:'block'}}>

                    <div className="row" style={contactHeaderRow}>

                        <div className="col-md-3">
                            <CustomInput 
                                type="switch" 
                                id="select-all-contact" 
                                name="select-all-contact" 
                                label="Select Contacts"
                                onChange={this.contactSelectAll.bind(this, "select all")}
                            />
                        </div>
                        <div className="col-md-3">

                        </div>
                        <div className="col-md-3">

                        </div>
                        <div className="col-md-3">
                            Email
                        </div>



                    </div>

                    {contactList}

                    <div className="row">
                        <div style={{width:'100%'}} className="text-right">
                            <Button className="btn-laff-sm" onClick={ () => this.confirmEmailContacts() }>Confirm Contacts</Button>
                        </div>
                                            
                    </div>

                    </div>

                    {/* Confirm */}
                    <div className="container" id="confirm-email-contacts" style={this.state.showConfirmList ? {display:'block'}:{display:'none'}}>

                    <div className="row" style={contactHeaderRow}>

                        <div className="col-md-3">
                            Contact
                        </div>
                        <div className="col-md-3">

                        </div>
                        <div className="col-md-3">

                        </div>
                        <div className="col-md-3">
                            Email
                        </div>

                    </div>

                    {selectedList}

                    <div className="row">
                        <div style={{width:'100%'}} className="text-right">
                            <Button className="btn-laff-sm" onClick={ () => this.backToSelection() }>Back to Selection</Button>&nbsp;
                            
                            <Button className="btn-laff-sm" onClick={ () => this.onPreview() }>Preview Newsletter >></Button>
                            
                        </div>
                                            
                    </div>

                    </div>

                    <br /><br /><br />

                </div>
                    
                

                <Footer />
            </div>
        );
    }

}

const contactRow = {
    backgroundColor:'#fff',
    paddingTop:'5px',
    paddingBottom:'5px',
    fontSize:'14px',
    borderBottom:'6px #a8b4ba solid'
}

const contactHeaderRow = {
    backgroundColor:'#fff',
    paddingTop:'15px',
    paddingBottom:'15px',
    borderBottom:'6px #a8b4ba solid'
}

export default SelectEmailContacts;