import React, {Component, useState} from 'react';
import { Button, Input} from 'reactstrap';
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";

import { faTimesCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


class EditableDateInput extends Component
{

    constructor(props) {

        super(props);
        
        this.state = {            
            
            date: new Date(this.props.date + ' GMT-05:00'),
            //dateString: this.props.date,
            
            selectedDate: new Date(this.props.date + ' GMT-05:00'),
            editMode: false,
            
            
            
            

		}            
    }

    changeEditMode = () => {
        this.setState( { editMode: !this.state.editMode } );
        this.setState( { selectedDate: this.state.date } );
    }

    updateComponentValue = () => {
 
        


        this.setState({ editMode: false });

        if (this.state.date != this.state.selectedDate) {
            
            this.setState({ date: this.state.selectedDate });

            setTimeout(() => {
                
                this.props.onUpdate( this.state.date );
    
            },200);
        }

        
        

        
    }

    handleChange = (date) => {
       
        this.setState( { selectedDate: date } );
    }

    renderEditView = () => {
        return <div>

            {/*
                <DatePicker
                        selected={ this.state.date }
                        onChange={ this.handleChange }
                        name="gigDate"
                        dateFormat="yyyy-MM-dd"
                        isClearable
                        showYearDropdown
                        scrollableMonthYearDropdown
                />*/}
                <DatePicker 
                    selected={ this.state.selectedDate }
                    onChange={ this.handleChange }
                    name="gigDate"
                    className="date-input"
                    dateFormat="yyyy-MM-dd"
                    
                    showYearDropdown
                    scrollableMonthYearDropdown
                />
                
                <Button 
                    className="btn btn-sm laff-green d-inline no-border"  
                    onClick={ this.updateComponentValue }
                    style={{marginLeft:'2px', marginBottom:'3px'}}
                    
                >
                    <FontAwesomeIcon 
                        icon={faCheckCircle} size="1x" 
                        style={{verticalAlign:'0.125em'}}
                        data-toggle="tooltip" 
                        title="Down"
                        transform="down-4"
                                                
                    /> 
                </Button>
                <Button 
                    className="btn btn-sm laff-red d-inline no-border" 
                    onClick={ this.changeEditMode }
                    style={{marginLeft:'2px', marginBottom:'3px'}}
                >
                    <FontAwesomeIcon 
                        icon={faTimesCircle} size="1x" 
                        style={{verticalAlign:'0.125em'}}
                        data-toggle="tooltip" 
                        title="Down"
                        transform="down-4"
                                                
                    /> 
                </Button>
            

            
           
            

        </div>
        
    }

    renderDefaultView = () => {
        return <div onClick={ this.changeEditMode }>
            { format(this.state.date, 'yyyy-MM-dd') }
        </div>
    }
    


    render () {
        return this.state.editMode ?
                    this.renderEditView()
                    :
                    this.renderDefaultView()
                
        ;
    }

}

export default EditableDateInput;



