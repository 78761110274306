import React from 'react'

function Footer() {

    return (

        <div className="footer fixed-bottom" >
            <div className="container-fluid">
                &copy; Copyright {new Date().getFullYear()} Laffnet.net
            </div>            
        </div>
    )

}

export default Footer;
