import React from 'react'

import NavBar from '../layout/NavBar';
import Footer from '../layout/Footer';

function NotFound() {

    return (
        <div>
            <NavBar />

            <div className="text-center" style={{marginTop: '50px', marginBottom: '50px'}}>
                <h1>404</h1>
                <h2>Page Not Found.</h2>
            </div>
            
            <Footer />
        </div>
    );

}


export default NotFound;