import React from 'react'
//import { defaultCipherList } from 'constants'


function Profile() {

    return (
        <div>
            <h1>Profile</h1>
            <p>This is the Profile</p>
        </div>
    )

}

export default Profile;
