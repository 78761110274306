import React, {Component} from 'react';
import { Link } from 'react-router-dom';

class Gig extends Component 
{
    constructor(props) {

        super(props);
        this.state = {

            showTitle: props.details.show_title,
            venue: props.details.venue,
            showDate: props.details.show_date,
            startTime: props.details.start_time,
            streetAddess: props.details.venue_addr_num + ' ' + props.details.venue_addr_street,
            city: props.details.venue_addr_city,
            prov: props.details.venue_addr_prov,
            link: props.details.link,
            buttonText: props.details.button_text
        }
        
    }

    componentDidUpdate(prevProps) {

    }


    render() {

        return (

            

            <div style={{padding: '30px 0 30px 0', borderBottom:'3px solid #fff', fontSize:'30px'}}>

                <div>{this.state.showDate} - {this.state.startTime} - {this.state.showTitle} at {this.state.venue}</div>
                <div>{this.state.streetAddess}</div>
                
                <Link to={{ pathname: this.state.link }} target="_blank" ><button className="btn btn-primary">{this.state.buttonText}</button></Link>
            </div>

            
            
        );

    }

}

const linkStyle = {
    color: '#fff',
    textDecoration: 'none'
}

export default Gig;