import React, {Component} from 'react';
import { Button, Input, Table , Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import EditableInput from '../ui/EditableInput';
import EditableContactSelect from '../ui/EditableContactSelect';

import SelectContact from '../contacts/SelectContact'

import DatePicker from "react-datepicker";
import { format } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";

import GigDate from './GigDate';

import axios from 'axios';

import { faClipboard, faTasks } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import  Bootbox  from  'bootbox-react';

class CardDetails extends Component
{
    constructor(props) {

        super(props);
        
        this.state = {            
            card_id: '',
            card: null,
            stageList: null,
            taskList: null,
            dateList: null,
            noteList: null,
            eventList: null,

            showFollowUpAction: false,
            showGigNotes: false,
            
            showNoteAlert: false,
            showDetails: false,
            showAddSpot: false,

            newSpotDate: new Date(),
            newSpotArriveTime: new Date(),
            newSpotSetTime: new Date(),
            
            followUpDate: null
        }            
        
        
    }
    
    componentDidUpdate(prevProps) {

        //console.log("updating 1");

        if(prevProps.card !== this.props.card) {

            if (this.props.card) {

                // get the stage list
                //console.log("Getting the stage list");
                axios({
                    method: 'post',
                    url: 'https://laffnetdev.website/api/funnel/index.php',
                    headers: { 
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept': 'application/json' 
                    },
                    data: {
                        action: 'GET_STAGE_LIST',
                    }
                })
                .then(result => {
                    //console.log(result.data.list);   
                    //console.log("Got the stage list");           
                    this.setState({stageList: result.data.list});
                })
                .catch(error => {
                    console.log("ERROR: "  + JSON.stringify(error) );
                });

                //console.log("Getting the card info");
                // get the card info
                this.getCardInfo();
                            
                this.setState({card_id: this.props.card});
                this.setState({showDetails:false});
                
            }            
        }
    }

    getCardInfo = () => {
        axios({
            method: 'post',
            url: 'https://laffnetdev.website/api/funnel/index.php',
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json' 
            },
            data: {
                action: 'GET_CARD_DETAILS',
                cardID: this.props.card,
            }
        })
        .then(result => {
            console.log("Blah");
            console.log(result.data.card.card_info);

            //console.log(result.data.card.gig_tasks);
            //console.log(result.data.card.gig_dates);
            this.setState({card: result.data.card.card_info});
            this.setState({taskList: result.data.card.gig_tasks});
            this.setState({dateList: result.data.card.gig_dates});
            this.setState({noteList: result.data.card.gig_notes});
            this.setState({eventList: result.data.card.gig_events});
            
            

            setTimeout(() => {
                
                if (this.state.card["stage_id"] == "2") {
                    if (this.state.card["revenue"] == "0.00") {
                        alert("Please enter $ amount.");
                    } else if (this.state.card["nextDate"] == "0000-00-00" || this.state.card["nextDate"] == "") {
                        alert("Please enter a date.");
                    }
                }

                
            },200);
            
        })
        .catch(error => {
            console.log("ERROR: "  + JSON.stringify(error) );
        });
    }

    closeCardDetails = () => {
        this.props.onClose();
        this.setState({card: null});
    }

    changeStage = (stage_id) => {
        
        var new_stage_id = stage_id;
        var new_stage_name = '';

        // find the stage name for the selected id

        for (var i = 0; i < this.state.stageList.length; i++)
        {
            if ( this.state.stageList[i].row_id == new_stage_id)
            {
                new_stage_name = this.state.stageList[i].stage_name;
                break;
            }
        }


        const temp_card = this.state.card;

        temp_card.stage_id = new_stage_id;
        temp_card.stage_name = new_stage_name;
        this.setState( { card: temp_card } );


        const card_tobe_moved = 'card-' + this.state.card.row_id;
        const stage_target = 'board-' + new_stage_id;

        this.props.moveCard(stage_target, card_tobe_moved);
        this.props.onReorderCards();

        setTimeout(() => {
            this.getCardInfo();
        },500);
        
    }

    updateCardInfo = (field_name, new_val) => {

        if (
                field_name == "revenue" || 
                field_name == "start_date" ||
                field_name == "roster_position" ||
                field_name == "ticket_price" ||
                field_name == "contact_id"
                
            ) {

            // update the card
            axios({
                method: 'post',
                url: 'https://laffnetdev.website/api/funnel/index.php',
                headers: { 
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': 'application/json' 
                },
                data: {
                    action: 'UPDATE_CARD_INFO_BY_FIELD',
                    cardID: this.state.card_id,
                    fieldName: field_name,
                    newValue: new_val,
                }
            })
            .then(result => {
                
                console.log(result.data);
              

                if ( field_name == "contact_id" ) {
                    this.getCardInfo();
                }
                
                

            })
            .catch(error => {
                console.log("ERROR: "  + JSON.stringify(error) );
            });

            
            
        } else {
            console.log(field_name + " " + new_val);
        }

        
        
    }



    /************************* */
    handleFocusChange = () => {


    }

    setStartDate = () => {

    }

    setEndDate = () => {

    }
    /******************************* */
    
     

    handleFollowUpChange = (date) => {
       
        this.setState( { followUpDate: date } );
    }

    toggleFollowUp = () => {

        this.setState( {showFollowUpAction: !this.state.showFollowUpAction} );
        this.setState( {followUpDate: null} );
    }

    addFollowUp = () => {
        
        const actionName = document.getElementById("follow-up-action-name").value.trim();
        const actionDate = document.getElementById("follow-up-action-date").value.trim();

        //console.log("Save follow up " + actionName + " " + actionDate);

        var self = this;

        //console.log("Save follow up " + self.state.card_id + " " + self.state.card.contact_id);

        // insert a date
        
        axios({
            method: 'post',
            url: 'https://laffnetdev.website/api/funnel/index.php',
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json' 
            },
            data: {
                action: 'INSERT_FOLLOW_UP_DATE',
                gigID: self.state.card_id,
                contactID: self.state.card.contact_id,
                eventName: actionName,
                eventDate: actionDate,
            }
        })
        .then(result => {
            
            console.log(result.data);
            self.setState( {showFollowUpAction: false} );

            self.getCardInfo();
    
        })
        .catch(error => {
            console.log("ERROR: "  + JSON.stringify(error) );
        });
        

        
    }

    toggleDetails = () => {
        this.setState( {showDetails: !this.state.showDetails} );
    }
    

    toggleGigNotes = () => {
        this.setState( {showGigNotes: !this.state.showGigNotes} );
    }

    toggleAddSpots = () => {
        this.setState( {showAddSpot: !this.state.showAddSpot} );
    }

    addNewNote = () => {
        const note = document.getElementById("new-gig-note").value.trim();
        const card_id = this.state.card_id;

        var self = this;

        if (note == "")
        {
            this.setState( {showNoteAlert: true} );
            return false;

        }

        // insert a note
        axios({
            method: 'post',
            url: 'https://laffnetdev.website/api/funnel/index.php',
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json' 
            },
            data: {
                action: 'INSERT_GIG_NOTE',
                gigID: card_id,
                note: note,
            }
        })
        .then(result => {
            
            console.log(result.data);
            self.setState( {showGigNotes: false} );
    
            self.getCardInfo();
        })
        .catch(error => {
            console.log("ERROR: "  + JSON.stringify(error) );
        });

    }

    handleNewSpotDateChange = (date) => {
        this.setState( {newSpotDate: date} );
    }
    
    handleNewSpotArriveTimeChange = (time) => {
        this.setState( {newSpotArriveTime: time} );
    }
                                
    handleNewSpotSetTimeChange = (time) => {
        this.setState( {newSpotSetTime: time} );
    }

    addNewSpot = () => {

        var self = this;
        axios({
            method: 'post',
            url: 'https://laffnetdev.website/api/funnel/index.php',
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json' 
            },
            data: {
                action: 'INSERT_NEW_SPOT',
                gigID: this.state.card_id,
                date: this.state.newSpotDate,
                arriveTime: this.state.newSpotArriveTime,
                setTime: this.state.newSpotSetTime,
            }
        })
        .then(result => {
            
            console.log(result.data.query);
            self.toggleAddSpots();

            self.getCardInfo();
    
        })
        .catch(error => {
            console.log("ERROR: "  + JSON.stringify(error) );
        });


    }


    render() {

        var modalBody = '';
        var tasks = '';
        var stages = '';
        var dates = 'No Dates Entered';
        var notes = 'No Notes Entered';
        var events = 'No Follow Up Actions Created';

        if (this.state.eventList) 
        {
            events = this.state.eventList
                .map((item,i)=> (

                    <div className="row mb-2" key={item.row_id}>
                        <div className="col-lg-4">{item.event_date}</div>
                        <div className="col-lg-8">{item.event_name}</div>
                    </div>

                ));
        }
        

        

        if (this.state.noteList)
        {
            notes = this.state.noteList
                .map((item,i)=> (

                    <div className="row mb-2" key={item.row_id}>
                        <div className="col-lg-4">{item.created}</div>
                        <div className="col-lg-8">{item.notes}</div>
                    </div>

                ));
        }

        if (this.state.taskList)
        {
            tasks = this.state.taskList
                .map((item,i)=> (

                    <div className="text-center" key={item.row_id} style={{marginBottom:'2px'}}>                        
                        <button className={"btn btn-sm " + (item.act_enabled=='1'?"laff-green":"laff-gray" )} style={{width:'140px'}}>{item.name}</button>                        
                    </div>
                ));
        }

        if (this.state.dateList)
        {
            
            dates = this.state.dateList
                .map((item,i)=> (

                    // use GigDate here 
                    // GigDate will display there 3 columns
                    // GigDate should use Editable fields

                    /*
                    <div className="row">                                   
                        <div className="col-4">{item.date}</div>
                        <div className="col-4">{item.arrive_time}</div>
                        <div className="col-4">{item.set_time}</div>
                    </div>
                    */

                    <GigDate key={item.row_id} dateItem={item}/>


                ));
        }
        

        if (this.state.stageList)
        {           

            stages = this.state.stageList
                .map((stage,i)=> (
                    <div key={stage.row_id} className="col-4 col-lg-2 text-center" style={{fontSize:'12px', paddingRight:'20px', textAlign:'center', marginBottom:'25px'}}>

                        <div style={{marginBottom:'10px'}}>{stage.stage_name}</div>
                        { (this.state.card && (this.state.card.stage_id == stage.row_id)) ? <span style={dotActiveBorder}><span style={dotActive}> &nbsp; &nbsp; &nbsp; </span></span> : <span style={dotBorder}><span style={dot} onClick={ () => this.changeStage(stage.row_id) }> &nbsp; &nbsp; &nbsp; </span></span>}
                        
                        

                                           
                        
                    </div>
                ));
        }
        

        if (this.state.card)
        {           
            
            modalBody = <div className="container-fluid" style={{fontSize:'14px'}}>

                            <div className="row" style={{marginBottom:'10px'}}>
                                <div className="col-3">
                                    <div style={{fontWeight:'bold', fontSize:'20px'}}>{this.state.card.stage_name}</div>
                                </div>

                                <div className="col-7">
                                    <div className="d-inline" style={{paddingLeft:'5px'}}>
                                        <button className="btn btn-sm laff-purple"  onClick={this.toggleFollowUp}>
                                            <FontAwesomeIcon 
                                                icon={faTasks} size="1x" 
                                                style={{cursor:'pointer', verticalAlign:'0.125em'}}
                                                data-toggle="tooltip" 
                                                title="Tasks"
                                                transform="down-4"
                                                
                                            />
                                            <span style={{paddingLeft:'5px'}}>Set Follow Up Actions</span>
                                        </button>
                                    </div>

                                    <div className="d-inline" style={{paddingLeft:'5px'}}>
                                        <button className="btn btn-sm laff-purple"  onClick={this.toggleGigNotes}>
                                            <FontAwesomeIcon 
                                                icon={faClipboard} size="1x"
                                                style={{cursor:'pointer', verticalAlign:'0.125em'}}
                                                data-toggle="tooltip" 
                                                title="Notes"
                                                transform="down-4"
                                                
                                            />
                                            <span style={{paddingLeft:'5px'}}>View/Add Gig Notes</span>
                                        </button>
                                    </div>
                                </div>

                                <div className="col-2 text-right">
                                    <Button className="laff-blue rounded-circle" size="sm" style={{borderColor:'#fff'}} onClick={this.closeCardDetails} aria-label="Close"><span>&nbsp;&times;&nbsp;</span></Button>
                                </div>
                            </div>

                            {/*
                            <div>
                                <SelectContact />
                            </div>
                            */}
                            <div className="row">

                                <div className="col-sm-2">
                                    <div style={{paddingRight:'20px'}}>{this.state.card.picture ? <img style={imageStyle} src={'./img/contacts/'+ this.state.card.picture} /> : <img style={imageStyle} src={'./img/contacts/portrait-placeholder.jpg'} />}</div>
                                </div>
                                <div className="col-sm-6">
                                    {/* Contact Name */}
                                    <EditableContactSelect 
                                        text={this.state.card.contact}
                                        value={this.state.card.contact_id}
                                        onUpdate={this.updateCardInfo}
                                        inputWidth='300px' 
                                        fieldName='contact_id'                                               
                                    />
                                    {this.state.card.company}
                                </div>

                                
                                <div className="col-sm-4 text-right">
                                    {/* Revenue */}
                                    <EditableInput 
                                        text={this.state.card.revenue}
                                        onUpdate={this.updateCardInfo}
                                        inputWidth='100px'
                                        prefix='$'
                                        fieldName='revenue'
                                        pencilID='pencil-edit-1'
                                    />

                                    {/* Next Date */}
                                    <div style={this.state.card.nextDate=='0000-00-00'?{display:'none'}:{display:'block'}}>

                                        <div style={{border: '1px solid #ccc', borderRadius:'3px', padding:'10px'}}>

                                            <div className="row">
                                                <div className="col-6">Next Date:</div>
                                                <div className="col-6">{this.state.card.nextDate}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6">Arrive at:</div>
                                                <div className="col-6">{this.state.card.nextArriveTime}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6">Set time:</div>
                                                <div className="col-6">{this.state.card.nextSetTime}</div>
                                            </div>

                                        </div>

                                    </div>
                                    
                                    
                                
                                    

                                    <br />

                                    


                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-8">

                                    <div className="row">
                                        <div className="col-6">Gig Address</div>
                                        <div className="col-6"></div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6 col-lg-4">Roster Position</div>
                                        <div className="col-6 col-lg-3">
                                            <EditableInput 
                                                text={this.state.card.roster_position}
                                                onUpdate={this.updateCardInfo}
                                                inputWidth='120px'
                                                fieldName='roster_position'
                                                pencilID='pencil-edit-2'
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6 col-lg-4">Ticket Price</div>
                                        <div className="col-6 col-lg-3">
                                            <EditableInput 
                                                text={this.state.card.ticket_price}
                                                onUpdate={this.updateCardInfo}
                                                inputWidth='80px'
                                                prefix='$'
                                                fieldName='ticket_price'
                                                pencilID='pencil-edit-3'
                                            />
                                        </div>
                                    </div>
                                                                       
                                    <br /><br />
                                    
                                    <button className="btn btn-sm btn-secondary" onClick={this.toggleDetails}>Details { this.state.showDetails ? '-' : '+' }</button>{' '}
                                    <button className="btn btn-sm btn-secondary" onClick={this.toggleAddSpots}>Add Spot</button>

                                    <div style={ this.state.showDetails ? {display:'block'} : {display:'none'} }>
                                        <div style={{padding:'10px', border:'1px #ccc solid'}}>

                                            {/* Gig Dates */}
                                            <div className="row">    
                                        
                                                <div className="col-4"><strong><u>Date</u></strong></div>
                                                <div className="col-4"><strong><u>Arrive at</u></strong></div>
                                                <div className="col-4"><strong><u>Set Time</u></strong></div>
                                            </div>
                                        
                                            {dates}

                                            <br />
                                            <strong><u>Notes</u></strong><br />
                                            {notes}


                                            <br />
                                            <strong><u>Follow Up Actions</u></strong><br />
                                            {events}
                                        
                                        </div>
                                    </div>
                                    
                                                                

                                </div>



                                <div className="col-sm-4">                             
                                    
                                    

                                    <div className="row">
                                        <div className="col-sm-12">
                                            
                                            {tasks}
                                            
                                        </div>
                                        
                                    </div>

                                    
                                    

                                    
                                </div>
                            </div>

                            <br />
                            


                            <hr />        
                            <div className="row">
                                {stages}
                            </div>        
                                
                            
                            

                        </div>;
        }

        
        
        
        


        return (
            <div>

                    <Modal 
                        isOpen={this.props.show} 
                         
                        size="lg"
                    >

                        <ModalBody className="laff-modal">


                            
                            {modalBody}

                            


                            {/* Follow up actions */} 
                            <Modal isOpen={this.state.showFollowUpAction} >
                                
                                <ModalBody className="laff-modal" style={{fontSize:'14px'}}>
                                    
                                    <h3>Create a Follow Up Action</h3>
                                    <br />
                                    <Input 
                                        className="" 
                                        type="text" 
                                        name="follow-up-action-name" 
                                        id="follow-up-action-name" 
                                        bsSize="sm" 
                                        style={{}} 
                                        placeholder="Follow Up Action" 
                                        defaultValue={this.state.text}
                                        onChange={this.handleChange}
                                    />
                                    <br />
                                    <DatePicker 
                                        selected={ this.state.followUpDate }
                                        onChange={ this.handleFollowUpChange }
                                        name="follow-up-action-date"
                                        id="follow-up-action-date"
                                        className="date-input"
                                        
                                        dateFormat="yyyy-MM-dd"
                                        placeholderText="Action Date"
                                        showYearDropdown
                                        scrollableMonthYearDropdown
                                    />


                                    <br />
                                    <div className="text-right">
                                        <Button className="btn-gray-sm" onClick={this.toggleFollowUp}>Close</Button>{' '}
                                        <Button className="btn-laff-sm" onClick={this.addFollowUp}>Save</Button>
                                    </div>
                                

                                </ModalBody>
                                
                            </Modal>



                            {/* Gig Notes */} 
                            <Modal isOpen={this.state.showGigNotes}>
                                
                                <ModalBody className="laff-modal">

                                    <h3>Add a Gig Note</h3>
                                    <br />
                                    <Input type="textarea" name="new-gig-note" id="new-gig-note" />
                                    <br />
                                    <div className="text-right">
                                        <Button className="btn-gray-sm" onClick={this.toggleGigNotes}>Close</Button>{' '}
                                        <Button className="btn-laff-sm" onClick={this.addNewNote}>Save</Button>
                                    </div>
                                    

                                </ModalBody>
                                
                                    
                                <Bootbox 
                                    show={this.state.showNoteAlert} 
                                    type={"alert"}  
                                    message={"Note cannot be empty."}  
                                    onClose={ ()=>{ this.setState( {showNoteAlert: false} ); } }
                                                                        
                                />
                            </Modal>


            

                            {/* Add Spot */} 
                            <Modal isOpen={this.state.showAddSpot}>
                                
                                <ModalBody className="laff-modal">

                                    <h3>Add a Spot</h3>
                                    <br />
                                    <div style={{fontSize:'14px'}}>

                                        <div className='row'>
                                            <div className="col-3">Date:</div>
                                            <div className="col-3">
                                                <DatePicker 
                                                    selected={ this.state.newSpotDate }
                                                    onChange={ this.handleNewSpotDateChange }
                                                    name="gigDate"
                                                    className="date-input"
                                                    dateFormat="yyyy-MM-dd"
                                                    
                                                    showYearDropdown
                                                    scrollableMonthYearDropdown
                                                />
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className="col-3">Arrive at:</div>
                                            <div className="col-3">
                                                <DatePicker 
                                            
                                                    selected={ this.state.newSpotArriveTime }
                                                    onChange={ this.handleNewSpotArriveTimeChange }
                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    timeIntervals={15}
                                                    timeCaption="Time"
                                                    dateFormat="h:mm aa"
                                                    className="date-input"
                                                />
                                            </div>

                                        </div>

                                        <div className='row'>
                                            <div className="col-3">Set Time:</div>
                                            <div className="col-3">
                                                <DatePicker 
                                            
                                                    selected={ this.state.newSpotSetTime }
                                                    onChange={ this.handleNewSpotSetTimeChange }
                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    timeIntervals={15}
                                                    timeCaption="Time"
                                                    dateFormat="h:mm aa"
                                                    className="date-input"
                                                />
                                            </div>
                                        </div>
                                    
                                    
                                    </div>

                                    <br />
                                    <div className="text-right">
                                        <Button className="btn-gray-sm" onClick={this.toggleAddSpots}>Close</Button>{' '}
                                        <Button className="btn-laff-sm" onClick={this.addNewSpot}>Save</Button>
                                    </div>
                                    

                                </ModalBody>
                                
                                    
                                <Bootbox 
                                    show={this.state.showNoteAlert} 
                                    type={"alert"}  
                                    message={"Note cannot be empty."}  
                                    onClose={ ()=>{ this.setState( {showNoteAlert: false} ); } }
                                                                        
                                />
                            </Modal>

                        </ModalBody>

                    </Modal>

            </div>

        );

    }


}

const imageStyle = {
    width:'100px', 
    height:'auto', 
    borderRadius:'50%',
    /*paddingLeft:'20px',
    paddingRight:'20px'*/
}

const dropboxIcon = {
    paddingRight: 'calc(1.5em + .75rem)',
	backgroundImage: 'url("./img/icons/hammer-solid.svg")',
	backgroundRepeat: 'no-repeat',
	backgroundPosition: 'center right calc(.375em + .1875rem)',
	backgroundSize: 'calc(.75em + .375rem) calc(.75em + .375rem)',
}


const dot = {
    height: '25px',
    width: '25px',
    backgroundColor: '#ebecf0',
    borderRadius: '50%',
    display: 'inlineBlock',
    cursor: 'pointer',
}

const dotBorder = {
    padding:'5px', 
    backgroundColor:'#fff', 
    border:'1px solid #ebecf0', 
    borderRadius:'50%'
} 



const dotActive = {    
    height: '25px',
    width: '25px',
    backgroundColor: '#2BCB0A',
    borderRadius: '50%',
    display: 'inlineBlock',
    cursor: 'pointer',
}

const dotActiveBorder = {
    padding:'5px', 
    backgroundColor:'#fff', 
    border:'1px solid #2BCB0A', 
    borderRadius:'50%'
}




export default CardDetails;