import React from 'react';
import ReactDOM from 'react-dom';
import GoogleContacts from 'react-google-contacts';
 


const clientId = '735905868624-3bu5piopoba2raqi3a3umdl6p1qmnt41.apps.googleusercontent.com'

const success = response => {
  console.log(response) // eslint-disable-line

  
  for (var i = 0; i < response.length; i++)
  {
    console.log(response[i].title + " " + response[i].email);
  }

}

const error = response => {
  console.error(response) // eslint-disable-line
}

const loading = () => {
  console.log('loading') // eslint-disable-line
}

const loggedOut = () => {
  console.log('logged out') // eslint-disable-line
}

export default () => (
    <div>
    {/*
    <GoogleContacts onRequest={loading} onSuccess={success} onFailure={error} clientId={clientId} />

    <GoogleContacts theme="dark" onRequest={loading} onSuccess={success} onFailure={error} clientId={clientId} disabled />
    <br />
    <br />
    <GoogleContacts
      theme="dark"
      onRequest={loading}
      onSuccess={success}
      onFailure={error}
      clientId={clientId}
      render={renderProps => <button onClick={renderProps.onClick}>This is my custom Google button</button>}
    />
    */}
    
    <br />
    <br />
    <GoogleContacts theme="dark" onRequest={loading} onSuccess={success} onFailure={error} clientId={clientId} />
    <br />
    <br />
    
  </div>
)


/*
const responseCallback = (response) => {
  console.log(response);
}
 




ReactDOM.render(
  <GoogleContacts
    clientId="735905868624-3bu5piopoba2raqi3a3umdl6p1qmnt41.apps.googleusercontent.com"
    buttonText="Import"
    onSuccess={this.props.responseCallback}
    onFailure={this.props.responseCallback}
  />,
  document.getElementById('googleButton')
);
*/