import React, {Component} from 'react';
import { Button, Input, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';


import axios from 'axios';



import { faArrowCircleUp, faArrowCircleDown, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class ActivityList extends Component
{
    constructor(props) {

        super(props);
        
        this.state = {            
            
            list: null,
            stage_id:'',
            stage: null,

		}            
    }

    componentDidUpdate(prevProps) {

        if(prevProps.stage !== this.props.stage) {

            //this.setState({stage: 5});

        
            this.setState({stage_id: this.props.stage});


            // get the stage
            axios({
                method: 'post',
                url: 'https://laffnetdev.website/api/funnel/index.php',
                headers: { 
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': 'application/json' 
                },
                data: {
                    action: 'GET_STAGE',
                    stage_id: this.props.stage
                }
            })
            .then(result => {
                //console.log(result.data.stage);              
                this.setState({stage: result.data.stage});
            })
            .catch(error => {
                console.log("ERROR: "  + JSON.stringify(error) );
            });

            // get activity list
            this.loadActivities();

        }
        

    }

    loadActivities = () => {
        axios({
            method: 'post',
            url: 'https://laffnetdev.website/api/funnel/index.php',
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json' 
            },
            data: {
                action: 'GET_ACTIVITY_LIST',
                stage_id: this.props.stage
            }
        })
        .then(result => {
            //console.log(result.data.list);              
            this.setState({list: result.data.list});
        })
        .catch(error => {
            console.log("ERROR: "  + JSON.stringify(error) );
        });
    }


    closeActivity = () => {

        this.props.onClose();
        this.setState({stage: null});
    }

    moveUp = (index) => {
        //alert(index);

        const tempList = [ ...this.state.list ];
        
        const index_1 = index;
        const index_2 = index-1;

        const tempActivity = tempList[index_1];
        tempList[index_1] = tempList[index_2];
        tempList[index_2] = tempActivity;

        this.setState({list: tempList});
        
        setTimeout(() => {
            this.saveActivityOrder();
        },100);
        

    }

    moveDown = (index) => {
        //alert(index);

        const tempList = [ ...this.state.list ];
        
        const index_1 = index;
        const index_2 = index+1;

        const tempActivity = tempList[index_1];
        tempList[index_1] = tempList[index_2];
        tempList[index_2] = tempActivity;

        this.setState({list: tempList});

        setTimeout(() => {
            this.saveActivityOrder();
        },100);
    }

    // save the activity order after re-ordering
    saveActivityOrder = () => {

        //console.log(this.state.list);
        const tempList = [ ...this.state.list ];

        axios({
            method: 'post',
            url: 'https://laffnetdev.website/api/funnel/index.php',
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json' 
			},
			data: {
                action: 'SAVE_ACTIVITY_ORDER',
                list: tempList,

			}
        })
        .then(result => {
            
            //console.log(result.data);
			
        })
        .catch(error => {
            console.log("ERROR: "  + JSON.stringify(error) ) 
        });

    }


    
    createNewActivity = () => {
        
        
        axios({
            method: 'post',
            url: 'https://laffnetdev.website/api/funnel/index.php',
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json' 
            },
            data: {
                action: 'CREATE_NEW_ACTIVITY',
                stage_id: this.state.stage_id,
            }
        })
        .then(result => {
            
            // reload activity list
            this.loadActivities();
            
            
        })
        .catch(error => {
            console.log("ERROR: "  + JSON.stringify(error) ) 
        });
        
    }

    

    render() {

        var modalBody = '';

        var tasks = '';

        if (this.state.list)
        {
            tasks = this.state.list
                .map((item,i)=> (
                    <div key={item.row_id} className="row">
                        <div className="col-7">
                            <div style={{backgroundColor:'#FFF', color:'#000', marginBottom:'10px', borderRadius:'3px', padding:'4px 8px'}}>
                                {item.name}  
                            </div>
                        </div>
                        <div className="col-5">
                            <div className="d-inline" style={{paddingRight:'5px'}}>

                                <Button 
                                    className={"btn btn-sm no-border " + (i > 0 ? "laff-purple":"laff-gray")}  // Checking if this is the first item 
                                    onClick={i > 0 ? () => this.moveUp(i): undefined } 
                                    style={ i > 0 ? {cursor:'pointer'} : {cursor:'not-allowed'}}
                                    disabled={i > 0 ? '':'disabled'}
                                > 
                                    <FontAwesomeIcon 
                                        icon={faArrowCircleUp} size="1x" 
                                        style={{verticalAlign:'0.125em'}}
                                        data-toggle="tooltip" 
                                        title="Up"
                                        transform="down-4"
                                        
                                    />
                                </Button>
                                                            
                            </div>
                            <div className="d-inline" style={{paddingRight:'5px'}}>
                                <Button 
                                    className={"btn btn-sm no-border " + (i < (this.state.list.length-1) ? "laff-purple":"laff-gray")}   // Checking if this is the last item  
                                    onClick={i < (this.state.list.length-1) ? () => this.moveDown(i): undefined } 
                                    style={ i < (this.state.list.length-1) ? {cursor:'pointer'} : {cursor:'not-allowed'}}
                                    disabled={ i < (this.state.list.length-1) ? '':'disabled'}
                                >
                                    <FontAwesomeIcon 
                                        icon={faArrowCircleDown} size="1x" 
                                        style={{verticalAlign:'0.125em'}}
                                        data-toggle="tooltip" 
                                        title="Down"
                                        transform="down-4"
                                        
                                        
                                    /> 
                                </Button>
                            </div>
                            <div className="d-inline" style={{paddingRight:'5px'}}>
                                <Button className="btn btn-sm laff-red"  >
                                    <FontAwesomeIcon 
                                        icon={faTrashAlt} size="1x" 
                                        style={{cursor:'pointer', verticalAlign:'0.125em'}}
                                        data-toggle="tooltip" 
                                        title="Delete"
                                        transform="down-4"
                                        
                                    />  {/* onClick={() => this.changeView('list')} */}
                                </Button>
                                
                            
                            </div>
                        </div>
                                            
                    </div>
                ));
        }


        if (this.state.stage)
        {           

            modalBody = <div className="container-fluid">

                            <div className="row" style={{marginBottom:'10px'}}>
                                <div className="col-9">
                                    <div style={{fontWeight:'bold', fontSize:'20px'}}>{this.state.stage.stage_name}</div>
                                </div>
                                <div className="col-3 text-right">
                                    <Button className="laff-blue rounded-circle" size="sm" style={{borderColor:'#fff'}} onClick={this.closeActivity} aria-label="Close"><span>&nbsp;&times;&nbsp;</span></Button>
                                </div>
                            </div>

                            <div style={{marginBottom:'10px'}}>
                                <Button className="btn-laff-sm" onClick={ () => this.createNewActivity() }> + </Button>
                            </div>

                            {tasks}


                        </div>;
        }

        return (
            <div>

                    <Modal 
                        isOpen={this.props.show} 
                         
                        size="md"
                    >

                        <ModalBody className="laff-blue">

                            
                            
                            {modalBody}

                                                   
                            

                        </ModalBody>

                    </Modal>

            </div>

        );
    }
}

export default ActivityList;