import React, {Component} from 'react';
import { Link } from 'react-router-dom';

import axios from 'axios';

import NavBar from '../layout/NavBar';
import Footer from '../layout/Footer';
import SideNavBar from '../layout/SideNavBar';

class SavedTemplates extends Component {

    constructor(props) {

        super(props);
        
        this.state = {
            emailTemplates: [],
            
		}
            
    }


    componentDidMount() {

		// email templates
        axios({
            method: 'post',
            url: 'https://laffnetdev.website/api/emails/index.php',
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json' 
			},
			data: {
				action: 'GET_EMAIL_TEMPLATES'
			}
            
        })
        .then(result => {
			

			this.setState({emailTemplates: result.data.email_templates});
			console.log(this.state.emailTemplates.length);
        })
		.catch(error => console.log("ERROR: "  + JSON.stringify(error) ) );


		
    }

    render() {

        const templateList = this.state.emailTemplates.map((emailTemplate,i)=> (
            <div className="col-12 col-md-6" key={emailTemplate.id} >
                <div style={tileStyle}>
                    <div><strong>{ emailTemplate.template_name }</strong></div>
                    <div>Created on: { emailTemplate.created }</div>
                    <div>Updated on: { emailTemplate.updated }</div>
                    {/* <div>{ emailTemplate.id }</div> */}
                    
                    <Link to={`/edit-email-template/${emailTemplate.id}`} >EDIT</Link>
                    <br />
                    <Link to={`/select-email-contacts/${emailTemplate.id}`} >SEND EMAIL</Link>
                </div>                
            </div>
        ));


        return (
			<div>
				<NavBar />

                <SideNavBar />

                <div className="page-main-container">

                    <div className="container">

                        <h3>Saved Email Templates</h3>

                        <div className="row">
                            {templateList}
                        </div>

                    </div>

                </div>

				

                

				<Footer />

			</div>   
			
		);
    }


}



const tileStyle = {
    backgroundColor:'#ffffff',
    borderRadius:'3px',
    fontSize:'14px',
    padding:'10px',
    marginBottom:'30px'
}


export default SavedTemplates;