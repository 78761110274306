import React, { Component } from 'react'
import axios from 'axios'

class ContactListUpload extends Component {

    constructor(props) {

        super(props);
        
        this.state = {
            hasHeaderRow: true,
            delimiter: ',',
            selectedFile: 'null',
            selectedFileContent: '',
            dataRows:[],
        }

    }

    // triggered when a new file is selected
    onFileChange = (e) => {
        const file = e.target.files[0];
        
        
        const reader = new FileReader();
        //console.log(file);
        reader.onload = (e) =>
        {
            this.setState({ selectedFileContent: e.target.result});

            //console.log(e.target.result);
            let content = e.target.result;
            let rows = content.split('\r\n');

            

            let headerRow = rows[0].split(',');            // columns headers
            let dataRow = rows[1].split(',');              // first data row
            
            //console.log(headerRow);
            //console.log(dataRow);


            let dataSet = [];

            for (let i = 0; i < headerRow.length; i++)
            {
                dataSet.push({ col:headerRow[i], val:dataRow[i], sel:'' })
            }
            //console.log(dataSet);
            
            this.setState({ dataRows: dataSet});
            this.setState({ selectedFile: file});



        }
        reader.readAsText(file);

        
    }

    // triggered when upload button is clicked
    onClickUpload = (e) => {

        //console.log("Uploading file");
        // uploading the csv
        axios({
            method: 'post',
            url: 'https://laffnetdev.website/api/import/index.php',
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json' 
			},
			data: {
                action: 'UPLOAD_SELECTED_FILE',
                fileContent: this.state.selectedFileContent,
            
			}
            
        })
        .then(result => {

            //console.log( result.data.filename  );
            this.props.triggerDataRowUpdate( this.state.dataRows, result.data.filename );
            
        })
		.catch(error => console.log("ERROR: "  + JSON.stringify(error) ) );
    }

    render () {

        return (
            <div>
                
                <input style={{display:'none'}} type="file" name="file" onChange={this.onFileChange} ref={fileInput => this.fileInput = fileInput}/>
                <p>
                    <button className="btn btn-sm btn-laff-sm" onClick={() => this.fileInput.click()}>Choose File</button>
                </p>
                <p style={this.state.selectedFile=='null' ? {display:'none'}:{display:'block'}}>
                    {this.state.selectedFile.name} <button className="btn btn-sm btn-laff-sm" onClick={this.onClickUpload}>Upload File</button>
                </p>
                
                

            </div>
        )

    }

}

export default ContactListUpload