import React, {Component, useContext} from 'react';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter, TabContent, TabPane, Nav, NavItem, NavLink, Col, Row, Input, Label, FormGroup } from 'reactstrap';
import { ColorExtractor } from 'react-color-extractor';
import { faPencilAlt, faCoffee } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ColourPicker from './ColourPicker';
import Gig from './Gig';
import GigEdit from './GigEdit';

import NavBar from '../layout/NavBar';
import Footer from '../layout/Footer';
import SideNavBar from '../layout/SideNavBar';

import axios from 'axios';

import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css';
import { areIntervalsOverlapping } from 'date-fns';

import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg } from 'html-to-image';

import './WebsiteManagement.css';

class WebsiteManagement extends Component 
{
    constructor(props) {

        super(props);

        this.state = {

            fonts: [],
            selectedFontID: '',

            colorPalette: ['#000000', '#ffffff', '#ffffff', '#ffffff', '#ffffff'],
            textColorPalette1: ['#000000', '#ffffff', '#cccccc'],
            textColorPalette2: ['#000000', '#ffffff', '#cccccc'],

            extractedColors: [],
            showColorSelection: false,
            bgImageSelected: false,

            websiteBGImageGallery: [],
            websiteBGImageGalleryOpen: false,

            primaryColor: '',
            secondaryColor:'',
            accentColor:'',

            gigs: [],
            connectFormTitles: [],
            connectFromTitle1: '',
            connectFromTitle2: '',

            showBGSectionEdit: false,   // BG Edit

            opendedGig:'',
            showGigResponse: false,
            showSubscribeForm: false,
            showWebSettingEdit: false, // Settings
            activeSettingTab: '1',

            bgColor1: '#000000',
            bgColor2: '#ffffff',
            textColor1: '#ffffff',
            textColor2: '#000000',

            btn1TextColor: '#ffffff',
            btn1BgColor: '#000000',
            btn2TextColor: '#ffffff',
            btn2BgColor: '#000000',
            btn3TextColor: '#ffffff',
            btn3BgColor: '#000000',
            btn4TextColor: '#ffffff',
            btn4BgColor: '#000000',
            
            stageNameHovering: false,
            profilePicHovering: false,
            aboutSectionHovering: false,
            aboutPicHovering: false,
            gigSectionHovering: false,
            gigPicHovering: false,
            videoSectionHovering: false,

            showProfileSectionEdit: false,
            showStageNameSectionEdit: false,
            showAboutSectionEdit: false,
            showAboutPicSectionEdit: false,
            showGigSectionEdit: false,
            showGigPicSectionEdit: false,
            showVideoSectionEdit: false,

            activeProfileSectionTab: '1',
            activeStageNameSectionTab: '1',
            activeAboutSectionTab: '1',
            activeAboutPicSectionTab: '1',
            activeGigSectionTab: '1',
            activeGigPicSectionTab: '1',
            activeVideoSectionTab: '1',

            bodyFont: 'Arial, Helvetica, Sans-serif',
            titleFont: 'Arial, Helvetica, Sans-serif',

            aboutHeader: '',
            aboutText: '',
            gigHeader: '',
            videoHeader:'',

            videos: ['vGcOGYkttI4', '6oOLXG8DmO8', '6pGpwNLoSdw', 'EoG9GBlfrrQ'],

            activeSiteSettingTab: '1',
            showSiteSettings: false,

            bgImageUrl: "https://laffnetdev.website/img/website/BG_default.jpg", 
            bgImageUploaded: false,

            // cropper
            bg_base64: '',
            src: null,
            crop: {
                unit: '%',
                width: 30,
                aspect: 2 / 1
            },

            
        }

    }

    componentDidMount() {
        
        var self = this;
        
        // Getting website info
        axios({
            method: 'post',
            url: 'https://laffnetdev.website/api/website/index.php',
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json' 
			},
			data: {
				action: 'GET_WEB_CONFIG_INFO',
				// userID
			}
        })
        .then(result => {            
            console.log("*********************");
			console.log(result.data);
            console.log("*********************");
            
            this.setState({gigs: result.data.website_info.gigs}); // 

            if (result.data.website_info.header_bg == "") {
                this.setState( {showBGSectionEdit: true} );
            } else {
                this.setState({ bgImageUrl: 'https://laffnetdev.website/img/website/gallery/'+result.data.website_info.header_bg });
            }
			
            this.setState( {colorPalette: result.data.website_info.color_palette.split(",") } );

            
            this.setState( {slogan: result.data.website_info.slogan } );
            this.setState( {title: result.data.website_info.title } );
            this.setState( {firstName: result.data.website_info.firstname } );
            this.setState( {lastName: result.data.website_info.lastname } );

            this.setState( {email: result.data.website_info.email } );
            this.setState( {phone: result.data.website_info.phone } );


            this.setState( {facebook: result.data.website_info.facebook } );
            this.setState( {twitter: result.data.website_info.twitter } );
            this.setState( {linkedin: result.data.website_info.linkedin } );
            this.setState( {tiktok: result.data.website_info.tiktok } );
            this.setState( {instagram: result.data.website_info.instagram } );
            this.setState( {youtube: result.data.website_info.youtube } );
            /*
            this.setState( {profile_pic: 'https://laffnetdev.website/img/customers/' + result.data.website_info.profile_pic } );
            

            this.setState( {sloganSectionBgColor: result.data.website_info.slogan_bg } );
            this.setState( {sloganSectionTxtColor: result.data.website_info.slogan_text } );

            this.setState( {firstPageBgColor: result.data.website_info.firstpage_bg } );

            this.setState( {firstPageBtnColor: result.data.website_info.gigs_bg } );
            this.setState( {firstPageBtnTxtColor: result.data.website_info.gigs_text } );

            this.setState( {firstPageButton1: 'Book Now' } );
            this.setState( {firstPageButton2: 'Subscribe' } );
           
           
           
        
            this.setState( {gigSectionBgColor: result.data.website_info.gigs_bg } );
            this.setState( {gigSectionTxtColor: result.data.website_info.gigs_text } );  
            
            this.setState( {aboutSectionBgColor: result.data.website_info.about_bg } );
            this.setState( {aboutSectionTxtColor: result.data.website_info.about_text } );    
            
            this.setState( {videoSectionBgColor: result.data.website_info.video_bg } );
            this.setState( {videoSectionTxtColor: result.data.website_info.video_text } );
                      
            */
            
            this.setState( {aboutHeader: result.data.website_info.about_header } );
            
            this.setState( {aboutText: result.data.website_info.about_txt } );
            
            this.setState( {gigHeader: result.data.website_info.gig_header } );
            
            this.setState( {videoHeader: result.data.website_info.video_header } );

            


            this.setState( {bgColor1: result.data.website_info.bg_color_1 } );
            this.setState( {bgColor2: result.data.website_info.bg_color_2 } );

            this.setState( {textColor1: result.data.website_info.text_color_1 } );
            this.setState( {textColor2: result.data.website_info.text_color_2 } ); 

            this.setState( {selectedFontID: parseInt(result.data.website_info.font_id) } );



            

            var titles = [];
            titles.push("Got an event? Book " + this.state.firstName);
            titles.push("Bookers connect with " + this.state.firstName + " here!");
            titles.push("Got a date in mind? Book " + this.state.firstName);
            titles.push("Got a spot to fill? Book " + this.state.firstName);
            titles.push("Entertaining a group? Book " + this.state.firstName);
            titles.push("Corporate event? Book " + this.state.firstName);
            titles.push("Comedy Venue? Book " + this.state.firstName);
            titles.push("Wanna order some Funny? Book " + this.state.firstName);
            
            this.setState( { connectFormTitles: titles } ); 
            
            this.setState( { connectFromTitle1: this.state.connectFormTitles[ Math.floor(Math.random() * this.state.connectFormTitles.length) ] } );
            this.setState( { connectFromTitle2: this.state.connectFormTitles[ Math.floor(Math.random() * this.state.connectFormTitles.length) ] } );
           




            // Getting fonts
            axios({
                method: 'post',
                url: 'https://laffnetdev.website/api/website/index.php',
                headers: { 
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': 'application/json' 
                },
                data: {
                    action: 'GET_ALL_FONTS',
                    
                }
            })
            .then(result => {
                           
                //console.log("---- GETTING FONTS -----");
                //console.log(result.data);
                
                this.setState({fonts: result.data.fonts});
                setTimeout(function(){ 
                    self.assignFonts();
                }, 1000);
    
                
            })
            .catch(error => 
                console.log("ERROR: "  + JSON.stringify(error) ) 
            );
            
            
            
        })
        .catch(error => 
            console.log("ERROR: "  + JSON.stringify(error) ) 
        );

        window.addEventListener('scroll', this.listenToScroll)

               
    }




    listenToScroll = () => {

        
        let scroll = window.scrollY;
        //console.log(scroll)
        
        if ( scroll > 900) { 
            if ( document.getElementById("web-sticky-header") )           
                document.getElementById("web-sticky-header").style.display = "block";
        } else {
            if ( document.getElementById("web-sticky-header") )
                document.getElementById("web-sticky-header").style.display = "none";
        }

        
    }

    assignFonts = () => {
                    
        
        this.setState( {titleFont: this.state.fonts[ this.state.selectedFontID - 1 ].title_font + ',Arial, Helvetica'} );
        this.setState( {bodyFont: this.state.fonts[ this.state.selectedFontID - 1 ].body_font +  ',Arial, Helvetica'} );
        
        
    }

    onChangeFont = (fontID) => {
       
        var self = this;
        this.setState( {selectedFontID: fontID});

        setTimeout(function(){ 
            self.assignFonts();
        }, 500);

    }


    /* BG and text color */
    /************************************************/    

    onChangeBgColor1 = (newColor) => {
        
        this.setState({ bgColor1: newColor });

        /*
        this.setState({ sloganSectionBgColor: newColor });
        this.setState({ gigSectionBgColor: newColor });
        this.setState({ videoSectionBgColor: newColor });   
        */ 
        
    }

    onChangeBgColor2 = (newColor) => {

        this.setState({ bgColor2: newColor });

        /*
        this.setState({ firstPageBgColor: newColor });
        this.setState({ aboutSectionBgColor: newColor });
        */
        
    }

    onChangeTextColor1 = (newColor) => {

        this.setState({ textColor1: newColor });

        /*
        this.setState({ sloganSectionTxtColor: newColor });
        this.setState({ gigSectionTxtColor: newColor });
        this.setState({ videoSectionTxtColor: newColor });
        */
        
    }

    onChangeTextColor2 = (newColor) => {

        this.setState({ textColor2: newColor });

        /*
        this.setState({ aboutSectionTxtColor: newColor });    
        */    
    }

    onChangeBtn1TextColor = (newColor) => {
        this.setState({ btn1TextColor: newColor });
    }

    onChangeBtn1BgColor = (newColor) => {
        this.setState({ btn1BgColor: newColor });
    }

    onChangeBtn2TextColor = (newColor) => {
        this.setState({ btn2TextColor: newColor });
    }

    onChangeBtn2BgColor = (newColor) => {
        this.setState({ btn2BgColor: newColor });
    }

    onChangeBtn3TextColor = (newColor) => {
        this.setState({ btn3TextColor: newColor });
    }

    onChangeBtn3BgColor = (newColor) => {
        this.setState({ btn3BgColor: newColor });
    }

    onChangeBtn4TextColor = (newColor) => {
        this.setState({ btn4TextColor: newColor });
    }

    onChangeBtn4BgColor = (newColor) => {
        this.setState({ btn4BgColor: newColor });
    }
    /************************************************/




    /* Settings */
    /************************************************/
    /*
    openWebSettingEdit = (e) => {
        this.setState( {showWebSettingEdit: true} );
    }

    closeWebSettingEdit = (e) => {
        this.setState( {showWebSettingEdit: false} );
    }

    toggleSettingTab = (tab) => {
        if (this.state.activeSettingTab !== tab) {
            this.setState({
                activeSettingTab: tab
            });
        }
    }
    */


    

    toggleProfileSectionTab = (tab) => {
        if (this.state.activeProfileSectionTab !== tab) {
            this.setState({activeProfileSectionTab: tab});
        }
    }

    toggleStageNameSectionTab = (tab) => {
        if (this.state.activeStageNameSectionTab !== tab) {
            this.setState({activeStageNameSectionTab: tab});
        }
    }

    toggleAboutSectionTab = (tab) => {
        if (this.state.activeAboutSectionTab !== tab) {
            this.setState({activeAboutSectionTab: tab});
        }
    }


    toggleAboutPicSectionTab = (tab) => {
        if (this.state.activeAboutPicSectionTab !== tab) {
            this.setState({activeAboutPicSectionTab: tab});
        }
    }

    toggleGigSectionTab = (tab) => {
        if (this.state.activeGigSectionTab !== tab) {
            this.setState({activeGigSectionTab: tab});
        }
    }

    toggleGigPicSectionTab = (tab) => {
        if (this.state.activeGigPicSectionTab !== tab) {
            this.setState({activeGigPicSectionTab: tab});
        }
    }

    toggleVideoSectionTab = (tab) => {
        if (this.state.activeVideoSectionTab !== tab) {
            this.setState({activeVideoSectionTab: tab});
        }
    }



    

    



    showStageNameHover = (value) => {        
        this.setState({stageNameHovering : value});       
    }

    showProfilePicHover = (value) => {
        this.setState({profilePicHovering: value});
    }

    showAboutHover = (value) => {
        this.setState({aboutSectionHovering: value});
    }

    showAboutPicHover = (value) => {
        this.setState({aboutPicHovering: value});
    }

    showGigHover = (value) => {
        this.setState({gigSectionHovering: value});
    }

    showGigPicHover = (value) => {
        this.setState({gigPicHovering: value});
    }

    showVideoHover = (value) => {
        this.setState({videoSectionHovering: value});
    }

    openGigResponse = (value, index) => {        
        this.setState({showGigResponse: value});
        this.setState({opendedGig: this.state.gigs[index]});
    }
    
    closeGigResponse = () => {
        this.setState({showGigResponse: false});
    }

    openSubscribeForm = (value) => {
        
        this.setState({showSubscribeForm: value});
    }

    closeSubscribeForm = () => {
        
        this.setState({showSubscribeForm: false});
    }
    
    

    /**********************************************/


    onClickProfileSection = (e) => {
        this.setState({showProfileSectionEdit: true});
    }

    onClickStageNameSection = (e) => {
        this.setState({showStageNameSectionEdit: true});
    }

    onClickAboutSection = (e) => {
        this.setState({showAboutSectionEdit: true});
    }

    onClickAboutPicSection = (e) => {
        this.setState({showAboutPicSectionEdit: true});
    }

    onClickGigSection = (e) => {
        this.setState({showGigSectionEdit: true});
    }

    onClickGigPicSection = (e) => {
        this.setState({showGigPicSectionEdit: true});
    }

    onClickVideoSection = (e) => {
        this.setState({showVideoSectionEdit: true});
    }

    /*******************************************/


    onClickSiteSettings = (e) => {
        this.setState({showSiteSettings: true});
    }


    onCloseSiteSettings = (e) => {
        this.setState({showSiteSettings: false});
    }


    toggleSiteSettingsTab = (tab) => {
        if (this.state.activeSiteSettingTab !== tab) {
            this.setState({activeSiteSettingTab: tab});
        }
    }

    
    /*******************************************/

    


    closeProfileSectionEdit = (e) => {
        this.setState({showProfileSectionEdit: false});
    }

    closeStageNameSectionEdit = (e) => {
        this.setState({showStageNameSectionEdit: false});
    }

    closeAboutSectionEdit = (e) => {
        this.setState({showAboutSectionEdit: false});
    }

    closeAboutPicSection = (e) => {
        this.setState({showAboutPicSectionEdit: false});
    }

    closeGigSectionEdit = (e) => {
        this.setState({showGigSectionEdit: false});
    }

    closeGigPicSection = (e) => {
        this.setState({showGigPicSectionEdit: false});
    }

    closeVideoSectionEdit = (e) => {
        this.setState({showVideoSectionEdit: false});
    }


    /* onChange Event Handlers */
    handleChangeFirstName = (e) => {
        this.setState({ firstName: e.target.value });
    }

    handleChangeLastName = (e) => {
        this.setState({ lastName: e.target.value });
    }

    handleChangeTitle = (e) => {
        this.setState({ title: e.target.value });
    }

    handleChangeSlogan = (e) => {
        this.setState({ slogan: e.target.value });
    }

    handleChangeAboutText = (e) => {
        this.setState({ aboutText: e.target.value });
    }

    handleChangeAboutHeader = (e) => {
        this.setState({ aboutHeader: e.target.value });
    }

    handleChangeGigHeader = (e) => {
        this.setState({ gigHeader: e.target.value });
    }

    handleChangeVideoHeader = (e) => {
        this.setState({ videoHeader: e.target.value });
    }


    

    onSaveSiteSettings = (e) => {

        console.log("SAVING");
        console.log(this.state.colorPalette);

        axios({
           
            method: 'post',
            url: 'https://laffnetdev.website/api/website/index.php',
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json' 
			},
			data: {
				action: 'SAVE_WEB_COLORS',
				colors: this.state.colorPalette.toString(),

                bg_color_1: this.state.bgColor1,
                bg_color_2: this.state.bgColor2,
                text_color_1: this.state.textColor1,
                text_color_2: this.state.textColor2,
                font_id: this.state.selectedFontID,
                bg_img: this.state.bgImageUrl,


                


			}
        })
        .then(result => {

            console.log("======================");
			
            this.setState({showSiteSettings: false});
         

            			
        })
        .catch(error => 
            console.log("ERROR: "  + JSON.stringify(error) ) 
        );


        
    }


    /* Videos */

    addNewVideo = (e) => {

        var url = document.getElementById("new-video-url").value;
        var videoID = this.getYouTubeID(url);
              
        var tempVideos = [ ...this.state.videos ];
        tempVideos.push(videoID);

        this.setState( {videos: tempVideos} );

        document.getElementById("new-video-url").value = "";
    }

    getYouTubeID = (url) => {
        url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
        return (url[2] !== undefined) ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];


        // $video_embed  = 'https://www.youtube.com/embed/' . $my_array_of_vars['v'] . '?rel=0';
    }

    removeVideo = (index) => {

        if (index > -1) {

            var tempVideos = [ ...this.state.videos ];
            tempVideos.splice(index, 1);
        }
        
        this.setState( {videos: tempVideos} );

        
    }


    
    /****************************************************** CROP START */

    onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () =>
                this.setState({ src: reader.result })
            );
            reader.readAsDataURL(e.target.files[0]);
        }


        this.setState({ bgImageUploaded: false });
    };
    
    // If you setState the crop in here you should return false.
    onImageLoaded = (image) => {
        this.imageRef = image;
    };
    

    /**
     * Saving the cropped image on the server
     *  */ 
    onCropSave = (e) => {
        
        var self = this;
        
        //console.log("Length: " + self.state.bg_base64.length);
        //console.log("===============");
        axios({
            method: 'post',
            url: 'https://laffnetdev.website/api/website/index.php',
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json' 
			},
			data: {
				action: 'SAVE_WEB_HEADER',                
                //image : 'https://laffnetdev.website/img/contacts/contact-16.jpg'
				image: self.state.bg_base64
			}
        })
        .then(result => {
						
			console.log(result.data);

            var self = this;
                    
            this.setState({ src: null });
            this.setState({ croppedImageUrl: null });

            this.setState({ bgImageUrl: 'https://laffnetdev.website/img/website/'+result.data.file });

            

            setTimeout(function(){ 
                self.setState({ bgImageUploaded: true });
            }, 500);

            
                 

            			
        })
        .catch(error => 
            console.log("ERROR: "  + JSON.stringify(error) ) 
        );
        
    };

    onCropComplete = (crop) => {
        this.makeClientCrop(crop);
    };
    
    onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.setState({ crop });
    };
    
    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
          const croppedImageUrl = await this.getCroppedImg(
            this.imageRef,
            crop,
            'newFile.jpeg'
          );
          this.setState({ croppedImageUrl });
        }
    }
    
    getCroppedImg(image, crop, fileName) {

        var self = this;
        const canvas = document.createElement('canvas');
        const pixelRatio = window.devicePixelRatio;
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');
    
        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;
    
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';
    
        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width * scaleX,
          crop.height * scaleY
        );

        // As Base64 string
        const base64Image = canvas.toDataURL("image/jpeg");
        this.setState( {bg_base64: base64Image} );

        
    
        return new Promise((resolve, reject) => {
          canvas.toBlob(
            (blob) => {
              if (!blob) {
                //reject(new Error('Canvas is empty'));
                console.error('Canvas is empty');
                return;
              }
              blob.name = fileName;

              window.URL.revokeObjectURL(this.fileUrl);
              this.fileUrl = window.URL.createObjectURL(blob);
              resolve(this.fileUrl);
            },
            'image/jpeg',
            1
          );
        });
    }

    /************************************************************* CROP END */

    getColorPalette = (newColors) => {
        

        newColors.sort();

        var defaultPalette = ['#000000', '#ffffff', '#ffffff', '#ffffff', '#ffffff'];
        
        this.setState( { colorPalette : defaultPalette } );

        this.setState( { extractedColors : newColors } );

        // ***************************************************************
        // 2023-02-28 Changes Start
        

        //this.setState( { showColorSelection : true } );

        



        var tempPalette = [ ...this.state.colorPalette ];


        this.setState( { primaryColor: '#000000' } );
        this.setState( { bgColor1: '#000000' } );        
        tempPalette[0] = '#000000';


    

        this.setState( {secondaryColor: newColors[0]} );
        this.setState( {bgColor2: newColors[0]} );
        tempPalette[1] = newColors[0];




        this.setState( {accentColor: newColors[1]} );
        tempPalette[2] = newColors[1];


        tempPalette[3] = newColors[2];
        tempPalette[4] = '#ffffff';


        this.setState( {colorPalette: tempPalette} );
        


        this.setState( {textColor1: '#ffffff'} );
        this.setState( {textColor2: '#ffffff'} );
        

        // 2023-02-28 Changes End
        // ***************************************************************
        




        /*
        var testArr = newColors;
        
        //console.log("--------------------------------------");
        //console.log(testArr);
        testArr.sort();

        console.log("-----------------");
        console.log(testArr);

        

        
        testArr.unshift('#000000');
        testArr.push('#ffffff');
        


        axios({
            method: 'post',
            url: 'https://laffnetdev.website/api/website/index.php',
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json' 
			},
			data: {
				action: 'SAVE_WEB_COLORS',
				colors: testArr.toString()
			}
        })
        .then(result => {

            console.log("======================");
			console.log(result.data.colors);            

            this.setState( {colorPalette: result.data.colors.split(",") } );    
            
            

            this.setState( {sloganSectionBgColor: result.data.slogan_bg } );
            this.setState( {sloganSectionTxtColor: result.data.slogan_text } );

            this.setState( {firstPageBgColor: result.data.firstpage_bg } );

            this.setState( {firstPageBtnColor: result.data.gigs_bg } );
            this.setState( {firstPageBtnTxtColor: result.data.gigs_text } );
            
            this.setState( {aboutSectionBgColor: result.data.about_bg } );
            this.setState( {aboutSectionTxtColor: result.data.about_text } );
        

            this.setState( {videoSectionBgColor: result.data.video_bg } );
            this.setState( {videoSectionTxtColor: result.data.video_text } );

            this.setState( {gigSectionBgColor: result.data.gigs_bg } );
            this.setState( {gigSectionTxtColor: result.data.gigs_text } );
            
            
         

            			
        })
        .catch(error => 
            console.log("ERROR: "  + JSON.stringify(error) ) 
        );
        */
        
            

    }


        


    onSelectPrimaryColor = (color) => {

        this.setState( {primaryColor: color} );
        this.setState( {bgColor1: color} );

        var tempPalette = [ ...this.state.colorPalette ];
        tempPalette[1] = color;

        this.setState( {colorPalette: tempPalette} );
    }

    onSelectSecondaryColor = (color) => {

        this.setState( {secondaryColor: color} );
        this.setState( {bgColor2: color} );

        var tempPalette = [ ...this.state.colorPalette ];
        tempPalette[2] = color;

        this.setState( {colorPalette: tempPalette} );
    }

    onSelectAccentColor = (color) => {

        this.setState( {accentColor: color} );

        var tempPalette = [ ...this.state.colorPalette ];
        tempPalette[3] = color;

        this.setState( {colorPalette: tempPalette} );
    }
                       
    
    BgGalleryImagesSelected  = (i) => {

        //console.log( this.state.websiteBGImageGallery[i].colors );
        console.log( this.state.websiteBGImageGallery[i] );

        var self = this;
        this.closeImageGallery();

        
        this.setState({ bgImageSelected: true });
        this.setState({ bgImageUrl: 'https://laffnetdev.website/img/website/gallery/'+this.state.websiteBGImageGallery[i].filename });
        


        setTimeout(function(){ 

            /*
            <ColorExtractor getColors={self.getColorPalette}>
                <img src={this.state.bgImageUrl} style={{width:'600px', height:'auto'}} />
            </ColorExtractor>
            */

            /*
            self.getColorPalette( self.state.websiteBGImageGallery[i].colors );

            self.onSelectPrimaryColor( self.state.websiteBGImageGallery[i].primary );
            self.onSelectSecondaryColor( self.state.websiteBGImageGallery[i].secondary );
            self.onSelectAccentColor( self.state.websiteBGImageGallery[i].third );
            */

        }, 500);
        

    }

    closeImageGallery = () => {
        this.setState({ websiteBGImageGalleryOpen: false });
    }

    openImageGallery = () => {

        axios({
            method: 'post',
            url: 'https://laffnetdev.website/api/website/index.php',
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json' 
			},
			data: {
				action: 'GET_WEB_IMAGE_GALLERY',                
			}
        })
        .then(result => {
						
			console.log(result.data);

            var self = this;
              
            
            
            this.setState({ websiteBGImageGallery: result.data.images });

            setTimeout(function(){ 
                self.setState({ websiteBGImageGalleryOpen: true });
            }, 500);





            /*
            this.setState({ src: null });
            this.setState({ croppedImageUrl: null });

            this.setState({ bgImageUrl: 'https://laffnetdev.website/img/website/'+result.data.file });

            */

            
            

            
                 

            			
        })
        .catch(error => 
            console.log("ERROR: "  + JSON.stringify(error) ) 
        );

    }
                    
    clickedOnButton = () => {
        

        htmlToImage.toJpeg(document.getElementById('gig-modal-details'), { quality: 1.0 })
            .then(function (dataUrl) {
                
                //console.log(dataUrl);
                
                

                axios({
                    method: 'post',
                    url: 'https://laffnetdev.website/api/website/index.php',
                    headers: { 
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Content-Type': 'multipart/form-data',
                        'Accept': 'application/json' 
                    },
                    data: {
                        action: 'SAVE_SM_POST_IMAGE',
                        image: dataUrl
                    }
                })
                .then(result => {
                                
                    var link = document.createElement('a');
                    link.download = 'my-image-name.jpeg';
                    link.href = dataUrl;
                    link.click();

                                
                })
                .catch(error => 
                    console.log("ERROR: "  + JSON.stringify(error) ) 
                );

                


                
            });
        
    }

    renderPrimarySwatch = () => {
        const { extractedColors } = this.state
        
        return extractedColors.map((color, id) => {
            return (
                
                <span key={id} style={ this.state.primaryColor==color ? selectedStyle : defaultStyle} className='extractedColourBoxes' onClick={() => this.onSelectPrimaryColor(color)}>
                    <span                        
                        style={{
                            backgroundColor: color,
                            width: '100%',
                            height: '100%',                            
                            border: '1px dotted #ccc'
                        }}
                    >
                    &nbsp;&nbsp; &nbsp;&nbsp;
                    </span>
                    
                </span>

                
                
                
            )
        })
    }

    renderSecondarySwatch = () => {
        const { extractedColors } = this.state
        
        return extractedColors.map((color, id) => {
            return (

                <span key={id} style={ this.state.secondaryColor==color ? selectedStyle : defaultStyle} className='extractedColourBoxes' onClick={() => this.onSelectSecondaryColor(color)} >
                    <span                        
                        style={{
                            backgroundColor: color,
                            width: '100%',
                            height: '100%',                            
                            border: '1px dotted #ccc'
                        }}
                    >
                    &nbsp;&nbsp; &nbsp;&nbsp;
                    </span>
                    
                </span>

                
                
                
            )
        })
    }

    renderAccentSwatch = () => {
        const { extractedColors } = this.state
        
        return extractedColors.map((color, id) => {
            return (

                <span key={id} style={ this.state.accentColor==color ? selectedStyle : defaultStyle} className='extractedColourBoxes' onClick={() => this.onSelectAccentColor(color)} >
                    <span                        
                        style={{
                            backgroundColor: color,
                            width: '100%',
                            height: '100%',                            
                            border: '1px dotted #ccc'
                        }}
                    >
                    &nbsp;&nbsp; &nbsp;&nbsp;
                    </span>
                    
                </span>

                
                
                
            )
        })
    }

    render() {

        var self = this;
        const { crop, croppedImageUrl, src } = this.state;

        const gigsSection = this.state.gigs
            .map((gig, i) => (
                <div key={i} style={{margin:'25px 0px', fontSize:'22px', display: gig.show_date_str==''?'none':'block'}}>
                    {gig.show_title}<br />
                    {gig.venue} - {gig.venue_addr_street} {gig.venue_addr_city} {gig.venue_addr_prov}<br />
                    {gig.show_date_str} @ {gig.show_time_str}<br /><br />
                    
                    <button className="btn" style={{backgroundColor: this.state.btn3BgColor, color: this.state.btn3TextColor}} onClick={ () => this.openGigResponse(true, i) }>Find out More {'>>'}</button>
                    <br /><br />
                    <hr />
                </div>
            ));

        const upcomingGigsEdit = this.state.gigs    
			.map((gig,i)=> (
				<div key={gig.row_id} >
					<GigEdit details={gig} index={i}/>	
				</div>
			));


         
        const socialIcons = (
                            <div>
                                <img src="https://laffnetdev.website/img/socialmedia/FB White Black.png" style={{width:'45px', height:'auto', marginLeft:'1px', marginRight:'1px'}} />
                                <img src="https://laffnetdev.website/img/socialmedia/IG White Black.png" style={{width:'45px', height:'auto', marginLeft:'1px', marginRight:'1px'}} />
                                <img src="https://laffnetdev.website/img/socialmedia/LI White Black.png" style={{width:'45px', height:'auto', marginLeft:'1px', marginRight:'1px'}} />
                                <img src="https://laffnetdev.website/img/socialmedia/TW White Black.png" style={{width:'45px', height:'auto', marginLeft:'1px', marginRight:'1px'}} />
                                <img src="https://laffnetdev.website/img/socialmedia/TT White Black.png" style={{width:'45px', height:'auto', marginLeft:'1px', marginRight:'1px'}} />
                                <img src="https://laffnetdev.website/img/socialmedia/YT White Black.png" style={{width:'45px', height:'auto', marginLeft:'1px', marginRight:'1px'}} />
                            </div>
                            );

        

        const webBGImages = this.state.websiteBGImageGallery
			.map((image,i)=> (

                <div className="col-6 col-sm-6 col-md-6 mb-2" key={i} onClick={() => this.BgGalleryImagesSelected(i)}>

                    <img src={"https://laffnetdev.website/img/website/gallery/"+image.filename} style={{width:'100%', height: 'auto'}}/>
               
                </div>

			));

            
        const fontOptions = this.state.fonts
			.map((font,i)=> (

                <div className="col-6 col-sm-6 col-md-6" key={i}>

                    <div style={{border: this.state.selectedFontID==font.id?'2px solid #000':'1px solid #ccc', marginBottom:'15px', padding:'10px', borderRadius:'3px'}}
                                onClick={() => this.onChangeFont(font.id)}>
                        <div style={{borderBottom:'1px solid #ccc'}}>{font.display_name}</div>
                        <div style={{fontFamily: font.title_font}}><h4>Lorem ipsum dolor sit</h4></div>
                        <div style={{fontFamily: font.body_font}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit...</div>
                    </div>

                </div>

			));

        const colorOptions = 
        (
            <div>
                <br />
                <h5>Background Colors</h5>
                <div className="row">
                    <div className="col-md-4">Color 1:</div>
                    <div className="col-md-8">
                        <ColourPicker  colors={this.state.colorPalette} selected={this.state.bgColor1} onChange={this.onChangeBgColor1}/>
                    </div>
                </div>
                <br />

                <div className="row">
                    <div className="col-md-4">Color 2:</div>
                    <div className="col-md-8">
                        <ColourPicker  colors={this.state.colorPalette} selected={this.state.bgColor2} onChange={this.onChangeBgColor2}/>
                    </div>
                </div>
                <br />
                <br />


                <h5>Text Colors</h5>
                <div className="row">
                    <div className="col-md-4">Color 1:</div>
                    <div className="col-md-8">
                        <ColourPicker  colors={this.state.textColorPalette1} selected={this.state.textColor1} onChange={this.onChangeTextColor1}/>
                    </div>
                </div>
                <br />

                <div className="row">
                    <div className="col-md-4">Color 2:</div>
                    <div className="col-md-8">
                        <ColourPicker  colors={this.state.textColorPalette1} selected={this.state.textColor2} onChange={this.onChangeTextColor2}/>
                    </div>
                </div>

                <br />
                
            </div> 
        );

        
        let videoStyle = "";
        if (this.state.videos.length == 1) {
            videoStyle = "col-12";
        } else {
            videoStyle = "col-6";
        } 
        var embeddedVideos = this.state.videos.map((video,i)=> (
            <div key={i} className={videoStyle} style={{marginBottom:'30px'}}>
                <div className="embed-responsive embed-responsive-16by9"><iframe src={"https://www.youtube.com/embed/" + video + "?rel=0"} allowfullscreen="allowfullscreen" className="embed-responsive-item" frameborder="0"></iframe></div>
            </div>
        ));

        var editEmbeddedVideos = this.state.videos.map((video,i)=> (

            <div key={i} className="col-sm-6 col-md-4" style={{marginBottom:'30px'}}>
                <div className="embed-responsive embed-responsive-16by9"><iframe src={"https://www.youtube.com/embed/" + video + "?rel=0"} allowfullscreen="allowfullscreen" className="embed-responsive-item" frameborder="0"></iframe></div>
                <br />
                <div className="text-center">
                    <button data-index={i} className="btn btn-sm btn-laff" onClick={() => this.removeVideo(i)}>Remove</button>
                </div>
            </div>
                
            
        ));

        return (
            <div>
            
                <NavBar />

                <SideNavBar />

                <div style={{marginLeft: '80px'}} > 
                    
                    {/* 
                    ********************************************
                    Home
                    ********************************************
                    */}
                    <div id="page-home" style={{
                                                width: '100%',
                                                height: '100vh',
                                                backgroundImage: 'url(' + this.state.bgImageUrl + ')', 
                                                backgroundPosition: '50% 50%',
                                                backgroundRepeat: 'no-repeat',
                                                backgroundAttachment: 'fixed',
                                                backgroundSize: 'cover'
                                                }}>

                        <div style={{backgroundColor:'#00000070', width:'100%', height: '100vh'}}>

                        

                            <nav className="navbar navbar-expand-lg navbar-light website-nabbar">
                                
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                            
                                <div className="collapse navbar-collapse" id="navbarTogglerDemo02" style={{backgroundColor: this.state.bgColor1, color: this.state.textColor1}}>
                                    <ul className="navbar-nav ml-auto mt-2 mt-lg-0"> 
                                        <li className="nav-item active">
                                            <a className="nav-link" href="#">Home <span className="sr-only">(current)</span></a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#" style={{color: this.state.textColor1}} >About</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#" style={{color: this.state.textColor1}} >Appearances</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#" style={{color: this.state.textColor1}} >Reels</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#" style={{color: this.state.textColor1}} >Get Show Updates</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#" style={{color: this.state.textColor1}} >Book {this.state.firstName}</a>
                                        </li>
                                    </ul>            
                                </div>
                                
                            </nav>

                            <div className="container" style={{height:'100%'}}>
                                <div className="row" style={{height:'100%'}}>
                                    <div className="col-md-6" style={{height:'100%'}}>

                                        <div style={{position:'relative', top:'50%', transform: 'translateY(-50%)'}}>

                                            <div onMouseOver={()=>this.showProfilePicHover(true)} onMouseOut={()=>this.showProfilePicHover(false)}
                                                style={{border: this.state.profilePicHovering ? '1px solid #fff':'none', color: this.state.textColor1}}>

                                                <button type="button" className="btn btn-primary" id="btn-about-section-edit" onClick={this.onClickProfileSection} 
                                                    style={{zIndex:'150', position: 'absolute', top:'2px', right:'17px', display: this.state.profilePicHovering ? 'block':'none'}}>
                                                    <FontAwesomeIcon 
                                                        id="pencil-edit" 
                                                        icon={faPencilAlt} 
                                                        size="lg" 
                                                        style={pencilStyle}                                                                  
                                                    />
                                                </button>



            
                                                <div className="text-center" style={{paddingTop:'50px', paddingBottom:'50px'/*, marginLeft:'-50px'*/}}>
                                                    <img src="https://laffnetdev.website/img/customers/profile-32568.png" style={{width: '100%', height:'auto', borderRadius: '50%'}}/>
                                                    <br /><br />
                                                    <div style={{marginBottom:'10px'}}>
                                                        <div className="d-inline-block" style={{paddingRight:'20px'}}><img src="https://laffnetdev.website/img/flags/canada.png" /></div>
                                                        <div className="d-inline-block" style={{}}> Toronto | Canada</div>
                                                    </div>
                                                    {socialIcons}
                                                </div>
                                                
                                            </div>
                                        </div>

                                        <Modal 
                                            isOpen={this.state.showProfileSectionEdit}
                                            size="lg"
                                            backdrop={false}
                                        >
                                            <ModalBody>                        

                                                <div>
                                                    <div className="row">
                                                        <div className="col-8 laff-blue-txt">
                                                            <h4></h4>
                                                        </div> 
                                                        <div className="col-4 text-right">
                                                            <Button className="laff-blue rounded-circle" size="sm" onClick={this.closeProfileSectionEdit} aria-label="Close" style={{borderColor:'#0050F5'}}>
                                                                <span>&nbsp;&times;&nbsp;</span>
                                                            </Button>           
                                                        </div>                       
                                                    </div>
                                                    <br />

                                                    <Nav tabs>
                                                        <NavItem>
                                                            <NavLink
                                                                className={({ active: this.state.activeProfileSectionTab === '1' })}
                                                                onClick={() => { this.toggleProfileSectionTab('1'); }}
                                                            >
                                                            Text
                                                            </NavLink>
                                                        
                                                        </NavItem>
                                                        {/* 
                                                        <NavItem>
                                                            <NavLink
                                                                className={({ active: this.state.activeProfileSectionTab === '2' })}
                                                                onClick={() => { this.toggleProfileSectionTab('2'); }}
                                                            >
                                                            Color Palette
                                                            </NavLink>
                                                        </NavItem>
                                                        <NavItem>
                                                            <NavLink
                                                                className={({ active: this.state.activeProfileSectionTab === '3' })}
                                                                onClick={() => { this.toggleProfileSectionTab('3'); }}
                                                            >
                                                            Fonts
                                                            </NavLink>
                                                        </NavItem>
                                                        */}
                                                    </Nav>
                                                    <TabContent >
                                                        <TabPane tabId="1" className={this.state.activeProfileSectionTab === '1'? 'active':''}>
                                                            <br />
                                                            <div className="row">
                                                                Text goes here .....
                                                            </div>                                                    
                                                        </TabPane>
                                                        {/*
                                                        <TabPane tabId="2" className={this.state.activeProfileSectionTab === '2'? 'active':''}>
                                                            <Row>
                                                                <Col sm="12">                                                                
                                                                    {colorOptions}                                                                
                                                                </Col>
                                                            </Row>
                                                            <br />
                                                        </TabPane>
                                                        <TabPane tabId="3" className={this.state.activeProfileSectionTab === '3'? 'active':''}>
                                                            <br />
                                                            <div className="row">
                                                                {fontOptions}
                                                            </div>                                                    
                                                        </TabPane>
                                                        */}
                                                    </TabContent>
                                                                                                    
                                                </div>
                                                                                                                    
                                            </ModalBody>
                                            <ModalFooter>
                                                <Button className='btn-sm' color="secondary" onClick={this.closeProfileSectionEdit}>Done</Button>
                                            </ModalFooter>
                                        </Modal>
                                        
                                    </div>
                                    <div className="col-md-6 text-center" style={{color: this.state.textColor1, height:'100%'}}>

                                        <div style={{position:'relative', top:'50%', transform: 'translateY(-50%)'}}>
                                            <div onMouseOver={()=>this.showStageNameHover(true)} onMouseOut={()=>this.showStageNameHover(false)}
                                                style={{border: this.state.stageNameHovering ? '1px solid #fff':'none'}}>

                                                <button type="button" className="btn btn-primary" id="btn-about-section-edit" onClick={this.onClickStageNameSection} 
                                                    style={{zIndex:'150', position: 'absolute', top:'2px', right:'17px', display: this.state.stageNameHovering ? 'block':'none'}}>
                                                    <FontAwesomeIcon 
                                                        id="pencil-edit" 
                                                        icon={faPencilAlt} 
                                                        size="lg" 
                                                        style={pencilStyle}                                                                  
                                                    />
                                                </button>

                                                <div id="stage-name" style={{fontFamily:this.state.titleFont}}>
                                                    {this.state.firstName} {this.state.lastName}                                                                      
                                                </div>

                                                <div id="title" style={{fontFamily:this.state.titleFont}}>{this.state.title}</div>

                                                <div id="tag-line" style={{fontFamily:this.state.titleFont}}><i>{this.state.slogan}</i></div>
                                                
                                                <div style={{paddingTop:'100px'}}>
                                                    <button className="btn btn-lg" style={{width:'75%', paddingTop:'20px', paddingBottom:'20px', backgroundColor: this.state.bgColor2, color: this.state.btn1TextColor}}
                                                            onClick={() => this.openSubscribeForm(true)} >Subscribe for Show Updates</button>
                                                </div>
                                                <br />

                                            </div>
                                        </div>
                                        <Modal 
                                            isOpen={this.state.showStageNameSectionEdit}
                                            size="lg"
                                            backdrop={false}
                                        >
                                            <ModalBody>                        

                                                <div>
                                                    <div className="row">
                                                        <div className="col-8 laff-blue-txt">
                                                            <h4></h4>
                                                        </div> 
                                                        <div className="col-4 text-right">
                                                            <Button className="laff-blue rounded-circle" size="sm" onClick={this.closeStageNameSectionEdit} aria-label="Close" style={{borderColor:'#0050F5'}}>
                                                                <span>&nbsp;&times;&nbsp;</span>
                                                            </Button>           
                                                        </div>                       
                                                    </div>
                                                    <br />

                                                    <Nav tabs>
                                                        <NavItem>
                                                            <NavLink
                                                                className={({ active: this.state.activeStageNameSectionTab === '1' })}
                                                                onClick={() => { this.toggleStageNameSectionTab('1'); }}
                                                            >
                                                            Title Section
                                                            </NavLink>
                                                        
                                                        </NavItem>
                                                        <NavItem>
                                                            <NavLink
                                                                className={({ active: this.state.activeStageNameSectionTab === '2' })}
                                                                onClick={() => { this.toggleStageNameSectionTab('2'); }}
                                                            >
                                                            Button
                                                            </NavLink>
                                                        </NavItem>
                                                        
                                                        {/* 
                                                        <NavItem>
                                                            <NavLink
                                                                className={({ active: this.state.activeStageNameSectionTab === '3' })}
                                                                onClick={() => { this.toggleStageNameSectionTab('3'); }}
                                                            >
                                                            Fonts
                                                            </NavLink>
                                                        </NavItem>
                                                        */}
                                                    </Nav>
                                                    <TabContent >
                                                        <TabPane tabId="1" className={this.state.activeStageNameSectionTab === '1'? 'active':''}>

                                                            <br />
                                                            <div className="row">
                                                                <div className="col-6">
                                                                    <Input type="text" name="firstname" id="firstname" placeholder="First Name" value={this.state.firstName} onChange={this.handleChangeFirstName} maxLength="15"/>
                                                                </div>
                                                                <div className="col-6">
                                                                    <Input type="text" name="lastname" id="lastname" placeholder="Last Name" value={this.state.lastName} onChange={this.handleChangeLastName} maxLength="20"/>
                                                                </div>
                                                            </div>
                                                            <br />
                                                            <div className="row">
                                                                <div className="col-6">
                                                                    <Input type="text" name="cus-title" id="cus-title" placeholder="Title" value={this.state.title} onChange={this.handleChangeTitle} maxLength="15"/>
                                                                </div>
                                                            </div>
                                                            <br />
                                                            <div className="row">
                                                                <div className="col-12 col-md-9">
                                                                    <Input type="text" name="slogan" id="slogan" placeholder="Tag Line" value={this.state.slogan} onChange={this.handleChangeSlogan} maxLength="20"/>
                                                                </div>
                                                            </div>

                                                            <br />

                                                        </TabPane>
                                                        
                                                        <TabPane tabId="2" className={this.state.activeStageNameSectionTab === '2'? 'active':''}>
                                                            <Row>
                                                                <Col sm="12">                                                                
                                                                    
                                                                    <br />
                                                                    <h5>Button Color</h5>
                                                                    <div className="row">
                                                                        <div className="col-md-4">Text Color:</div>
                                                                        <div className="col-md-8">
                                                                            
                                                                            <ColourPicker  colors={this.state.colorPalette} selected={this.state.btn1TextColor} onChange={this.onChangeBtn1TextColor}/>
                                                                            
                                                                        </div>
                                                                    </div>
                                                                    <br />

                                                                    <div className="row">
                                                                        <div className="col-md-4">Background Color:</div>
                                                                        <div className="col-md-8">
                                                                            
                                                                            <ColourPicker  colors={this.state.colorPalette} selected={this.state.btn1BgColor} onChange={this.onChangeBtn1BgColor}/>
                                                                            
                                                                        </div>
                                                                    </div>

                                                                </Col>
                                                            </Row>
                                                            <br />
                                                        </TabPane>
                                                        {/* 
                                                        <TabPane tabId="3" className={this.state.activeStageNameSectionTab === '3'? 'active':''}>
                                                            <br />
                                                            <div className="row">
                                                                {fontOptions}
                                                            </div>                                                    
                                                        </TabPane>
                                                        */}
                                                    </TabContent>
                                                                                                    
                                                </div>
                                                                        
                                            </ModalBody>
                                            <ModalFooter>
                                                <Button className='btn-sm' color="secondary" onClick={this.closeStageNameSectionEdit}>Done</Button>
                                            </ModalFooter>
                                        </Modal>

                                        
                                    </div>                
                                </div>

                            </div>

                        </div>
                    </div>



                    {/* 
                    ********************************************
                    About
                    ********************************************
                    */}                    
                    <div id="page-about" style={{backgroundColor: this.state.bgColor1}}>
        
                        <div class="container" style={{height:'100%'}}>

                            <div className="row" style={{height:'100%'}}>
                                <div className="col-md-6" style={{color: this.state.textColor1, height:'100%'}}>
                                
                                    <div style={{position:'relative', top:'50%', transform: 'translateY(-50%)'}}>
                                        
                                        <div onMouseOver={()=>this.showAboutHover(true)} onMouseOut={()=>this.showAboutHover(false)}
                                            style={{border: this.state.aboutSectionHovering ? '1px solid #fff':'none'}}>
                                            <br />   
                                            <button type="button" className="btn btn-primary" id="btn-about-section-edit" onClick={this.onClickAboutSection } 
                                                style={{zIndex:'150', position: 'absolute', top:'26px', right:'17px', display: this.state.aboutSectionHovering ? 'block':'none'}}>
                                                <FontAwesomeIcon 
                                                    id="pencil-edit" 
                                                    icon={faPencilAlt} 
                                                    size="lg" 
                                                    style={pencilStyle}                                                                  
                                                />
                                            </button>
                                            
                                            <div style={{fontFamily:this.state.titleFont, fontSize:'36px', }}><strong>{this.state.aboutHeader}</strong></div>

                                            <div style={{fontFamily:this.state.bodyFont, fontSize:'22px'}}>
                                                <p>
                                                {this.state.aboutText}
                                                </p>
                                            </div>

                                            <br /><br />
                                            <div style={{width: '100%'}} className="text-center">
                                                <button className="btn btn-lg" style={{width:'75%', paddingTop:'20px', paddingBottom:'20px',backgroundColor: this.state.bgColor2, color: this.state.btn2TextColor}} 
                                                        onClick={() => this.openSubscribeForm(true)} >Subscribe for Show Updates</button>
                                            </div>
                                        </div>

                                        
                                    </div> 
                                    
                                    


                                    <Modal 
                                        isOpen={this.state.showAboutSectionEdit}
                                        size="lg"
                                        backdrop={false}
                                    >
                                        <ModalBody>                        

                                            <div>
                                                <div className="row">
                                                    <div className="col-8 laff-blue-txt">
                                                        <h4></h4>
                                                    </div> 
                                                    <div className="col-4 text-right">
                                                        <Button className="laff-blue rounded-circle" size="sm" onClick={this.closeAboutSectionEdit} aria-label="Close" style={{borderColor:'#0050F5'}}>
                                                            <span>&nbsp;&times;&nbsp;</span>
                                                        </Button>           
                                                    </div>                       
                                                </div>
                                                <br />

                                                <Nav tabs>
                                                    <NavItem>
                                                        <NavLink
                                                            className={({ active: this.state.activeAboutSectionTab === '1' })}
                                                            onClick={() => { this.toggleAboutSectionTab('1'); }}
                                                        >
                                                        About Section
                                                        </NavLink>
                                                    
                                                    </NavItem>
                                                    {/* 
                                                    <NavItem>
                                                        <NavLink
                                                            className={({ active: this.state.activeAboutSectionTab === '2' })}
                                                            onClick={() => { this.toggleAboutSectionTab('2'); }}
                                                        >
                                                        Color Palette
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            className={({ active: this.state.activeAboutSectionTab === '3' })}
                                                            onClick={() => { this.toggleAboutSectionTab('3'); }}
                                                        >
                                                        Fonts
                                                        </NavLink>
                                                    </NavItem>
                                                    */}
                                                </Nav>
                                                <TabContent >
                                                    <TabPane tabId="1" className={this.state.activeAboutSectionTab === '1'? 'active':''}>
                                                        <br />
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <Input type="text" name="about_header" id="about_header" placeholder="Section Header" value={this.state.aboutHeader} onChange={this.handleChangeAboutHeader} />
                                                            </div>
                                                        </div>
                                                        <br />
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <textarea className="form-control"
                                                                    name="about_text" id="about_text" placeholder="About" 
                                                                    value={this.state.aboutText}
                                                                    onChange={this.handleChangeAboutText}
                                                                    rows={5}
                                                                />
                                                            
                                                            </div>
                                                        </div> 

                                                        <br />
                                                        <h5>Button Color</h5>
                                                        <div className="row">
                                                            <div className="col-md-4">Text Color:</div>
                                                            <div className="col-md-8">
                                                                
                                                                <ColourPicker  colors={this.state.colorPalette} selected={this.state.btn2TextColor} onChange={this.onChangeBtn2TextColor}/>
                                                                
                                                            </div>
                                                        </div>
                                                        <br />

                                                        <div className="row">
                                                            <div className="col-md-4">Background Color:</div>
                                                            <div className="col-md-8">
                                                                
                                                                <ColourPicker  colors={this.state.colorPalette} selected={this.state.btn2BgColor} onChange={this.onChangeBtn2BgColor}/>
                                                                
                                                            </div>
                                                        </div>                                                  
                                                    </TabPane>
                                                    {/*
                                                    <TabPane tabId="2" className={this.state.activeAboutSectionTab === '2'? 'active':''}>
                                                        <Row>
                                                            <Col sm="12">                                                                
                                                                {colorOptions}
                                                                
                                                            </Col>
                                                        </Row>
                                                        <br />
                                                    </TabPane>
                                                    <TabPane tabId="3" className={this.state.activeAboutSectionTab === '3'? 'active':''}>
                                                        <br />
                                                        <div className="row">
                                                            {fontOptions}
                                                        </div>                                                    
                                                    </TabPane>
                                                    */}
                                                </TabContent>
                                                                                                
                                            </div>
                                                                    
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button className='btn-sm' color="secondary" onClick={this.closeAboutSectionEdit}>Done</Button>
                                        </ModalFooter>
                                    </Modal>




                                </div>
                                <div className="col-md-6" >

                                    <div style={{position:'relative', top:'50%', transform: 'translateY(-50%)'}}>
                                        <br />
                                        <div onMouseOver={()=>this.showAboutPicHover(true)} onMouseOut={()=>this.showAboutPicHover(false)}
                                            style={{border: this.state.aboutPicHovering ? '1px solid #fff':'none', color: this.state.textColor1}}>

                                            <button type="button" className="btn btn-primary" id="btn-about-section-edit" onClick={this.onClickAboutPicSection} 
                                                style={{zIndex:'150', position: 'absolute', top:'26px', right:'17px', display: this.state.aboutPicHovering ? 'block':'none'}}>
                                                <FontAwesomeIcon 
                                                    id="pencil-edit" 
                                                    icon={faPencilAlt} 
                                                    size="lg" 
                                                    style={pencilStyle}                                                                  
                                                />
                                            </button>

                                            <div className="text-center" style={{paddingTop:'50px', paddingBottom:'50px' /*, marginRight:'-50px'*/}}>
                                                <img src="https://laffnetdev.website/img/customers/profile-32568.png" style={{width: '100%', height:'auto', borderRadius: '50%'}}/>
                                                <br /><br />
                                                
                                                {socialIcons}

                                            </div>
                                        
                                        </div>
                                    </div>

                                    <Modal 
                                        isOpen={this.state.showAboutPicSectionEdit}
                                        size="lg"
                                        backdrop={false}
                                    >
                                        <ModalBody>                        

                                            <div>
                                                <div className="row">
                                                    <div className="col-8 laff-blue-txt">
                                                        <h4></h4>
                                                    </div> 
                                                    <div className="col-4 text-right">
                                                        <Button className="laff-blue rounded-circle" size="sm" onClick={this.closeAboutPicSection} aria-label="Close" style={{borderColor:'#0050F5'}}>
                                                            <span>&nbsp;&times;&nbsp;</span>
                                                        </Button>           
                                                    </div>                       
                                                </div>
                                                <br />

                                                <Nav tabs>
                                                    <NavItem>
                                                        <NavLink
                                                            className={({ active: this.state.activeAboutPicSectionTab === '1' })}
                                                            onClick={() => { this.toggleAboutPicSectionTab('1'); }}
                                                        >
                                                        Text
                                                        </NavLink>
                                                    
                                                    </NavItem>
                                                    {/* 
                                                    <NavItem>
                                                        <NavLink
                                                            className={({ active: this.state.activeAboutPicSectionTab === '2' })}
                                                            onClick={() => { this.toggleAboutPicSectionTab('2'); }}
                                                        >
                                                        Color Palette
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            className={({ active: this.state.activeAboutPicSectionTab === '3' })}
                                                            onClick={() => { this.toggleAboutPicSectionTab('3'); }}
                                                        >
                                                        Fonts
                                                        </NavLink>
                                                    </NavItem>
                                                    */}
                                                </Nav>
                                                <TabContent >
                                                    <TabPane tabId="1" className={this.state.activeAboutPicSectionTab === '1'? 'active':''}>
                                                        <br />
                                                        <div className="row">
                                                            Text goes here
                                                        </div>                                                    
                                                    </TabPane>
                                                    {/* 
                                                    <TabPane tabId="2" className={this.state.activeAboutPicSectionTab === '2'? 'active':''}>
                                                        <Row>
                                                            <Col sm="12">                                                                
                                                                {colorOptions}  
                                                                

                                                            </Col>
                                                        </Row>
                                                        <br />
                                                    </TabPane>
                                                    <TabPane tabId="3" className={this.state.activeAboutPicSectionTab === '3'? 'active':''}>
                                                        <br />
                                                        <div className="row">
                                                            {fontOptions}
                                                        </div>                                                    
                                                    </TabPane>
                                                    */}
                                                </TabContent>
                                                                                                
                                            </div>
                                                                    
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button className='btn-sm' color="secondary" onClick={this.closeAboutPicSection}>Done</Button>
                                        </ModalFooter>
                                    </Modal>

                                </div>                
                            </div>

                        </div>
                            

                    </div>



                    <div style={{width: '100%',
                                backgroundImage: 'url(' + this.state.bgImageUrl + ')', 
                                backgroundPosition: '50% 50%',
                                backgroundRepeat: 'no-repeat',
                                backgroundAttachment: 'fixed',
                                backgroundSize: 'cover',
                                
                                }}>
                                    {/* 
                                    paddingTop: '90px',
                                    paddingBottom: '90px'
                                    */}
                            

                        <div className="container-fluid booking-form" style={{backgroundColor:'rgba(0,0,0,0.44)'}}>
                            <div className="row text-center">
                                
                                <div className="col-12">
                                    

                                    <div style={{width:'100%', maxWidth:'1000px', marginLeft:'auto', marginRight:'auto'}}>

                                    

                                        <div style={{position: 'relative',  }}> {/* marginTop:'30px', marginBottom:'30px' */}
                                            <div  style={{fontSize: '40px', width: '100%', padding: '30px',  color: 'rgb(255, 255, 255)' }}> {/* backgroundColor: 'rgba(0, 0, 0, 0.65)', */}
                                                
                                                <div style={{marginTop:'20px', marginBottom:'20px', fontSize:'48px'}}>
                                                    
                                                    <strong>
                                                    { this.state.connectFromTitle1 }
                                                    </strong>
                                                    

                                                </div>
                                                

                                                <div className="row">
                                                    <div className="col-6 mb-4">
                                                        <input type="text" className="form-control form-control-lg rounded-0" placeholder="First Name" />
                                                    </div>

                                                    <div className="col-6 mb-4">
                                                        <input type="text" className="form-control form-control-lg rounded-0" placeholder="Last Name" />
                                                    </div>

                                                    <div className="col-6 mb-4">
                                                        <input type="text" className="form-control form-control-lg rounded-0" placeholder="Email" />
                                                    </div>

                                                    <div className="col-6 mb-4">
                                                        <input type="text" className="form-control form-control-lg rounded-0" placeholder="Phone" />
                                                    </div>

                                                    <div className="col-6 mb-4">
                                                        <input type="text" className="form-control form-control-lg rounded-0" placeholder="Date of Engagement" />
                                                    </div>

                                                    <div className="col-6 mb-4">
                                                        <input type="text" className="form-control form-control-lg rounded-0" placeholder="Location" />
                                                    </div>

                                                    <div className="col-12 mb-4">
                                                        <textarea className="form-control form-control-lg rounded-0" placeholder="Message"></textarea>
                                                    </div>

                                                    <div className="col-12 text-right mt-0 pt-0 mb-0 pb-0">
                                                        
                                                        <button className="btn btn-lg" style={{width:'200px', paddingTop:'20px', paddingBottom:'20px',backgroundColor: this.state.bgColor2, color: this.state.btn2TextColor}} 
                                                         >Connect!</button>
                                                    </div>




                                                </div>


                                            </div>
                                        </div>

                                    </div>

                                </div>
                                
                            </div>
                        </div>
                    
                        


                    </div>

                    {/* 
                    ********************************************
                    Gigs
                    ********************************************
                    */}

                    <div id="page-gigs" style={{backgroundColor: this.state.bgColor2, color: this.state.textColor2}}>

                        <div className="container" style={{height:'100%'}}>
                            
                            <div className="row" style={{height:'100%'}}>
                                
                                <div className="col-12 text-center" style={{height:'100%'}}>
                                

                                    <div style={{position:'relative', top:'50%', transform: 'translateY(-50%)'}}>

                                        <div onMouseOver={()=>this.showGigHover(true)} onMouseOut={()=>this.showGigHover(false)}
                                            style={{border: this.state.gigSectionHovering  ? '1px solid #fff':'none'}}>

                                            <button type="button" className="btn btn-primary" id="btn-about-section-edit" onClick={this.onClickGigSection} 
                                                style={{zIndex:'150', position: 'absolute', top:'75px', right:'17px', display: this.state.gigSectionHovering ? 'block':'none'}}>
                                                <FontAwesomeIcon 
                                                    id="pencil-edit" 
                                                    icon={faPencilAlt} 
                                                    size="lg" 
                                                    style={pencilStyle}                                                                  
                                                />
                                            </button>
                                            
                                            <div style={{fontFamily:this.state.titleFont, fontSize:'36px'}}><strong>{this.state.gigHeader}</strong></div>
                                            <div className="text-center" style={{fontFamily:this.state.bodyFont}}>
                                                {gigsSection}
                                            </div>  


                                            <div style={{paddingTop:'50px'}}>
                                                <button className="btn btn-lg" style={{width:'75%', paddingTop:'20px', paddingBottom:'20px', backgroundColor: this.state.bgColor1, color: this.state.btn3TextColor}} 
                                                        onClick={() => this.openSubscribeForm(true)} >Subscribe for Show Updates</button>
                                            </div>  
                                        </div>

                                        <Modal 
                                            isOpen={this.state.showGigSectionEdit}
                                            size="lg"
                                            backdrop={false}
                                        >
                                            <ModalBody>                        

                                                <div>
                                                    <div className="row">
                                                        <div className="col-8 laff-blue-txt">
                                                            <h4></h4>
                                                        </div> 
                                                        <div className="col-4 text-right">
                                                            <Button className="laff-blue rounded-circle" size="sm" onClick={this.closeGigSectionEdit} aria-label="Close" style={{borderColor:'#0050F5'}}>
                                                                <span>&nbsp;&times;&nbsp;</span>
                                                            </Button>           
                                                        </div>                       
                                                    </div>
                                                    <br />

                                                    <Nav tabs>
                                                        <NavItem>
                                                            <NavLink
                                                                className={({ active: this.state.activeGigSectionTab === '1' })}
                                                                onClick={() => { this.toggleGigSectionTab('1'); }}
                                                            >
                                                                Gigs
                                                            </NavLink>
                                                        
                                                        </NavItem>
                                                        
                                                        <NavItem>
                                                            <NavLink
                                                                className={({ active: this.state.activeGigSectionTab === '2' })}
                                                                onClick={() => { this.toggleGigSectionTab('2'); }}
                                                            >
                                                                Button
                                                            </NavLink>
                                                        </NavItem>
                                                        {/* 
                                                        <NavItem>
                                                            <NavLink
                                                                className={({ active: this.state.activeGigSectionTab === '3' })}
                                                                onClick={() => { this.toggleGigSectionTab('3'); }}
                                                            >
                                                            Fonts
                                                            </NavLink>
                                                        </NavItem>
                                                        */}
                                                    </Nav>
                                                    <TabContent >
                                                        <TabPane tabId="1" className={this.state.activeGigSectionTab === '1'? 'active':''}>
                                                            
                                                            {upcomingGigsEdit}
                                                                                                  
                                                        </TabPane>
                                                        
                                                        <TabPane tabId="2" className={this.state.activeGigSectionTab === '2'? 'active':''}>
                                                            <br />
                                                            <div className="row">
                                                                <div className="col-6">
                                                                    <Input type="text" name="gig_header" id="gig_header" placeholder="Section Header" value={this.state.gigHeader} onChange={this.handleChangeGigHeader} />
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <br />
                                                                <h5>Button Color</h5>
                                                                <div className="row">
                                                                    <div className="col-md-4">Text Color:</div>
                                                                    <div className="col-md-8">
                                                                        
                                                                        <ColourPicker  colors={this.state.colorPalette} selected={this.state.btn3TextColor} onChange={this.onChangeBtn3TextColor}/>
                                                                        
                                                                    </div>
                                                                </div>
                                                                <br />

                                                                <div className="row">
                                                                    <div className="col-md-4">Background Color:</div>
                                                                    <div className="col-md-8">
                                                                        
                                                                        <ColourPicker  colors={this.state.colorPalette} selected={this.state.btn3BgColor} onChange={this.onChangeBtn3BgColor}/>
                                                                        
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <br />
                                                        </TabPane>
                                                        {/* 
                                                        <TabPane tabId="3" className={this.state.activeGigSectionTab === '3'? 'active':''}>
                                                            <br />
                                                            <div className="row">
                                                                {fontOptions}
                                                            </div>                                                    
                                                        </TabPane>
                                                        */}
                                                    </TabContent>
                                                                                                    
                                                </div>
                                                                        
                                            </ModalBody>
                                            <ModalFooter>
                                                <Button className='btn-sm' color="secondary" onClick={this.closeGigSectionEdit}>Done</Button>
                                            </ModalFooter>
                                        </Modal>

                                    </div>
                                    
                                    
                                    
                                </div>                
                            </div>
                        </div>

                    </div>
                    

                    <div style={{width: '100%',
                                backgroundImage: 'url(' + this.state.bgImageUrl + ')', 
                                backgroundPosition: '50% 50%',
                                backgroundRepeat: 'no-repeat',
                                backgroundAttachment: 'fixed',
                                backgroundSize: 'cover',
                                
                                }}>
                                    {/* 
                                    paddingTop: '90px',
                                    paddingBottom: '90px'
                                    */}
                            

                        <div className="container-fluid booking-form" style={{backgroundColor:'rgba(0,0,0,0.44)'}}>
                            <div className="row text-center">
                                
                                <div className="col-12">
                                    

                                    <div style={{width:'100%', maxWidth:'1000px', marginLeft:'auto', marginRight:'auto'}}>

                                    

                                        <div style={{position: 'relative',  }}> {/* marginTop:'30px', marginBottom:'30px' */}
                                            <div  style={{fontSize: '40px', width: '100%', padding: '30px',  color: 'rgb(255, 255, 255)' }}> {/* backgroundColor: 'rgba(0, 0, 0, 0.65)', */}
                                                
                                                <div style={{marginTop:'20px', marginBottom:'20px', fontSize:'48px'}}>
                                                    
                                                    <strong>
                                                    { this.state.connectFromTitle2 }
                                                    </strong>
                                                    

                                                </div>
                                                

                                                <div className="row">
                                                    <div className="col-6 mb-4">
                                                        <input type="text" className="form-control form-control-lg rounded-0" placeholder="First Name" />
                                                    </div>

                                                    <div className="col-6 mb-4">
                                                        <input type="text" className="form-control form-control-lg rounded-0" placeholder="Last Name" />
                                                    </div>

                                                    <div className="col-6 mb-4">
                                                        <input type="text" className="form-control form-control-lg rounded-0" placeholder="Email" />
                                                    </div>

                                                    <div className="col-6 mb-4">
                                                        <input type="text" className="form-control form-control-lg rounded-0" placeholder="Phone" />
                                                    </div>

                                                    <div className="col-6 mb-4">
                                                        <input type="text" className="form-control form-control-lg rounded-0" placeholder="Date of Engagement" />
                                                    </div>

                                                    <div className="col-6 mb-4">
                                                        <input type="text" className="form-control form-control-lg rounded-0" placeholder="Location" />
                                                    </div>

                                                    <div className="col-12 mb-4">
                                                        <textarea className="form-control form-control-lg rounded-0" placeholder="Message"></textarea>
                                                    </div>

                                                    <div className="col-12 text-right mt-0 pt-0 mb-0 pb-0">
                                                        
                                                        <button className="btn btn-lg" style={{width:'200px', paddingTop:'20px', paddingBottom:'20px',backgroundColor: this.state.bgColor2, color: this.state.btn2TextColor}} 
                                                         >Connect!</button>
                                                    </div>




                                                </div>


                                            </div>
                                        </div>

                                    </div>

                                </div>
                                
                            </div>
                        </div>
                    
                        


                    </div>

                    {/* 
                    ********************************************
                    Videos
                    ********************************************
                    */}
                    <div id="page-reels" style={{minHeight: '100vh', backgroundColor: this.state.bgColor1, color: this.state.textColor1}}>
                        
                        <div className="container" style={{height:'100%'}}>

                            <div style={{position:'relative', top:'50%', transform: 'translateY(-50%)'}}>

                                <div onMouseOver={()=>this.showVideoHover(true)} onMouseOut={()=>this.showVideoHover(false)}
                                            style={{border: this.state.videoSectionHovering ? '1px solid #fff':'none'}}>
                                
                                    <div className="row">
                                        <div className="col-12">                                        
                                            
                                            

                                            <button type="button" className="btn btn-primary" id="btn-about-section-edit" onClick={this.onClickVideoSection} 
                                                style={{zIndex:'150', position: 'absolute', top:'26px', right:'17px', display: this.state.videoSectionHovering ? 'block':'none'}}>
                                                <FontAwesomeIcon 
                                                    id="pencil-edit" 
                                                    icon={faPencilAlt} 
                                                    size="lg" 
                                                    style={pencilStyle}                                                                  
                                                />
                                            </button>
                                        </div>
                                    </div>

                                    <div style={{fontFamily:this.state.titleFont, fontSize:'36px'}}><strong>{this.state.videoHeader}</strong></div>
                                    
                                    <div className="row">
                                        {embeddedVideos}
                                    </div>
                                    

                                    <div style={{width:'50%', marginLeft:'auto', marginRight:'auto'}}>
                                        <button className="btn btn-lg" style={{width:'100%', paddingTop:'20px', paddingBottom:'20px', backgroundColor: this.state.bgColor2, color: this.state.btn4TextColor}} 
                                                onClick={() => this.openSubscribeForm(true)} >Subscribe for Show Updates</button>
                                    </div>
                                    
                                </div>


                                <Modal 
                                    isOpen={this.state.showVideoSectionEdit}
                                    size="lg"
                                    backdrop={false}
                                >
                                    <ModalBody>                        

                                        <div>
                                            <div className="row">
                                                <div className="col-8 laff-blue-txt">
                                                    <h4></h4>
                                                </div> 
                                                <div className="col-4 text-right">
                                                    <Button className="laff-blue rounded-circle" size="sm" onClick={this.closeVideoSectionEdit} aria-label="Close" style={{borderColor:'#0050F5'}}>
                                                        <span>&nbsp;&times;&nbsp;</span>
                                                    </Button>           
                                                </div>                       
                                            </div>
                                            <br />

                                            <Nav tabs>
                                                <NavItem>
                                                    <NavLink
                                                        className={({ active: this.state.activeVideoSectionTab === '1' })}
                                                        onClick={() => { this.toggleVideoSectionTab('1'); }}
                                                    >
                                                    Section
                                                    </NavLink>
                                                
                                                </NavItem>
                                                {/* 
                                                <NavItem>
                                                    <NavLink
                                                        className={({ active: this.state.activeVideoSectionTab === '2' })}
                                                        onClick={() => { this.toggleVideoSectionTab('2'); }}
                                                    >
                                                    Color Palette
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        className={({ active: this.state.activeVideoSectionTab === '3' })}
                                                        onClick={() => { this.toggleVideoSectionTab('3'); }}
                                                    >
                                                    Fonts
                                                    </NavLink>
                                                </NavItem>
                                                */}
                                            </Nav>
                                            <TabContent >
                                                <TabPane tabId="1" className={this.state.activeVideoSectionTab === '1'? 'active':''}>
                                                    <br />
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <Input type="text" name="reels_header" id="reels_header" placeholder="Section Header" value={this.state.videoHeader} onChange={this.handleChangeVideoHeader} />
                                                        </div>
                                                    </div> 

                                                    <hr />
                                                    <div className="row" style={this.state.videos.length < 6 ? {display:'block'} : {display:'none'}}>
                                                        <div className="col-12">
                                                            <input className="form-control form-control-sm" type="text" id="new-video-url"/>
                                                        </div>
                                                        <div className="col-12">
                                                            <Button className="laff-blue" size="sm" onClick={this.addNewVideo} aria-label="Add" style={{borderColor:'#0050F5'}}>
                                                                Add
                                                            </Button>  
                                                        </div>
                                                        
                                                    </div>
                                                    <br />
                                                    <div className="row">                                                        
                                                        {editEmbeddedVideos}
                                                    </div>
                                                    
                                                    <hr />
                                                    <h5>Button Color</h5>
                                                    <div className="row">
                                                        <div className="col-md-4">Text Color:</div>
                                                        <div className="col-md-8">
                                                            
                                                            <ColourPicker  colors={this.state.colorPalette} selected={this.state.btn4TextColor} onChange={this.onChangeBtn4TextColor}/>
                                                            
                                                        </div>
                                                    </div>
                                                    <br />

                                                    <div className="row">
                                                        <div className="col-md-4">Background Color:</div>
                                                        <div className="col-md-8">
                                                            
                                                            <ColourPicker  colors={this.state.colorPalette} selected={this.state.btn4BgColor} onChange={this.onChangeBtn4BgColor}/>
                                                            
                                                        </div>
                                                    </div>
                                                                                                              
                                                </TabPane>
                                                {/* 
                                                <TabPane tabId="2" className={this.state.activeVideoSectionTab === '2'? 'active':''}>
                                                    <Row>
                                                        <Col sm="12">                                                                
                                                            {colorOptions}

                                                                                                                            
                                                        </Col>
                                                    </Row>
                                                    <br />
                                                </TabPane>
                                                <TabPane tabId="3" className={this.state.activeVideoSectionTab === '3'? 'active':''}>
                                                    <br />
                                                    <div className="row">
                                                        {fontOptions}
                                                    </div>                                                    
                                                </TabPane>
                                                */}
                                            </TabContent>
                                                                                            
                                        </div>
                                                                
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button className='btn-sm' color="secondary" onClick={this.closeVideoSectionEdit}>Done</Button>
                                    </ModalFooter>
                                </Modal>


                            </div>

                            

                        </div>
                        

                    </div>

                    {/* 
                    ********************************************
                    Footer
                    ********************************************
                    */}
                    <div id="footer" style={{backgroundColor: 'black', borderTop: '1px solid #ffffff'}}>

                        <div className="container">
                            
                            <div class="row" style={{paddingTop:'40px', paddingBottom:'40px'}}>
                                
                                <div class="col-12 col-md-4" style={{color:'#ffffff'}}>
                                    <h3>{this.state.firstName} {this.state.lastName}</h3>
                                    {this.state.title}
                                </div>

                                <div class="col-12 col-md-4">
                                    {socialIcons}
                                </div>

                                <div class="col-12 col-md-4 text-right" style={{color:'#ffffff'}}>
                                    {this.state.phone}<br />
                                    {this.state.email}
                                </div>

                            </div>            
                            
                            {/* 
                            <img src="https://laffnetdev.website/img/website/5_Footer_Page.png" style={{width:'100%', height:'auto'}}/>
                            */}
                        </div>
                        

                    </div>

                    <Modal 
                        isOpen={this.state.showSubscribeForm}
                        size="lg"
                        backdrop={true}
                        centered
                    >
                        {/* <div className="col-4 text-right">
                                    <Button className="laff-blue rounded-circle" size="sm" onClick={() => this.openSubscribeForm(false)} aria-label="Close" style={{borderColor:'#0050F5'}}>
                                        <span>&nbsp;&times;&nbsp;</span>
                                    </Button>           
                                </div> 
                        */}
                        <ModalHeader 
                            toggle={this.closeSubscribeForm}
                            style={{backgroundColor:this.state.bgColor2, color: this.state.btn2TextColor}}
                        >
                            <span style={{fontSize:'28px'}}>Subscribe for Show Updates</span>
                        </ModalHeader>
                        
                        <ModalBody>                        

                            <div>
                                
                                <br />
                                <div className="row">
                                    <div className="col-sm-6 mb-4">
                                        <input type="text" className="form-control form-control-lg rounded-0" placeholder="First Name" style={{border:'2px solid ' + this.state.bgColor2}}/>
                                    </div>
                                    <div className="col-sm-6 mb-4">
                                        <input type="text" className="form-control form-control-lg rounded-0" placeholder="Last Name" style={{border:'2px solid ' + this.state.bgColor2}}/>
                                    </div>
                                    <div className="col-sm-6 mb-4">
                                        <input type="text" className="form-control form-control-lg rounded-0" placeholder="Email" style={{border:'2px solid ' + this.state.bgColor2}}/>
                                    </div>
                                </div>
                                                               
                                                                                
                            </div>


                            <hr />
                            <div className="clear-fix">
                                <div className="float-left">
                                    <FormGroup check>

                                        <Label check>
                                            <Input type="checkbox" />{' '}
                                            I agree to receive weekly show updates from {this.state.firstName}
                                        </Label>

                                    </FormGroup>
                                    <br />
                                    <a href="google.com" target="_blank" style={{textDecoration:'none', color:'#000'}}><u>Privacy Policy</u></a>
                                </div>
                                <div className="float-right">
                                    <button className="btn btn-lg" 
                                        style={{ paddingTop:'20px', paddingBottom:'20px',backgroundColor: this.state.bgColor2, color: this.state.btn2TextColor}} 
                                        >
                                        Subscribe Now!
                                    </button>
                                </div>
                            </div>
                                                                                                
                        </ModalBody>
                        
                    </Modal>
                    



                    <Modal 
                        isOpen={this.state.showGigResponse}
                        size="lg"
                        backdrop={true}
                        centered
                    >
                        <ModalHeader 
                            toggle={this.closeGigResponse}
                            style={{backgroundColor:this.state.bgColor2, color: this.state.btn2TextColor}}
                        >
                            <span style={{fontSize:'28px'}}>Coming to this show?</span>
                        </ModalHeader>
                        <ModalBody>                        

                            <div>
                                <div id="gig-modal-details" style={{
                                        color: '#ffffff',
                                        width: '100%',
                                        background: 'url("' + this.state.bgImageUrl + '"), #000', 
                                        
                                        backgroundPosition: '50% 50%',
                                        backgroundRepeat: 'no-repeat',
                                        /*backgroundAttachment: 'fixed',*/
                                        backgroundSize: 'cover',
                                        backgroundBlendMode: 'luminosity',
                                        position:'relative',
                                        /*filter: 'grayscale(100%)',
                                        zIndex:'90'*/
                                        
                                    }}>

                                        <div style={{width:'100%', backgroundColor:'rgba(0, 0, 0, 0.6)', /*position:'absolute',left:'0', top:'0', zIndex:'100'*/}}>
                                            <div className="row">
                                                <div className="col-6 text-center">
                                                    <br />
                                                    {/* fontFamily: 'Brush Script MT', */}
                                                    <div style={{   textAlign: 'center',
                                                                    color: this.state.opendedGig.color,
                                                                    textShadow: '0 0 5px #fff, 0 0 10px ' + this.state.opendedGig.color + ', 0 0 15px ' + this.state.opendedGig.color + ', 0 0 20px ' + this.state.opendedGig.color + ', 0 0 30px ' + this.state.opendedGig.color + ', 0 0 40px ' + this.state.opendedGig.color + ', 0 0 55px ' + this.state.opendedGig.color + ', 0 0 75px ' + this.state.opendedGig.color ,
                                                                    fontFamily: 'Brush Script MT, Neonderthaw',                                                                    
                                                                    fontSize: '3vw',
                                                                    transform: 'rotate(-10deg)'
                                                                    }}>Appearing at</div>

                                                    <div style={{ }}>

                                                        <div style={{fontSize:'2.0vw', fontFamily: this.state.titleFont }}>{this.state.opendedGig.venue}</div>
                                                        <div style={{fontSize:'1.5vw', fontFamily: this.state.bodyFont, marginTop:'-15px'}}>{this.state.opendedGig.venue_addr_city} {this.state.opendedGig.venue_addr_prov}</div>

                                                        <div style={{fontSize:'2vw', fontFamily: this.state.titleFont}}>{this.state.opendedGig.name}</div>

                                                        <div style={{fontSize:'1vw', fontFamily: this.state.bodyFont}}>{this.state.opendedGig.show_comb_datetime_str}</div>
                                                    </div>                    

                                                    {/* 
                                                    
                                                    {this.state.gigs[this.state.opendedGig]}
                                                    */}
                                                </div>
                                                <div className="col-6">

                                                    <div className="text-center" style={{paddingTop:'20px', paddingBottom:'20px'}}>
                                                        <img src="https://laffnetdev.website/img/customers/profile-32568.png" style={{width: '90%', height:'auto', borderRadius: '50%'}}/>                                                            
                                                    </div>

                                                </div>
                                            </div>

                                            <img src="https://laffnetdev.website/img/logo_laffnet.png" style={{width:'125px', height:'auto', filter: 'brightness(0) invert(1)', opacity:'0.4', 
                                                        position: 'absolute', zIndex:'50', right:'0', bottom:'0'}} />
                                        </div>

                                </div>






                                
                                <br />
                                <div className="row">
                                    <div className="col-4 mb-4 text-center">
                                        <button className="btn btn-lg" style={{width:'150px', backgroundColor:this.state.bgColor2, color: this.state.btn2TextColor}}>Coming!</button>
                                    </div>
                                    <div className="col-4 mb-4 text-center">
                                        <button className="btn btn-lg" style={{width:'150px', backgroundColor:this.state.bgColor2+'CF', color: this.state.btn2TextColor}}>Maybe?</button>
                                    </div>
                                    <div className="col-4 mb-4 text-center">
                                        <button className="btn btn-lg" style={{width:'150px', backgroundColor:this.state.bgColor2+'9A', color: this.state.btn2TextColor}}>Sorry...Can't</button>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <button className="btn btn-secondary" onClick={()=>this.clickedOnButton() }>Download</button>
                                    </div>
                                </div>
                                                               
                                                                                
                            </div>
                                                                                                
                        </ModalBody>
                    
                    </Modal>

   

                    {/* Sticky Header */}
                    <div id="web-sticky-header" style={{width:'100%', backgroundColor: 'rgba(0, 0, 0, 0.6)', position: 'fixed', top: '0', left: '0', margin: '0 auto', display: 'none', zIndex: '1050', padding: '20px' }}> 
                    
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-4" style={{color:'#ffffff'}}>
                                    <span>
                                        <img src="https://laffnetdev.website/img/customers/profile-32568.png" style={{width: '100%', maxWidth: '70px', height: 'auto', borderRadius: '50%'}}/>
                                    </span>
                                    <span className="ml-5" style={{fontSize:'26px'}}>
                                        {this.state.firstName} {this.state.lastName}                                    
                                    </span>
                                    
                                </div>
                                <div className="col-8">
                                    <nav className="navbar navbar-expand-lg navbar-light website-nabbar">
                                    
                                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
                                            <span className="navbar-toggler-icon"></span>
                                        </button>
                                    
                                        <div className="collapse navbar-collapse" id="navbarTogglerDemo02" style={{color: this.state.textColor1}}>
                                            <ul className="navbar-nav ml-auto mt-2 mt-lg-0"> 
                                                <li className="nav-item active">
                                                    <a className="nav-link" href="#">Home <span className="sr-only">(current)</span></a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" href="#" style={{color: this.state.textColor1}} >About</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" href="#" style={{color: this.state.textColor1}} >Appearances</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" href="#" style={{color: this.state.textColor1}} >Reels</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" href="#" style={{color: this.state.textColor1}} >Get Show Updates</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" href="#" style={{color: this.state.textColor1}} >Book {this.state.firstName}</a>
                                                </li>
                                            </ul>            
                                        </div>
                                        
                                    </nav>
                                </div>
                            </div>
                        </div>

                    </div>

                    




                        
                    <div className="text-center" style={{zIndex: '1049', position: 'fixed', right: '0px', top: '100px' }}>
                        {/*<Button className='btn btn-sm btn-primary' onClick={this.onClickSiteSettings}>Site Settings</Button>*/}
                        
                        <div style={{width: '230px', backgroundColor:'#ccc', padding: '10px', borderRadius:'3px', cursor: 'pointer'}} onClick={this.onClickSiteSettings}>
                            
                            <span ><strong>Site Settings</strong></span>

                        </div>
                        
                    </div>



                    <div style={floatBtnStyle, {display: 'none'}}>
                        <div className='clearfix' style={{backgroundColor:'#00000090', color:'#ffffff', padding: '10px', borderRadius:'3px', width:'280px'}}>

                            <div className="row">
                                <div className="col-6 text-center">

                                    <img src="https://laffnetdev.website/img/customers/profile-32568.png" style={{width: '100px', height: 'auto', borderRadius: '50%'}}/>

                                </div>
                                <div className="col-6 text-center">
                                    <div style={{width:'100%', height:'100%'}}>
                                        <div style={{top:'50%', transform: 'translateY(50%)', fontSize:'22px'}}>
                                            <strong>Book<br />{this.state.firstName}</strong>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="float-left">
                                
                            </div>

                            <div className="float-left text-center" >
                                
                            </div>
                            
                            
                            
                        </div>                        
                    </div>


                    <Modal 
                        isOpen={this.state.showSiteSettings}
                        size="lg"
                        backdrop={false}
                    >
                        <ModalBody>                        

                            <div>
                                <div className="row">
                                    <div className="col-8 laff-blue-txt">
                                        <h4></h4>
                                    </div> 
                                    <div className="col-4 text-right">
                                        <Button className="laff-blue rounded-circle" size="sm" onClick={this.onCloseSiteSettings} aria-label="Close" style={{borderColor:'#0050F5'}}>
                                            <span>&nbsp;&times;&nbsp;</span>
                                        </Button>           
                                    </div>                       
                                </div>
                                <br />

                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            className={({ active: this.state.activeSiteSettingTab === '1' })}
                                            onClick={() => { this.toggleSiteSettingsTab('1'); }}
                                        >
                                        Image Upload
                                        </NavLink>
                                    
                                    </NavItem>
                                    
                                    <NavItem>
                                        <NavLink
                                            className={({ active: this.state.activeSiteSettingTab === '2' })}
                                            onClick={() => { this.toggleSiteSettingsTab('2'); }}
                                        >
                                        Color Palette
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={({ active: this.state.activeSiteSettingTab === '3' })}
                                            onClick={() => { this.toggleSiteSettingsTab('3'); }}
                                        >
                                        Fonts
                                        </NavLink>
                                    </NavItem>
                                    
                                </Nav>
                                <TabContent >
                                    <TabPane tabId="1" className={this.state.activeSiteSettingTab === '1'? 'active':''}>
                                        <br />
                                        <div className="row">

                                            <div className="col-sm-5 text-center">
                                                <label htmlFor="filePicker" style={{ background:"grey", padding:"5px 10px", borderRadius:'3px', color: '#fff' }}>
                                                Upload Main Image
                                                </label>
                                                    <input type="file" id="filePicker" style={{visibility:"hidden"}} onChange={this.onSelectFile} />
                                            </div>
                                            <div className="col-sm-2 text-center">
                                                OR
                                            </div>
                                            <div className="col-sm-5 text-center">
                                                <button className="btn btn-sm btn-secondary" onClick={this.openImageGallery}>Pick From the Gallery</button>
                                                <br />

                                                

                                                {this.state.bgImageSelected && (
                                                    <div>
                                                        <ColorExtractor getColors={self.getColorPalette}>
                                                            <img src={this.state.bgImageUrl} style={{width:'100%', height:'auto'}} />
                                                        </ColorExtractor>
                                                    </div>
                                                )}
                                                

                                            </div>

                                            




                                            <Modal 
                                                isOpen={this.state.websiteBGImageGalleryOpen}
                                                size="lg"
                                                backdrop={false}
                                            >
                                                <ModalBody>                        

                                                    <div>
                                                        <div className="row">
                                                            <div className="col-8 laff-blue-txt">
                                                                <h4></h4>
                                                            </div> 
                                                            <div className="col-4 text-right">
                                                                <Button className="laff-blue rounded-circle" size="sm" onClick={this.closeImageGallery} aria-label="Close" style={{borderColor:'#0050F5'}}>
                                                                    <span>&nbsp;&times;&nbsp;</span>
                                                                </Button>           
                                                            </div>                       
                                                        </div>
                                                        <br />

                                                        <div className="row">
                                                            {webBGImages}
                                                        </div>
                                                        
                                                                                                        
                                                    </div>
                                                                                                                        
                                                </ModalBody>
                                                <ModalFooter>
                                                    <Button className='btn-sm' color="secondary" onClick={this.closeImageGallery}>Done</Button>
                                                </ModalFooter>
                                            </Modal>


                                            


                                            {src && (
                                            <ReactCrop
                                                src={src}
                                                crop={crop}
                                                ruleOfThirds
                                                onImageLoaded={this.onImageLoaded}
                                                onComplete={this.onCropComplete}
                                                onChange={this.onCropChange}
                                            />
                                            )}


                                            {croppedImageUrl && (
                                                <div className="row">
                                                    <div className="col-12 col-sm-6" style={{border:'1px #ccc solid'}}>
                                                        <h5>Preview</h5>
                                                        

                                                            <img alt="Crop" style={{ maxWidth: '100%' }} src={croppedImageUrl} />

                                                        
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <button type="button" className="btn btn-primary" id="btn-about-section-edit" onClick={this.onCropSave} >
                                                            <FontAwesomeIcon 
                                                                id="pencil-edit" 
                                                                icon={faPencilAlt} 
                                                                size="lg" 
                                                                style={pencilStyle}                                                                  
                                                            />
                                                            Crop
                                                        </button>
                                                    </div>
                                                </div>    
                                            )}


                                            {/*
                                            {this.state.bgImageSelected && (


                                                <div style={{display:this.state.showColorSelection?'block':'none', padding: '20px'}}>

                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            Primary Color:<br />
                                                            {this.renderPrimarySwatch()}
                                                        </div>
                                                        <div className='col-12'>
                                                            Secondary Color:<br />
                                                            {this.renderSecondarySwatch()}
                                                        </div>
                                                        <div className='col-12'>
                                                            Accent Color:<br />
                                                            {this.renderAccentSwatch()}
                                                        </div>
                                                    </div>

                                                </div>
                                                
                                            )}
                                            */}



                                            {this.state.bgImageUploaded && (
                                                <div>
                                                    
                                                    <ColorExtractor getColors={this.getColorPalette}>
                                                        <img src={this.state.bgImageUrl} style={{width:'600px', height:'auto'}} />
                                                    </ColorExtractor>


                                                    
                                                    <div style={{display:this.state.showColorSelection?'block':'none'}}>

                                                        <div className='row'>
                                                            <div className='col-12'>
                                                                Primary Color:<br />
                                                                {this.renderPrimarySwatch()}
                                                            </div>
                                                            <div className='col-12'>
                                                                Secondary Color:<br />
                                                                {this.renderSecondarySwatch()}
                                                            </div>
                                                            <div className='col-12'>
                                                                Accent Color:<br />
                                                                {this.renderAccentSwatch()}
                                                            </div>
                                                        </div>
                                                        
                                                    </div>

                                                </div>
                                            )}

                                        </div>                                                    
                                    </TabPane>
                                    
                                    <TabPane tabId="2" className={this.state.activeSiteSettingTab === '2'? 'active':''}>
                                        <Row>
                                            <Col sm="12">                                                                
                                                {colorOptions}                                                                
                                            </Col>
                                        </Row>
                                        <br />
                                    </TabPane>
                                    <TabPane tabId="3" className={this.state.activeSiteSettingTab === '3'? 'active':''}>
                                        <br />
                                        <div className="row">
                                            {fontOptions}
                                        </div>                                                    
                                    </TabPane>
                                    
                                </TabContent>
                                                                                
                            </div>
                                                                                                
                        </ModalBody>
                        <ModalFooter>
                            <Button className='btn-sm' color="secondary" onClick={this.onSaveSiteSettings}>Done</Button>
                        </ModalFooter>
                    </Modal>

                    
                </div>


            </div>
        );


    }
}


const selectedStyle = {
    border:'2px solid #000',
    padding: '2px',
    width: '50px', 
    height: '50px', 
    marginRight: '2px'
}

const defaultStyle = {
    border:'2px solid #ccc',
    padding: '2px', 
    width: '50px', 
    height: '50px', 
    marginRight: '2px'
}


const floatBtnStyle = {
    zIndex:'1049', 
    position:'fixed', 
    right:'10px', 
    bottom:'10px'
}

const pencilStyle = {  
    color:'#fff', 
    fontSize: '18px', 
    cursor: 'pointer',
    marginLeft:'8px',    
    /*opacity:'0', 
    transition: '.6s ease opacity'*/
}

export default WebsiteManagement;