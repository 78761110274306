import React, {Component} from 'react';
import Script from 'react-load-script';
import NavBar from '../layout/NavBar';


import GetGoogleContacts from './GetGoogleContacts';
import Gmail from './Gmail';

class TestGoogle extends Component {


    
    constructor(props) {

        super(props);
        
        this.state = {
            //autocomplete : 'null',
            			
        }

    }

    componentDidMount() {

    }

    handleScriptLoad = () => {

        // Declare Options For Autocomplete
        const options = {
            types: ['(cities)'],
        };
    
        // Initialize Google Autocomplete
        /*global google*/ // To disable any eslint 'google not defined' errors
        this.autocomplete = new google.maps.places.Autocomplete(
            document.getElementById('autocomplete'),
            {types: ['geocode']},
        );
    
        // Avoid paying for data that you don't need by restricting the set of
        // place fields that are returned to just the address components and formatted
        // address.
        this.autocomplete.setFields(['address_components']);
        //this.autocomplete.setFields(['address_components', 'formatted_address']);
    
        // Fire Event when a suggested name is selected
        this.autocomplete.addListener('place_changed', this.handlePlaceSelect);
    }


    handlePlaceSelect = () => {

        // Get the place details from the autocomplete object.
        var place = this.autocomplete.getPlace();
        console.log(place);

        var componentForm = {
            street_number: 'short_name',
            route: 'long_name',
            locality: 'long_name',
            administrative_area_level_1: 'short_name',
            country: 'long_name',
            postal_code: 'short_name'
        };

        // clear each field
        for (var component in componentForm) {
            document.getElementById(component).value = '';
        }
        
          
        // Get each component of the address from the place details,
        // and then fill-in the corresponding field on the form.
        for (var i = 0; i < place.address_components.length; i++) {
            var addressType = place.address_components[i].types[0];
            if (componentForm[addressType]) {
                var val = place.address_components[i][componentForm[addressType]];
                document.getElementById(addressType).value = val;
            }
        }

    
    }

    

    render () {

        return (
            <div>
                <NavBar />
                <br />
                
                <Script
                    url="https://maps.googleapis.com/maps/api/js?key=AIzaSyBtlfZ_WchTua-Z1NaD8KJ6xCKCZmeJMpM&libraries=places" onLoad={this.handleScriptLoad}
                />
                <input id="autocomplete" placeholder="Enter your address" type="text" style={{width:'400px'}}/>

                <br /><br />
                Suite: <input type="text" id="suite_number" /><br />
                Number: <input type="text" id="street_number" /><br />
                Street: <input type="text" id="route" /><br />
                City: <input type="text" id="locality" /><br />
                State/Prov: <input type="text" id="administrative_area_level_1" /><br />
                Postal Code/Zip: <input type="text" id="postal_code" /><br />
                Country: <input type="text" id="country" /><br />
            

                <br /><br />
                Contacts<br />
                <GetGoogleContacts />
                <br /><br />
                Gmail<br />
                <Gmail />
            </div>
        );
    }


}

export default TestGoogle;