import React, {Component} from 'react'
//import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

import axios from 'axios'
import { Alert } from 'reactstrap';

class NavBar extends Component {

    constructor(props) {

        super(props);

        

        this.state = {

            id: '',
            firstName: '',
            lastName: '',
            showAlert: true,

            development: false,            
            

        }
        
    }

    componentDidMount() {
        

        if ( this.state.development) {

            
            // for local testing
            this.setState({id: '32566'});
            this.setState({firstName: 'Fremil'});
            this.setState({lastName: 'Revelpulle'});

        } else {
            // for publish
            this.checkLogin(); /* TODO - uncomment this if session needs to work */
        }
        

        var self = this;
        setTimeout(function(){ 

            //self.props.setUser( self.state.id );

		}, 500);
        
        
        
        

        
    }

    checkLogin = () => {

        const {pathname} = this.props.location;
        console.log("PathName: " + pathname);

        axios({
            method: 'post',
            url: 'https://laffnetdev.website/api/auth/index.php',
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json' 
			},
			data: {
                action: 'IS_USER_LOGGED_IN'
                
			}
            
        })
        .then(result => {
			
            console.log( JSON.stringify(result.data) ); 
            
            if (result.data.status === 'OK')
            {
                
                // Get customer's basic info
                axios({
                    method: 'post',
                    url: 'https://laffnetdev.website/api/customer/index.php',
                    headers: { 
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept': 'application/json' 
                    },
                    data: {
                        action: 'GET_CUSTOMER_BASIC_INFO'                        
                    }                    
                })
                .then(result => {
                    
                    //console.log( JSON.stringify(result.data.info.firstname) ); 
                                        
                    this.setState({id: result.data.info.id});
                    this.setState({firstName: result.data.info.firstname});
                    this.setState({lastName: result.data.info.lastname});

                    //console.log(this.state.firstName + " " + this.state.lastName);
                    
                })
                .catch(error => {
                    console.log( "ERROR: GET_CUSTOMER_BASIC_INFO "  + JSON.stringify(error) ) 
                    
                });




            }
            else
            {
                this.props.history.push("/login");
            }
        })
        .catch(error => {
            console.log( "ERROR: "  + JSON.stringify(error) ) 
            
        });


    }

    logout = (e) => {

        console.log("Clicked Logout");

        e.preventDefault();
        
        axios({
            method: 'post',
            url: 'https://laffnetdev.website/api/auth/index.php',
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json' 
			},
			data: {
                action: 'LOG_OUT_USER'
                //action: 'TEST'
                
			}
            
        })
        .then(result => {
			
            console.log( JSON.stringify(result.data) ); 
            
            if (result.data.status === 'success')
            {
                this.props.history.push("/login");
            }
            else
            {
                
            }
        })
        .catch(error => {
            console.log( "ERROR: "  + JSON.stringify(error) ) 
            
        });
    }

    closeAlert = (e) => {
        this.setState({showAlert: false});
    }

    render() {

        /*
        const globalState = {
            globalText: "foofoofoo525",
        };
        */

        //const UserContext = React.createContext(globalState);

        return (

            

            /*
            <header style={headerStyle}>
                <h1>Laffbook</h1>
                <Link style={linkStyle} to="/">Contacts</Link> | 
                <Link style={linkStyle} to="/profile">Profile</Link>
    
            </header>
            */
    
            /*
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <a className="navbar-brand" href="/">Navbar</a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
            
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mr-auto">
                    <li className="nav-item active">
                        <a className="nav-link" href="/">Home <span className="sr-only">(current)</span></a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/profile">Profile</a>
                    </li>
                    </ul>
                    <form className="form-inline my-2 my-lg-0">
                    <input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search" />
                    <button className="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
                    </form>
                </div>
            </nav>
            */

            <div>
                {/*
                <UserContext.Provider value={globalState}>
      
                </UserContext.Provider>
                */}


                

                <nav className="navbar navbar-dark py-0 navbar-expand-lg py-md-0 sticky-top">
                    <a className="navbar-brand" href="/my-office">
                        <img src="/img/logo_laffnet.png" alt="LaffNet"/>
                    </a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="navbar-collapse collapse" id="navbarNav">
                        <ul className="navbar-nav">
                            {/* 
                            <li className="nav-item py-0" ><a className="nav-link" href="/gmail">Communications</a></li>
                            <li className="nav-item py-0" ><a className="nav-link" href="/contacts">Contacts</a></li>
                            <li className="nav-item py-0" ><a className="nav-link" href="/">Marketing</a></li>
                            <li className="nav-item py-0" ><a className="nav-link" href="/gig-pipeline">Gig Pipeline</a></li>
                            <li className="nav-item py-0" ><a className="nav-link" href="/calendar">Spots & Payments</a></li>
                            <li className="nav-item py-0" ><a className="nav-link" href="/">Gig Lab</a></li>
                            */}
                        </ul>
                        
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item">
                                <img src="/img/icons/educaton.png" style={navIcon} alt=""/>
                            </li>
                            <li className="nav-item">
                                <img src="/img/icons/notification.png" style={navIcon} alt=""/>
                            </li>
                            
                            {/*
                            <li className="nav-item">
                                <span className="nav-link">Hi {this.state.firstName}!</span>
                            </li>
                            */}
                                

                            <li className="nav-item dropdown">
                                
                                <a href="" id="imageDropdown" data-toggle="dropdown">
                                    <img src="/img/customers/portrait-placeholder.jpg" style={portraitStyle} alt=""/> <span style={{color:'#ffffff'}}>Hi {this.state.firstName}!</span>
                                </a>
                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                                <a className="dropdown-item" href="/profile">Profile</a>
                                <a className="dropdown-item" href="/settings">Settings</a>
                                <a className="dropdown-item" href="/admin-dashboard">Dashboard (ADMIN)</a>
                                <div className="dropdown-divider"></div>
                                <a className="dropdown-item" href="" onClick={this.logout}>Log Out</a>
                                <div className="dropdown-divider"></div>
                                </div>
                            </li>	
                                
                        </ul>
                        
                    </div>
                </nav>


                {/*
                <Alert color="dark" isOpen={this.state.showAlert} toggle={this.closeAlert}>
                    This is a notification — check it out!
                </Alert>
                */}
            </div>
        )
    }
    
}

const portraitStyle = {

    height:'40px', 
    width: 'auto',
    borderRadius: '50%',
    /*border: '2px solid #A23F1C'*/
}

const navIcon = {
    height:'40px', 
    width: 'auto',
    paddingRight:'20px',
}



/*
const headerStyle = {
    background: '#333',
    color: '#fff',
}

const linkStyle = {
    color: '#fff',
    textDecoration: 'none'
}
*/
export default withRouter(NavBar);