import React, {Component} from 'react';
import { Button, Input} from 'reactstrap';

import { faMobileAlt, faBuilding, faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from 'axios';

class ContactEdit extends Component
{
    constructor(props) {

        super(props);
        
        this.state = {
            contact: this.props.contact
            
		}
            
    }

    /*
    componentDidUpdate(prevProps) {

        

        if(prevProps.contact !== this.props.contact) {

            
            this.setState({contact: this.props.contact});

            var self = this;

            //setTimeout(function(){ 
            
            //}, 500);
            
            
        }
    }
    */

    componentDidMount() {
        
        //console.log("Mounting " + this.props.section);
        //console.log("Showing: " + this.props.contact.id);
        //this.setState( { showModal: this.props.show } );
    }


    /* Handles Checkbox changing */
    handleCheckboxChange = ( e ) => {

        
        const fieldName = e.currentTarget.querySelector("input").name;
        const fieldVal = e.currentTarget.querySelector("input").checked ? 'Y':'N';

        const tempContact = { ...this.state.contact, [fieldName]: fieldVal }
        this.setState( {contact: tempContact} );
        
    }

    /* Handles Input changing */
    handleChange = ( e ) => {

        if (e.target) {
            const fieldName = e.target.name;
            const fieldVal = e.target.value;
            
            // console.log(fieldName + " " + fieldVal);

            const tempContact = { ...this.state.contact, [fieldName]: fieldVal }
            this.setState( {contact: tempContact} );
        }
        
    }

    saveContact = () => {

        var self = this;

        // Save the contact and callback
        this.props.onSave(self.state.contact);

        console.log(self.state.contact);

        axios({
            method: 'post',
            url: 'https://laffnetdev.website/api/contacts/index.php',
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json' 
			},
			data: {
				action: 'SAVE_CONTACT',
				contact: self.state.contact,
			}
        })
        .then(result => {
			
			//console.log(this.state.searchText);
			console.log(result.data);

			
        })
        .catch(error => 
            console.log("ERROR: "  + JSON.stringify(error) ) 
        );
        


        


    }

    
    render() {

        const contact = this.state.contact;
        const section = this.props.section;

        return (

            <div className="container-fluid" style={{backgroundColor:'#e7e7e7', padding:'12px'}}>

                <div className="row">
                    <div className="col-10">
                        {contact.picture ? <img src={'./img/contacts/'+ contact.picture} style={imageStyle}/> : <img src={'./img/contacts/portrait-placeholder.jpg'} style={imageStyle}/>} 
                        <span style={{marginLeft:'25px', fontSize:'20px' }} >{contact.firstname} {contact.lastname}</span>
                    </div>

                    <div className="col-2 text-right">
                        <Button className="laff-blue rounded-circle" size="sm" onClick={this.props.onClose} aria-label="Close"><span>&nbsp;&times;&nbsp;</span></Button>                                                
                    </div>
                </div>
                
                <hr />

                {/* Name - Relationship */}
                <div id="edit-section-1" style={{display: this.props.section==1?'block':'none'}}>     
                    <div className="row" style={{marginBottom:'10px'}}>  
                        
                        <div className="col-12"><strong>Editing Name & Relationship</strong></div>
                    </div>
                    <div className="row" style={{marginBottom:'10px'}}>
                        <div className="col-6"><input className="form-control form-control-sm" value={contact.firstname} placeholder="First Name" name="firstname" onChange={this.handleChange}/></div>
                        <div className="col-6"><input className="form-control form-control-sm" value={contact.lastname} placeholder="Last Name" name="lastname" onChange={this.handleChange}/></div>
                    </div>
                    <div className="row" style={{marginBottom:'10px'}}>
                        <div className="col-12">

                            <div className="btn-group-toggle" data-toggle="buttons">
                                

                                <label className={`btn btn-secondary ${contact.isFan == "Y"?"active":""}`} onClick={this.handleCheckboxChange} > 
                                    <input type="checkbox" checked={contact.isFan == 'Y'?'checked':''}  autoComplete="off" name="isFan" readOnly /> Fan 
                                </label>

                                <label className={`btn btn-secondary ${contact.isPromoter == "Y"?"active":""}`} onClick={this.handleCheckboxChange} > 
                                    <input type="checkbox" checked={contact.isPromoter == 'Y'?'checked':''} autoComplete="off" name="isPromoter" readOnly /> Promoter
                                </label>

                                <label className={`btn btn-secondary ${contact.isBooker == "Y"?"active":""}`} onClick={this.handleCheckboxChange} > 
                                    <input type="checkbox" checked={contact.isBooker == 'Y'?'checked':''} autoComplete="off" name="isBooker" readOnly /> Booker
                                </label>
                            </div>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 text-right"><Button color="primary" size="sm" onClick={this.saveContact}>Done</Button></div>
                    </div>
                </div>

                {/* Social Media */}
                <div id="edit-section-2" style={{display: this.props.section==2?'block':'none'}}> 
                    <div className="row" style={{marginBottom:'10px'}}>
                        <div className="col-12"><strong>Editing Social Media</strong></div>
                    </div>
                    <div className="row">
                        <div className="col-12 text-right"><Button color="primary" size="sm" onClick={this.props.onClose}>Done</Button></div>
                    </div>
                </div>

                {/* Work Info */}
                <div id="edit-section-3" style={{display: this.props.section==3?'block':'none'}}> 
                    <div className="row" style={{marginBottom:'10px'}}>                        
                        <div className="col-12"><strong>Editing Company & Title Details</strong></div>
                    </div>
                    <div className="row" style={{marginBottom:'10px'}}>
                        <div className="col-6"><input className="form-control form-control-sm" name="company" value={contact.company} placeholder="Company" onChange={this.handleChange}/></div>
                        <div className="col-6"><input className="form-control form-control-sm" name="work_title" value={contact.work_title} placeholder="Title" onChange={this.handleChange}/></div>
                    </div>
                    <div className="row">
                        <div className="col-12 text-right"><Button color="primary" size="sm" onClick={this.saveContact}>Done</Button></div>
                    </div>
                </div>

                {/* Phone Numbers */}
                <div id="edit-section-4" style={{display: this.props.section==4?'block':'none'}}> 
                    <div className="row" style={{marginBottom:'10px'}}>  
                        <div className="col-12">
                            <strong>Editing Phone Details</strong>
                        </div>                        
                    </div>
                    <div className="row" style={{marginBottom:'10px'}}>
                        <div className="col-1"><FontAwesomeIcon icon={faMobileAlt} size="lg"/></div>
                        <div className="col-6 col-md-4"><input className="form-control form-control-sm" value={contact.phone_mobile} placeholder="Mobile Phone" name="phone_mobile" onChange={this.handleChange} /></div>                        
                    </div>
                    <div className="row" style={{marginBottom:'10px'}}>
                        <div className="col-1"><FontAwesomeIcon icon={faBuilding} size="lg"/></div>
                        <div className="col-6 col-md-4"><input className="form-control form-control-sm" value={contact.phone_work_1} placeholder="Work Phone 1" name="phone_work_1" onChange={this.handleChange} /></div>   
                        <div className="col-1 text-center">x</div>
                        <div className="col-3 col-md-3 col-lg-2"><input className="form-control form-control-sm" value={contact.phone_work_1_ext} placeholder="Extension" name="phone_work_1_ext" onChange={this.handleChange} /></div>                     
                    </div>
                    <div className="row" style={{marginBottom:'10px'}}>
                        <div className="col-1"><FontAwesomeIcon icon={faBuilding} size="lg"/></div>
                        <div className="col-6 col-md-4"><input className="form-control form-control-sm" value={contact.phone_work_2} placeholder="Work Phone 2" name="phone_work_2" onChange={this.handleChange} /></div>
                        <div className="col-1 text-center">x</div>
                        <div className="col-3 col-md-3 col-lg-2"><input className="form-control form-control-sm" value={contact.phone_work_2_ext} placeholder="Extension" name="phone_work_2_ext" onChange={this.handleChange} /></div>                     
                    </div>
                    <div className="row" style={{marginBottom:'10px'}}>
                        <div className="col-1"><FontAwesomeIcon icon={faHome} size="lg"/></div>                      
                        <div className="col-6 col-md-4"><input className="form-control form-control-sm" value={contact.phone_home_1} placeholder="Home Phone 1" name="phone_home_1" onChange={this.handleChange} /></div>                                          
                    </div>
                    <div className="row" style={{marginBottom:'10px'}}>
                        <div className="col-1"><FontAwesomeIcon icon={faHome} size="lg"/></div>
                        <div className="col-6 col-md-4"><input className="form-control form-control-sm" value={contact.phone_home_2} placeholder="Home Phone 2" name="phone_home_2" onChange={this.handleChange} /></div>                                          
                    </div>

                    <div className="row">
                        <div className="col-12 text-right"><Button color="primary" size="sm" onClick={this.saveContact}>Done</Button></div>
                    </div>
                </div>
                
                {/* Address */}
                
                <div id="edit-section-5" style={{display: this.props.section==5?'block':'none'}}> 
                    <div className="row" style={{marginBottom:'10px'}}>                        
                        <div className="col-12"><strong>Editing Address Details</strong></div>
                    </div>

                    {/* Work */}
                    <div className="row" style={{marginBottom:'10px'}}>
                        <div className="col-12"><FontAwesomeIcon icon={faBuilding} size="lg" /></div>
                    </div>
                    <div className="row">
                        <div style={{marginBottom:'10px'}} className="col-6 col-sm-2"><input type="text" className="form-control form-control-sm" value={contact.work_suite} placeholder="Suite" name="work_suite" onChange={this.handleChange} /></div>
                        <div style={{marginBottom:'10px'}} className="col-6 col-sm-2"><input type="text" className="form-control form-control-sm" value={contact.work_number} placeholder="Number" name="work_number" onChange={this.handleChange} /></div>
                        <div style={{marginBottom:'10px'}} className="col-12 col-sm-8"><input type="text" className="form-control form-control-sm" value={contact.work_street} placeholder="Street" name="work_street" onChange={this.handleChange} /></div>
                    </div>
                    <div className="row">
                        <div style={{marginBottom:'10px'}} className="col-4"><input type="text" className="form-control form-control-sm" value={contact.work_city} placeholder="City" name="work_city" onChange={this.handleChange} /></div>
                        <div style={{marginBottom:'10px'}} className="col-4"><input type="text" className="form-control form-control-sm" value={contact.work_province} placeholder="Province" name="work_province" onChange={this.handleChange} /></div>
                        <div style={{marginBottom:'10px'}} className="col-4"><input type="text" className="form-control form-control-sm" value={contact.work_postalcode} placeholder="Postal Code" name="work_postalcode" onChange={this.handleChange} /></div>
                    </div>
                    <div className="row">
                        <div style={{marginBottom:'10px'}} className="col-6 col-sm-4"><input type="text" className="form-control form-control-sm" value={contact.work_country} placeholder="Country" name="work_country" onChange={this.handleChange} /></div>                                                 
                    </div>


                    <br />
                    {/* Home */}
                    <div className="row" style={{marginBottom:'10px'}}>                        
                        <div className="col-12"><FontAwesomeIcon icon={faHome} size="lg"/></div>                        
                    </div>
                    <div className="row">
                        <div style={{marginBottom:'10px'}} className="col-6 col-sm-2"><input type="text" className="form-control form-control-sm" value={contact.home_suite} placeholder="Suite" name="home_suite" onChange={this.handleChange} /></div>
                        <div style={{marginBottom:'10px'}} className="col-6 col-sm-2"><input type="text" className="form-control form-control-sm" value={contact.home_number} placeholder="Number" name="home_number" onChange={this.handleChange} /></div>
                        <div style={{marginBottom:'10px'}} className="col-12 col-sm-8"><input type="text" className="form-control form-control-sm" value={contact.home_street} placeholder="Street"name="home_street" onChange={this.handleChange} /></div>
                    </div>
                    <div className="row">
                        <div style={{marginBottom:'10px'}} className="col-4"><input type="text" className="form-control form-control-sm" value={contact.home_city} placeholder="City" name="home_city" onChange={this.handleChange} /></div>
                        <div style={{marginBottom:'10px'}} className="col-4"><input type="text" className="form-control form-control-sm" value={contact.home_province} placeholder="Province" name="home_province" onChange={this.handleChange} /></div>
                        <div style={{marginBottom:'10px'}} className="col-4"><input type="text" className="form-control form-control-sm" value={contact.home_postalcode} placeholder="Postal Code" name="home_postalcode" onChange={this.handleChange} /></div>
                    </div>
                    <div className="row">
                        <div style={{marginBottom:'10px'}} className="col-6 col-sm-4"><input type="text" className="form-control form-control-sm" value={contact.home_country} placeholder="Country" name="home_country" onChange={this.handleChange} /></div>                                                 
                    </div>

                    <div className="row">
                        <div className="col-12 text-right"><Button color="primary" size="sm" onClick={this.saveContact}>Done</Button></div>
                    </div>
                </div>


                {/* e-info */}
                
                <div id="edit-section-6" style={{display: this.props.section==6?'block':'none'}}> 
                    <div className="row" style={{marginBottom:'10px'}}>                        
                        <div className="col-12"><strong>Editing E-info Details</strong></div>
                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            {/* Work */}
                            <div className="row" style={{marginBottom:'10px'}}>
                                <div className="col-12 text-center"><FontAwesomeIcon icon={faBuilding} size="lg" /></div>
                            </div>
                            <div className="row" style={{marginBottom:'10px'}}>
                                <div style={{marginBottom:'10px'}} className="col-12"><input type="text" className="form-control form-control-sm" value={contact.email_work} placeholder="Email" name="email_work" onChange={this.handleChange} /></div>
                                <div style={{marginBottom:'10px'}} className="col-12"><input type="text" className="form-control form-control-sm" value={contact.website_work} placeholder="Website" name="website_work" onChange={this.handleChange} /></div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            {/* Home */}
                            <div className="row" style={{marginBottom:'10px'}}>                        
                                <div className="col-12 text-center"><FontAwesomeIcon icon={faHome} size="lg"/></div>                        
                            </div>
                            <div className="row" style={{marginBottom:'10px'}}>
                                <div style={{marginBottom:'10px'}} className="col-12"><input type="text" className="form-control form-control-sm" value={contact.email_personal} placeholder="Email" name="email_personal" onChange={this.handleChange} /></div>
                                <div style={{marginBottom:'10px'}} className="col-12"><input type="text" className="form-control form-control-sm" value={contact.website_personal} placeholder="Website" name="website_personal" onChange={this.handleChange} /></div>
                            </div>
                        </div>
                    </div>
                                        
                    <div className="row">
                        <div className="col-12 text-right"><Button color="primary" size="sm" onClick={this.saveContact}>Done</Button></div>
                    </div>
                </div>
                

                {/*
                <div className="row">
                    <div className="col-sm-6">
                        
                        <div className="row">
                            <div className="col-12">
                                <Input type="text" className="form-control-sm" name="prefix" id="prefix" placeholder="Prefix" defaultValue={contact.prefix}/>
                            </div> 
                            <div className="col-12">
                                <Input type="text" className="form-control-sm" name="firstname" id="firstname" placeholder="First Name" defaultValue={contact.firstname}/>
                            </div> 
                            <div className="col-12">
                                <Input type="text" className="form-control-sm" name="lastname" id="lastname" placeholder="Last Name" defaultValue={contact.lastname}/>
                            </div>
                            <div className="col-12">
                                <Input type="text" className="form-control-sm" name="salutation" id="salutation" placeholder="Salutation" defaultValue={contact.salutation}/>
                            </div>
                        </div>               


                    </div>
                    <div className="col-sm-6">

                        {contact.picture ? <img src={'./img/contacts/'+ contact.picture} style={imageStyle}/> : <img src={'./img/contacts/portrait-placeholder.jpg'} style={imageStyle}/>} 
                        <Button style={{marginLeft:'25px'}} color="primary" size="sm">Change</Button>

                        <br /><br />
                        <span style={{width:'50px', backgroundColor:'#ffffff', border:'1px dotted #cccccc', padding:'5px'}}>&nbsp; &nbsp;</span>
                        <span style={{width:'50px', backgroundColor:'#ff9933', border:'1px dotted #cccccc', padding:'5px'}}>&nbsp; &nbsp;</span>

                    </div>
                </div>

                 
                <div className="row">
                    <div className="col-sm-6">                        
                        <br />
                        <div className="row">
                            <div className="col-12">
                                Work
                            </div>
                            <div className="col-12">
                                <Input type="text" className="form-control-sm" name="company" id="company" placeholder="Company" defaultValue={contact.company}/>
                            </div>
                            <div className="col-12">
                                <Input type="text" className="form-control-sm" name="work_title" id="work_title" placeholder="Title" defaultValue={contact.work_title}/>
                            </div>

                            <div className="col-12">
                                <Input type="text" className="form-control-sm" name="work_suite" id="work_suite" placeholder="Suite" defaultValue={contact.work_suite}/>
                            </div>
                            <div className="col-12">
                                <Input type="text" className="form-control-sm" name="work_number" id="work_number" placeholder="Number" defaultValue={contact.work_number}/>
                            </div>
                            <div className="col-12">
                                <Input type="text" className="form-control-sm" name="work_street" id="work_street" placeholder="Street" defaultValue={contact.work_street}/>
                            </div>
                            <div className="col-12">
                                <Input type="text" className="form-control-sm" name="work_city" id="work_city" placeholder="City" defaultValue={contact.work_city}/>
                            </div>
                            <div className="col-12">
                                <Input type="text" className="form-control-sm" name="work_province" id="work_province" placeholder="Province" defaultValue={contact.work_province}/>
                            </div>
                            <div className="col-12">
                                <Input type="text" className="form-control-sm" name="work_postalcode" id="work_postalcode" placeholder="Postalcode" defaultValue={contact.work_postalcode}/>
                            </div>
                            <div className="col-12">
                                <Input type="text" className="form-control-sm" name="work_country" id="work_country" placeholder="Country" defaultValue={contact.work_country}/>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6">
                        <br />
                        <div className="row">                            
                            <div className="col-12">
                                Home
                            </div>
                            <div className="col-12">
                                <Input type="text" className="form-control-sm" name="home_suite" id="home_suite" placeholder="Suite" defaultValue={contact.home_suite}/>
                            </div>
                            <div className="col-12">
                                <Input type="text" className="form-control-sm" name="home_number" id="home_number" placeholder="Number" defaultValue={contact.home_number}/>
                            </div>
                            <div className="col-12">
                                <Input type="text" className="form-control-sm" name="home_street" id="home_street" placeholder="Street" defaultValue={contact.home_street}/>
                            </div>
                            <div className="col-12">
                                <Input type="text" className="form-control-sm" name="home_city" id="home_city" placeholder="City" defaultValue={contact.home_city}/>
                            </div>
                            <div className="col-12">
                                <Input type="text" className="form-control-sm" name="home_province" id="home_province" placeholder="Province" defaultValue={contact.home_province}/>
                            </div>
                            <div className="col-12">
                                <Input type="text" className="form-control-sm" name="home_postalcode" id="home_postalcode" placeholder="Postalcode" defaultValue={contact.home_postalcode}/>
                            </div>
                            <div className="col-12">
                                <Input type="text" className="form-control-sm" name="home_country" id="home_country" placeholder="Country" defaultValue={contact.home_country}/>
                            </div>
                        </div>
                    </div>

                </div>
                */}

            </div>

        );

    }
}

const imageStyle = {
    height:'60px',
    width:'auto',
    borderRadius: '50%',
    /*paddingLeft:'20px',
    paddingRight:'20px'*/
}

export default ContactEdit;