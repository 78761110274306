import React, {Component} from 'react';

//import ContactImportFields from './ContactImportFields'
import ImportAccordion from './ImportAccordion'
import ContactListUpload from './ContactListUpload'
import axios from 'axios'

import NavBar from '../layout/NavBar';
import Footer from '../layout/Footer';
import SideNavBar from '../layout/SideNavBar';

import { faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class CSVImport extends Component {

    constructor(props) {

        super(props);
        
        this.state = {

            importRows: [],

            // importColumns:['fname', 'lname', 'streetaddr', 'city', 'country', 'postalcode', 'phone', 'email', 'website'],
            // importData:['John', 'Smith', '123 New Street', 'Toronto', 'Canada', 'M6G 9H5', '647-852-5412','12345@xyz.com', 'xyz.com'],
            openAccordion: false,
            activeColumnKey:'',
            filename:'',

            fieldsMapped: false,

            cleanColumns: [],
            cleanRows: [],

        }
    }
    
    componentDidMount() {

        
        //this.state.importColumns = ['fname', 'lname', 'streetaddr', 'city', 'country', 'postalcode', 'phone', 'email', 'website'];
        //this.state.importData = ['John', 'Smith', '123 New Street', 'Toronto', 'Canada', 'M6G 9H5', '647-852-5412','12345@xyz.com', 'xyz.com'];
    }

    showAccordion = (e) => {

        //console.log( e.target.id );
        
        this.setState( { activeColumnKey: e.target.dataset.key } );

        // using 'self' because 'this' is not available inside timeout function
        // using a timeout because it has enough time to set activeColumnKey 
        var self = this;
        var heightOffset = 38; // to push the according below the element
        setTimeout(function() {
            
            var top = document.getElementById("column-"+self.state.activeColumnKey).getBoundingClientRect().top; // get the top-left distance to the top of the element
            document.getElementById('import-accordion').style.top = top + heightOffset + 'px';  // set the top distance to the accordion

            var left = document.getElementById("column-"+self.state.activeColumnKey).getBoundingClientRect().left; // get the left distance to the top-left of the element
            document.getElementById('import-accordion').style.left = left + 'px';  // set the left distance to the accordion



            // Highlight currently selected row
            /*
            const demoClasses = document.querySelectorAll('.tr-data-sel');

            demoClasses.forEach(element => {
                element.style.backgroundColor = 'rgba(238, 238, 238,1.0)';
            });
            */

            document.getElementById('tr-'+ self.state.activeColumnKey).style.backgroundColor = 'rgba(0, 80, 245,0.3)'; //#0050F5

            self.setState( { openAccordion:true } );


        }, 500);


    }

    // getting called by the child
    onSelectAccordionItem = (selectedText, selectedId) => {
        //alert(selectedText); 
        this.setState( {openAccordion:false} );

        document.getElementById("column-"+this.state.activeColumnKey).value = selectedText;
        
        //console.log(this.state.activeColumnKey + " " + selectedId);
        //console.log(this.state.importRows[1]);
        
        const tempRows = [ ...this.state.importRows ];
        tempRows[this.state.activeColumnKey].sel = selectedId;
        this.setState( {importRows:tempRows} );
        

        // Remove highlight of the tr
        document.getElementById('tr-'+ this.state.activeColumnKey).style.backgroundColor = 'rgba(238, 238, 238,1.0)';


        /*
        var self = this;
        setTimeout(function() {
            console.log(self.state.importRows);
        }, 500);
        */
    }

    onFileUpload = (rows, filename) => {

        this.setState( {importRows:rows} );
        this.setState( {filename:filename} );
        
    }

    confirmContacts = (e) => {
    
        e.preventDefault();
        this.setState( {fieldsMapped: true} );


        
        
        
        const tempCols = [ ...this.state.cleanColumns ];
        tempCols.push( { selColHeader: "Fan", dbColName: "isFan", checkbox: true } );
        tempCols.push( { selColHeader: "Booker", dbColName: "isBooker", checkbox: true } );
        tempCols.push( { selColHeader: "Promoter", dbColName: "isPromoter", checkbox: true  } );
        this.setState( {cleanColumns:tempCols} );
        
        



        const tempRows = [ ...this.state.cleanRows ];
        for (let i = 0; i < this.state.cleanRows.length; i++) {
            tempRows[i].push("Y");
            tempRows[i].push("N");
            tempRows[i].push("N");
        }      
        this.setState( {cleanRows:tempRows} );

        //console.log("Columns");
        //console.log(tempCols);

        //console.log("Rows");
        //console.log(tempRows);

        

        
    }

    uploadContacts = (e) => {

        e.preventDefault();

        console.log("Columns");
        console.log(this.state.cleanColumns);

        console.log("Rows");
        console.log(this.state.cleanRows);

        axios({
            method: 'post',
            url: 'https://laffnetdev.website/api/import/index.php',
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json' 
			},
			data: {
                action: 'INSERT_CONTACT_LIST',
                columns: this.state.cleanColumns,
                rows: this.state.cleanRows,
            
			}
            
        })
        .then(result => {

            console.log("Inserted");
            console.log( result.data  );
            
            if ( result.data.success ) {
                this.props.history.push("/contacts");
            } else {
                alert("Error occurred while trying to import 1 or more rows of data.");
            }
              
            
        })
        .catch(error => console.log("ERROR: "  + JSON.stringify(error) ) );

    }

    backToSelectFields = (e) => {

        e.preventDefault();
        
        this.setState( { cleanColumns: [] } );
        this.setState( { cleanRows: [] } );

    }

    processContacts = (e) => {

        e.preventDefault();

        this.setState( { cleanColumns: [] } );
        this.setState( { cleanRows: [] } );
        
        console.log("Sending");
        console.log(this.state.importRows);

        // uploading the data rows
        axios({
            method: 'post',
            url: 'https://laffnetdev.website/api/import/index.php',
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json' 
			},
			data: {
                action: 'UPLOAD_CONTACT_LIST',
                dataRows: this.state.importRows,
                filename: this.state.filename,
            
			}
            
        })
        .then(result => {

            console.log("Receiving");
            console.log( result.data  );


            this.setState( {cleanColumns: result.data.columns} );
            this.setState( {cleanRows: result.data.rows} );
            
            
            
        })
        .catch(error => console.log("ERROR: "  + JSON.stringify(error) ) );
        
    }



    
    handleCheck = (e) => {
        //alert(e.target.checked);
        //alert(e.target.value);


        var index = e.target.value;

        var row = index.split("_")[0];
        var col = index.split("_")[1];

        var val = (e.target.checked ? "Y":"N");

        //console.log("Fan Checked: " + e.target.checked + " " + row + " " + col );

        const tempRows = [ ...this.state.cleanRows ];
        tempRows[row][col] = val;
        this.setState( {cleanRows:tempRows} );


    }

    /*
    handleBookerCheck = (e) => {
        var index = e.target.value;
        var val = (e.target.checked ? "Y":"N");

        console.log("Booker Checked: " + e.target.checked);
    }

    handlePromoterCheck = (e) => {

    }
    */
    

    render () {

        var self = this;
        return (
            <div>

                <NavBar />

                <SideNavBar />

                <div className="page-main-container">

                    <br />
                                       
                    <div className="container page-content">
    
                        <div id="import-accordion" style={this.state.openAccordion ? {display:'block'}:{display:'none'}}>
                            <ImportAccordion triggerContactImport={this.onSelectAccordionItem}/>
                        </div>
    
                        <div style={ (this.state.importRows.length==0) ? {display:'block'}:{display:'none'}}>
                            <ContactListUpload triggerDataRowUpdate={this.onFileUpload}/>
                        </div>
    
    
                        
                        
    
    
                        <div style={ (this.state.importRows.length && this.state.cleanRows.length==0) ? {display:'block'}:{display:'none'}} id="div-setup">
                            <div style={{width:'100%', maxWidth:'750px', marginLeft:'auto', marginRight:'auto', backgroundColor:'#eee', padding:'25px' }}>                
                                <table className="table">
                                    <thead>
                                        <tr>                       
                                            <th scope="col">Column Header</th> 
                                            <th scope="col">Your Data</th>
                                            <th scope="col">Select Field</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
    
                                        {this.state.importRows.map((item, i) => {
                                            return (
                                                <tr key={i} id={"tr-"+i} className="tr-data-sel">
                                                    <td>{ item.col }</td>
                                                    <td>{ item.val }</td>
                                                    <td><input type="text" className="form-control form-control-sm" id={"column-"+ i}/></td>
                                                    <td><button className="btn btn-sm btn-laff-sm-blue" onClick={this.showAccordion} id={"btn-open-accordion-"+ i} data-key={i}> >> </button></td>
                                                </tr>
                                            )
                                            
                                        })}
    
                                        {/* 
                                        {this.state.importRows.map(function(item, i){
                                            return (
                                                <tr key={i}>
                                                    <td>{ item.col }</td>
                                                    <td>{ item.val }</td>
                                                    <td><input type="text" className="form-control form-control-sm" /></td>
                                                    <td><button onClick={this.showAccordion}> >> </button></td>
                                                </tr>
                                            )
                                            
                                        })}
                                        */}
                                        
    
                                    
                                    </tbody>
                                </table>
    
                                <div className="text-right">
                                    <button className="btn btn-sm btn-laff-sm" onClick={this.processContacts}>Process Contacts</button>
                                </div>
                                <br /><br />
                            </div>
                        </div>
    
    
    
    
    
                        {/* 
                            Cleaned up data - Table - ready to go to Relationship stage
                        */}                
                        <div style={this.state.cleanRows.length && !this.state.fieldsMapped ? {display:'block'}:{display:'none'}} id="div-preview">
                            <div style={{width:'100%'}}>
                            <table className="table" style={{backgroundColor:'#eee'}}>
                                    <thead>
                                        <tr>  
    
                                            {this.state.cleanColumns.map((item, i) => {
                                                return (
                                                    <th key={i}>{item.selColHeader}</th>                                                    
                                                )                                            
                                            })}               
                                            
                                        </tr>
                                    </thead>
                                    <tbody>
                                        
    
                                        {this.state.cleanRows.map((row, i) => {
                                            
                                            return (
                                                <tr key={i}>
    
                                                    {row.map((item, j) => {                                                
                                                        return (                                                    
                                                            <td key={j}>{item}</td>
                                                        )}                                                
                                                    )}                                                
    
                                                </tr>
    
    
                                            )
                                                                                    
                                        })}
                                    
                                    </tbody>
                                </table>
                            </div>
    
                            <div style={{backgroundColor:'#eee', padding:'5px'}} className="text-right">
                                <button className="btn btn-sm btn-laff-sm" onClick={this.backToSelectFields}> 
                                    <FontAwesomeIcon 
                                        icon={faAngleDoubleLeft} size="1x"
                                        style={{cursor:'pointer', verticalAlign:'0.125em'}}
                                        data-toggle="tooltip" 
                                        title="Notes"
                                        transform="down-4"                                    
                                    /> Go Back
                                </button>
                                &nbsp;
                                <button className="btn btn-sm btn-laff-sm" onClick={this.confirmContacts}>
                                    Confirm <FontAwesomeIcon 
                                        icon={faAngleDoubleRight} size="1x"
                                        style={{cursor:'pointer', verticalAlign:'0.125em'}}
                                        data-toggle="tooltip" 
                                        title="Notes"
                                        transform="down-4"                               
                                    />   
                                </button> 
                            </div>
                            <br /><br />
                        </div>
    
    
    
    
                        {/* Selecting relationships */}
    
                        <div style={this.state.cleanRows.length && this.state.fieldsMapped ? {display:'block'}:{display:'none'}} id="div-preview">
                            <div style={{width:'100%'}}>
                            <table className="table" style={{backgroundColor:'#eee'}}>
                                    <thead>
                                        <tr>  
    
                                            {this.state.cleanColumns.map((item, i) => {
                                                return (
                                                    <th key={i}>{item.selColHeader}</th>                                                    
                                                )                                            
                                            })}
            
                                            {/* 
                                            <th>Fan</th>
                                            <th>Booker</th>
                                            <th>Promoter</th>
                                            */}                  
                                            
                                        </tr>
                                    </thead>
                                    <tbody>                                    
    
                                        {this.state.cleanRows.map((row, i) => {
                                            
                                            return (
                                                <tr key={i}>
    
                                                    {row.map((item, j) => {
                                                    
                                                        if (this.state.cleanColumns[j].checkbox)
                                                        {
                                                            if (this.state.cleanColumns[j].selColHeader=="Fan") {
                                                                return (
                                                                    <td key={j}>
                                                                        <input type="checkbox" className="chk-contact" value={i + "_" + j} checked={item=="Y"} onChange={this.handleCheck}  />
                                                                    </td>
                                                                );
                                                            } else if (this.state.cleanColumns[j].selColHeader=="Booker") {
                                                                return (
                                                                    <td key={j}>
                                                                        <input type="checkbox" className="chk-contact" value={i + "_" + j} checked={item=="Y"} onChange={this.handleCheck}  /> 
                                                                    </td>
                                                                );                                                            
                                                            } else if (this.state.cleanColumns[j].selColHeader=="Promoter") {
                                                                return (
                                                                    <td key={j}>
                                                                        <input type="checkbox" className="chk-contact" value={i + "_" + j} checked={item=="Y"} onChange={this.handleCheck}  /> 
                                                                    </td>
                                                                );                                                            
                                                            }
                                                            
    
                                                            
                                                        }
                                                        else
                                                        {
                                                            return (
                                                                <td key={j}>{item}</td>
                                                            );
                                                        }
    
                                                        
                                                    
                                                    })}
    
                                                    {/* 
                                                    <td>
                                                        <input type="checkbox" className="chk-contact" id={"chk-fan-" + i} value={i} checked onChange={this.handleFanCheck}  /> 
                                                    </td>
                                                    <td>
                                                        <input type="checkbox" className="chk-contact" id={"chk-booker-" + i} value={i}  onChange={this.handleBookerCheck}  /> 
                                                    </td>
                                                    <td>
                                                        <input type="checkbox" className="chk-contact" id={"chk-promoter" + i} value={i}  onChange={this.handlePromoterCheck}  /> 
                                                    </td>
                                                    */}
                                                </tr>
    
    
                                            )
                                                                                    
                                        })}
                                    
                                    </tbody>
                                </table>
                            </div>
    
                            <div style={{backgroundColor:'#eee', padding:'5px'}} className="text-right">
                                
                                <button className="btn btn-sm btn-laff-sm" onClick={this.uploadContacts}>
                                    Upload <FontAwesomeIcon 
                                        icon={faAngleDoubleRight} size="1x"
                                        style={{cursor:'pointer', verticalAlign:'0.125em'}}
                                        data-toggle="tooltip" 
                                        title="Notes"
                                        transform="down-4"                               
                                    />   
                                </button> 
                            </div>
                            <br /><br />
                        </div>
    
    
                    </div>
                </div>
                
                <Footer />

            </div>
        );
    }

}
export default CSVImport;