
import React, {Component, useState} from 'react';
import { Button, Input} from 'reactstrap';
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";

import { faTimesCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


class EditableTimeInput extends Component
{

    constructor(props) {

        super(props);
        
        this.state = {            
            
            time: new Date(this.props.date + ' ' + this.props.time + ' GMT-05:00'),
            //dateString: this.props.date,
            
            selectedTime: new Date(this.props.date + ' ' + this.props.time + ' GMT-05:00'),
            editMode: false,
            
                        

        } 
        
        //console.log(this.state.time);
    }

    changeEditMode = () => {
        this.setState( { editMode: !this.state.editMode } );
        this.setState( { selectedTime: this.state.time } );
    }

    updateComponentValue = () => {
 
        


        this.setState({ editMode: false });

        if (this.state.time != this.state.selectedTime) {
            
            this.setState({ time: this.state.selectedTime });

            setTimeout(() => {
                
                this.props.onUpdate( this.state.time );
    
            },200);
        }

        
        

        
    }

    handleChange = (time) => {
       
        this.setState( { selectedTime: time } );
    }

    renderEditView = () => {
        return <div>

           
                <DatePicker 
                    
                    selected={ this.state.selectedTime }
                    onChange={ this.handleChange }
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    className="date-input"
                />
                <Button 
                    className="btn btn-sm laff-green d-inline no-border"  
                    onClick={ this.updateComponentValue }
                    style={{marginLeft:'2px', marginBottom:'3px'}}
                    
                >
                    <FontAwesomeIcon 
                        icon={faCheckCircle} size="1x" 
                        style={{verticalAlign:'0.125em'}}
                        data-toggle="tooltip" 
                        title="Down"
                        transform="down-4"
                                                
                    /> 
                </Button>
                <Button 
                    className="btn btn-sm laff-red d-inline no-border" 
                    onClick={ this.changeEditMode }
                    style={{marginLeft:'2px', marginBottom:'3px'}}
                >
                    <FontAwesomeIcon 
                        icon={faTimesCircle} size="1x" 
                        style={{verticalAlign:'0.125em'}}
                        data-toggle="tooltip" 
                        title="Down"
                        transform="down-4"
                                                
                    /> 
                </Button>
            

            
           
            

        </div>
        
    }

    renderDefaultView = () => {
        return <div onClick={ this.changeEditMode }>
            { format(this.state.time, 'h:mm aa') }
            
        </div>
    }
    


    render () {
        return this.state.editMode ?
                    this.renderEditView()
                    :
                    this.renderDefaultView()
                
        ;
    }

}

export default EditableTimeInput;



