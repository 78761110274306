import React, {Component} from 'react';
import { Link } from 'react-router-dom';

import { faHome, faTachometerAlt, faUserFriends, faBullhorn, faSolid, faGlobe, faCalendarAlt, faGift, faTasks, faChevronDown } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class SideNavBar extends Component {

    constructor(props) {

        super(props);
        
        this.state = {            

            
		}
    }

    render() {

        return (
            <div style={sideBar}>
                <div className="text-center" >
                    <div style={{margin:'30px 20px'}}>
                        <Link to="/my-office" className="side-nav-link">
                            <FontAwesomeIcon 
                                icon={faHome} size="2x" 
                                style={{cursor:'pointer', verticalAlign:'0.125em'}}
                                data-toggle="tooltip" 
                                title="Home"                                                              
                            />
                        </Link>
                    </div>
                    <div style={{margin:'30px 20px'}}>
                        <Link to="/contacts" className="side-nav-link">                    
                            <FontAwesomeIcon 
                                icon={faTachometerAlt} size="2x" 
                                style={{cursor:'pointer', verticalAlign:'0.125em'}}
                                data-toggle="tooltip" 
                                title="Dashboard"                                                              
                            />
                        </Link>
                    </div>
                    <div style={{margin:'30px 20px'}}>
                        <Link to="/contacts" className="side-nav-link">    
                            <FontAwesomeIcon 
                                icon={faUserFriends} size="2x" 
                                style={{cursor:'pointer', verticalAlign:'0.125em'}}
                                data-toggle="tooltip" 
                                title="Contacts"                                                              
                            />
                        </Link>
                    </div>
                    <div style={{margin:'30px 20px'}}>
                        <Link to="/gig-pipeline" className="side-nav-link">                    
                            <FontAwesomeIcon 
                                icon={faBullhorn} size="2x" 
                                style={{cursor:'pointer', verticalAlign:'0.125em'}}
                                data-toggle="tooltip" 
                                title="Marketing"                                                              
                            />
                        </Link>
                    </div>
                    <div style={{margin:'30px 20px'}}>
                        <Link to="/web-management" className="side-nav-link">                    
                            <FontAwesomeIcon 
                                icon={faGlobe} size="2x" 
                                style={{cursor:'pointer', verticalAlign:'0.125em'}}
                                data-toggle="tooltip" 
                                title="Website Setup"                                                              
                            />
                        </Link>
                    </div>

                </div>
            </div>
        );
    }
}

const sideBar = {
    backgroundColor:'#ccc', 
    height:'calc(100vh - 65px)', 
    width:'80px',


    position: 'fixed', /* Fixed Sidebar (stay in place on scroll) */
    zIndex: 1, /* Stay on top */
    /*top: 60,*/ /* Stay at the top */
    left: 0,
    
    overflowX: 'hidden', 
}

export default SideNavBar;
