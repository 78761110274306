import React, {Component} from 'react';
import axios from 'axios';

import NavBar from '../layout/NavBar';
import Footer from '../layout/Footer';


class EmailSend extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {

        }
    }

    componentDidMount() {
    
    
    }

    onClickSend = (e) => {

        e.preventDefault();
        console.log("On Click Send");
        this.sendEmail();
        
        

    }

    
    sendEmail = () => {
        
        //console.log(this.props.content);
        var newHtml = this.props.content.replace("charset", "XXXX");
  

        axios({
            method: 'post',
            url: 'https://laffnetdev.website/api/emails/index.php',
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json' ,

                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': 'content-type'
            },
            
			data: {
                action: 'SEND_EMAIL',        
                emailContent: newHtml,
                emailSubject: this.props.subject
                
                //emailContent: '<h1>Hello</h1><p>This is a test</p>',
                //emailSubject: 'Test from Laffnet'
			}
            
        })
        .then(result => {
			
            console.log(result);
            
			
        })
        .catch(error => console.log("ERROR: "  + JSON.stringify(error) ) );
        
    }


    render() {


        return (
            <div>
                <button id="send-email" className="btn btn-laff-sm" onClick={this.onClickSend}>Send Email</button>
            </div>
        );
    }

}
export default EmailSend;