import React, {Component} from 'react';
import Contact from '../contacts/Contact';
import EmailTemplates from './EmailTemplates';
import axios from 'axios';

import { Link } from 'react-router-dom';

import NavBar from '../layout/NavBar';
import Footer from '../layout/Footer';


/**
 * List of Customer's saved Email Templates
 */
class CustomerSavedTemplates extends Component {

    constructor(props) {

        super(props);
        
        this.state = {
            savedTemplateList: [],
		}
            
    }
    
    componentDidMount() {

		// loading saved email templates
		
		console.log("Loading");
        axios({
            method: 'post',
            url: 'https://laffnetdev.website/api/emails/index.php',
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json' 
			},
			data: {
				action: 'GET_SAVED_EMAIL_TEMPLATES'
			}
            
        })
        .then(result => {
			
			this.setState({savedTemplateList: result.data.saved_templates});
			
        })
		.catch(error => console.log("ERROR: "  + JSON.stringify(error) ) );
		

		// contacts - placeholder
		/*
        axios({
            method: 'post',
            url: 'https://laffnetdev.website/api/contacts/index.php',
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json' 
			},
			data: {
				action: 'GET_CONTACT_LIST'
			}
            
        })
        .then(result => {
			
			//console.log("gfgd3g");

			this.setState({contacts: result.data.contacts});
        })
		.catch(error => console.log("ERROR: "  + JSON.stringify(error) ) );
		*/


		console.log("Loaded");
		
    }

    render() {
        
        const templateList = this.state.savedTemplateList
					
			.map((template,i)=> (
				
				<div key={template.template_id} className="row">

					<div className="col-6"><Link to={`/select-email-contacts/${template.template_id}`} >{template.email_subject}</Link></div>
					<div className="col-6">{template.created}</div>
				</div>


			));

	   	return (
			<div>
				<NavBar />

				{/* 
<Link to={`/edit-email-template/${emailTemplate.id}`} >EDIT</Link>
*/}

				<div className="container">
					<div className="row">
						<div className="col-6">Email Subject</div>
						<div className="col-6">Created</div>
					</div>
					{templateList}
					
				</div>

                

				<Footer />

			</div>   
			
		);


    } 

}



export default CustomerSavedTemplates;