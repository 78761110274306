

import React, {Component} from 'react';
//import { Collapse, Button, CardBody, Card, Input } from 'reactstrap';
import axios from 'axios';

import ImportAccordionCategory from './ImportAccordionCategory';

class ImportAccordion extends Component {

    constructor(props) {

        super(props);
        
        this.state = {

            /*isOpen: false,*/
            categories: []
			
        }

        
    }
    
    componentDidMount() {

        // Getting the categories
        axios({
            method: 'post',
            url: 'https://laffnetdev.website/api/import/index.php',
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json' 
			},
			data: {
                action: 'GET_IMPORT_CATEGORIES',
            
			}
            
        })
        .then(result => {
            

            this.setState({categories: result.data.categories});

            //console.log( this.state.categories  );
            
        })
		.catch(error => console.log("ERROR: "  + JSON.stringify(error) ) );

    }

    /*
    onToggle = (e) => {

        this.setState( {isOpen: !this.state.isOpen } );
    }
    */
    

    // triggered when selecting an item - getting called from child
    onClickAccordionItem = (selectedText, selectedId) => {
        
        // Calling parent component
        this.props.triggerContactImport(selectedText, selectedId); // calling the parent

    }

    // triggered when toggling accordion category
    onToggleCategory = (categoryOpened) => {
        //alert(categoryOpened);

        const tempCategories = [ ...this.state.categories ];
        //console.log(tempCategories);


        
        tempCategories.map(function(item){

            if (item.category_name === categoryOpened)
            {
                if ( item.isOpen == "true" )
                    item.isOpen = "false";
                else
                    item.isOpen = "true";
            }
            else
            {
                item.isOpen = "false";
            }
            // need to change the array
        })

        this.setState({categories: tempCategories});
        //console.log(this.state.categories);
        
        
        /*
        var temp = [ 
                        {row_id:'2', category_id:'2', category_name:'Email', isOpen:"true" },
                        {row_id:'3', category_id:'3', category_name:'Phone', isOpen:"true" } 

                ]; 
        this.setState({categories: temp});
        */
        /*
        var self = this;
        setTimeout(function() {
            
            console.log(self.state.categories);
            
        }, 500);
        */
        
        
    }
    
    onClickIgnore = (e) => {

        var selectedItemText = e.target.textContent;
        // Calling parent component
        this.onClickAccordionItem(selectedItemText, '0'); // calling the parent
    }
    
    onClickClose = (e) => {
        this.props.triggerContactImport('');
    }
    

    render () {

        
        
        var self = this;
        return (
            <div>
                
                <div className="accordion-header text-right" style={{fontWeight:'bold'}}><span onClick={this.onClickClose}>x</span></div>
                <div className="accordion-body" onClick={ this.onClickIgnore }>IGNORE THIS DATA</div>

                {this.state.categories.map(function(item){
                    return <div key={item.row_id}>
                                <ImportAccordionCategory 
                                    category={item.category_name} 
                                    isOpen={item.isOpen}
                                    
                                    onToggle={self.onToggleCategory}
                                    triggerAccordion={self.onClickAccordionItem}  
                                />
                            </div>;
                })}


                
                
                
            </div>
        );
       
       
    }

}
export default ImportAccordion