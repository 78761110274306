import React, {Component} from 'react';
import { Link } from 'react-router-dom';


import NavBar from '../layout/NavBar';
import Footer from '../layout/Footer';
import SideNavBar from '../layout/SideNavBar';

class ContactImport extends Component
{
    constructor(props) {

        super(props);
        
        this.state = {
            
            
            
		}
            
    }

    render() {

        return (

            <div>
                <NavBar />

                <SideNavBar />

                <div className="page-main-container">

                    <div className="container">

                            
                        <br />
                        <br />

                        <div className="row">
                            <div className="col-12 col-sm-6 col-md-3">
                                <Link to="/csv-import">
                                    <div className="laff-blue text-center" style={{padding:'10px', borderRadius:'2px'}}>
                                        CSV Import
                                    </div>
                                </Link>                        
                            </div>
                            <div className="col-12 col-sm-6 col-md-3">
                                <Link to="/google-contact-import">
                                    <div className="laff-blue text-center" style={{padding:'10px', borderRadius:'2px'}}>
                                        Google Contact Import
                                    </div>
                                </Link>
                            </div>
                        </div>



                    </div>

                </div>

                    

                <Footer />
            </div>

        );
    }
} 

export default ContactImport;