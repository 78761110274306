import React, {Component} from 'react';
import EmailSend from './EmailSend';

import axios from 'axios';


import NavBar from '../layout/NavBar';
import Footer from '../layout/Footer';

class EditEmailTemplate extends Component {

    constructor(props) {

        super(props);
        
        this.state = {
            templateID: this.props.match.params.id,
            templateName: '',
            templateCSS: '',
            templateHTML: '',

            compiledHTML: '<h1>Hello</h1><p>This is a test from laffnet<br />blahblah</p>',
            emailSubject: 'Test from Laffnet'
            
		}
            
    }


    componentDidMount() {

        //alert(this.state.templateID);
        // get email template
        
        axios({
            method: 'post',
            url: 'https://laffnetdev.website/api/emails/index.php',
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json' 
			},
			data: {
                action: 'GET_EMAIL_TEMPLATE',
                id: this.state.templateID
			}
            
        })
        .then(result => {
			
            this.setState({templateName: result.data.email_template.template_name});
            this.setState({templateCSS: result.data.email_template.css});
            this.setState({templateHTML: result.data.email_template.html});
            //console.log(this.state.templateCSS);
            this.initStripo();
			
        })
        .catch(error => console.log("ERROR: "  + JSON.stringify(error) ) );
        
        

		
    }

    //
    initStripo = (e) => {

        console.log("INIT Stripo");
        

        var templateHTML = this.state.templateHTML;
        var templateCSS = this.state.templateCSS;

        const apiRequestData = {
            emailId: 123
        };
        const script = document.createElement('script');
        script.id = 'stripoScript';
        script.type = 'text/javascript';
        script.src = 'https://plugins.stripo.email/static/latest/stripo.js';
        script.onload = function () {
            window.Stripo.init({
                settingsId: 'stripoSettingsContainer',
                previewId: 'stripoPreviewContainer',
                codeEditorButtonId: 'codeEditor',
                locale: 'en',
                html: templateHTML,
                css: templateCSS,
				onTemplateLoaded: function() {
                    console.log('Template Loaded'); /* spinner hide */ 
                    
				}, 
				/* not working
				undoButtonId:'undo',
				redoButtonId:'redo',
				*/
				
				externalImagesLibrary: this.externalImagesLibrary,
					
					
                //ignoreClickOutsideSelectors: '#externalFileLibrary',
                apiRequestData: apiRequestData,
                getAuthToken: function (callback) {
                    
                    axios({
                        method: 'post',
                        url: 'https://plugins.stripo.email/api/v1/auth',
                        headers: { 
                            'Accept': 'application/json' 
                        },
                        data: {
                            pluginId: '84c0094912f44688a216d685d9429e55',
							secretKey: 'cd41027cffe54056b3fd42c46e86c3fc'
                        }
                        
                    })
                    .then(response => {
                        
                        
                        callback(response.data.token);
                        
                    })
                    .catch(error => console.log("ERROR: "  + JSON.stringify(error) ) );
							
					
                }
            });
        };
        document.body.appendChild(script);

    }

    externalImagesLibrary = (e) => {

    }

    updateTemplateName = (e) => {

        
        axios({
            method: 'post',
            url: 'https://laffnetdev.website/api/emails/index.php',
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json' 
			},
			data: {
                action: 'UPDATE_EMAIL_TEMPLATE_NAME',
                id: this.state.templateID,
                templateName: this.state.templateName
			}
            
        })
        .then(result => {
			
            alert("Saved");
            
			
        })
        .catch(error => console.log("ERROR: "  + JSON.stringify(error) ) );



    }

    
    compileEmail = (e) => {
        
        console.log("Nothing Happens");

        var self = this;

        
        window.StripoApi.compileEmail( function(error, html, ampHtml, ampErrors) {

            
            self.setState( {compiledHTML:html} );

            console.log("Email Compiled.");
            //console.log(html);
            

        });

        
    }
    
    saveTemplate = (e) => {

        var templateID = this.state.templateID;

        

        window.StripoApi.getTemplate( function(html, css, width, height) {
            
            //console.log(html);

            /* 
            Word 'charset' causes issues when sending through post. So 'charset' is replaced with another text,  
            which will be replaced back to 'charset' in the destination file 
            */
            var newHtml = html.replace("charset", "XXXX");

            /*'Access-Control-Allow-Origin': '*',*/
            
            axios({
                method: 'post',
                url: 'https://laffnetdev.website/api/emails/index.php',
                headers: { 
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': 'application/json',
                    
                },
                data: {
                    action: 'UPDATE_EMAIL_TEMPLATE',
                    
                    id: templateID,
                    css: css,
                    html: newHtml,
                    
                }
                
            })
            .then(result => {
                
                //console.log(result.data.msg);
                alert("Saved...");
                
                
            })
            .catch(error => console.log("ERROR: UPDATE_EMAIL_TEMPLATE - "  + JSON.stringify(error) ) );
            
            
            

            
		});


    }


    onTemplateNameChanged = (e) => {

        this.setState({templateName: e.target.value});

    }

    render() {
       


        return (
			<div>
				<NavBar />

				<div className="">

                    <div className="text-center">
                        <h3>{ this.state.templateName } <button id="changeTemplate" className="btn btn-sm btn-laff" data-toggle="modal" data-target="#chng-templ-modal">Change</button></h3>	
                    </div>

                    <div id="externalSystemContainer">                        
                        <button id="codeEditor" className="btn btn-laff-sm">Code editor</button>
                        
                        <button id="save-template" className="btn btn-laff-sm float-right" onClick={this.saveTemplate}>Save Template</button>
                        {/* 
                        <button id="compile-email" className="btn btn-laff-sm float-right" onClick={this.compileEmail}>Compile Email</button>
                        */}
                        {/* 
                        <button id="send-email" className="btn btn-laff-sm float-right" onClick={this.sendEmail}>Send Email</button>
                        */}
                        
                        {/* 
                        <EmailSend content={this.state.compiledHTML} subject={this.state.emailSubject}/>
                        */}
                    </div>

                    <div className="row">
                        {/* Plugin containers */}
                        <div className="col-md-6" id="stripoSettingsContainer">Loading...</div>
                        <div className="col-md-6" id="stripoPreviewContainer"></div>		
					</div>
                    
				</div>


                {/* Template Name change modal */}
                <div className="modal fade" id="chng-templ-modal" tabIndex="-1" role="dialog" aria-labelledby="chng-templ-modalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Change Template Name</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <br/>
                                <input type="text" className="form-control form-control-sm" value={this.state.templateName} onChange={this.onTemplateNameChanged}/>
                                <br/>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-sm btn-secondary" data-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-laff-sm" onClick={this.updateTemplateName}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>

				<Footer />

			</div>   
			
		);
    }


}

export default EditEmailTemplate;