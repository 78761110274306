import React, {Component} from 'react';
import PropTypes from 'prop-types';


class Contact extends Component {
  
    

    constructor(props) {

        super(props);
        
        this.state = {
            height: 100,
            contactID: this.props.contact.id,
            //checked: false,
            //checked: (this.props.contact.checked=='Y'),
        }
            
    }
    

    componentDidMount() {

        

    }

    componentDidUpdate(prevProps) {

        //console.log( this.props.contact.checked );
        /*
        if(prevProps.contact.checked !== this.props.contact.checked)
        {
            this.setState( { checked: (this.props.contact.checked=='Y') } );
        }
        */

        //console.log("updating 1");
        /*
        if(prevProps.contact.checked !== this.props.contact.checked) {

            console.log(this.props.contact.id + " " + this.props.contact.checked);
            //this.setState({contact: this.props.contact});

            
            var self = this;

            setTimeout(function(){ 
                
            }, 500);
            
            
        }
        */


    }

    openDetails = (e) => {

        //alert(this.state.contactID);
        this.props.openDetails(this.state.contactID);
        //this.props.openDetails(this.props.index);
    }

    handleCheck = (e) => {

        

        this.props.contactChecked( this.state.contactID, e.target.checked );

        /*
        this.setState( {checked: !this.state.checked} ); 

        var self = this;

        setTimeout(function(){ 
            self.props.contactChecked( self.state.contactID, self.state.checked );            
        }, 500);
        */

        
        
    }

/*
    getAgeStyle = () => {
        return {
            color: this.props.contact.age > 35 ? 'red':'blue'
        }
    }

    zoomPicOut = () => {
        this.setState({height: this.state.height-20});
    }

    zoomPicIn = () => {
        this.setState({height: this.state.height+20});
    }
*/
    render() {

        const contact = this.props.contact;
        const { firstname, lastname, id, picture, colour, email_personal, phone_mobile, home_city, checked } = this.props.contact;
        const { height } = this.state;
        
        if (this.props.view=='list')
        {
            return (
                
                <div className="d-flex flex-wrap align-items-center" style={{
                                                                        backgroundColor:'#ffffff',
                                                                        paddingTop:'5px', 
                                                                        paddingBottom:'5px',
                                                                        borderBottom:'6px #a8b4ba solid' }}  >
                    <div className="col-md-10">
                        <div className="row">   
                            <div className="float-left" style={{ paddingLeft:'20px' }}>
                                <input type="checkbox" className="chk-contact" id={"chk-" + id} value={id}  onChange={this.handleCheck}  /> 
                                &nbsp;
                                {picture ? <img src={'./img/contacts/'+ picture} style={imageStyle}/> : <img src={'./img/contacts/portrait-placeholder.jpg'} style={imageStyle}/>}                    
                            </div>
                            <div className="col-md-4">
                                <div style={{ fontSize:'14px' }}>{firstname} {lastname}</div>
                                <div style={{ fontSize:'14px', color:'#888888' }}>Position</div>
                                <div style={{ fontSize:'14px', color:'#888888' }}>{contact.company}</div>
                            </div>
                            <div className="col-md-4">
                                <div style={{ fontSize:'13px', color:'#888888', lineHeight:'21px' }}>{email_personal}</div>
                                <div style={{ fontSize:'13px', color:'#888888', lineHeight:'21px' }}>{phone_mobile}</div>
                                <div style={{ fontSize:'13px', color:'#888888', lineHeight:'21px' }}>{home_city}</div>                    
                            </div>
                        </div>                    
                    </div>
                    <div className="col-md-2">
                        <div className="float-right" style={{ paddingRight:'20px' }}>
                            <img src="./img/icons/eye.png" style={{width:'28px', cursor:'pointer'}} onClick={this.openDetails} />              
                        </div>
                    </div>



                    
                    

                    
                    {/*
                    <button onClick={this.zoomPicOut}>-</button>
                    <button onClick={this.zoomPicIn}>+</button>
                    */}
                </div>
            
            );
        }
        else if (this.props.view=='tile')
        {
            return (
                <div className="d-flex flex-wrap align-items-center" style={{
                                                                        backgroundColor:'#ffffff',
                                                                        paddingTop:'5px', 
                                                                        paddingBottom:'5px',
                                                                        borderBottom:'6px #a8b4ba solid' }}  >
                    <div className="col-12">
                        <div className="row">   
                            <div className="col-8" style={{ paddingLeft:'20px' }}>
                                <input type="checkbox" id={id}/>
                                &nbsp;
                                {picture ? <img src={'./img/contacts/'+ picture} style={imageStyle}/> : <img src={'./img/contacts/portrait-placeholder.jpg'} style={imageStyle}/>}                    
                            </div>

                            <div className="col-4 text-right">
                                <img src="./img/icons/eye.png" style={{width:'28px', cursor:'pointer'}} onClick={this.openDetails} />                
                            </div>
                        </div>
                        <div className="row"> 
                            <div className="col-md-12">
                                <div style={{ fontSize:'14px' }}>{firstname} {lastname}</div>
                                <div style={{ fontSize:'14px', color:'#888888' }}>Position</div>
                                <div style={{ fontSize:'14px', color:'#888888' }}>Company</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div style={{ fontSize:'13px', color:'#888888', lineHeight:'21px' }}>{email_personal}</div>
                                <div style={{ fontSize:'13px', color:'#888888', lineHeight:'21px' }}>{phone_mobile}</div>
                                <div style={{ fontSize:'13px', color:'#888888', lineHeight:'21px' }}>{home_city}</div>                    
                            </div>
                        </div>                    
                    </div>
                    
                </div>
            );
        }
        
        
    }

    
}

// PropTypes
Contact.porpTypes = {
    contact: PropTypes.object.isRequired
}


// style definition


const imageStyle = {
    height:'60px',
    width:'auto',
    borderRadius: '50%',
    paddingLeft:'20px',
    paddingRight:'20px'
}


export default Contact;