import React, {Component} from 'react';
import EmailTemplate from './EmailTemplate';
import axios from 'axios';

import NavBar from '../layout/NavBar';
import Footer from '../layout/Footer';


class EmailTemplates extends Component {

    constructor(props) {

        super(props);
        
        this.state = {
            emailTemplates: [],
            
		}
            
    }
    
    componentDidMount() {

		// email templates
        axios({
            method: 'post',
            url: 'https://laffnetdev.website/api/emails/index.php',
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json' 
			},
			data: {
				action: 'GET_EMAIL_TEMPLATES'
			}
            
        })
        .then(result => {
			

			this.setState({emailTemplates: result.data.email_templates});
			console.log(this.state.emailTemplates.length);
        })
		.catch(error => console.log("ERROR: "  + JSON.stringify(error) ) );


		
    }

    render() {
        
        /*
		return (
			
			this.state.contacts.map((contact,i)=> (
				<Contact key={contact.id} contact={contact}/>
			))
		);
        */
		
		const templateList = this.state.emailTemplates.map((emailTemplate,i)=> (
								<div className="col-3" key={emailTemplate.id}>
									<EmailTemplate emailTemplate={emailTemplate}/>	
								</div>
							));

	   	return (
			<div>
				

                {/* Email Templates */}
				<div>
					<div className="row">
						{templateList}
					</div>				
				</div>

				

			</div>   
			
		);


    } 

}



export default EmailTemplates;