import React, {Component, useState} from 'react';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';




import MyOffice from './components/office/MyOffice';



import NavBar from './components/layout/NavBar';
import Footer from './components/layout/Footer';
import ContactList from './components/contacts/ContactList';
import Profile from './components/pages/Profile';
import Home from './components/pages/Home';

import HomeExpress from './components/pages/HomeExpress';

import FBTest from './components/fb/FBTest';
import FBGraphAPI from './components/fb/FBGraphAPI';

import Signup from './components/signup/Signup';
import OnBoarding from './components/onboarding/OnBoarding';
import WebSetup from './components/onboarding/WebSetup';
import Headshot from './components/onboarding/Headshot';


import CustomerSavedTemplates from './components/emails/CustomerSavedTemplates';
import EmailTemplates from './components/emails/EmailTemplates';

import SelectEmailContacts from './components/bulkEmail/SelectEmailContacts';
import SavedTemplates from './components/bulkEmail/SavedTemplates';
import EditEmailTemplate from './components/bulkEmail/EditEmailTemplate';
import PreviewNewsletter from './components/bulkEmail/PreviewNewsletter';


import SendBulkEmail from './components/bulkEmail/SendBulkEmail';



import Calendar from './components/calendar/Calendar';
import NotFound from './components/pages/NotFound';
import Login from './components/pages/Login';
import AdminDashboard from './components/admin/AdminDashboard';
import AccountSetup from './components/setup/AccountSetup';
import SetupConfirm from './components/setup/SetupConfirm';
import ContactImport from './components/contactImport/ContactImport';
import CSVImport from './components/contactImport/CSVImport';
import GoogleContactImport from './components/contactImport/GoogleContactImport';

import Pipeline from './components/funnel/Pipeline';

import EmailSend from './components/bulkEmail/EmailSend';

import TestGoogle from './components/google/TestGoogle';
import Gmail from './components/google/Gmail';

import OutlookMail from './components/outlook/OutlookMail';

import WebsiteSetup from './components/website/WebsiteSetup';
import WebsiteManagement from './components/website/WebsiteManagement';


//import './fonts/roboto/Roboto-Regular.ttf';
//import './fonts/nunito/Nunito-Regular.ttf';

import './App.css';



/*
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
*/

class App extends Component {
	
	
	constructor(props) {

        super(props);
        
        this.state = {
			
		}
            
	}


	componentDidMount() {
                
	}
	
	render() {

		
		return (
			
			<Router>
				
				<div className="App" style={backgroundStyle}>
					<Switch>
						<Route exact path="/" component={Login} />
						<Route path="/login" component={Login} />
						<Route component={DefaultContainer}/>		
					</Switch>
				</div>
				
				
			</Router>

			/*
			<Router>
				
				<div className="App">
					<NavBar />
					
					
					
						<Switch>
							<Route exact path="/" component={Login} />
							<Route path="/login" component={Login} />
							<Route path="/home" component={Home} />
							<Route path="/contacts" component={ContactList} />
							<Route path="/emails" component={EmailList} />
							<Route path="/profile" component={Profile} />
							<Route path="/calendar" component={Calendar} />
							<Route path="*" component={NotFound} />
						</Switch>



					<Footer />
				</div>
			
			</Router>
			*/
			
		);
			
			
	}
}


const backgroundStyle = {
    /*width:'100%',  
	height:'100%',*/
	
/*
    backgroundImage: `url(./img/bg/70s.png)`, 
	
	
	backgroundRepeat: 'repeat', 
	
	backgroundColor: '#a8b4ba'*/ /* 838C91 Set a background color that will be displayed while the background image is loading */
}
  
const DefaultContainer = () => (
	<div>
		{/*<NavBar/>*/}
			{/*<div style={{minHeight:'450px'}}>*/}
				<Switch>

					<Route path="/signup" component={Signup} />
					<Route path="/my-office" component={MyOffice} />
					<Route path="/home-express" component={HomeExpress} />


					<Route path="/onboarding" component={OnBoarding} />
					<Route path="/websetup" component={WebSetup} />
					<Route path="/headshot" component={Headshot} />
					

					<Route path="/home" component={Home} />
					<Route path="/contacts" component={ContactList} />
					<Route path="/contact-import" component={ContactImport} />
					<Route path="/csv-import" component={CSVImport} />	
					<Route path="/google-contact-import" component={GoogleContactImport} />				
					

					
					<Route path="/saved-templates" component={SavedTemplates} />
					<Route path="/edit-email-template/:id" component={EditEmailTemplate} />
					<Route path="/select-email-contacts/:templateid" component={SelectEmailContacts} />
					<Route path="/preview-newsletter/:batchID" component={PreviewNewsletter} />
					

					<Route path="/send-bulk-email" component={SendBulkEmail} />
					
					<Route path="/web-setup" component={WebsiteSetup} />
					<Route path="/web-management" component={WebsiteManagement} />

					<Route path="/customer-templates" component={CustomerSavedTemplates} />
					

					<Route path="/fb-test" component={FBTest} />						
					<Route path="/fb-graph-api" component={FBGraphAPI} />

					

					<Route path="/email-templates" component={EmailTemplates} />

					<Route path="/sendemail" component={EmailSend} />

					<Route path="/profile" component={Profile} />
					<Route path="/calendar" component={Calendar} />
					<Route path="/settings" component={AccountSetup} />
					<Route path="/setup-confirm" component={SetupConfirm} />

					<Route path="/gig-pipeline" component={Pipeline} />
					

					<Route path="/test-google" component={TestGoogle} />	
					<Route path="/gmail" component={Gmail} />	

					<Route path="/outlook-mail" component={OutlookMail} />			

					
					<Route path="/admin-dashboard" component={AdminDashboard} />
										
					<Route path="*" component={NotFound} />
				</Switch>
			{/*</div>*/}
				
		
	</div>
)

   
export default App;
