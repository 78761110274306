

import React, {Component} from 'react';
import { Collapse, Button, CardBody, Card, Input } from 'reactstrap';
import axios from 'axios';

class ImportAccordionCategory extends Component {

    constructor(props) {

        super(props);
        
        this.state = {

            category: this.props.category,
            isOpen: this.props.isOpen==="true",
            fields: []
            			
        }

        //console.log(this.props.isOpen==="true");
    }
    
    componentDidMount() {

        // Getting the fields for the category
        axios({
            method: 'post',
            url: 'https://laffnetdev.website/api/import/index.php',
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json' 
			},
			data: {
                action: 'GET_IMPORT_FIELD_LIST',
                categoryName: this.state.category,
			}
            
        })
        .then(result => {
            

            //console.log(result.data.fields);
            //console.log(result.data.fields[0].category);

            this.setState({fields: result.data.fields});
            
        })
		.catch(error => console.log("ERROR: "  + JSON.stringify(error) ) );
    }


    onToggle = (e) => {

        //this.setState( {isOpen: !this.state.isOpen } );
        
        //console.log("Current: " + this.props.isOpen);
        
        
        this.props.onToggle(this.state.category);
        var self = this;
        //setTimeout(function() { 
        //    console.log(self.props.isOpen==="true"); 
        //}, 1000);
        
    }

    /*
    componentWillReceiveProps(newProp) {
        this.setState( {isOpen: newProp.isOpen==="true" } );
    }
    */

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.isOpen !== prevProps.isOpen) {

            this.setState( {isOpen: this.props.isOpen==="true" } );
            //this.fetchData(this.props.isOpen);
        }
    }

    onClickAccordionItem = (e) => {
        
        
        // closing the accordion, so it will stay closed when it opens next time
        this.setState( {isOpen: false } );

        
        var selectedItemText = e.target.textContent;    // Getting the text content of the clicked element
        var selectedRowId = e.target.dataset.rowid;     // Getting the data value if the clicked element
        //console.log(selectedRowId);


        this.props.triggerAccordion(selectedItemText, selectedRowId); // Calling parent component

        this.props.onToggle(this.state.category); // setting the parent state - so it's fine when you open next time 

        // CAN'T FIX NOW
        // e.target.style.textDecoration = 'line-through';
    }

    render () {
        /*
        const items = this.props.items.map(function(item){
            return <li> {item} </li>;
        });
        */
        var self = this; // 'this' is not available in .map 
        return (
            <div>
                
                <div className="accordion-category" onClick={this.onToggle}>
                    {this.state.category} 
                    <div className="float-right" style={this.state.isOpen ? {display:'none'}:{display:'block'}}>+</div>
                    <div className="float-right" style={this.state.isOpen ? {display:'block'} : {display:'none'}}><strong>-</strong></div>
                </div>
                <Collapse isOpen={this.state.isOpen}>

                    {this.state.fields.map(function(item){
                        return <div key={item.row_id} data-rowid={item.row_id} className="accordion-body" onClick={self.onClickAccordionItem}>{item.label}</div>;
                    })}

                    {/*
                    {this.props.items.map(function(item){
                        return <div key={item.row_id} className="accordion-body">{item.lebel}</div>;
                    })}
                    */}                      
                   
                </Collapse>              
                
            </div>
        );
    }

}

const headerStyle = {
    
}

/*
const bodyItemStyle = {
    

    ':hover': {
        backgroundColor: '#ccc'
    },
}
*/
export default ImportAccordionCategory