import React, {Component} from 'react';
import MicrosoftLogin from 'react-microsoft-login';
 

import NavBar from '../layout/NavBar';
import Footer from '../layout/Footer';

class OutlookMail extends Component {

    constructor(props) {

        super(props);
        
        this.state = {
                        			
        }

    }

    authHandler = (err, data) => {

        console.log(err, data);

    }

    render () {

        return (
            <div>

                <NavBar />
                {/*
                <MicrosoftLogin clientId={YOUR_CLIENT_ID} authCallback={this.authHandler} />
                */}
                Login

                <Footer />
            </div>
        );
    }

}

export default OutlookMail;


