
import React, {Component} from 'react';
import { Button, Input, Table , Modal, ModalHeader, ModalBody, ModalFooter, CustomInput} from 'reactstrap';


import NavBar from '../layout/NavBar';
import Footer from '../layout/Footer';
import SideNavBar from '../layout/SideNavBar';

import AppointmentDetails from './AppointmentDetails';
import LifeEventDetails from './LifeEventDetails';
import TaskDetails from './TaskDetails';

import DatePicker from "react-datepicker";
import { format } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";



import axios from 'axios';

import { faHome, faTachometerAlt, faUserFriends, faBullhorn, faCalendarAlt, faGift, faTasks, faChevronDown, faPlus } from '@fortawesome/free-solid-svg-icons';



import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import  Bootbox  from  'bootbox-react';

class MyOffice extends Component
{
    constructor(props) {

        super(props);
        
        this.state = {     
            customerID:'32566',       
            appts: [],
            tasks: [],
            lifeEvents: [],
            showAllEvents: false,
            selectedDate: new Date(),
            dateLabel: "Today's",

            currAppt: null,
            showApptDetails: false,

            currTask: null,
            showTaskDetails: false,

            currLEvent: null,
            showLEventDetails: false,
        }            
        
        
    }
    
    componentDidUpdate(prevProps) {

        
    }

    componentDidMount() {

        

        axios({
            method: 'post',
            url: 'https://laffnetdev.website/api/customer/index.php',
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json' 
			},
			data: {
                action: 'GET_CUSTOMER_BASIC_INFO',
                
			}
            
        })
        .then(result => {
			
            //console.log(result.data.info.id);
            //this.setState({customerID: result.data.info.id});

            this.loadData();
			
        })
        .catch(error => console.log("ERROR: "  + JSON.stringify(error) ) );





        
    }

    loadData = () => {
        
        

        axios({
            method: 'post',
            url: 'https://laffnetdev.website/api/office/index.php',
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json' 
			},
			data: {
                action: 'GET_DAILY_ACTIVITIES',
                customerID: this.state.customerID,
                date: this.state.selectedDate
                
			}
            
        })
        .then(result => {

            console.log(result.data);
			            
            this.setState({appts: result.data.events});
            this.setState({tasks: result.data.tasks});
            this.setState({lifeEvents: result.data.lifeEvents});

            this.setState({dateLabel: result.data.dateLabel});
			
        })
        .catch(error => console.log("ERROR: "  + JSON.stringify(error) ) );
        
    }

    showAllEvents = () => {
        this.setState({showAllEvents: true});
    }

    hideAllEvents = () => {
        this.setState({showAllEvents: false});
    }

    handleDateChange = (date) => {
        console.log(date);
        
        this.setState({selectedDate: date});
        var self = this;
		setTimeout(function(){ 
			self.loadData();
		}, 500);
    }
    
    openApptDetails = (index) => {
        console.log("Opening Appt");
        this.setState({currAppt: this.state.appts[index]});
        this.setState({showApptDetails: true});
    }
    closeApptDetails = () => {
        this.setState({currAppt: null});
        this.setState({showApptDetails: false});
    }


    openEventDetails = (index) => {
        console.log("Opening Life Event");
        this.setState({currLEvent: this.state.lifeEvents[index]});
        this.setState({showLEventDetails: true});

        var self = this;
        window.setTimeout(function(){
            console.log(self.state.currLEvent);
        },100);
    }
    closeEventDetails = () => {
        this.setState({currLEvent: null});
        this.setState({showLEventDetails: false});
    }
    

    openTaskDetails = (index) => {
        console.log("Opening Task");
        this.setState({currTask: this.state.tasks[index]});
        this.setState({showTaskDetails: true});

        var self = this;
        /*
        window.setTimeout(function(){
            console.log(self.state.currTask);
        },100);
        */
    }
    closeTaskDetails = () => {
        this.setState({currTask: null});
        this.setState({showTaskDetails: false});
    }



    render() {

        const entireEventsList = this.state.appts.map((appt,i)=> (
            <tr key={i} style={{cursor:'pointer'}} onClick={ () => alert("gfdgdfgd") }>
                <td style={{paddingTop: '8px', paddingBottom: '8px', width:'5%'}}>
                    <FontAwesomeIcon 
                        icon={faCalendarAlt} size="1x" 
                        style={{cursor:'pointer', verticalAlign:'0.125em', color:'#7B12BB'}}
                        data-toggle="tooltip" 
                        title="Appointment"
                        transform="down-4"                                                      
                    />
                </td>
                <td style={{paddingTop: '8px', paddingBottom: '8px', width:'20%'}}>{appt.contact_name}</td>
                <td style={{paddingTop: '8px', paddingBottom: '8px', width:'25%'}}>{appt.time}</td>
                <td style={{paddingTop: '8px', paddingBottom: '8px', width:'50%'}}>{appt.notes}</td>
            </tr>  
        ));

        const eventList = this.state.appts.slice(0, 5).map((appt,i)=> (
            <tr key={i} style={{cursor:'pointer'}} onClick={ () => this.openApptDetails(i) }>
                <td style={{paddingTop: '8px', paddingBottom: '8px', width:'5%'}}>
                    <FontAwesomeIcon 
                        icon={faCalendarAlt} size="1x" 
                        style={{cursor:'pointer', verticalAlign:'0.125em', color:'#7B12BB'}}
                        data-toggle="tooltip" 
                        title="Appointment"
                        transform="down-4"                                                      
                    />
                </td>
                <td style={{paddingTop: '8px', paddingBottom: '8px', width:'20%'}}>{appt.contact_name}</td>
                <td style={{paddingTop: '8px', paddingBottom: '8px', width:'25%'}}>{appt.time}</td>
                <td style={{paddingTop: '8px', paddingBottom: '8px', width:'50%'}}>{appt.notes}</td> 
            </tr>                
        ));

        const lifeEventList = this.state.lifeEvents.map((lifeEvent,i)=> (
            <tr key={i} style={{cursor:'pointer'}} onClick={ () => this.openEventDetails(i) }>
                <td style={{paddingTop: '8px', paddingBottom: '8px', width:'5%'}}>
                    <FontAwesomeIcon 
                        icon={faGift} size="1x" 
                        style={{cursor:'pointer', verticalAlign:'0.125em', color:'#FFDC00'}}
                        data-toggle="tooltip" 
                        title="Left Event"
                        transform="down-4"                                                      
                    />
                </td>
                <td style={{paddingTop: '8px', paddingBottom: '8px'}}>{lifeEvent.contact_name}</td>
                <td style={{paddingTop: '8px', paddingBottom: '8px'}}>{lifeEvent.type}</td>
                <td style={{paddingTop: '8px', paddingBottom: '8px'}}>{lifeEvent.date}</td>
                
                
            </tr>
        ));
            
        const taskList = this.state.tasks.map((task,i)=> (				
            <tr key={i} style={{cursor:'pointer'}} onClick={ () => this.openTaskDetails(i) }>
                <td style={{paddingTop: '8px', paddingBottom: '8px', width:'10%'}}>
                    <FontAwesomeIcon 
                        icon={faTasks} size="1x" 
                        style={{cursor:'pointer', verticalAlign:'0.125em', color:'#0050F5'}}
                        data-toggle="tooltip" 
                        title="Task"
                        transform="down-4"                                                      
                    />
                </td>
                <td style={{paddingTop: '8px', paddingBottom: '8px', width:'20%'}}>{task.contact_name}</td>
                <td style={{paddingTop: '8px', paddingBottom: '8px', width:'40%'}}>{task.name}</td>
                <td style={{paddingTop: '8px', paddingBottom: '8px', width:'20%'}}>Today</td>
                <td style={{paddingTop: '8px', paddingBottom: '8px', width:'10%'}}>
                
                </td>
            </tr>                
        ));


        return (
            <div>
                <NavBar />

                <SideNavBar />

                <div className="page-main-container">

                    <div className="container-fluid">

                        <div className="row" style={lessPadding}>
                            <div className="col-md-4" style={{paddingLeft:'3px', paddingRight:'3px', marginBottom:'6px'}}> 
                                
                                <div id="my-office-calendar" style={{height:'275px'}} className="laff-blue">
                                    <DatePicker
                                    
                                        selected={this.state.selectedDate}
                                        /*
                                        selected={startDate}
                                        onChange={date => setStartDate(date)}
                                        */
                                        onChange={this.handleDateChange}
                                        inline

                                        disabledKeyboardNavigation
                                    />

                                </div>
                            </div>

                            {/* appts */}
                            <div className="col-md-8" style={{ paddingLeft:'3px', paddingRight:'3px', marginBottom:'6px'}}>
                                <div style={{height:'275px', backgroundColor:'#cccccc'}}>

                                    <div className="laff-blue clearfix" style={{padding:'10px'}}>
                                        <div className="float-left">
                                            {this.state.dateLabel} Appointments <span className="badge badge-pill laff-green" style={{fontWeight:'normal'}}>{this.state.appts.length}</span>
                                        </div>
                                        <div className="float-right">                                            
                                            <FontAwesomeIcon  
                                                icon={faPlus} size="1x" 
                                                style={{cursor:'pointer'}}
                                                data-toggle="tooltip" 
                                                title="Add New Appointment"
                                                onClick={() => alert("Add New") }
                                            />
                                        </div>
                                    </div>
                                    <table style={{width:'100%', backgroundColor:'#fff', fontSize:'14px', marginBottom:'6px'}} className="table table-striped">
                                        <tbody>
                                            {eventList}
                                        </tbody>                                      
                                    </table>
                                    <div className="text-center" style={{fontSize:'14px'}}>
                                        <strong>
                                            {
                                                this.state.appts.length <= 5 ? 
                                                    (this.state.appts.length ? 
                                                        "That's All for the day":"Nothing to show"):
                                                    (<span onClick={this.showAllEvents} style={{cursor:'pointer'}}>More &gt;&gt;</span> )  

                                                    
                                            } 

                                    
                                        </strong>
                                    </div>
                                </div>

                                

                            </div>
                        </div>
                        <div className="row" style={{minHeight:'calc(100vh - 380px)', paddingLeft:'3px', paddingRight:'3px'}}>

                            {/* Life Events */}
                            <div className="col-md-4" style={lessPadding}>
                                <div style={{backgroundColor:'#cccccc', height:'100%'}}>
                                    <div className="laff-blue clearfix" style={{padding:'10px'}}> {/* , {minHeight:'100vh'} */}
                                        <div className="float-left">
                                            {this.state.dateLabel} Life Events <span className="badge badge-pill laff-green" style={{fontWeight:'normal'}}>{this.state.lifeEvents.length}</span>
                                        </div>                                        
                                        <div className="float-right">
                                            <FontAwesomeIcon  
                                                icon={faPlus} size="1x" 
                                                style={{cursor:'pointer'}}
                                                data-toggle="tooltip" 
                                                title="Add New Life Event"
                                                onClick={() => alert("Add New") }
                                            />
                                        </div>
                                    </div>
                                    <table style={{width:'100%', backgroundColor:'#fff', fontSize:'14px', marginBottom:'6px'}} className="table table-striped">
                                        <tbody>   
                                            {lifeEventList}
                                        </tbody>                                        
                                    </table>
                                    <div className="text-center" style={{fontSize:'14px'}}>
                                        <strong>{this.state.lifeEvents.length ? "That's All for the day":"Nothing to show"}</strong>
                                    </div>
                                </div>
                            </div>

                            {/* Tasks */}
                            <div className="col-md-8" style={lessPadding}>
                                <div style={{backgroundColor:'#cccccc', height:'100%'}}>
                                    <div className="laff-blue clearfix" style={{padding:'10px'}}>
                                        <div className="float-left">
                                            {this.state.dateLabel} Tasks <span className="badge badge-pill laff-green" style={{fontWeight:'normal'}}>{this.state.tasks.length}</span>
                                        </div>
                                        <div className="float-right">
                                            <FontAwesomeIcon  
                                                icon={faPlus} size="1x" 
                                                style={{cursor:'pointer'}}
                                                data-toggle="tooltip" 
                                                title="Add New Task"
                                                onClick={() => alert("Add New") }
                                            />
                                        </div>                                        
                                    </div>
                                    <table style={{width:'100%', backgroundColor:'#fff', fontSize:'14px', marginBottom:'6px'}} className="table table-striped">
                                        <tbody>
                                            {taskList}
                                        </tbody>
                                    </table>
                                    <div className="text-center" style={{fontSize:'14px'}}>
                                        <strong>{this.state.tasks.length ? "That's All for the day":"Nothing to show"}</strong>
                                    </div>
                                </div>
                                
                            </div>
                        </div>

                    </div>
                </div>

                    


                <Modal 
                    isOpen={this.state.showAllEvents}
                    size="lg"
                >
                    <ModalBody>                        

                        <div>
                            <div className="row">
                                <div className="col-8 laff-blue-txt">
                                    <h4>Today's Appointments</h4>
                                </div> 
                                <div className="col-4 text-right">
                                    <Button className="laff-blue rounded-circle" size="sm" onClick={this.hideAllEvents} aria-label="Close" style={{borderColor:'#0050F5'}}>
                                        <span>&nbsp;&times;&nbsp;</span>
                                    </Button>           
                                </div>                       
                            </div>
                            
                        </div>

                        <table style={{width:'100%', backgroundColor:'#fff', fontSize:'14px', marginBottom:'6px'}} className="table table-striped">
                            <tbody>
                                {entireEventsList}
                            </tbody>                                      
                        </table>
                                               
                        
                                                   
                    </ModalBody>
                </Modal>


                <AppointmentDetails showDetails={this.state.showApptDetails} closeApptDetails={this.closeApptDetails} appt={this.state.currAppt} />
                <LifeEventDetails showDetails={this.state.showLEventDetails} closeLEventDetails={this.closeEventDetails} lifeEvent={this.state.currLEvent} />                            
                <TaskDetails showDetails={this.state.showTaskDetails} closeTaskDetails={this.closeTaskDetails} task={this.state.currTask} /> 
                
                <Footer />
            </div>

        );

    }


}


const lessPadding = {
    paddingLeft:'3px',
    paddingRight:'3px'
}


export default MyOffice;