import React, {Component, useContext} from 'react';

import { InputGroup, InputGroupText, Button, Modal, ModalHeader, ModalBody, ModalFooter, TabContent, TabPane, Nav, NavItem, NavLink, Col, Row, Input, Label, FormGroup, Card, CardTitle, CardText } from 'reactstrap';
//import {TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col } from 'reactstrap';

import {  faPencilAlt, faCheckCircle, faTimesCircle, faDownload } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ImageGallery from './ImageGallery';
import ImageSearchGallery from './ImageSearchGallery'

import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css';

import NavBar from '../layout/NavBar';
import Footer from '../layout/Footer';
import SideNavBar from '../layout/SideNavBar';

import axios from 'axios';

import './WebSetup.css';



class WebSetup extends Component 
{
    constructor(props) {

        super(props);

        this.state = {
        
            showPicSelection: false,
            imagesPerPage: 4,
            activeTab: '1',

            updateCount: 0,

            bgImageUploaded: false,

            // cropper
            bg_base64: '',
            src: null,
            crop: {
                unit: '%',
                width: 30,
                aspect: 1920 / 1080
            },


            // Reels
            showReels: false,
            videos: []


        }

    }

    componentDidMount() {

        this.setState({showPicSelection: true});

    }

    toggle = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });
        }
    }
        

    /****************************************************** CROP START */

    onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () =>
                this.setState({ src: reader.result })
            );
            reader.readAsDataURL(e.target.files[0]);
        }


        this.setState({ bgImageUploaded: false });
    };
    
    // If you setState the crop in here you should return false.
    onImageLoaded = (image) => {
        this.imageRef = image;
    };
    

    /**
     * Saving the cropped image on the server
     *  */ 
    onCropSave = (e) => {
        
        var self = this;
        
        //console.log("Length: " + self.state.bg_base64.length);
        //console.log("===============");
        axios({
            method: 'post',
            url: 'https://laffnetdev.website/api/onboarding/index.php',
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json' 
			},
			data: {
				action: 'SAVE_UPLOADED_GALLERY_IMAGE',
				image: self.state.bg_base64
			}
        })
        .then(result => {
						
			console.log(result.data);

            var self = this;
                    
            this.setState({ src: null });
            this.setState({ croppedImageUrl: null });

            

            setTimeout(function(){ 
                self.setState({ bgImageUploaded: true });
            }, 500);

            
                
            var tmp = this.state.updateCount + 1;
            this.setState({ updateCount: tmp });

            			
        })
        .catch(error => 
            console.log("ERROR: "  + JSON.stringify(error) ) 
        );
        
    };

    onCropComplete = (crop) => {
        this.makeClientCrop(crop);
    };
    
    onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.setState({ crop });
    };
    
    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
          const croppedImageUrl = await this.getCroppedImg(
            this.imageRef,
            crop,
            'newFile.jpeg'
          );
          this.setState({ croppedImageUrl });
        }
    }
    
    getCroppedImg(image, crop, fileName) {

        var self = this;
        const canvas = document.createElement('canvas');
        const pixelRatio = window.devicePixelRatio;
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');
    
        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;
    
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';
    
        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width * scaleX,
          crop.height * scaleY
        );

        // As Base64 string
        const base64Image = canvas.toDataURL("image/jpeg");
        this.setState( {bg_base64: base64Image} );

        
    
        return new Promise((resolve, reject) => {
          canvas.toBlob(
            (blob) => {
              if (!blob) {
                //reject(new Error('Canvas is empty'));
                console.error('Canvas is empty');
                return;
              }
              blob.name = fileName;

              window.URL.revokeObjectURL(this.fileUrl);
              this.fileUrl = window.URL.createObjectURL(blob);
              resolve(this.fileUrl);
            },
            'image/jpeg',
            1
          );
        });
    }

    /************************************************************* CROP END */


    triggerFilePicker = () => {

        document.getElementById('filePicker').click();

    }


    gotoUpload = () => {
        this.toggle('8');
    }

    chooseWebsitePic = (filename) => {
        
        console.log(filename);
        axios({
            method: 'post',
            url: 'https://laffnetdev.website/api/onboarding/index.php',
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json' 
            },
            data: {
                
                action:     'SAVE_WEB_IMAGE',                
                filename:   filename

            }   
        })
        .then(result => {
        
            this.setState({showPicSelection: false});
            this.setState({showReels: true});    
            
            
        })
        .catch(error => 
            console.log("ERROR: "  + JSON.stringify(error) ) 
        );









                

    }



    /* Videos */

    addNewVideo = (e) => {

        var url = document.getElementById("new-video-url").value;
        this.getVideoEmbedCode(url);

        
              
        
    }

    getVideoEmbedCode = (url) => {

        axios({
            method: 'post',
            url: 'https://laffnetdev.website/api/onboarding/index.php',
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json' 
			},
			data: {
				action: 'GET_VIDEO_EMBED_CODE',
				url: url
			}
        })
        .then(result => {
						
            console.log(result.data.code);

            var videoCode = result.data.code;
			
            var tempVideos = [ ...this.state.videos ];
            tempVideos.push(videoCode);

            this.setState( {videos: tempVideos} );

            document.getElementById("new-video-url").value = "";
            			
        })
        .catch(error => 
            console.log("ERROR: "  + JSON.stringify(error) ) 
        );

        //url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
        //return (url[2] !== undefined) ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];


        // $video_embed  = 'https://www.youtube.com/embed/' . $my_array_of_vars['v'] . '?rel=0';
    }

    removeVideo = (index) => {

        if (index > -1) {

            var tempVideos = [ ...this.state.videos ];
            tempVideos.splice(index, 1);
        }
        
        this.setState( {videos: tempVideos} );

        
    }


    render() {

        var self = this;
        const { crop, croppedImageUrl, src } = this.state;

        var editEmbeddedVideos = this.state.videos.map((video,i)=> (

            
            
            <div key={i} className="col-sm-6 col-md-4" style={{marginBottom:'30px'}}>
                <div className="embed-responsive embed-responsive-16by9"><iframe src={ video } allowFullScreen="allowfullscreen" className="embed-responsive-item" frameBorder="0"></iframe></div>
                <br />
                <div className="text-center">
                    <button data-index={i} className="btn btn-sm laff-red" onClick={() => this.removeVideo(i)}>Remove</button>
                </div>
            </div>

                            
            
        ));

        return (
            <div style={backgroundStyle}>


                <Modal
                    isOpen={this.state.showPicSelection}
                    size="lg"
                    backdrop={false}
                    centered
                >
                    <ModalHeader className="laff-blue">
                        Select a brand background pic that screams YOU and your show...
                    </ModalHeader>
                    <ModalBody style={{backgroundColor:'#000000'}}> 
                        {/* 
                        <button onClick={() => { this.toggle('6'); }}>Test</button>                       
                        */}
                        <br />
                        <Nav tabs>
                            <NavItem>
                                <NavLink className={({ active: this.state.activeTab === '1' })} onClick={() => { this.toggle('1'); }}>All</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={({ active: this.state.activeTab === '2' })} onClick={() => { this.toggle('2'); }}>Cityscapes</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={({ active: this.state.activeTab === '3' })} onClick={() => { this.toggle('3'); }}>Urban</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={({ active: this.state.activeTab === '4' })} onClick={() => { this.toggle('4'); }}>Abstract</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={({ active: this.state.activeTab === '5' })} onClick={() => { this.toggle('5'); }}>Nature</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={({ active: this.state.activeTab === '6' })} onClick={() => { this.toggle('6'); }}>Brick Stuff</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={({ active: this.state.activeTab === '7' })} onClick={() => { this.toggle('7'); }}>Stage Stuff</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={({ active: this.state.activeTab === '8' })} onClick={() => { this.toggle('8'); }}>Upload</NavLink>
                            </NavItem>
                        </Nav>
                        
                        <br />
                        <TabContent activeTab={this.state.activeTab}>
                            <TabPane tabId="1">
                                <ImageSearchGallery onClickUpload={this.gotoUpload} onChoosePic={this.chooseWebsitePic}/>
                            </TabPane>

                            <TabPane tabId="2">                              
                                <ImageGallery category="Cityscapes" onClickUpload={this.gotoUpload} onChoosePic={this.chooseWebsitePic}/>                                
                            </TabPane>

                            <TabPane tabId="3">
                                <ImageGallery category="Urban" onClickUpload={this.gotoUpload} onChoosePic={this.chooseWebsitePic}/>
                            </TabPane>

                            <TabPane tabId="4">
                                <ImageGallery category="Abstract" onClickUpload={this.gotoUpload} onChoosePic={this.chooseWebsitePic}/>
                            </TabPane>

                            <TabPane tabId="5">
                                <ImageGallery category="Nature" onClickUpload={this.gotoUpload} onChoosePic={this.chooseWebsitePic}/>                            
                            </TabPane>

                            <TabPane tabId="6">
                                <ImageGallery category="Brick Stuff" onClickUpload={this.gotoUpload} onChoosePic={this.chooseWebsitePic}/>
                            </TabPane>

                            <TabPane tabId="7">
                                <ImageGallery category="Stage Stuff" onClickUpload={this.gotoUpload} onChoosePic={this.chooseWebsitePic}/>
                            </TabPane>

                            <TabPane tabId="8">

                                { !croppedImageUrl && (
                                    <div>   
                                        <button className="btn btn-sm btn-laff-sm-blue" onClick={() => this.triggerFilePicker()}>Add New</button>
                                        <br />
                                    </div>
                                )}

                                <div>
                                    <input type="file" id="filePicker" style={{visibility: 'hidden'}} onChange={this.onSelectFile} />

                                    {src && (
                                    <ReactCrop
                                        src={src}
                                        crop={crop}
                                        ruleOfThirds
                                        onImageLoaded={this.onImageLoaded}
                                        onComplete={this.onCropComplete}
                                        onChange={this.onCropChange}
                                    />
                                    )}

                                    
                                    {croppedImageUrl && (
                                        <div className="row">

                                            {/* 
                                            <div className="col-12 col-sm-6" style={{border:'1px #ccc solid'}}>
                                                <h5>Preview</h5>
                                                

                                                    <img alt="Crop" style={{ maxWidth: '100%' }} src={croppedImageUrl} />

                                                
                                            </div>
                                            */}

                                            <div className="col-6 text-left">
                                                <button className="btn btn-sm btn-laff-sm-blue" onClick={() => this.triggerFilePicker()}>Choose Another Image</button>
                                            </div>

                                            <div className="col-6 text-right">
                                                <button type="button" className="btn btn-sm btn-laff-sm-blue" id="btn-about-section-edit" onClick={this.onCropSave} >
                                                    <FontAwesomeIcon 
                                                        id="pencil-edit" 
                                                        icon={faDownload} 
                                                        size="lg" 
                                                        style={pencilStyle}                                                                  
                                                    />
                                                    &nbsp;Done
                                                </button>
                                            </div>

                                        </div>    
                                    )}
                                    

                                </div>



                                <br />

                                <ImageGallery category="Your Stuff" key={this.state.updateCount} onChoosePic={this.chooseWebsitePic}/>
                            </TabPane>

                        </TabContent>


                        <div className="text-left">
                            <img src="img/LaffNet Logo Transparent.png" style={{width:'110px', height:'auto'}}/>
                        </div>

                    </ModalBody>
                    
                </Modal>



                <Modal
                    isOpen={this.state.showReels}
                    size="lg"
                    backdrop={false}
                    centered
                >
                    <ModalHeader className="laff-blue">
                        Your Laffnet Booking & Lead Capture Site is a video-friendly zone!
                    </ModalHeader>
                    <ModalBody style={{backgroundColor:'#000000'}}> 

                        <p style={{color: '#fff'}}>Paste the links to up to 6 YouTube videos that you link to be featured on your site.</p>
                        <br />

                        <div className="row" style={this.state.videos.length < 6 ? {display:'block'} : {display:'none'}}>
                            <div className="col-12">
                                <InputGroup>            
                                    <Input placeholder="Your Video Link" id="new-video-url"/>
                                    <Button className="laff-blue" size="sm" onClick={this.addNewVideo} aria-label="Add" style={{borderColor:'#0050F5'}}>
                                        Add
                                    </Button>  
                                </InputGroup>

                                
                            </div>
                            
                            
                        </div>

                        
                        
                        <br />
                        <div className="row">
                            {editEmbeddedVideos}
                        </div>
                        

                    </ModalBody>


                </Modal>

                
            </div>
        );
    }


}

const backgroundStyle = {
    width:'100%',  
    height:'100vh',
    backgroundImage: `url(./img/laffnetbg_85.png)`, /* Location of the image */
	backgroundPosition: 'center center', /* Background image is centered vertically and horizontally at all times */
	backgroundRepeat: 'no-repeat', /* Background image doesn't tile */
	backgroundAttachment: 'fixed', /* Background image is fixed in the viewport so that it doesn't move when the content's height is greater than the image's height */
	backgroundSize: 'cover', /* This is what makes the background image rescale based on the container's size */
	backgroundColor: '#464646' /* Set a background color that will be displayed while the background image is loading */
}

const pencilStyle = {  
    color:'#fff', 
    fontSize: '14px', 
    cursor: 'pointer',
    marginLeft:'4px',    
    /*opacity:'0', 
    transition: '.6s ease opacity'*/
}

export default WebSetup;