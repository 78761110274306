import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import GoogleContacts from 'react-google-contacts';
 
import axios from 'axios'
import NavBar from '../layout/NavBar';
import Footer from '../layout/Footer';

class GoogleContactImport extends Component {

    constructor(props) {

        super(props);
        
        this.state = {

            importContacts: [],
            clientId: '735905868624-3bu5piopoba2raqi3a3umdl6p1qmnt41.apps.googleusercontent.com',

        }
    }
    
    componentDidMount() {


        
    }

    success = (response) => {

        this.setState({ importContacts: response});
        
        /*
        for (var i = 0; i < response.length; i++)
        {
            console.log(response[i].title + " " + response[i].email);
        }
        */

    }

    error = (response) => {
        console.error(response);
    }

    loading = () => {
        console.log('loading');
    }

    render () {

        return (
            <div>

                <NavBar />
                <br />
                                       
                <div className="container page-content">

                    <GoogleContacts theme="dark" onRequest={this.loading} onSuccess={this.success} onFailure={this.error} clientId={this.state.clientId} />

                    <table className="table" style={{width:'100%'}}>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Phone</th>
                            </tr>
                        </thead>
                        <tbody>

                            {this.state.importContacts.map((item, i) => {
                                return (
                                    <tr>
                                        <td key={i}>{item.title}</td>
                                        <td key={i}>{item.email}</td>
                                        <td key={i}>{item.phoneNumber}</td>

                                    </tr>
                                                                            
                                )
                                
                            })}

                        </tbody>
                    </table>
                </div>
                <Footer />
            </div>
        )
    }
}

export default GoogleContactImport;






