import React, {Component} from 'react';
import Contact from './Contact';
import ContactDetails from './ContactDetails';
import CreateNewContact from './CreateNewContact';
import PropTypes from 'prop-types';
import axios from 'axios';

import NavBar from '../layout/NavBar';
import Footer from '../layout/Footer';
import SideNavBar from '../layout/SideNavBar';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { faThList, faTh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/*
Font Awesome
Solid Style - fas
Regular Style - far
Light Style - fal
Duotone Style - fa
*/
class ContactList extends Component {

	constructor(props) {

		super(props);
		
        
        this.state = {
			contacts: [],
			currentView: 'list',     /*tile|list*/
			searchText: '',
			showDetails: false,
			currContact: null,
			currContactIndex: null,
			filters:[],
			allSelected: false,
			selectedIDs:[],
			showCreateNew: false,
			

		}

		//this.handleChange = this.handleChange.bind(this); /* Binding field to state field */
            
	}
	
    
    
    componentDidMount() {

		this.loadContacts();

        
		





		/*
		axios.get('https://jsonplaceholder.typicode.com/todos')
			.then(res => console.log(res.data))
		*/

		// get data from source	
		//axios.get('https://jsonplaceholder.typicode.com/todos?_limit=10')
		// .then(res => this.setState({ contacts: res.data}))

		// add data to the source
		//axios.post('https://jsonplaceholder.typicode.com/todos', {
		//	name: name,
		//	age: age
		//})
		//	.then(res => this.setState( {contacts: [...this.state.contacts, res.data]}));

		//delete request to the server
		//axios.delete(`https://jsonplaceholder.typicode.com/todos/${id}`)
		//	.then(res => this.setState( {contacts: [...this.state.contacts, res.data]}));

	}

	loadContacts = () => {

		axios({
            method: 'post',
            url: 'https://laffnetdev.website/api/contacts/index.php',
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json' 
			},
			data: {
				action: 'GET_CONTACT_LIST',
				filters: this.state.filters,
			}
        })
        .then(result => {
			
			//console.log(this.state.searchText);
			//console.log(result.data.filter);

			this.setState({contacts: result.data.contacts});
        })
		.catch(error => console.log("ERROR: "  + JSON.stringify(error) ) );

	}

	

	handleChange = (e) => {
	
		this.setState({searchText: e.target.value});

	}

	changeView = (view) => {
		this.setState({currentView: view});
		
	}

	openContact = (id) => {

		var clickedContact;
		for (var i = 0; i < this.state.contacts.length; i++)
		{
			if (this.state.contacts[i].id == id)
			{
				clickedContact = this.state.contacts[i];
				break;
			}
		}
		
		this.setState( { currContact: clickedContact } );
		this.setState( { showDetails: true } )
		
		//this.setState( { currContact: this.state.contacts[index] } );
		
		document.body.className='modal-open';
	}

	/*
	openContact = (index) => {

		
		this.setState( { showDetails: true } )
		this.setState( { currContact: this.state.contacts[index] } );
		
		document.body.className='modal-open';
	}
	*/

	toggle = (e) => {

		//console.log('Closing');
		this.setState( { showDetails: !this.state.showDetails } );
		this.setState( { currContact: null} );

		document.body.className=''; // needs to remove after closing the modal, otherwise page scroll bar doesn't show up
		

	}



	newContactCreated = (contact_id, contact_name) => {

		this.toggleNewContact();
		this.loadContacts();
	}

	toggleNewContact = () => {
		this.setState( { showCreateNew: !this.state.showCreateNew } );
	}

	createNewContact = () => {
		console.log("Create New Contact");

		this.toggleNewContact();
		



		//const tempContacts = [ ...this.state.contacts ]; 
				
		/*
		tempContacts.sort((a, b) => {
			if (a.firstname < b.firstname) return -1
			return a.firstname > b.firstname ? 1 : 0
		})

		this.setState( { contacts: tempContacts } );
		*/
	}

	selectFilter = (filter) => {
		

		const tempFilters = [ ...this.state.filters ]; 
		tempFilters.push(filter);

		this.setState( { filters: tempFilters } );

		
		var self = this;
		setTimeout(function(){ 
			self.loadContacts();
		}, 500);

	}

	removeFilter = (filter) => {
		
		const tempFilters = [ ...this.state.filters ]; 
		
		// searching and removing the filter item from the array
		for (var i = 0; i < tempFilters.length; i++)
		{
			if (tempFilters[i] === filter)
			{
				tempFilters.splice(i, 1);
			}
		}

		this.setState( { filters: tempFilters } );
		
		var self = this;
		setTimeout(function(){ 
			self.loadContacts();
		}, 500);

	}

	checkUncheckAll = (e) => { 

		// setting the checked flag
		/*
		const tempContacts = [ ...this.state.contacts ]; 
		tempContacts[0].checked = 'Y';
		

		var self = this;
		setTimeout(function(){ 
			self.setState( { contacts: tempContacts } );
		}, 500);
		*/

		if (this.state.allSelected)
		{
			var clist = document.getElementsByClassName("chk-contact");
			for (var i = 0; i < clist.length; ++i) 
			{ 
				clist[i].checked = "";
			}

			// emptying the selectedIDs array
			const tempIDs = [];
			this.setState( {selectedIDs: tempIDs} );

		}
		else
		{
			const tempIDs = [];

			var clist = document.getElementsByClassName("chk-contact");
			for (var i = 0; i < clist.length; ++i) 
			{ 
				clist[i].checked = "checked";
				
				tempIDs.push(clist[i].value);
				
			}
			this.setState( {selectedIDs: tempIDs} );
		}

		this.setState({ allSelected : !this.state.allSelected});

		/*
		 
		const tempIDs = [ ...this.state.selectedIDs ]; 

			tempIDs.push(checkedID); 
		*/


		
		

	}

	contactChecked = (checkedID, isChecked) => {
		//alert(checkedID + " " + isChecked);

		if (isChecked)
		{
			const tempIDs = [ ...this.state.selectedIDs ]; 

			tempIDs.push(checkedID);


			var self = this;
			setTimeout(function(){ 
				self.setState( {selectedIDs: tempIDs} );
			}, 100);
		}
		else
		{
			const tempIDs = [ ...this.state.selectedIDs ]; 

			for (var i = 0; i < tempIDs.length; i++)
			{
				if (tempIDs[i] == checkedID)
				{
					tempIDs.splice(i, 1);
				}
			}

			var self = this;
			setTimeout(function(){ 
				self.setState( {selectedIDs: tempIDs} );
			}, 100);
		}

		
		
		

		
	}

    render() {
        
        /*
		return (
			
			this.state.contacts.map((contact,i)=> (
				<Contact key={contact.id} contact={contact}/>
			))
		);
        */

		const filters = this.state.filters.map((filter,i)=> (	
			<span key={i} className="badge badge-pill badge-secondary" style={{padding:'8px 16px', marginRight:'2px', fontWeight:'400'}}>
				{filter}&nbsp;&nbsp;<span style={{cursor:'pointer'}} onClick={() => this.removeFilter(filter)}><strong>X</strong></span>
			</span>
		));
		

		const contactList = this.state.contacts
		
			.filter ((contact,i)=> {
				// remove contact that do not match the current filter
				return  ( 
					contact.firstname.toLowerCase().indexOf( this.state.searchText.toLowerCase() ) >= 0 ||
					contact.lastname.toLowerCase().indexOf( this.state.searchText.toLowerCase() ) >= 0 ||
					contact.home_city.toLowerCase().indexOf( this.state.searchText.toLowerCase() ) >= 0
				);
			})
			.map((contact,i)=> (
				<div key={contact.id} className={this.state.currentView=='list' ? "col-12":"col-md-4"} >
					<Contact contact={contact} view={this.state.currentView} openDetails={this.openContact} contactChecked={this.contactChecked} index={i}/>	
				</div>
			));

	   	return (
			<div>
				<NavBar />

				<SideNavBar />

                <div className="page-main-container">

					<div className="secondary-menu">
						
						<div className="container">

							<form>
								<div className="form-row" style={{marginBottom:'10px'}}>
									
									<div className="col-12 col-sm-10 col-md-3">
										<input type="text" id="search-box" className="form-control" style={searchboxIcon} value={this.state.searchText} onChange={this.handleChange}  />
									</div>
									
									

									<div className="col-2 col-sm-2 col-md-1 text-right">
										<div className="dropdown show">
											<a className="btn btn-sm laff-blue dropdown-toggle" style={filterboxIcon} href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
												
											</a>

											<div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
												<a className="dropdown-item" href="#" onClick={(e)=>this.selectFilter('Fan')}>Fan</a>
												<a className="dropdown-item" href="#" onClick={(e)=>this.selectFilter('Promoter')}>Promoter</a>
												<a className="dropdown-item" href="#" onClick={(e)=>this.selectFilter('Booker')}>Booker</a>
											</div>
										</div>
									</div>
									<div className="col-10 col-sm-10 col-md-8 text-left">
										<div className="">
											{filters}
										</div>
									</div>	
										
									
								</div>
							</form>
												
							<div className="row">

								<div className="col-12 col-sm-12 col-md-3">

									<button className="btn btn-sm laff-purple" style={contactListBtn, {width:'125px'}} onClick={() => this.checkUncheckAll()}>
										{this.state.allSelected ? 'Deselect All':'Select All'}
									</button>
									
									&nbsp;&nbsp;

									<FontAwesomeIcon className={this.state.currentView=='list'?'laff-blue-txt':'laff-gray-txt'} 
										icon={faThList} size="2x" 
										style={{cursor:'pointer'}}
										data-toggle="tooltip" 
										title="List View"
										transform="down-4"
										onClick={() => this.changeView('list')}
									/>
											
									&nbsp;&nbsp;

									<FontAwesomeIcon className={this.state.currentView=='tile'?'laff-blue-txt':'laff-gray-txt'} 
										icon={faTh} size="2x" 
										style={{cursor:'pointer'}}
										data-toggle="tooltip" 
										title="Tile View"
										transform="down-4"
										onClick={() => this.changeView('tile')}
									/>


									{/* 
									<button className="btn btn-sm btn-laff" data-toggle="tooltip" title="Tile View" onClick={() => this.changeView('tile')}>
										<img src="./img/icons/tile-view.png" style={{width:'20px', height:'auto'}}/>
									</button>
									<button className="btn btn-sm btn-laff" data-toggle="tooltip" title="List View" onClick={() => this.changeView('list')}>
										<img src="./img/icons/list-view.png" style={{width:'20px', height:'auto'}}/>
									</button>

									//
									*/}
								</div>
								
								<div className="col-12 col-sm-12 col-md-9" style={{textAlign:'right'}}>
									<button className={ this.state.selectedIDs.length==0 ? "btn btn-sm laff-purple disabled" : "btn btn-sm laff-purple" } style={contactListBtn} >Email</button>&nbsp; 
									<button className={ this.state.selectedIDs.length==0 ? "btn btn-sm laff-purple disabled" : "btn btn-sm laff-purple" } style={contactListBtn} >Message</button>&nbsp;
									<button className="btn btn-sm laff-purple" style={contactListBtn} onClick={event =>  window.location.href='/contact-import'} >Upload</button>&nbsp;
									<button className="btn btn-sm laff-purple" style={contactListBtn} >Export</button>&nbsp;
									<button className="btn btn-sm laff-purple" style={contactListBtn} onClick={this.createNewContact}>New</button>
								</div>
							</div>
							{/*<span>{this.state.contacts.length}</span>*/}
						</div>	
					</div>

					<div className="container page-content">
						<div className="row">
								
							{contactList}
							
						</div>
					</div>
					
				</div>
				
				
				
				
				<ContactDetails show={this.state.showDetails} toggle={this.toggle} contact={this.state.currContact} /> 
				
				<CreateNewContact show={this.state.showCreateNew} toggle={this.toggleNewContact} created={this.newContactCreated} />
				<br />



				
				<Footer />

			</div>   
			
		);


    } 
}

// PropTypes
/*
ContactList.propTypes = {
    contacts: PropTypes.array.isRequired
}
*/

const searchboxIcon = {
	paddingRight: 'calc(1.5em + .75rem)',
	backgroundImage: 'url("./img/icons/search-solid.svg")',
	backgroundRepeat: 'no-repeat',
	backgroundPosition: 'center right calc(.375em + .1875rem)',
	backgroundSize: 'calc(.75em + .375rem) calc(.75em + .375rem)',
}

const filterboxIcon = {
	paddingRight: 'calc(1.5em + .75rem)',
	backgroundImage: 'url("./img/icons/filter-white.png")',
	backgroundRepeat: 'no-repeat',
	backgroundPosition: 'center right calc(.375em + .1875rem)',
	backgroundSize: 'calc(.75em + .375rem) calc(.75em + .375rem)',
}

const contactListBtn = {
	borderRadius: '8px', 
	paddingLeft:'20px', 
	paddingRight:'20px'
}


export default ContactList;