import React, {Component} from 'react';
import { Link } from 'react-router-dom';


import NavBar from '../layout/NavBar';
import Footer from '../layout/Footer';


class AdminDashboard extends Component
{
    constructor(props) {

        super(props);
        
        this.state = {
            
            
            
		}
            
    }

    render() {

        return (

            <div>
                <NavBar />

                    <div className="container">

                        <h3>Admin Dashboard</h3>

                        <Link to="/saved-templates">Edit Email Templates</Link><br />
                        <Link to="/contact-import">Contact Import</Link>
                    </div>

                <Footer />
            </div>

        );
    }
} 

export default AdminDashboard;