import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import FacebookLogin from 'react-facebook-login';
import axios from 'axios';

import { faFacebook } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Button } from 'reactstrap';

import FBLogin from '../fb/FBLogin';
import './Login.css';

class Login extends Component {

    constructor(props) {

        super(props);
        
        this.state = {
            username:'',
            password:'',
            error: '',
            isLoading: false

        }
            
    }

    componentDidMount() {
            
        this.checkLogin();
        this.loadFbLoginApi();

	}

    checkLogin = () => {

        //const {pathname} = this.props.location;
        //console.log("PathName: " + pathname);

        axios({
            method: 'post',
            url: 'https://laffnetdev.website/api/auth/index.php',
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json' 
			},
			data: {
                action: 'IS_USER_LOGGED_IN'
                
			}
            
        })
        .then(result => {
			
            console.log( JSON.stringify(result.data) ); 
            
            if (result.data.status === 'OK')
            {
                this.props.history.push("/home");
            }
            else
            {
                
            }
        })
        .catch(error => {
            console.log( "ERROR: "  + JSON.stringify(error) ) 
            
        });


    }

    onSubmit = (e) => {
        e.preventDefault();

        const { username, password } = this.state;
    
        if (username === '' || password === '')
        {
            this.setState({ error:'Email and Password Cannot be empty.' });
            return;
        }

        this.setState({ error:'', isLoading:true });
        this.loginUser();

    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });

        
    }

    loginUser = () => {

        axios({
            method: 'post',
            url: 'https://laffnetdev.website/api/auth/index.php',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json' 
			},
			data: {
                action: 'AUTHENTICATE_USER',
                username: this.state.username,
                password: this.state.password
			}
            
        })
        .then(result => {
			
            console.log( JSON.stringify(result.data) ); 
            
            
            if (result.data.status === 'success')
            {
                this.props.history.push("/my-office");
            }
            else
            {
                this.setState({ error:'Invalid Username or Password.', isLoading:false });
            }
        })
        .catch(error => {
            console.log( "ERROR: "  + JSON.stringify(error) ) 
            this.setState({ error:'Failed Loggin In. Please Contact Customer Support.', isLoading:false });
        });
        
    }

    fbLoginSuccessfull = () => {
        this.props.history.push("/my-office");
    }




    /********************************************************************* */

    loadFbLoginApi = () => {

        window.fbAsyncInit = function() {
            window.FB.init({
                appId      : '945023493426227',
                cookie     : true,  // enable cookies to allow the server to access
                // the session
                xfbml      : true,  // parse social plugins on this page
                version    : 'v2.5' // use version 2.1
            });
        };

        console.log("Loading fb api");
          // Load the SDK asynchronously
        (function(d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s); js.id = id;
            js.src = "//connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
    }

    //picture.width(500).height(500)


    completeFBAuthentication = (res) => {
        console.log('Welcome!  Fetching your information.... ');
        

        var accessToken = res.accessToken;
        var accessExpiry = res.data_access_expiration_time;
        var fbUserId = res.userID;

        var self = this;        
        
        window.FB.api(
            '/me',
            'GET',
            {"fields":"id,name,email,picture.width(1080).height(1080)"},
            function(response) {
                // Insert your code here
                //console.log(response);
                //console.log(response.picture.data.url);


                var userEmail = response.email;
                var userPic = response.picture.data.url;

                axios({
                    method: 'post',
                    url: 'https://laffnetdev.website/api/auth/index.php',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept': 'application/json' 
                    },
                    data: {
                        action: 'SOCIAL_AUTHENTICATE_USER',
                        email: userEmail,
                        platformID: fbUserId,
                        platform: 'fb',
                        accessToken: accessToken,
                        accessTokenExpiry: accessExpiry
                    }
                          
                })
                .then(result => {
            
                    console.log("Loggin IN using fb credentials");
                    console.log( JSON.stringify(result.data) ); 
                    console.log( "****** " + result.data.status.toLowerCase() );
                    console.log("========================");
            
                    console.log("*1*");
            
                    if (result.data.status.toLowerCase() === 'success')
                    {
                    
                        /********************************************************/
                        /********************************************************/
                        /********************************************************/

                        window.FB.api(
                            '/me/accounts',
                            'GET',
                            {},
                            function(response) {
                                // Insert your code here
                                console.log("Getting Page ID/s");
                                console.log(response);
                            }
                        );
                        /*
                        window.FB.api(
                            '/' + fbUserId + '/accounts?access_token=' + accessToken,
                            'GET',
                            function(response) {
                                // Insert your code here

                                console.log("Getting Page ID/s");
                                console.log(response);
                                //console.log(response.picture.data.url);
                
                
                                
                
                
                                
                            }
                        );
                        */

                        /********************************************************/
                        /********************************************************/
                        /********************************************************/
            
                        console.log("*2*");


                        //self.props.history.push("/my-office"); TO DO 
                        
                        console.log("*3*");
                    }
                    else
                    {
                        console.log("*4*");
                        self.setState({ error:'Invalid User.', isLoading:false });
                    }



                    


                })
                .catch(error => {
                console.log("*5*");
                    console.log( "FB LOGIN ERROR: "  + JSON.stringify(error) ) 
                    self.setState({ error:'Failed Loggin In. Please Contact Customer Support.', isLoading:false });
            
            
                    
                });


                
            }
        );

        
    }
  
    statusChangeCallback = (response) => {
        console.log('statusChangeCallback');
        console.log(response);
        if (response.status === 'connected') {
            this.completeFBAuthentication(response.authResponse);

            
        } else if (response.status === 'not_authorized') {
            console.log("Please log into this app.");
        } else {
            console.log("Please log into this facebook.");
        }
    }
  
    checkLoginState = () => {
        window.FB.getLoginStatus(function(response) {
            this.statusChangeCallback(response);
        }.bind(this));
    }
  
    handleFBLogin = () => {
        window.FB.login(this.checkLoginState());
    }
    

    onClickSignup = () => {
        this.props.history.push("/signup");
    }
    /******************************************************************** */


    render() {

        
        
        return (
            <div style={backgroundStyle}>
                
                


                <div className="container" style={{paddingTop:'100px'}}>
                    <div className="row justify-content-center">
                        
                        <div className="col-9 col-sm-6 col-md-5 col-lg-4 col-xl-3" style={{textAlign:'center'}}>
                            
                            <img src="./img/logo_laffnet.png" className="img-fluid" alt="LaffNet" />
                            <br /><br />

                            <form onSubmit={this.onSubmit}>

                                {/*<input type="text" placeholder="Email" name="email" class="form-control" ng-model="username"/><br />*/}
                                {/*<input type="password" placeholder="Password" name="password" class="form-control" ng-model="pw"/>*/}
                                {/*<input type="submit" value="LOG IN" class="btn btn-sm btn-login" ng-click="loginClicked()" data-ng-disabled="checkLogin()"/>*/}
                                {/*<button type="button" className="btn btn-social-login" ng-click="onFBLogin();" id="btn-facebook"><img src="img/facobook.png" /> Facebook</button>*/}


                                <input type="text" placeholder="Email" name="username" className="form-control" onChange={this.onChange}/><br />  
                                

                                <input type="password" placeholder="Password" name="password" className="form-control" onChange={this.onChange}/>

                                <div style={{textAlign:'right', fontSize:'13px'}} className="shaded-text"><a href="#" >Forgot password?</a></div>

                                
                                <p style={{color:'#fff'}}>{this.state.error}</p>
                                
                                <button className="btn btn-sm btn-laff" disabled={this.state.isLoading}>LOG IN</button>
                               
                                
                                <br /><br />												
                                <span className="shaded-text">OR LOG IN WITH</span>
                                <br /><br />			

                                <button type="button" className="btn btn-social-login" id="btn-facebook"><img src="img/facobook.png" alt="facebook"/> Facebook</button>
                               
                                
                                <button type="button" className="btn btn-social-login" id="btn-google" ><img src="img/google.png" alt="google" /> Google</button>
                                <br /><br />
                                
                                <div className="shaded-text" style={{fontSize:'21px'}}>Don't have an account? <i onClick={() => this.onClickSignup()} style={{cursor:"pointer"}}>Sign up</i></div>



                                <div id="fb-test-div"></div>
                            </form>

                        </div>
                    </div>

                    {/* 
                    <div>
                        <FBLogin fbLoginSuccessfull={this.fbLoginSuccessfull}/>
                    </div>
                    */}

                    <Button
                        classNames = "btn-facebook"
                        id         = "btn-social-login"
                        onClick = {this.handleFBLogin}
                        >
                            <span className="fa fa-facebook"></span> Sign in with Facebook
                    </Button>
                </div>
                
            </div>
        );
        
        
    }
}


const backgroundStyle = {
    width:'100%',  
    height:'100vh',
    backgroundImage: `url(./img/laffnetbg_85.png)`, /* Location of the image */
	backgroundPosition: 'center center', /* Background image is centered vertically and horizontally at all times */
	backgroundRepeat: 'no-repeat', /* Background image doesn't tile */
	backgroundAttachment: 'fixed', /* Background image is fixed in the viewport so that it doesn't move when the content's height is greater than the image's height */
	backgroundSize: 'cover', /* This is what makes the background image rescale based on the container's size */
	backgroundColor: '#464646' /* Set a background color that will be displayed while the background image is loading */
}





export default Login;