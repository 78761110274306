import React, {Component} from 'react';
import { Button, Input} from 'reactstrap';
import EditableInput from '../ui/EditableInput';

import axios from 'axios';

class Board extends Component
{
    constructor(props) {

        super(props);
        
        this.state = {            
            
		}            
    }

    dragStart = (e) => {

        
        const target = e.target;
        
        if (target.id.substring(0,5) == "board")
        {
            
            e.dataTransfer.setData('board_id', target.id); 


            document.getElementById( "dragging-board-id" ).value = target.id; // Hack - because "e.dataTransfer.getData" is not available in "onDragOver"

            //e.dataTransfer.setData('Text', target.id);

            //console.log("Board - dragStart " + target.id);
                    
            setTimeout(() => {
                //target.style.display="none";
                target.style.opacity="0";
            },0);
        }
    }

    drop = (e) => {
        
        e.preventDefault();

        //console.log( e.dataTransfer.getData('Text') + "***************");

        //console.log( e.dataTransfer.getData('board_id') + "***************");

        

        const card_id = e.dataTransfer.getData('card_id');
        const board_id = e.dataTransfer.getData('board_id');

        if (card_id)
        {
            //console.log("DROPPED on board: " + card_id);
            const boardId = e.target.id;
            this.props.addCardToBoard(boardId, card_id);

            console.log("Dropped card on Board");
            this.props.onReorderCards(); // saves the order of the cards

            
        }
        else if (board_id)
        {
            document.getElementById( "dragging-board-id" ).value = "";
            document.getElementById(board_id).style.opacity="1.0";
            //this.props.addBoardToPipeline(board_id);

            console.log("Dropped board");

            this.props.onReorderStages(); // saves the order of the stages
        }


        
        


        
        
    }

    


    dragEnd = (e) => {

        
        //const board_id = e.dataTransfer.getData('board_id');
        const board_id = document.getElementById( "dragging-board-id" ).value;


        if ( board_id )
        {
            document.getElementById( "dragging-board-id" ).value = "";
            document.getElementById(board_id).style.opacity="1.0";            
        }
        
    }

    


    dragOver = (e) => {

        


        e.preventDefault();

        /*
        console.log( e.dataTransfer.types );
        console.log( e.dataTransfer.items[0] );
        console.log( e.dataTransfer.getData('board_id') );
        console.log( "Target: " + e.target.id );
        */

        // board that is being dragged
        //const board_id = e.dataTransfer.getData('board_id');
        //const card_id = e.dataTransfer.getData('card_id');

        const board_id = document.getElementById( "dragging-board-id" ).value;
        const card_id = document.getElementById( "dragging-card-id" ).value;

        


        
        //console.log("Moving board: " + board_id + " card:" + card_id);
        
        if (board_id)
        {

            //console.log("DRAGGING Board");

            if (e.target.id)
            {
                //console.log("Board - dragOver - " + e.target.id );
                this.props.onDragOverBoard(board_id, e.target.id);                
            }
        }
        else if (card_id)
        {
            //console.log("DRAGGING Card");

            this.props.onDragOverBoard(card_id, e.target.id);
        }
        
    }

    onMouseOver = (e) => {
        
        //if ( this.props.children.length == 0 )
        //{
            document.getElementById("sales-board-controls-" + this.props.row).style.display = 'block';
        //}           
    }

    onMouseOut = (e) => {
        document.getElementById("sales-board-controls-" + this.props.row).style.display = 'none';
    }


    updateName = (field_name, new_name) => {
        //console.log("Changed name to: " + new_name + " on " + this.props.row);

        const stage_id = this.props.row;

        axios({
            method: 'post',
            url: 'https://laffnetdev.website/api/funnel/index.php',
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json' 
			},
			data: {
                action: 'UPDATE_STAGE_NAME',
                stageID: stage_id,
                stageName: new_name
			}
        })
        .then(result => {
            
            console.log("Updated Stage Name.");
			
        })
        .catch(error => {
            console.log("ERROR: "  + JSON.stringify(error) ) 
        });

    }

    render() {

        var hideBtn = '';
        if (this.props.children.length == 0)
        {
            //hideBtn = <Button className="btn btn-sm">Hide</Button>;

            hideBtn = <span className="board-action-btn">Hide</span>;
        }      


        return (
            <div>
                


                <div
                    id={this.props.id}
                    onDrop={this.drop}
                    onDragStart={this.dragStart}
                    onDragEnd={this.dragEnd}
                    onDragOver={this.dragOver}
                    draggable={this.props.draggable}
                    onMouseOver={this.onMouseOver}
                    onMouseOut={this.onMouseOut}
                    
                    className="sales-board"
                >
                    
                    <div style={{marginBottom:'10px', padding:'10px', backgroundColor:'#838C91', borderRadius:'3px', color:'#fff'  }}>

                        <div className="clearfix">
                            <div className="float-left" style={{cursor:'pointer'}}>
                                <EditableInput 
                                    text={this.props.name}
                                    onUpdate={this.updateName}
                                    inputWidth='100px'
                                    fieldName='stage_name'
                                />
                            </div>
                            <div className="float-right">
                                ${this.props.total.toFixed(2)}
                            </div>
                        </div>

                        

                        <div style={{ height:'25px', paddingTop:'5px' }}>
                            <div id={"sales-board-controls-" + this.props.row} className="sales-board-controls">

                                {hideBtn}

                                <span className="board-action-btn">Rename</span>
                                <span className="board-action-btn" onClick={ () => this.props.openActivities(this.props.row) }>Actions</span>  
                                {/* 
                                <Button className="btn btn-sm">Rename</Button>
                                <Button className="btn btn-sm">Actions</Button>
                                */}

                            </div> 
                        </div>
                         
                        
                        
                    </div>
                    
                    
                    
                    { this.props.children }


                    
                    
                    
                    

                </div>

            </div>
        );
    }
}


export default Board;