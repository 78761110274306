import React, {Component} from 'react';

import axios from 'axios';

import { Button, CustomInput } from 'reactstrap';

import NavBar from '../layout/NavBar';
import Footer from '../layout/Footer';


class FBTest extends Component {

    constructor(props) {

        super(props);
        
        this.state = {
            
            
		}
    
    }

    componentDidMount() {

        

        
        

		console.log("Loaded");
		
    }

    
    testFunc = () => {
        
        axios({
            method: 'post',
            url: 'https://laffnetdev.website/api/fb/index.php',
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json' 
			},
			data: {
                action: 'TEST_FB',
                
			}
            
        })
        .then(result => {
			
            alert( JSON.stringify( result.data ) );
			
        })
		.catch(error => console.log("ERROR: "  + JSON.stringify(error) ) );
        
        

        
    }
    
  
    render() {

        

        return(
            <div>
                <NavBar />
                <br />

                
                    
                    <Button className="btn-laff-sm" onClick={ () => this.testFunc() }>Test</Button>
                    
                    <br /><br /><br />


                <Footer />
            </div>
        );
    }

}



export default FBTest;