import React, {Component} from 'react';
import PropTypes from 'prop-types';


class EmailTemplate extends Component {
  
    state = {
        
    }

    render() {

        const { id, template_name, html, css, thumbnail, visible } = this.props.emailTemplate;
        
        
        return (
            
            <div className="">
                {template_name}
            </div>

            
        
        );
        
        
    }

    
}

// PropTypes
//Contact.porpTypes = {
    //contact: PropTypes.object.isRequired
//}


// style definition


const imageStyle = {
    height:'60px',
    width:'auto',
    borderRadius: '50%',
    paddingLeft:'20px',
    paddingRight:'20px'
}


export default EmailTemplate;