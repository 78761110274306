import React, {Component} from 'react';


import { ColorExtractor } from 'react-color-extractor';

import axios from 'axios';


import NavBar from '../layout/NavBar';
import Footer from '../layout/Footer';
import Dialog from '../layout/Dialog';

class AccountSetup extends Component {

    constructor(props) {

        super(props);
        
        this.state = {

            inputRequired: false,

            selectedImage: '',
            colors: [],
			
		}
            
	}

    componentDidMount() {

        

    }

    onAccountSetup = (e) => {


        var standUp = document.getElementById('toggle-stand-up').checked;
        var varietyAct = document.getElementById('toggle-variety-act').checked;
        var sketchImprov = document.getElementById('toggle-sketch-improv').checked;
        var comedyVlogger = document.getElementById('toggle-comedy-vlogger').checked;
        var promoter = document.getElementById('toggle-promoter').checked;
        var booker = document.getElementById('toggle-booker').checked;
        var venue = document.getElementById('toggle-venue').checked;
        var agency = document.getElementById('toggle-agency').checked;

        
        
        if (!standUp && !varietyAct && !sketchImprov && !comedyVlogger && !promoter && !booker && !venue && !agency)
        {
            this.setState( {inputRequired: true} );
        }
        else
        {
            this.props.history.push('/setup-confirm')
        }


        /*<Link to={`/edit-email-template/${emailTemplate.id}`} >EDIT</Link>*/
                
    }

    onDialogClose = (e) => {
        this.setState( {inputRequired: false} );
    }

    onToggle = (e) => {
        console.log("Toggled");
    }





    useImage = (filename) => {
        this.setState( {selectedImage: filename} );
        
        console.log(filename);
    }

    getColors = newColors => {
        //this.setState(state => ({ colors: [...state.colors, ...colors] }))

        var testArr = newColors;
        console.log(testArr);
        testArr.sort();
        console.log(testArr);


        this.setState( {colors: newColors } );

        /*
        if (this.state.colors) {
            setTimeout(function(){ 
                console.log(this.state.colors.length);
            }, 1000);
        }
        */
        
    }
    
    renderSwatches = () => {
        const { colors } = this.state
        
        return colors.map((color, id) => {
            return (
                <div
                    key={id}
                    style={{
                        backgroundColor: color,
                        width: 100,
                        height: 100
                    }}
                />
            )
        })
    }

    render() {

        return (
			<div>
				<NavBar />


                    <p>Test</p>

                    <div className="row">
                        <div className="col-1"><img src="./img/test/1.png" style={{width:'100%', maxWidth:'100%', height:'auto'}} onClick={() => this.useImage('1.png')} /></div>
                        <div className="col-1"><img src="./img/test/2.png" style={{width:'100%', maxWidth:'100%', height:'auto'}} onClick={() => this.useImage('2.png')} /></div>
                        <div className="col-1"><img src="./img/test/3.png" style={{width:'100%', maxWidth:'100%', height:'auto'}} onClick={() => this.useImage('3.png')} /></div>
                        <div className="col-1"><img src="./img/test/4.png" style={{width:'100%', maxWidth:'100%', height:'auto'}} onClick={() => this.useImage('4.png')} /></div>
                        <div className="col-1"><img src="./img/test/5.png" style={{width:'100%', maxWidth:'100%', height:'auto'}} onClick={() => this.useImage('5.png')} /></div>
                        <div className="col-1"><img src="./img/test/6.png" style={{width:'100%', maxWidth:'100%', height:'auto'}} onClick={() => this.useImage('6.png')} /></div>
                        <div className="col-1"><img src="./img/test/7.png" style={{width:'100%', maxWidth:'100%', height:'auto'}} onClick={() => this.useImage('7.png')} /></div>
                        <div className="col-1"><img src="./img/test/8.png" style={{width:'100%', maxWidth:'100%', height:'auto'}} onClick={() => this.useImage('8.png')} /></div>
                        <div className="col-1"><img src="./img/test/9.png" style={{width:'100%', maxWidth:'100%', height:'auto'}} onClick={() => this.useImage('9.png')} /></div>
                        <div className="col-1"><img src="./img/test/10.png" style={{width:'100%', maxWidth:'100%', height:'auto'}} onClick={() => this.useImage('10.png')} /></div>
                        <div className="col-1"><img src="./img/test/11.png" style={{width:'100%', maxWidth:'100%', height:'auto'}} onClick={() => this.useImage('11.png')} /></div>
                        <div className="col-1"><img src="./img/test/12.png" style={{width:'100%', maxWidth:'100%', height:'auto'}} onClick={() => this.useImage('12.png')} /></div>
                        <div className="col-1"><img src="./img/test/13.png" style={{width:'100%', maxWidth:'100%', height:'auto'}} onClick={() => this.useImage('13.png')} /></div>
                        <div className="col-1"><img src="./img/test/14.png" style={{width:'100%', maxWidth:'100%', height:'auto'}} onClick={() => this.useImage('14.png')} /></div>
                        <div className="col-1"><img src="./img/test/15.png" style={{width:'100%', maxWidth:'100%', height:'auto'}} onClick={() => this.useImage('15.png')} /></div>
                        <div className="col-1"><img src="./img/test/16.png" style={{width:'100%', maxWidth:'100%', height:'auto'}} onClick={() => this.useImage('16.png')} /></div>
                    </div>


                    
                    <br /><br />

                    <ColorExtractor getColors={this.getColors}>
                        <img src={'./img/test/' + this.state.selectedImage} style={{width:'600px', height:'auto'}} />
                    </ColorExtractor>
                    <div
                        style={{
                            marginTop: 20,
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
                        {this.renderSwatches()}
                    </div>
                    
                    
                    
                    
                    


                    <hr />

                    {/* Testing Done */}

                    <br />                    
                    
                    <div style={{width:'100%', maxWidth:'600px', marginLeft: 'auto', marginRight:'auto', backgroundColor:'#ffffff', padding:'15px', borderRadius:'20px'}}>

                        <div style={{textAlign:'center', marginBottom: '16px'}}>
                            <span style={{fontSize:'22px', fontWeight:'bold'}}>Welcome Sarah!</span><br />
                            <span className="grayText" style={{fontSize:'22px'}}>Let's get LaffNet set up just for you.</span>
                        </div>


                        {/* Separator */}
                        <div style={{backgroundColor:'#6c757d', height:'15px', marginBottom: '16px', borderRadius:'20px'}}></div>


                        <div className="grayText" style={{textAlign:'center', fontSize:'20px'}}>
                            <p>Select <span className="bold">(at least one)</span> or <span className="bold">ALL</span> of the things that you do in <img src="/img/icons/material/info.png" style={infoIcon}/> the Comedy Business:</p>
                        </div>


                        

                        <div className="grayText bold" style={{marginBottom: '16px'}}>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="row" style={{marginBottom:'5px'}}>
                                        <div className="col-8"><img src="/img/icons/material/info.png" style={infoIcon}/> Stand Up</div>
                                        <div className="col-4">
                                            <input id="toggle-stand-up" type="checkbox" data-toggle="toggle" data-size="xs" data-on=" " data-off=" " data-style="ios" data-onstyle="laff" data-offstyle="secondary" onChange={this.onToggle}/>
                                        </div>
                                    </div>
                                    <div className="row" style={{marginBottom:'5px'}}>
                                        <div className="col-8"><img src="/img/icons/material/info.png" style={infoIcon}/> Variety Act</div>
                                        <div className="col-4">
                                            <input id="toggle-variety-act" type="checkbox" data-toggle="toggle" data-size="xs" data-on=" " data-off=" " data-style="ios" data-onstyle="laff" data-offstyle="secondary"/>
                                        </div>
                                    </div>
                                    <div className="row" style={{marginBottom:'5px'}}>
                                        <div className="col-8"><img src="/img/icons/material/info.png" style={infoIcon}/> Sketch/Improv</div>
                                        <div className="col-4">
                                            <input id="toggle-sketch-improv" type="checkbox" data-toggle="toggle" data-size="xs" data-on=" " data-off=" " data-style="ios" data-onstyle="laff" data-offstyle="secondary"/>
                                        </div>
                                    </div>
                                    <div className="row" style={{marginBottom:'5px'}}>
                                        <div className="col-8"><img src="/img/icons/material/info.png" style={infoIcon}/> Comedy Vlogger</div>
                                        <div className="col-4">
                                            <input id="toggle-comedy-vlogger" type="checkbox" data-toggle="toggle" data-size="xs" data-on=" " data-off=" " data-style="ios" data-onstyle="laff" data-offstyle="secondary"/>
                                        </div>
                                    </div>

                                </div>

                                <div className="col-sm-6">
                                    <div className="row" style={{marginBottom:'5px'}}>
                                        <div className="col-8"><img src="/img/icons/material/info.png" style={infoIcon}/> Promoter</div>
                                        <div className="col-4">
                                            <input id="toggle-promoter" type="checkbox" data-toggle="toggle" data-size="xs" data-on=" " data-off=" " data-style="ios" data-onstyle="laff" data-offstyle="secondary"/>
                                        </div>
                                    </div>
                                    <div className="row" style={{marginBottom:'5px'}}>
                                        <div className="col-8"><img src="/img/icons/material/info.png" style={infoIcon}/> Booker</div>
                                        <div className="col-4">
                                            <input id="toggle-booker" type="checkbox" data-toggle="toggle" data-size="xs" data-on=" " data-off=" " data-style="ios" data-onstyle="laff" data-offstyle="secondary"/>
                                        </div>
                                    </div>
                                    <div className="row" style={{marginBottom:'5px'}}>
                                        <div className="col-8"><img src="/img/icons/material/info.png" style={infoIcon}/> Venue</div>
                                        <div className="col-4">
                                            <input id="toggle-venue" type="checkbox" data-toggle="toggle" data-size="xs" data-on=" " data-off=" " data-style="ios" data-onstyle="laff" data-offstyle="secondary"/>
                                        </div>
                                    </div>
                                    <div className="row" style={{marginBottom:'5px'}}>
                                        <div className="col-8"><img src="/img/icons/material/info.png" style={infoIcon}/> Agency</div>
                                        <div className="col-4">
                                            <input id="toggle-agency" type="checkbox" data-toggle="toggle" data-size="xs" data-on=" " data-off=" " data-style="ios" data-onstyle="laff" data-offstyle="secondary"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Separator */}
                        <div style={{backgroundColor:'#6c757d', height:'15px', marginBottom: '16px', borderRadius:'20px'}}></div>
                        

                        <div onClick={this.onAccountSetup} 
                            style={{textAlign:'center', backgroundColor:'#6c757d', borderRadius:'20px', padding:'15px', position: 'relative', height:'102px', cursor:'pointer'}}>

                            <div style={{position: 'absolute', top: '21px', right: '20px', verticalAlign:'middle'}}>
                                <img style={{height:'60px', width:'auto'}} src="/img/arrow-white.png"/>
                            </div>                            
                            <div className="whiteText">Yep, this is what I do.</div>
                            <div className="whiteText bold" style={{fontSize:'32px'}}>Set me up!</div>
                            
                        </div>

                        

                        
                    </div>
                    {/*
                    <div className="container">

                        

                        <input type="checkbox" data-toggle="toggle" data-size="sm" data-on="Enabled" data-off="Disabled" data-style="ios" /><br /><br />
                        <input type="checkbox" data-toggle="toggle" data-size="sm" data-on="Enabled" data-off="Disabled" /><br /><br />

                        <input type="checkbox" data-toggle="toggle" data-size="sm" data-on="Enabled" data-off="Disabled" data-style="ios" data-onstyle="success" data-offstyle="secondary"/><br /><br />
                        <input type="checkbox" data-toggle="toggle" data-size="sm" data-on="Enabled" data-off="Disabled" data-onstyle="success" data-offstyle="secondary"/><br /><br />

                        <input type="checkbox" data-toggle="toggle" data-size="xs" data-on=" " data-off=" " data-style="ios" data-onstyle="laff-sm" data-offstyle="secondary"/><br /><br />
                        <input type="checkbox" data-toggle="toggle" data-size="xs" data-on=" " data-off=" " data-style="ios" data-onstyle="laff" data-offstyle="secondary"/><br /><br />

                        <input type="checkbox" data-toggle="toggle" data-size="sm" data-on="Enabled" data-off="Disabled" data-onstyle="success" data-offstyle="secondary"/>

                        
                        
                    </div>
                    */}
                    <br />

                    
                    
                    <Dialog isOpen={this.state.inputRequired} onClose={ this.onDialogClose }>                       
                        <h1>Oops!</h1> 
                        <p>LaffNet needs to know what you do in the comedy business.</p>
                        <p>This allows us to provide you the tools that are most relevant to YOU.</p>
                        <br />
                        <p>
                            Please select at least one of the options.<br />
                            You can change these at any time in your Settings Menu.
                        </p> 
                        <button type="button" className="btn btn-secondary" onClick={ this.onDialogClose }><strong>Got it!</strong></button>
                    </Dialog>
                    
                    
                    

                <Footer />

            </div>
        );
    }
}

const infoIcon = {
    height:'20px', 
    width:'auto', 
    paddingBottom:'2px',
    cursor:'pointer'
}


export default AccountSetup;
