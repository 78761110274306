import React from 'react';
import FacebookLogin from 'react-facebook-login';
import axios from 'axios';
 
//import { useHistory } from "react-router-dom";


class FBLogin extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
        email: '',
        error: '',
        isLoading: false
    
    }

  }
  



  responseFacebook = (response) => {

    console.log("FB Response:");
    console.log(response);
    console.log("-------------------");

    var self = this;

    self.setState({ email:response.email});
    
    axios({
      method: 'post',
      url: 'https://laffnetdev.website/api/auth/index.php',
      headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json' 
      },
      data: {
                action: 'SOCIAL_AUTHENTICATE_USER',
                email: response.email,
                platformID: response.userID,
                platform: 'fb',
      }
            
    })
    .then(result => {

        console.log("Loggin IN using fb credentials");
        console.log( JSON.stringify(result.data) ); 
        console.log( "****** " + result.data.status.toLowerCase() );
        console.log("========================");

        console.log("*1*");

        if (result.data.status.toLowerCase() === 'success')
        {
          //let history = useHistory();

          console.log("*2*");
          //self.props.history.push("/my-office");
          self.props.fbLoginSuccessfull();
          console.log("*3*");
        }
        else
        {
          console.log("*4*");
          self.setState({ error:'Invalid User.', isLoading:false });
        }
    })
    .catch(error => {
      console.log("*5*");
        console.log( "FB LOGIN ERROR: "  + JSON.stringify(error) ) 
        self.setState({ error:'Failed Loggin In. Please Contact Customer Support.', isLoading:false });


        
    });
    
  }
  

  //728485644505887
  //profile_pic
  render() {
    return (
      <FacebookLogin
        appId="945023493426227"
        /*autoLoad={true}*/
        fields="name,email,picture" 
        scope="public_profile,email"
        callback={this.responseFacebook}
      />
    )
  }
}

export default FBLogin;