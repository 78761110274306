import React, {Component} from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

import EditableContactSelect from '../ui/EditableContactSelect';
import DatePicker from "react-datepicker";
import { format, setMinutes, setHours } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";


import { faPencilAlt, faBuilding, faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


class AppointmentDetails extends Component 
{
    constructor(props) {

        super(props);
        
        this.state = {
            
            appt: null,
            showDetails: false,
            apptStart: null,
            apptEnd: null,


            
            
            
		}
            
    }

    componentDidUpdate(prevProps) {

        

        if(prevProps.appt !== this.props.appt) {

            
            this.setState({appt: this.props.appt});
            this.setState({showDetails: this.props.showDetails});

            if (this.props.appt)
            {
                // start date
                var dateTimeParts = this.props.appt.start.split(' ');
                var dateParts =dateTimeParts[0].split('-');
                var timeParts =dateTimeParts[1].split(':');

                var startDate = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
                var hr = parseInt(timeParts[0]);
                var min = parseInt(timeParts[1]);

                startDate = setHours( setMinutes(startDate, min ), hr);
                this.setState({apptStart: startDate }); 


                // end date
                dateTimeParts = this.props.appt.end.split(' ');
                dateParts =dateTimeParts[0].split('-');
                timeParts =dateTimeParts[1].split(':');
                
                var endDate = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
                var hr = parseInt(timeParts[0]);
                var min = parseInt(timeParts[1]);

                endDate = setHours( setMinutes(endDate, min ), hr);
                this.setState({apptEnd: endDate });

                             

            }
            
            var self = this;
            /*
            setTimeout(function(){ 
                self.setRating();
            }, 500);
            */
            
        }
    }

    hideApptDetails = () => {
        this.setState({showDetails: false});
        this.props.closeApptDetails();
    }

    handleApptStartDate = (date) => {       
        this.setState( { apptStart: date } );
    }
    handleApptEndDate = (date) => {       
        this.setState( { apptEnd: date } );
    }

    onTitleChange = (e) => {
        //console.log(e.target.value);

        const thisAppt = this.state.appt;
        thisAppt.name = e.target.value;

        this.setState({appt: thisAppt});
    }

    onNotesChange = (e) => {

        const thisAppt = this.state.appt;
        thisAppt.notes = e.target.value;

        this.setState({appt: thisAppt});

    }

    updateContact = (field_name, contact_id, contact_name) => {
        
        
        const thisAppt = this.state.appt;
        thisAppt.contact_id = contact_id;
        thisAppt.contact_name = contact_name;
        
        this.setState({appt: thisAppt});

    }

    saveAppointment = (e) => {

        console.log(this.state.appt.contact_id);
        console.log(this.state.appt.name);
        console.log(this.state.apptStart);
        console.log(this.state.apptEnd);
        console.log(this.state.appt.notes);

        


        

        
        
        
    }

    render() {

        

        if ( this.state.appt ) {

            return (
                <div>
                    
                    <Modal 
                        isOpen={this.state.showDetails}
                        size="lg"
                    >
                        <ModalBody>                        

                            <div>
                                <div className="row">
                                    <div className="col-8 laff-blue-txt">
                                                                                
                                        <EditableContactSelect 
                                            text={this.state.appt.contact_name}
                                            value={this.state.appt.contact_id}
                                            onUpdate={this.updateContact}
                                            inputWidth='300px' 
                                            fieldName='contact_id'                                               
                                        />
                                        
                                    </div> 
                                    <div className="col-4 text-right">
                                        <Button className="laff-blue rounded-circle" size="sm" onClick={this.hideApptDetails} aria-label="Close" style={{borderColor:'#0050F5'}}>
                                            <span>&nbsp;&times;&nbsp;</span>
                                        </Button>           
                                    </div>                       
                                </div>

                                
                                
                                
                            </div>

                            <div className="row" style={rowPadding}>
                                <div className="col-4">Title</div>
                                <div className="col-8">
                                    <input type="text" className="form-control form-control-sm" value={this.state.appt.name} onChange={this.onTitleChange}/>
                                </div>
                            </div>

                            <div className="row" style={rowPadding}>
                                <div className="col-4">Start</div>
                                <div className="col-8">
                                    <DatePicker 
                                        selected={ this.state.apptStart }
                                        onChange={ this.handleApptStartDate }
                                        name="follow-up-action-date"
                                        id="follow-up-action-date"
                                        className="date-time-input form-control form-control-sm"
                                        showTimeSelect
                                        dateFormat="yyyy-MM-dd h:mm aa"
                                        placeholderText="Appointment Start"
                                        showYearDropdown
                                        scrollableMonthYearDropdown
                                    />
                                </div>
                            </div>
                            <div className="row" style={rowPadding}>
                                
                                <div className="col-4">End</div>
                                <div className="col-8">
                                    <DatePicker 
                                        selected={ this.state.apptEnd }
                                        onChange={ this.handleApptEndDate }
                                        name="follow-up-action-date"
                                        id="follow-up-action-date"
                                        className="date-time-input form-control form-control-sm"
                                        showTimeSelect
                                        dateFormat="yyyy-MM-dd h:mm aa"
                                        placeholderText="Appointment End"
                                        showYearDropdown
                                        scrollableMonthYearDropdown
                                    />
                                </div>

                                
                            </div>

                            <div className="row" style={rowPadding}>
                                <div className="col-4">Notes</div>
                                <div className="col-8">
                                    <textarea className="form-control form-control-sm" defaultValue={this.state.appt.notes} onChange={this.onNotesChange}></textarea>
                                </div>                                                           
                            </div>

                            <div className="row" style={rowPadding}>
                                <div className="col-12 text-right">
                                    <Button className='btn btn-sm btn-success' onClick={this.saveAppointment}>Save</Button>
                                </div>
                            </div>


                            
                                                
                            
                                                    
                        </ModalBody>
                    </Modal>
                    
                </div>
            );

        } else {
            return (
                <div></div>
            );
        }
    }
}


const rowPadding = {
    padding: '3px 0px'
}

export default AppointmentDetails;