import React, {Component} from 'react';


class ColourPicker extends Component 
{
    constructor(props) {

        super(props);
        this.state = {

            colors: this.props.colors,
            selected: this.props.selected
        }
    }

    componentDidUpdate(prevProps) {

    }

    onSelectColor = (col) => {
        this.setState( {selected: col} );
        this.props.onChange(col);
    }


    render() {

        var colorOptions = this.state.colors.map((color, i) => (
                        
            <span key={i} style={ this.state.selected==color ? selectedStyle : defaultStyle} onClick={() => this.onSelectColor(color)}>
                <span style={{backgroundColor:color, width: '35px', padding:'2px 6px', border:'1px dotted #ccc'}}>
                    &nbsp; &nbsp;
                </span>
                
            </span>
            
        )); 

        return (
            <div>
                {colorOptions}
            </div>
        );

    }

}

const selectedStyle = {
    border:'2px solid #000',
    width: '35px', 
    height:'35px', 
    padding:'5px 2px', 
    marginRight:'2px'
}

const defaultStyle = {
    border:'2px solid #ccc',
    width: '35px', 
    height:'35px', 
    padding:'5px 2px', 
    marginRight:'2px'
}

export default ColourPicker;