import React, {Component} from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

import EditableContactSelect from '../ui/EditableContactSelect';
import DatePicker from "react-datepicker";
import { format, setMinutes, setHours } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";


import { faPencilAlt, faBuilding, faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


class LifeEventDetails extends Component 
{
    constructor(props) {

        super(props);
        
        this.state = {
            
            lifeEvent: null,
            showDetails: false,
            eventName: null,
            eventDate: null,


            
            
            
		}
            
    }

    componentDidUpdate(prevProps) {

        

        if(prevProps.lifeEvent !== this.props.lifeEvent) {

            
            this.setState({lifeEvent: this.props.lifeEvent});
            this.setState({showDetails: this.props.showDetails});

            if (this.props.lifeEvent)
            {
                
                // event date
                var dateParts =this.props.lifeEvent.date.split('-');
                

                var lEventDate = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
                

                
                this.setState({eventDate: lEventDate }); 


                

                           

            }
            
            var self = this;
            /*
            setTimeout(function(){ 
                self.setRating();
            }, 500);
            */
            
        }
    }

    hideEventDetails = () => {
        this.setState({showDetails: false});
        this.props.closeLEventDetails();
    }

    updateContact = (field_name, contact_id, contact_name) => {
        
        
        const thisEvent = this.state.lifeEvent;
        thisEvent.contact_id = contact_id;
        thisEvent.contact_name = contact_name;
        
        this.setState({lifeEvent: thisEvent});

    }

    handleDate = (date) => {       
        this.setState( { eventDate: date } );
    }


    render() {

        

        if ( this.state.lifeEvent ) {

            return (
                <div>
                    
                    <Modal 
                        isOpen={this.state.showDetails}
                        size="lg"
                    >
                        <ModalBody>                        

                            <div>
                                <div className="row">
                                    <div className="col-8 laff-blue-txt">
                                        

                                        <EditableContactSelect 
                                            text={this.state.lifeEvent.contact_name}
                                            value={this.state.lifeEvent.contact_id}
                                            onUpdate={this.updateContact}
                                            inputWidth='300px' 
                                            fieldName='contact_id'                                               
                                        />

                                    </div> 
                                    <div className="col-4 text-right">
                                        <Button className="laff-blue rounded-circle" size="sm" onClick={this.hideEventDetails} aria-label="Close" style={{borderColor:'#0050F5'}}>
                                            <span>&nbsp;&times;&nbsp;</span>
                                        </Button>           
                                    </div>                       
                                </div>

                                
                                
                            </div>

                            <div className="row" style={rowPadding}>
                                <div className="col-4">Event</div>
                                <div className="col-8">
                                    <select className="form-control form-control-sm">
                                        <option value="">--Select--</option>
                                        <option value="Birthday">Birthday</option>
                                        <option value="Anniverssary">Anniverssary</option>
                                    </select>
                                </div>
                            </div>

                            <div className="row" style={rowPadding}>
                                <div className="col-4">Date</div>
                                <div className="col-8">
                                    <DatePicker 
                                        selected={ this.state.eventDate }
                                        onChange={ this.handleDate }
                                        name="follow-up-action-date"
                                        id="follow-up-action-date"
                                        className="date-time-input form-control form-control-sm"
                                        
                                        dateFormat="yyyy-MM-dd"
                                        placeholderText="Event Date"
                                        showYearDropdown
                                        scrollableMonthYearDropdown
                                    />
                                </div>
                            </div>
                            


                            <div className="row" style={rowPadding}>
                                <div className="col-12 text-right">
                                    <Button className='btn btn-sm btn-success'>Save</Button>
                                </div>
                            </div>


                            
                                                
                            
                                                    
                        </ModalBody>
                    </Modal>
                    
                </div>
            );

        } else {
            return (
                <div></div>
            );
        }
    }
}


const rowPadding = {
    padding: '3px 0px'
}

export default LifeEventDetails;