import React, {Component, useContext} from 'react';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter, TabContent, TabPane, Nav, NavItem, NavLink, Col, Row, Input, Label, FormGroup } from 'reactstrap';

//import {  faCheckCircle, faTimesCircle  } from '@fortawesome/free-regular-svg-icons';
import {  faCheckCircle, faTimesCircle  } from '@fortawesome/free-solid-svg-icons';


//import { faPencilAlt, faSolid, faRegular, faCircleCheck, faCircleXmark  } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


import NavBar from '../layout/NavBar';
import Footer from '../layout/Footer';
import SideNavBar from '../layout/SideNavBar';

import axios from 'axios';

class Signup extends Component 
{
    constructor(props) {

        super(props);

        this.state = {

            showSignupOptions: false,
            showEmailField: false,
            signupEmail: '',
            signupEmailValid: false,
            enteredSecurityCode: '',
            securityCodeSent: false,
            securityCodeValidated: false,

            pw_has_min_length: false,
            pw_has_lower_case: false,
            pw_has_upper_case: false,
            pw_has_number: false,
            pw_has_specialchar: false,


        }

    }

    componentDidMount() {

        this.loadFbLoginApi();

        this.setState( {showSignupOptions: true} );

        

    }

    /**************************************************************************/
    /**************************************************************************/
    // FB
    /**************************************************************************/
    /**************************************************************************/
    loadFbLoginApi = () => {

        window.fbAsyncInit = function() {
            window.FB.init({
                appId      : '945023493426227',
                cookie     : true,  // enable cookies to allow the server to access
                // the session
                xfbml      : true,  // parse social plugins on this page
                version    : 'v2.5' // use version 2.1
            });
        };

        console.log("Loading fb api");
          // Load the SDK asynchronously
        (function(d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s); js.id = id;
            js.src = "//connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
    }
    
    fbSignUp = () => {

        var self = this;
        window.FB.login( function(response) {
            self.checkLoginState();
        }, {scope: 'email,public_profile'});
    }

    checkLoginState = () => {
        window.FB.getLoginStatus(function(response) {
            this.statusChangeCallback(response);
        }.bind(this));
    }

    statusChangeCallback = (response) => {

        var self = this;
        var accessToken = response.accessToken;
        var accessExpiry = response.data_access_expiration_time;

        console.log('statusChangeCallback');
        console.log(response);
        if (response.status === 'connected') {
            //this.completeFBAuthentication(response.authResponse);
            console.log("Connected to FB.");

                        
            window.FB.api(
                '/me',
                'GET',
                {"fields":"id,first_name, last_name  ,email,picture.width(1080).height(1080)"},
                function(response) {
                    // Insert your code here
                    //console.log(response);
                    //console.log(response.picture.data.url);
       
                    console.log("Get Profile Info");
                    console.log(response);

                    // Create the account
                    self.createAccount(response.email, response.first_name, response.last_name, 'facebook', response.id, accessToken, accessExpiry );

                    
                    
                }
            );

            
        } else if (response.status === 'not_authorized') {

            console.log("Please log into this app.");
            var tempText = this.state.displayText + "Please log into this app.<br />"
            this.setState({displayText: tempText });

            self.setState({FBLoggedIn: false });

        } else {

            console.log("Please log into this facebook.");
            var tempText = this.state.displayText + "Please log into this facebook.<br />"
            this.setState({displayText: tempText });
        }
    }
    
    /*******************************************/
    // Email
    /*******************************************/
    emailSignUp = () => {

        
        this.setState({showSignupOptions: false});
        this.setState({showEmailField: true});
    }

    onChangeEmailAddress = (e) => {

        var self = this;

        self.setState({ signupEmail: e.target.value });

        setTimeout(function() {
            self.verifyEmail(self.state.signupEmail);
        }, 100);


        //console.log("Email Address Changed");
    }

    verifyEmail = (email) => {

        var validRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

        if ( email.match(validRegex) ) {
            this.setState({ signupEmailValid: true });
        } else {
            this.setState({ signupEmailValid: false });
        }

    }

    completeEmailSignup = () => {

        // Send Verification Email

        var self = this;

        axios({
           
            method: 'post',
            url: 'https://laffnetdev.website/api/onboarding/index.php',
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json' 
			},
			data: {
				action: 'SEND_SIGNUP_SECURITY_CODE',
				email: self.state.signupEmail,         

			}
        })
        .then(result => {

            console.log(result.data.sent);
            console.log(result.data);

            if (result.data.sent) {
                console.log("======================");		
                this.setState({ securityCodeSent: true });
                this.setState({showEmailField: false});
            } else {
                alert("Couldn't send the email.");
            }
            

         
            

            			
        })
        .catch(error => 
            console.log("ERROR: "  + JSON.stringify(error) ) 
        );





        
        

    }

    // SecurityCode
    onEnterSecurityCode = (e) => {

        var self = this;

        self.setState({ enteredSecurityCode: e.target.value });
        
        setTimeout(function() {
            
            if (self.state.enteredSecurityCode.length == 6) {

                document.getElementById('security_code').disabled = true;

                
                axios({
           
                    method: 'post',
                    url: 'https://laffnetdev.website/api/onboarding/index.php',
                    headers: { 
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Content-Type': 'multipart/form-data',
                        'Accept': 'application/json' 
                    },
                    data: {
                        action: 'VERIFY_SIGNUP_SECURITY_CODE',
                        email: self.state.signupEmail,    
                        code: self.state.enteredSecurityCode,     
        
                    }
                })
                .then(result => {
        
                    
                    if (result.data.valid) {

                        self.setState({ securityCodeValidated: true });
                        self.setState({ securityCodeSent: false });
                        self.setState({ showEmailField: false });

                    } else {

                        document.getElementById('security_code').disabled = false;

                    }
                    
        
                                
                })
                .catch(error => 
                    console.log("ERROR: "  + JSON.stringify(error) ) 
                );
            }




            



        }, 100);
        
        

    }


    // Password
    onEnterPassword = (e) => {

        var self = this;

        self.setState({ enteredPassword: e.target.value });

        setTimeout(function() {

            self.checkPasswordStrength(self.state.enteredPassword);

        }, 100);

    }
    


    checkPasswordStrength = (password) => {

        var self = this;

        if (password.length < 8) {
            self.setState({ pw_has_min_length: false });
        } else {
            self.setState({ pw_has_min_length: true });
        }


        if ( password.match(/[a-z]/) ) {
            self.setState({ pw_has_lower_case: true });
        } else {
            self.setState({ pw_has_lower_case: false });
        }


        if ( password.match(/[A-Z]/) ) {
            self.setState({ pw_has_upper_case: true });
        } else {
            self.setState({ pw_has_upper_case: false });
        }

        if (password.match(/\d/)) {
            self.setState({ pw_has_number: true });
        } else {
            self.setState({ pw_has_number: false });
        }

        if (password.match(/[^a-zA-Z\d]/)) {
            self.setState({ pw_has_specialchar: true });
        } else {
            self.setState({ pw_has_specialchar: false });
        }
      
          


        /*
        // Initialize variables
        var strength = 0;
        var tips = "";
      
        // Check password length
        if (password.length < 8) {
          tips += "Make the password longer. ";
        } else {
          strength += 1;
        }
      
        // Check for mixed case
        if (password.match(/[a-z]/) && password.match(/[A-Z]/)) {
          strength += 1;
        } else {
          tips += "Use both lowercase and uppercase letters. ";
        }
      
        // Check for numbers
        if (password.match(/\d/)) {
          strength += 1;
        } else {
          tips += "Include at least one number. ";
        }
      
        // Check for special characters
        if (password.match(/[^a-zA-Z\d]/)) {
          strength += 1;
        } else {
          tips += "Include at least one special character. ";
        }
      
        // Return results
        if (strength < 2) {
          return "Easy to guess. " + tips;
        } else if (strength === 2) {
          return "Medium difficulty. " + tips;
        } else if (strength === 3) {
          return "Difficult. " + tips;
        } else {
          return "Extremely difficult. " + tips;
        }

        */

    }

    onCompleteAccountSetup = () => {

        
        var self = this;

        axios({
            method: 'post',
            url: 'https://laffnetdev.website/api/onboarding/index.php',
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json' 
            },
            data: {
                action: 'CREATE_PW',
                email: self.state.signupEmail,
                pw: self.state.enteredPassword,
                
            }
        })
        .then(result => {
                        
            console.log(result.data);
            /* 
            alert("Account Setup completed");
            */

            axios({
                method: 'post',
                url: 'https://laffnetdev.website/api/auth/index.php',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': 'application/json' 
                },
                data: {
                    action: 'AUTHENTICATE_USER',
                    username: self.state.signupEmail,
                    password: self.state.enteredPassword
                }
                
            })
            .then(result => {
                
                console.log( JSON.stringify(result.data) ); 
                
                
                if (result.data.status === 'success')
                {
                    this.props.history.push("/onboarding");
                }
                else
                {
                    this.setState({ error:'Invalid Username or Password.', isLoading:false });
                }
            })
            .catch(error => {
                console.log( "ERROR: "  + JSON.stringify(error) ) 
                this.setState({ error:'Failed Loggin In. Please Contact Customer Support.', isLoading:false });
            });

            
        })
        .catch(error => 
            console.log("ERROR: "  + JSON.stringify(error) ) 
        );

    }

    createAccount = (email, first_name, last_name, platform, platform_id, access_token, access_token_expiry ) => {

        axios({
            method: 'post',
            url: 'https://laffnetdev.website/api/signup/index.php',
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json' 
            },
            data: {
                action: 'CREATE_ACCOUNT',
                email: email,
                firstname: first_name,
                lastname: last_name,
                platform: platform,
                platformID: platform_id,
                accessToken: access_token,
                accessTokenExpiry: access_token_expiry
                
            }
        })
        .then(result => {
                        
            console.log(result.data);
            
            

            
        })
        .catch(error => 
            console.log("ERROR: "  + JSON.stringify(error) ) 
        );

    }

    onClickSignIn = () => {        
        this.props.history.push("/login");
    }

    render() {

        var self = this;

        return (
            
            <div style={backgroundStyle}>
                <Modal
                        isOpen={this.state.showSignupOptions}
                        size="lg"
                        backdrop={false}
                        centered
                    >
                    <ModalHeader className="laff-blue">
                        Let's Get This Show Started!
                    </ModalHeader>
                    <ModalBody style={{backgroundColor:'#000000'}}>                        



                        <div className="text-center">

                            <div className="text-center">
                                <img src="img/LaffNet Logo Transparent.png" style={{width:'50%', height:'auto'}}/>
                            </div>

                            <div className="whiteText">
                                Sign Up with<br /><br />
                                <button type="button" className="btn btn-social-login" id="btn-facebook" onClick = {this.fbSignUp}><img src="img/facobook.png" alt="facebook" /> Facebook</button>
                                &nbsp;or&nbsp;    
                                <button type="button" className="btn btn-social-login" id="btn-google" ><img src="img/google.png" alt="google" /> Google</button>
                                &nbsp;or&nbsp;
                                <button type="button" className="btn btn-social-login" id="btn-email" onClick = {this.emailSignUp}><img src="img/email_logo.jpg" alt="email" /> Email</button>
                            </div>

                            <br />
                            <div className="whiteText">Already have a LaffNet account?</div>
                            <br />
                            <button className="btn btn-primary btn-laff-sm-blue" onClick={() => this.onClickSignIn()} >Sign In {'>'}</button>
                            <br /><br />
                        </div>                        
                                                                                            
                    </ModalBody>
                    
                </Modal>


                {/* Email */}
                <Modal
                        isOpen={this.state.showEmailField}
                        size="lg"
                        backdrop={false}
                        centered
                    >
                    <ModalHeader className="laff-blue">
                        Please enter your email address...
                    </ModalHeader>
                    <ModalBody style={{backgroundColor:'#000000'}}>                        

                        <div>
                            <input type='text' className='form-control' id='email_address' defaultValue={this.state.signupEmail} onKeyUp={this.onChangeEmailAddress}/>                          
                        </div>
                        <div style={{color: 'red', display: (this.state.signupEmail=='' || this.state.signupEmailValid) ? 'none':'block'}}>
                            Please enter a valid email address...
                        </div>
                        <br />
                        <div className='text-right'>
                            <button className='btn btn-primary btn-laff-sm-blue' disabled={! this.state.signupEmailValid}  onClick={this.completeEmailSignup}>Next {'>'}</button>
                        </div> 

                        <div className="text-left">
                            <img src="img/LaffNet Logo Transparent.png" style={{width:'110px', height:'auto'}}/>
                        </div>                
                                                                                            
                    </ModalBody>
                    
                </Modal>

                {/* Security Code */}
                <Modal
                        isOpen={this.state.securityCodeSent}
                        size="lg"
                        backdrop={false}
                        centered
                    >
                    <ModalHeader className="laff-blue">
                        We emailed you a security code...
                    </ModalHeader>
                    <ModalBody style={{backgroundColor:'#000000'}}>                        

                        <div>
                            <input type='text' className='form-control' id='security_code' defaultValue={this.state.enteredSecurityCode} onKeyUp={this.onEnterSecurityCode}/>                          
                        </div>
                        <br />
                        <div className="text-left">
                            <img src="img/LaffNet Logo Transparent.png" style={{width:'110px', height:'auto'}}/>
                        </div>     
                                                                                            
                    </ModalBody>
                    
                </Modal>


                {/* Password */}
                <Modal
                        isOpen={this.state.securityCodeValidated}
                        size="lg"
                        backdrop={false}
                        centered
                    >
                    <ModalHeader className="laff-blue">
                        Please enter a password...
                    </ModalHeader>
                    <ModalBody style={{backgroundColor:'#000000'}}>                        

                        <div>
                            <input type='password' className='form-control' id='password' defaultValue={this.state.enteredPassword} onKeyUp={this.onEnterPassword}/>                          
                        </div>
                        <div style={{marginTop:'10px'}}>
                            <div><FontAwesomeIcon icon={this.state.pw_has_min_length ? faCheckCircle : faTimesCircle} style={this.state.pw_has_min_length ? okStyle : reqdStyle} /> <span style={{color:'#fff', fontSize:'12px'}}>Must contain at least 8 characters</span></div>
                            <div><FontAwesomeIcon icon={this.state.pw_has_lower_case ? faCheckCircle : faTimesCircle} style={this.state.pw_has_lower_case ? okStyle : reqdStyle} /> <span style={{color:'#fff', fontSize:'12px'}}>Must contain a lowercase character</span></div>
                            <div><FontAwesomeIcon icon={this.state.pw_has_upper_case ? faCheckCircle : faTimesCircle} style={this.state.pw_has_upper_case ? okStyle : reqdStyle} /> <span style={{color:'#fff', fontSize:'12px'}}>Must contain an uppercase character</span></div>
                            <div><FontAwesomeIcon icon={this.state.pw_has_number ? faCheckCircle : faTimesCircle} style={this.state.pw_has_number ? okStyle : reqdStyle} /> <span style={{color:'#fff', fontSize:'12px'}}>Must contain a number</span></div>
                            <div><FontAwesomeIcon icon={this.state.pw_has_specialchar ? faCheckCircle : faTimesCircle} style={this.state.pw_has_specialchar ? okStyle : reqdStyle} /> <span style={{color:'#fff', fontSize:'12px'}}>Must contain a special character</span></div>
                        </div>

                        <div className='text-right'>
                            <button className='btn btn-primary btn-laff-sm-blue' disabled={ !(this.state.pw_has_min_length && this.state.pw_has_lower_case && this.state.pw_has_upper_case && this.state.pw_has_number && this.state.pw_has_specialchar) }
                                onClick={this.onCompleteAccountSetup}>Next {'>'}</button>
                        </div>   

                        <div className="text-left">
                            <img src="img/LaffNet Logo Transparent.png" style={{width:'110px', height:'auto'}}/>
                        </div>   
                                                                                            
                    </ModalBody>
                    
                </Modal>

                

            </div>
        );
    }
}

//const backgroundStyle = {
//    width:'100%',  
//    height:'auto',
//    backgroundImage: `url(./img/LaffNetBg.png)`, /* Location of the image */
//	backgroundPosition: 'center top', /* Background image is centered vertically and horizontally at all times */
//	backgroundRepeat: 'no-repeat', /* Background image doesn't tile */
//	//backgroundAttachment: 'fixed', /* Background image is fixed in the viewport so that it doesn't move when the content's height is greater than the image's height */
//	backgroundSize: 'cover', /* This is what makes the background image rescale based on the container's size */
//	backgroundColor: '#464646' /* Set a background color that will be displayed while the background image is loading */
//}


const backgroundStyle = {
    width:'100%',  
    height:'100vh',
    backgroundImage: `url(./img/laffnetbg_85.png)`, /* Location of the image */
	backgroundPosition: 'center center', /* Background image is centered vertically and horizontally at all times */
	backgroundRepeat: 'no-repeat', /* Background image doesn't tile */
	backgroundAttachment: 'fixed', /* Background image is fixed in the viewport so that it doesn't move when the content's height is greater than the image's height */
	backgroundSize: 'cover', /* This is what makes the background image rescale based on the container's size */
	backgroundColor: '#464646' /* Set a background color that will be displayed while the background image is loading */
}


const okStyle = {
    color:'#2BCB0A',
    fontSize: '14px',
}

const reqdStyle = {

    color:'#D42428',
    fontSize: '14px',
}



export default Signup;