import React, {Component} from 'react';

import axios from 'axios';

import { Button, CustomInput } from 'reactstrap';

import NavBar from '../layout/NavBar';
import Footer from '../layout/Footer';


class FBGraphAPI extends Component {

    constructor(props) {

        super(props);
        
        this.state = {
            

            FBLoggedIn: false,
            displayText: '',

            publicName:'',
            publicEmail:'',

            fbID:'',
            accessToken:'',



            
            

            pageInfo: [],
            posts: [],
            comments: [],
            engagements: [],



		}
    
    }

    componentDidMount() {

        

        
        

		console.log("Loaded");

        this.loadFbLoginApi();
		
    }

    
    loadFbLoginApi = () => {

        window.fbAsyncInit = function() {
            window.FB.init({
                appId      : '945023493426227',
                cookie     : true,  // enable cookies to allow the server to access
                // the session
                xfbml      : true,  // parse social plugins on this page
                version    : 'v2.5' // use version 2.1
            });
        };

        console.log("Loading fb api");
          // Load the SDK asynchronously
        (function(d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s); js.id = id;
            js.src = "//connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
    }
    
    checkLoginState = () => {
        window.FB.getLoginStatus(function(response) {
            this.statusChangeCallback(response);
        }.bind(this));
    }
  
    handleFBLogin = () => {

        var self = this;
        window.FB.login( function(response) {
            self.checkLoginState();
        }, {scope: 'pages_show_list,pages_manage_posts,pages_read_engagement,pages_read_user_content'});
    }
  

    statusChangeCallback = (response) => {

        var self = this;

        console.log('statusChangeCallback');
        console.log(response);
        if (response.status === 'connected') {
            //this.completeFBAuthentication(response.authResponse);
            console.log("Connected to FB.");

            
            self.setState({FBLoggedIn: true });

            self.setState({accessToken: response.authResponse.accessToken});
            self.setState({fbID: response.authResponse.userID});
            

            

            var tempText = self.state.displayText + "<p>Congratulations!!! You're now connected to Facebook.</p>"
            self.setState({displayText: tempText });



            window.FB.api(
                '/me',
                'GET',
                {"fields":"id,name,email,picture.width(1080).height(1080)"},
                function(response) {
                    // Insert your code here
                    //console.log(response);
                    //console.log(response.picture.data.url);
    
    
                    var userEmail = response.email;
                    var userPic = response.picture.data.url;
    
                    console.log("Get Profile Info");
                    console.log(response);





                    //var tempText = self.state.displayText + "|" + response.email + "|" + response.id + "|" + response.name

                    tempText = self.state.displayText;
                    tempText += "<strong>Name on profile: </strong>" + response.name + "<br />";
                    tempText += "<strong>Email on profile: </strong>" + response.email + "<br />";

                    self.setState({displayText: tempText });

                    self.setState({publicName: response.name });
                    self.setState({publicEmail: response.email });
                    
                    //self.getPageAccessTokens();
                    
                }
            );

            
        } else if (response.status === 'not_authorized') {

            console.log("Please log into this app.");
            var tempText = this.state.displayText + "Please log into this app.<br />"
            this.setState({displayText: tempText });

            self.setState({FBLoggedIn: false });

        } else {

            console.log("Please log into this facebook.");
            var tempText = this.state.displayText + "Please log into this facebook.<br />"
            this.setState({displayText: tempText });
        }
    }

    getPageAccessTokens = () => {

        var self = this;

        // Get page access tokens
        window.FB.api(
            '/' + self.state.fbID + '/accounts?fields=name,access_token&access_token=' + self.state.accessToken,
            'GET',
            function(response) {
                // Insert your code here

                console.log("Getting Page IDs");
                
                //console.log(response.picture.data.url);


                if ( response.data.length > 0 ) {
                    console.log(response);

                    self.setState({pageInfo: response.data });

                    //self.publishPostOnPage(response.data[0]);

                } else {
                    var tempText = self.state.displayText + "<br />Couldn't find any pages that you manage.<br />"
                    self.setState({displayText: tempText });
                }


                
            }
        );



    }


    getPagePosts = (page, index) => {

        var self = this;

        console.log(page.name);
        console.log(page.access_token);
        console.log(page.id);

        window.FB.api(
            '/' + page.id + '/feed?access_token=' + page.access_token,
            'GET',
            function(response) {
                // Insert your code here

                console.log("Getting Page Posts");
                
                var pagePosts = response.data;


                /*
                // Getting Comments
                
                for (var i = 0; i < pagePosts.length; i++) {

                    pagePosts[i].comments = [];
                    pagePosts[i].comments = self.getPostComments(pagePosts[i], page.access_token);
                }
                */


                    
                /*
                var tempPageInfo = self.state.pageInfo;
                tempPageInfo[index].posts = [];
                tempPageInfo[index].posts = pagePosts;

                self.setState({pageInfo: tempPageInfo});
                */


                self.setState( {posts: pagePosts} );

                console.log("pageInfo");
                console.log( self.state.posts );
                

          

                
                
            }
        );
    }

    getPostComments = (post, page_access_token) => {

        var self = this;

        // Get comments
        window.FB.api(
            '/' + post.id + '/comments?access_token=' + page_access_token,
            'GET',
            function(response) {
                


                console.log("Comments");
                console.log(response);

                self.setState( {comments: response.data} );
                
                
            }
        );



        // Get likes
        window.FB.api(
            '/' + post.id + '?fields=likes.summary(true)&access_token=' + page_access_token,
            'GET',
            function(response) {
                


                console.log("Likes");
                console.log(response);

                self.setState( {engagements: response.likes.data} );
                
                
            }
        );




        


    }






    // Publishes a post
    publishPostOnPage = (page) => {


        window.FB.api(
            '/' + page.id + '/feed?message=Hello Fans!&access_token=' + page.access_token,
            'POST',
            function(response) {
                // Insert your code here

                console.log("Posting Post");
                
                console.log(response);


                

                
            }
        );

    }
        





    /*
    
<script>
  window.fbAsyncInit = function() {
    FB.init({
      appId      : '{your-app-id}',
      cookie     : true,
      xfbml      : true,
      version    : '{api-version}'
    });
      
    FB.AppEvents.logPageView();   
      
  };

  (function(d, s, id){
     var js, fjs = d.getElementsByTagName(s)[0];
     if (d.getElementById(id)) {return;}
     js = d.createElement(s); js.id = id;
     js.src = "https://connect.facebook.net/en_US/sdk.js";
     fjs.parentNode.insertBefore(js, fjs);
   }(document, 'script', 'facebook-jssdk'));
</script>
    
    */
    logoutFB = () => {
        window.FB.logout(function(response) {
            // Person is now logged out

            console.log("Logged out");
        });
    }

    
    render() {


        const commentList = this.state.comments.map((comment,i)=> (

            <div key={i}>
                <p>
                    {comment.from.name} <i>commented on {comment.created_time}</i><br />
                    {comment.message}

                    
                </p>


            </div>

        ));

        const engagementList = this.state.engagements.map((eng,i)=> (

            <div key={i}>
                <p>
                    {eng.name} LIKED

                    
                </p>


            </div>

        ));





        const postList = this.state.posts.map((post,i)=> (

            <div key={i}>
                <p>
                    {post.message}
                    <br />
                    <i>Created on {post.created_time}</i>
                    <br />
                    <Button
                        classNames = "btn-facebook"
                        id         = "btn-get-comments"
                        onClick = {() => this.getPostComments(post, this.state.pageInfo[0].access_token)}
                        >
                            <span className="fa fa-facebook"></span> Get Comments

                    </Button>
                </p>


            </div>

        ));

        const pageList = this.state.pageInfo.map((page,i)=> (
            
            <div key={i}>
                {page.name}
                <br />
                
                <Button
                    classNames = "btn-facebook"
                    id         = "btn-social-login"
                    onClick = {() => this.getPagePosts(page,i)}
                    >
                        <span className="fa fa-facebook"></span> Get Posts

                </Button>


            </div>

        ));

        

        return(
            <div>
                <NavBar />
                <br />

                    <div style={{ display: this.state.FBLoggedIn?'none':'block' }}>

                        <Button
                            classNames = "btn-facebook"
                            id         = "btn-social-login"
                            onClick = {this.handleFBLogin}
                            >
                                <span className="fa fa-facebook"></span> Sign in with Facebook
                        </Button>
                        
                        <br />
                        
                    </div>
                    
                    <div style={{ display: this.state.FBLoggedIn?'block':'none' }}>
                            
                        <Button
                            classNames = "btn-facebook"
                            id         = "btn-social-login"
                            onClick = {this.logoutFB}
                            >
                                <span className="fa fa-facebook"></span> Log Out of Facebook
                        </Button>

                        <br />
                    
                    </div>







                    <br />
                    <div dangerouslySetInnerHTML={{ __html: this.state.displayText }} />
                    
                    <div style={{ display: this.state.FBLoggedIn?'block':'none' }}>

                        <br />
                        <br />

                        <Button
                            classNames = "btn-facebook"
                            id         = "btn-social-login"
                            onClick = {this.getPageAccessTokens}
                            >
                                <span className="fa fa-facebook"></span> Get Pages Managed By Me
                        </Button>
                    </div>

                    <div style={{ display: this.state.pageInfo.length > 0 ?'block':'none' }}>
                        
                        
                        <br /><br />
                        <h3>My Pages:</h3>
                        {pageList}
                   

                        
                    </div>


                    <div style={{ display: this.state.posts.length > 0 ?'block':'none' }}>

                        <br /><br />
                        <h3>My Posts:</h3>
                        {postList}

                    </div>


                    <div style={{ display: this.state.comments.length > 0 ?'block':'none' }}>

                        <br /><br />
                        <h3>My Comments:</h3>
                        {commentList}
                        <br />
                        {engagementList}

                    </div>

                    <br /><br /><br /><br />




                <Footer />
            </div>
        );
    }

}



export default FBGraphAPI;