import React, { Component } from 'react';

function Dialog(props) {

    let dialog = (
        
        <div style={dialogContainer}>
            <div style={dialogStyle}>
                <div className="float-right" style={closeBtn} onClick={props.onClose}>x</div>
                {props.children}
            </div>
        </div>
        
            
    );

    if (! props.isOpen) {
        dialog = null;
    }

    return (
        <div>
            {dialog}
        </div>
    );

}

const dialogStyle = {

    width:'100%',
    maxWidth:'570px',
    margin: '15% auto',
    backgroundColor:'#6c757d',
    color:'#ffffff',
    borderRadius:'20px',
    padding:'15px',
    textAlign:'center'
}

const dialogContainer = {
    

    position: 'fixed', /* Stay in place */
    zIndex: '1', /* Sit on top */
    left: '0',
    top: '0',
    width: '100%', /* Full width */
    height: '100%', /* Full height */
    overflow: 'auto', /* Enable scroll if needed */
    backgroundColor: 'rgb(0,0,0)', /* Fallback color */
    backgroundColor: 'rgba(255,255,255,0.6)' /* Black w/ opacity */

}

const closeBtn = {

    fontWeight:'bold',
    cursor:'pointer',
    marginRight:'5px'

} 

export default Dialog;