import React, {Component} from 'react';
import Script from 'react-load-script';

import base64url from "base64url";


class Gmail extends Component {

    constructor(props) {

        super(props);
        
        this.state = {
            //autocomplete : 'null',

            clientId: '735905868624-3bu5piopoba2raqi3a3umdl6p1qmnt41.apps.googleusercontent.com',
            apiKey: 'AIzaSyBtlfZ_WchTua-Z1NaD8KJ6xCKCZmeJMpM',
            scopes: 'https://mail.google.com/ https://www.googleapis.com/auth/gmail.labels https://www.googleapis.com/auth/gmail.readonly https://www.googleapis.com/auth/gmail.send https://www.googleapis.com/auth/gmail.modify https://www.googleapis.com/auth/gmail.compose',  // 'https://www.googleapis.com/auth/gmail.readonly'
            discoveryDocs: "https://www.googleapis.com/discovery/v1/apis/gmail/v1/rest",

            authorizeButton: null,
            signoutButton: null,

            labels:[],
            messages:[],

            currLabel: 'INBOX',
            
            listCount: 0,
            

            currentPage :0, /*zero based index*/
            nextPageToken: null,
            pageTokens: [],
            			
        }

    }

    componentDidMount() {

        this.setState({ authorizeButton: document.getElementById('authorize_button') });
        this.setState({ signoutButton: document.getElementById('signout_button') });
    
    }
    
    initGapiClient = () => {
        console.log('gapi init start');

        const apikey = this.state.apiKey;
        const clientId = this.state.clientId;
        const discoveryDocs = this.state.dsicoveryDocs;
        const scope = this.state.scopes;

        const authBtn = this.state.authorizeButton;
        const signoutBtn = this.state.signoutButton;

        var self = this;

        window.gapi.client.init({
            apiKey: apikey,
            clientId: clientId,
            discoveryDocs: discoveryDocs,
            scope: scope
        }).then(function () {

            console.log("gapi init done");
            
            
            
            // Listen for sign-in state changes.
            window.gapi.auth2.getAuthInstance().isSignedIn.listen(self.updateSigninStatus);


            // Handle the initial sign-in state.
            self.updateSigninStatus(window.gapi.auth2.getAuthInstance().isSignedIn.get());
            authBtn.onclick = self.handleAuthClick;
            signoutBtn.onclick = self.handleSignoutClick;
    

        }, function(error) {
            //this.appendPre(JSON.stringify(error, null, 2));

            console.log(error);
        });
    }

    jsLoaded = () => {

        console.log("JS Loaded");

        

        var self = this;

        // Loading the client library
        window.gapi.load('client', {
            callback: function() {

                console.log("Client Loaded");
                // Handle gapi.client initialization.
                self.initGapiClient();
            },
            onerror: function() {
                // Handle loading error.
                alert('gapi.client failed to load!');
            },
            timeout: 5000, // 5 seconds.
            ontimeout: function() {
                // Handle timeout.
                alert('gapi.client could not load in a timely manner!');
            }
        });

        /*
        window.gapi.load('client:auth2', function () {

            console.log("gapi Loaded");
            
            setTimeout(() => {
                        
                if (window.gapi) console.log("gapi exists");
                else             console.log("gapi not exists");   

                window.gapi.client.init({
                    apiKey: apikey,
                    clientId: clientId,
                    discoveryDocs: discoveryDocs,
                    scope: scope
                }).then(function () {

                    console.log("gapi init done");
                    // Listen for sign-in state changes.
                    window.gapi.auth2.getAuthInstance().isSignedIn.listen(self.updateSigninStatus);


                    // Handle the initial sign-in state.
                    self.updateSigninStatus(window.gapi.auth2.getAuthInstance().isSignedIn.get());
                    this.state.authorizeButton.onclick = self.handleAuthClick;
                    this.state.signoutButton.onclick = self.handleSignoutClick;


                
            

                }, function(error) {
                    //this.appendPre(JSON.stringify(error, null, 2));

                    console.log(error);
                });

            }, 1000);

        });
        */
        
    }

    updateSigninStatus = (isSignedIn) => {
        if (isSignedIn) {
            this.state.authorizeButton.style.display = 'none';
            this.state.signoutButton.style.display = 'block';
            this.loadLabels();
          } else {
            this.state.authorizeButton.style.display = 'block';
            this.state.signoutButton.style.display = 'none';
          }
    }

    handleAuthClick = (event) => {
        window.gapi.auth2.getAuthInstance().signIn();
    }

      
    handleSignoutClick = (event) => {
        window.gapi.auth2.getAuthInstance().signOut();
    }


    loadLabels = () => {
        console.log("Displaying Drafts");
        const labelArray = [];
        var self = this;

        // Loading labels
        var restRequest = window.gapi.client.request({
            'path': 'https://www.googleapis.com/gmail/v1/users/me/labels' 
        });
        restRequest.execute(function(resp) {
            console.log(resp);

            labelArray.push( {'id':'INBOX', 'name':'INBOX'} ); // just to push INBOX to the beginning of the array
            for (var i = 0; i < resp.labels.length; i++)
            {
                if (resp.labels[i].type == 'user' || resp.labels[i].labelListVisibility == 'labelShow')
                {
                    if (resp.labels[i].id != 'INBOX')
                    {
                        labelArray.push( {'id':resp.labels[i].id, 'name':resp.labels[i].name} );
                    }
                }
            }


            self.setState({ labels: labelArray});
        });

        

        // set initial page token
        const tempTokens = [];
        tempTokens[self.state.currentPage] = '';
        self.setState( {pageTokens: tempTokens} );
        


        

        self.loadMessages();
        
        






        /*
        var request = window.gapi.client.gmail.users.drafts.list({
            'userId': 'revelpulle@gmail.com'
        });
        request.execute(function(resp) {
            var drafts = resp.drafts;
            console.log(drafts);
        });
        */        
       

        // works        
        // 'path': 'https://www.googleapis.com/gmail/v1/users/me/labels' - returns all the labels
        // 'path': 'https://www.googleapis.com/gmail/v1/users/me/profile' - returns the profile

        //'path': 'https://www.googleapis.com/gmail/v1/users/me/messages/172923b5f9a8e0a8' - get the message with id 172923b5f9a8e0a8

        //'path': 'https://www.googleapis.com/gmail/v1/users/me/messages?q=from:gszczurek@woodview.ca', - get messages from the given person
        // 'path': 'https://www.googleapis.com/gmail/v1/users/me/messages?q=from:gszczurek@woodview.ca',
        
        
        
        
        /* Not working
        var request = window.gapi.client.gmail.users.labels.list({
            'userId': 'revelpulle@gmail.com'
          });
          request.execute(function(resp) {
            var labels = resp.labels;
            console.log(labels);
          });
        
        */




        /*
        window.gapi.client.gmail.users.labels.list({
            'userId': 'me'
        }).then(function(response) {
            var labels = response.result.labels;
            self.appendPre('Labels:');
  
            if (labels && labels.length > 0) {
              for (var i = 0; i < labels.length; i++) {
                var label = labels[i];
                self.appendPre(label.name)
              }
            } else {
                self.appendPre('No Labels found.');
            }
        });
        */

    }

    changeLabel = (label) => {

        var self = this;

        self.setState( {currLabel:label} );
        self.setState( {nextPageToken: null} );
        self.setState( {currentPage: 0} );

        // set initial page token
        const tempTokens = [];
        tempTokens[0] = ''; // tempTokens[self.state.currentPage] = ''; is also correct
        self.setState( {pageTokens: tempTokens} );
        
        

        setTimeout(function() {

            console.log(self.state.currLabel);
            console.log(self.state.nextPageToken);
            console.log(self.state.currentPage);
            


            self.loadMessages();
        }, 1000);
        
    }

    
    /* pageNumber - zero based index */
    loadMessages = () => {

        var self = this;
        //console.log("resetting array");
        const messageArray = [];

        


        // getting the messages
        var params =  'labelIds=' + self.state.currLabel + '&maxResults=100';
        var currPage = self.state.currentPage;
        /*       
        if (self.state.nextPageToken)
        {
           params += '&pageToken=' +  self.state.nextPageToken;
        }
        */
        if (self.state.pageTokens[currPage] && self.state.pageTokens[currPage] !='')
        {
           params += '&pageToken=' +  self.state.nextPageToken;
        }




        var restRequest = window.gapi.client.request({
            'path': 'https://www.googleapis.com/gmail/v1/users/me/messages?' + params,
        });
        restRequest.execute(function(resp) {
            //console.log("Messages");
            console.log(resp);

            currPage++;
            self.setState( {currentPage: currPage} );

            if (resp.nextPageToken)
            {
                self.setState( {nextPageToken: resp.nextPageToken} );

                const tempTokens = [ ...self.state.pageTokens ];
                tempTokens[self.state.currentPage] = resp.nextPageToken;
                self.setState( {pageTokens: tempTokens} );


            }                           
            else
            {
                self.setState( {nextPageToken: null} );
            }
            

            if (resp.messages && resp.messages.length)
            {

                
                // getting messages one at a time
                for (var i=0; i < resp.messages.length; i++)
                {
                    var restRequest = window.gapi.client.request({
                        'path': 'https://www.googleapis.com/gmail/v1/users/me/messages/'+resp.messages[i].id,
                    });
                    restRequest.execute(function(resp2) {

                    
                            //console.log("Message: " + JSON.stringify(resp2) );

                            //console.log("Message: " + resp2.payload.body.data);
                        
                    
                        //console.log(resp2);
                        
                        

                        var emailSubject = '';
                        var emailDate = '';
                        var emailFrom = '';
                        for (var j = 0; j < resp2.payload.headers.length; j++ )
                        {
                            if (resp2.payload.headers[j].name == "Subject")
                            {
                                //console.log("Subject: " + resp2.payload.headers[j].value);
                                emailSubject = resp2.payload.headers[j].value;
                                break;
                            }
                            else if (resp2.payload.headers[j].name == "Received")
                            {
                                //emailDate = resp2.payload.headers[j].value.split(';')[1];

                                const arr = resp2.payload.headers[j].value.split(';')
                                emailDate = arr[arr.length - 1].trim();
                                
                            }
                            else if (resp2.payload.headers[j].name == "From")
                            {
                                
                                emailFrom = resp2.payload.headers[j].value;
                            }
                        }
                        
                        console.log("---------------------------------------------");


                        messageArray.push( { 
                                'index': messageArray.length, 
                                'subject':emailSubject, 
                                'summary':resp2.snippet, 
                                'id':resp2.id, 
                                'threadId':resp2.threadId,
                                'from':emailFrom,
                                'date':emailDate
                            });
                        
                    });
                    
                }


                setTimeout(function() { 
                    self.setState({ messages: messageArray });
                    //console.log("Length: " + messageArray.length);
                    //console.log(messageArray[0]);
                    console.log(self.state.nextPageToken);

                    console.log( self.state.pageTokens );
                    console.log( self.state.currentPage );
                    
                    
                }, 3000);
            
            }
            
        });

    }

    sendEmail = (e) => {

        console.log( window.gapi.auth.getToken().access_token );

        //gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse().id_token
        
        
        /*
        const message =
        "From: revelpulle@gmail.com\r\n" + 
        "To: dvismika@yahoo.com\r\n" +
        "Subject: As basic as it gets\r\n\r\n" +
        "This is the plain text body of the message.  Note the blank line between the header information and the body of the message.";

        // The body needs to be base64url encoded.
        const encodedMessage = btoa(message)

        const reallyEncodedMessage = encodedMessage.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '')
        
        var restRequest = window.gapi.client.request({
            'path': 'https://www.googleapis.com/gmail/v1/users/me/messages/send',
            'raw': reallyEncodedMessage
        });
        restRequest.execute(function(resp) {

            console.log(resp);
            
        });
        */


        /*
        let raw = [
            "to: ", "dvismika@yahoo.com", "\n",
            "from: ", "revelpulle@gmail.com", "\n",
            "subject: ", "As basic as it gets", "\n\n",
            "This is the plain text body of the message.",
        ].join('');

        var options = {
            hostname: 'www.googleapis.com',
            path:'/upload/gmail/v1/users/me/messages/send',
            headers: {
            'Authorization':'Bearer '+ window.gapi.auth.getToken().access_token,
            'Content-Type':'message/rfc822'
            },
            method: 'POST',
        };
 
        const req = https.request(options, (res) => {
    
            var body = '';
    
            res.on('data', (d) => {
                body += d;
            });
            res.on('end', () => {
                var parsed = body;
                console.log(parsed);
            })
        });
 
        req.on('error', (e) => {
            console.error(e);
        });
        req.write(raw);
        req.end();
        */




    }

    appendPre = (message) => {
        var pre = document.getElementById('content');
        var textContent = document.createTextNode(message + '\n');
        pre.appendChild(textContent);
    }

    showFullMessage = (id) => {
        //console.log(this.state.messages[index]);

        var restRequest = window.gapi.client.request({
            //'path': 'https://www.googleapis.com/gmail/v1/users/me/messages/'+id + '?format=raw',
            'path': 'https://www.googleapis.com/gmail/v1/users/me/messages/'+id + '?format=full',
        });
        restRequest.execute(function(resp) {
           
            console.log(resp);
            //console.log(resp.raw);
            //console.log( base64url.decode(resp.raw));
                    
            

            document.getElementById("test-content").innerHTML = base64url.decode(resp.payload.parts[1].body.data);


        });
    }

    render () {

        //console.log("Labels: " + this.state.labels.length);

        const labelList = this.state.labels.map((label,i)=> (
            <div key={label.id} className="col-12">
                <span style={{cursor:'pointer'}} onClick={() => { this.changeLabel(label.id) }}>{label.name}</span>
            </div>
        )); 


        const messageList = this.state.messages.map((message,i)=> (
            <div key={message.index} style={{backgroundColor:'#fff', marginBottom:'2px', fontSize:'13px'}} onClick={() => {this.showFullMessage(message.id)} }>
                <div className="col-6">
                    <strong>{message.from}</strong>
                </div>
                <div className="col-6 text-right">
                    {message.date}
                </div>
                <div className="col-12">
                    <strong>{message.subject}</strong>&nbsp;-&nbsp;{message.summary}
                </div>
            </div>
            
        ));
            



        return (
            <div>

                <div id="test-content"></div>
                {/*
                <Script
                    url="https://apis.google.com/js/client.js" onLoad={this.jsLoaded}
                />
                */}
                <Script
                    url="https://apis.google.com/js/client:plusone.js" onLoad={this.jsLoaded}
                />



                <button id="authorize_button" style={{display: 'none'}} >Authorize</button>
                <button id="signout_button" style={{display: 'none'}}>Sign Out</button>
                <pre id="content" style={{whiteSpace: 'pre-wrap'}}></pre>



                <div className="container-fluid">
                    <div onClick={ this.sendEmail }>Send</div>
                    <div class="text-right">
                        <span > PREV </span>
                        <span onClick={ this.loadMessages } style={ this.state.nextPageToken ? {display:'block'} : {display:'none'} }> NEXT </span>  
                    </div> 
                    <div className="row">
                        <div className="col-3">
                            <div className="row">
                                {labelList}
                            </div>
                        </div>         
                        <div className="col-9">
                            <div className="row">
                                {messageList}
                            </div>
                        </div>                    
                    </div>

                </div>
                

            </div>

        
        );
    }
}

export default Gmail;