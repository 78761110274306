import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import ColourPicker from './ColourPicker';
import axios from 'axios';

class GigEdit extends Component 
{
    constructor(props) {

        super(props);

        console.log("Edit Launch");
        console.log(props.details);
        this.state = {

            id: props.details.row_id,
            showTitle: props.details.show_title,
            venue: props.details.venue,
            showDate: props.details.show_date,
            startTime: props.details.start_time,
            streetAddess: props.details.venue_addr_num + ' ' + props.details.venue_addr_street,
            city: props.details.venue_addr_city,
            prov: props.details.venue_addr_prov,
            link: props.details.link,
            //buttonText: props.details.button_text,

            //colorPalette: props.palette,            

            //buttonColor: '#ffffff',
            //buttonTxtcolor: '#000000',
        }
        
    }

    componentDidUpdate(prevProps) {

    }

    
    onChangeTitle = (e) => {
        this.setState( {showTitle: e.target.value} );
    }

    onChangeVenue = (e) => {
        this.setState( {venue: e.target.value} );
        
    }

    onChangeDate = (e) => {
        this.setState( {showDate: e.target.value} );
    }

    onChangeTime = (e) => {
        this.setState( {startTime: e.target.value} );
    }

    onChangeLink = (e) => {
        this.setState( {link: e.target.value} );
    }

    /*
    onChangeBtnText = (e) => {
        this.setState( {buttonText: e.target.value} );
    }
    
    
    onChangeBtnColor = (color) => {
        this.setState( {buttonColor: color} );
    }

    onChangeBtnTxtColor = (color) => {
        this.setState( {buttonTxtcolor: color} );
    }
    */

    saveGig = (e) => {

        axios({
            method: 'post',
            url: 'https://laffnetdev.website/api/website/index.php',
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json' 
			},
			data: {
				action: 'SAVE_GIG_INFO',
                
                id: this.state.id,
                showTitle: this.state.showTitle,
                venue: this.state.venue,
                showDate: this.state.showDate,
                startTime: this.state.startTime,
                link: this.state.link,

                //streetAddess: props.details.venue_addr_num + ' ' + props.details.venue_addr_street,
                //city: props.details.venue_addr_city,
                //prov: props.details.venue_addr_prov,
                

                



			}
        })
        .then(result => {
            console.log("Saved.");

            			
        })
        .catch(error => 
            console.log("ERROR: SAVE_GIG_INFO - "  + JSON.stringify(error) ) 
        );

    }
    

    render() {

        return (

            

            <div style={{padding: '30px 0 30px 0', borderBottom:'1px solid #000'}}>

                <div className="row" style={{marginBottom: '3px'}}>
                    <div className="col-12">
                        <input type="text" className="form-control form-control-sm" placeholder="Event Title" value={this.state.showTitle} onChange={this.onChangeTitle}/>
                    </div>
                </div>

                <div className="row" style={{marginBottom: '3px'}}>
                    <div className="col-12">
                        <input type="text" className="form-control form-control-sm" placeholder="Venue" value={this.state.venue} onChange={this.onChangeVenue}/>
                    </div>
                </div>

                <div className="row" style={{marginBottom: '3px'}}>
                    <div className="col-6 col-sm-4 col-md-3">
                        <input type="text" className="form-control form-control-sm" placeholder="Event Date" value={this.state.showDate} onChange={this.onChangeDate}/>
                    </div>
                    <div className="col-6 col-sm-4 col-md-3">
                        <input type="text" className="form-control form-control-sm" placeholder="Start Time" value={this.state.startTime} onChange={this.onChangeTime}/>
                    </div>
                </div>

                <div className="row" style={{marginBottom: '3px'}}>
                    <div className="col-12">
                        <input type="text" className="form-control form-control-sm" placeholder="Link" value={this.state.link} onChange={this.onChangeLink}/>
                    </div>
                </div>

                {/* 
                <div className="row" style={{marginBottom: '3px'}}>
                    <div className="col-6 col-sm-4 col-md-3">
                        <input type="text" className="form-control form-control-sm" placeholder="Button Text" value={this.state.buttonText} onChange={this.onChangeBtnText}/>
                    </div>
                </div>

                <div className="row" style={{marginBottom: '10px'}}>
                    <div className="col-md-4">Button Color:</div>
                    <div className="col-md-8">
                        <ColourPicker  colors={this.state.colorPalette} selected={this.state.buttonColor} onChange={this.onChangeBtnColor}/>
                    </div>
                </div>

                <div className="row" style={{marginBottom: '10px'}}>
                    <div className="col-md-4">Button Text Color:</div>
                    <div className="col-md-8">
                        <ColourPicker  colors={this.state.colorPalette} selected={this.state.buttonTxtcolor} onChange={this.onChangeBtnTxtColor}/>
                    </div>
                </div>
                */}
                

                <div className="row">
                    <div className="col-12 text-right">
                        <button className="btn btn-sm btn-success" onClick={this.saveGig}>Save</button>
                    </div>
                </div>
                
            </div>

            
            
        );

    }

}


export default GigEdit;