import React, {Component} from 'react';
import { Button, Input, Table , Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';


import { faTimesCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import axios from 'axios';
import Select from 'react-select'
import Bootbox  from  'bootbox-react';

import CreateNewContact from '../contacts/CreateNewContact';



class NewOpportunity extends Component {

    constructor(props) {
    
        super(props);

        this.state = {
            showCreateNew: this.props.show,
            contacts: [],
            showCreateNewContact: false,

            
            selectedContactID: null,
            selectedContactName: null,

            contactSelected: false,
            
        }

    }

    componentDidMount() {

        

    }

    componentDidUpdate(prevProps) {
        if(prevProps.show !== this.props.show) {
            
            this.setState( {showCreateNew: this.props.show} );
            this.loadContacts();
        }
    }


    toggleCreateNew = () => {
        //this.setState( { showCreateNew: !this.state.showCreateNew } );
        this.props.onClose();
    }


    loadContacts = () => {
        
        axios({
            method: 'post',
            url: 'https://laffnetdev.website/api/contacts/index.php',
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json' 
			},
			data: {
				action: 'GET_CONTACT_LIST_FOR_SELECT',
				
			}
        })
        .then(result => {
			
			//console.log(this.state.searchText);
			//console.log(result.data.filter);

			this.setState({contacts: result.data.contacts});
        })
		.catch(error => console.log("ERROR: "  + JSON.stringify(error) ) );


    }

    
    // Contact Created - IMPORTANT
    newContactCreated = (contact_id, contact_name) => {

        console.log("Contact Created");
        console.log(contact_id + " " + contact_name);

        this.setState( {selectedContactID: contact_id} );
        this.setState( {selectedContactName: contact_name} );      


        this.toggleNewContact();
        this.confirmContactSelection();
        
    }
    


    

    // Contact Selected - IMPORTANT
    handleChange = (selectedItem) => {
        console.log("Contact Selected");
        console.log(selectedItem); 

        this.setState( {selectedContactID: selectedItem.value} );
        this.setState( {selectedContactName: selectedItem.label} );
    }

    confirmContactSelection = () => {
        this.setState( {contactSelected: true} );
    }

    toggleNewContact = () => {
        this.setState( { showCreateNewContact: !this.state.showCreateNewContact } );
    }

    
    createOpportunity = () => {

        var self = this;

        axios({
            method: 'post',
            url: 'https://laffnetdev.website/api/funnel/index.php',
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json' 
            },
            data: {
                action: 'CREATE_NEW_CARD',
                contactID: this.state.selectedContactID,
            }
        })
        .then(result => {
            
            
            
            if (result.data.card)
            {
                //console.log("New Card");
                //console.log(result.data.card);

                const card = result.data.card;
                self.props.created(card);
                
            
                
            }
            
            
        })
        .catch(error => {
            console.log("ERROR: "  + JSON.stringify(error) ) 
        });



        

    }
    
    render() {

        let modalContent = '';

        if (this.state.contactSelected) {
            modalContent =  <div className="row">
                                <div className="col-12">{this.state.selectedContactName}</div>
                                <div className="col-12">
                                    <br />

                                    <div className="text-right">
                                        <Button className="btn-laff-sm" onClick={this.createOpportunity}>Create New Opportunity</Button>
                                    </div>
                                </div>
                            </div>;
        } else {
            modalContent =  <div className="row">
                                <div className="col-md-5 text-center" >
                                    With an existing Contact

                                    <br />

                                    <Select                        
                                        options={this.state.contacts} 
                                        onChange={this.handleChange}
                                        placeholder={'Choose a Contact'}
                                    />
                                    <button className="btn btn-sm laff-purple" onClick={this.confirmContactSelection} style={this.state.selectedContactID?{display:'block'}:{display:'none'}}>
                                        Confirm Contact Selection
                                    </button>


                                    <br /><br />
                                </div>

                                <div className="col-md-1 d-sm-none d-md-block" style={{borderRight:'1px solid #ccc'}}>
                                    &nbsp;
                                </div>

                                <div className="col-md-1 d-sm-none d-md-block" >
                                    &nbsp;
                                </div>


                                <div className="col-md-5 text-center">
                                    With a new Contact

                                    <br />

                                    <button className="btn btn-sm laff-purple" onClick={this.toggleNewContact}>Create New Contact</button>
                                </div>
                            </div>;
        }

        

                            
                            

        return(
            <div>

                <Modal isOpen={this.state.showCreateNew} size="lg">
                    
                    <ModalBody className="laff-modal">

                        <div className="row">
                            <div className="col-10">
                                <h5>Create New Opportunity</h5>
                            </div>
                            <div className="col-2 text-right" style={{paddingBottom:'10px'}}>
                                <Button className="laff-blue rounded-circle" size="sm" onClick={this.toggleCreateNew} aria-label="Close"><span>&nbsp;&times;&nbsp;</span></Button>                                                
                            </div>
                        </div>

                        <br />

                        {modalContent}

                    </ModalBody>
                    
                        
                    <CreateNewContact show={this.state.showCreateNewContact} toggle={this.toggleNewContact} created={this.newContactCreated} />

                    {/*     
                    <Bootbox 
                        show={this.state.showNoteAlert} 
                        type={"alert"}  
                        message={"Note cannot be empty."}  
                        onClose={ ()=>{ this.setState( {showNoteAlert: false} ); } }
                                                            
                    />
                    */}
                </Modal>

            </div>
        );

    }
}

export default NewOpportunity;