import React, { Component } from 'react';
import EditableDateInput from '../ui/EditableDateInput';
import EditableTimeInput from '../ui/EditableTimeInput';
import axios from 'axios';

import { format } from "date-fns";

class GigDate extends Component
{
    constructor(props) {
        super(props);

        this.state =  {
            gigDate: this.props.dateItem.date,
            setTime: this.props.dateItem.set_time,
            arriveTime: this.props.dateItem.arrive_time,
            
        }

        //console.log(this.props.dateItem);
    }

    updateDate = (date) => {
        console.log("New Date: " + format (date, "yyyy-MM-dd") );

        this.updateDB('date', format (date, "yyyy-MM-dd") );       
        

    }


    updateArriveTime = (time) => {
        console.log("Arrive Time: " + format( time, "HH:mm" ) );

        this.updateDB('arrive_time', format( time, "HH:mm" ) );  
    }

    updateSetTime = (time) => {
        console.log("Set Time: " + format( time, "HH:mm" ) );

        this.updateDB('set_time', format( time, "HH:mm" ) );
    }



    updateDB = (field_name, new_val) => {

        axios({
            method: 'post',
            url: 'https://laffnetdev.website/api/funnel/index.php',
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json' 
            },
            data: {
                action: 'UPDATE_GIG_DATE',
                rowID: this.props.dateItem.row_id,
                fieldName: field_name,
                newValue: new_val,
            }
        })
        .then(result => {
            
            console.log(result.data);  
                       

        })
        .catch(error => {
            console.log("ERROR: "  + JSON.stringify(error) );
        });

    }
    
    render() {
        return(
            <div className="row">
                <div className="col-4"><EditableDateInput date={this.state.gigDate} onUpdate={this.updateDate}/></div>
                <div className="col-4"><EditableTimeInput date={this.state.gigDate} time={this.state.arriveTime} onUpdate={this.updateArriveTime}/></div>
                <div className="col-4"><EditableTimeInput date={this.state.gigDate} time={this.state.setTime} onUpdate={this.updateSetTime}/></div>
            </div>
        );
    }
}

export default GigDate;




/*
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

class GigDate extends Component
{
    constructor(props) {
        super(props);

        this.state =  {
            gigDate:new Date(),
            arriveTime:new Date(),

        }
    }

    handleDateChange = (date) => {
        this.setState({
            gigDate: date
        })
    }

    handleTimeChange = (time) => {
        this.setState({
            arriveTime: time
        })
    }

    render() {
        return(
            <div>
                <DatePicker
                    selected={ this.state.gigDate }
                    onChange={ this.handleDateChange }
                    name="gigDate"
                    dateFormat="yyyy-MM-dd"
                    isClearable
                    showYearDropdown
                    scrollableMonthYearDropdown
                />

                <DatePicker
                    selected={ this.state.arriveTime }
                    onChange={ this.handleTimeChange }
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                />
            </div>
        );
    }
}

export default GigDate
*/