import React, {Component} from 'react';
import Select from 'react-select'

import axios from 'axios';


class SelectContact extends Component {

    constructor(props) {
        
        super(props);

        this.state = {
            contacts: [],

        }
    }

    componentDidMount() {

		this.loadContacts();

    }



    loadContacts = () => {
        /*
        axios({
            method: 'post',
            url: 'https://laffnetdev.website/api/contacts/index.php',
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json' 
			},
			data: {
				action: 'GET_CONTACT_LIST',
				filters: this.state.filters,
			}
        })
        .then(result => {
			
			//console.log(this.state.searchText);
			//console.log(result.data.filter);

			this.setState({contacts: result.data.contacts});
        })
		.catch(error => console.log("ERROR: "  + JSON.stringify(error) ) );

        */
        const options = [
            { value: 'chocolate', label: 'Chocolate' },
            { value: 'strawberry', label: 'Strawberry' },
            { value: 'vanilla', label: 'Vanilla' }
        ]

        this.setState( {contacts: options} );

    }

    handleChange = (selectedItem) => {
        console.log(selectedItem);
    }

    render() {
    
        return (
            <div >
                <Select 
                    options={this.state.contacts} 
                    onChange={this.handleChange}    
                    defaultValue="vanilla" 
                />
            </div>
        );
    
    }
}

export default SelectContact;