import React, {Component} from 'react';
import { Button, Input} from 'reactstrap';

import { faPencilAlt, faTimesCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";



class EditableInput extends Component
{
    constructor(props) {

        super(props);
        
        this.state = {            
            
            text: this.props.text,
            updatedText: this.props.text,
            editMode: false,
            
            inputWidth: ( this.props.inputWidth ? this.props.inputWidth : '150px'),
            prefix: ( this.props.prefix ? this.props.prefix : '' ),

            pencilID: this.props.pencilID,

		}            
    }

    changeEditMode = () => {
        this.setState( { editMode: !this.state.editMode } );
    }

    updateComponentValue = () => {
 
        this.setState({ editMode: false });

        if (this.state.text != this.state.updatedText) {
            
            this.setState({ text: this.state.updatedText });

            setTimeout(() => {
                
                this.props.onUpdate( this.props.fieldName, this.state.text );
    
            },200);
        }

        
        

        
    }

    handleChange = (e) => {
        
        this.setState( { updatedText: e.target.value } );
    }

    renderEditView = () => {
        return <div>

                <Input 
                    className="d-inline" 
                    type="text" name="text" 
                    id="" bsSize="sm" 
                    style={{width: this.props.inputWidth}} 
                    placeholder="with a placeholder" 
                    defaultValue={this.state.text}
                    onChange={this.handleChange}
                />
                <Button 
                    className="btn btn-sm laff-green d-inline no-border"  
                    onClick={ this.updateComponentValue }
                    style={{marginLeft:'2px', marginBottom:'3px'}}
                    
                >
                    <FontAwesomeIcon 
                        icon={faCheckCircle} size="1x" 
                        style={{verticalAlign:'0.125em'}}
                        data-toggle="tooltip" 
                        title="Down"
                        transform="down-4"
                                                
                    /> 
                </Button>
                <Button 
                    className="btn btn-sm laff-red d-inline no-border" 
                    onClick={ this.changeEditMode }
                    style={{marginLeft:'2px', marginBottom:'3px'}}
                >
                    <FontAwesomeIcon 
                        icon={faTimesCircle} size="1x" 
                        style={{verticalAlign:'0.125em'}}
                        data-toggle="tooltip" 
                        title="Down"
                        transform="down-4"
                                                
                    /> 
                </Button>
            

            
            
            

        </div>
    }

    renderDefaultView = () => {
        return <div className="clearfix"
                    onClick={ this.changeEditMode }
                    onMouseOver={ this.mouseOverInfo } 
                    onMouseLeave={ this.mouseLeaveInfo }
                >

            <div className="float-right">
                {this.state.prefix}{this.state.text}
            </div>
            <div className="float-right" style={{paddingRight:'6px'}}>
                <FontAwesomeIcon 
                    id={this.state.pencilID} 
                    icon={faPencilAlt} 
                    size="lg" 
                    style={pencilStyle}                                                                  
                />
            </div>
        </div>
    }
    
    mouseOverInfo = () => {      
         
        var id = this.state.pencilID;
        var pencil = document.getElementById(id);  
        if ( pencil ) {
            pencil.style.display = 'block';

            window.setTimeout(function() {
                pencil.style.opacity = 1;
            },0);
        } 
        
    }

    mouseLeaveInfo = () => {
        var id = this.state.pencilID;
        var pencil = document.getElementById(id);
        if ( pencil ) {
            pencil.style.opacity = 0;
        }
        
    }

    render () {
        return this.state.editMode ?
                    this.renderEditView()
                    :
                    this.renderDefaultView()
                
        ;
    }
}

const pencilStyle = {  
    color:'#000', 
    fontSize: '18px', 
    cursor: 'pointer',
    marginLeft:'8px',    
    opacity:'0', 
    transition: '.6s ease opacity'
}

export default EditableInput;