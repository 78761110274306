import React, {Component} from 'react';
import { Button, Input} from 'reactstrap';

import { faPencilAlt, faTimesCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import axios from 'axios';
import Select from 'react-select'

class EditableContactSelect extends Component
{
    constructor(props) {

        super(props);
        
        this.state = {            
            
            text: this.props.text,
            id: this.props.value,


            updatedText: this.props.text,
            updatedID: this.props.value,
            editMode: false,
            
            inputWidth: ( this.props.inputWidth ? this.props.inputWidth : '150px'),
            prefix: ( this.props.prefix ? this.props.prefix : '' ),

            contacts: [],

            isClearable: true,
		}            
    }

    componentDidMount() {

		this.loadContacts();

    }



    loadContacts = () => {
        
        axios({
            method: 'post',
            url: 'https://laffnetdev.website/api/contacts/index.php',
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json' 
			},
			data: {
				action: 'GET_CONTACT_LIST_FOR_SELECT',
				
			}
        })
        .then(result => {
			
			//console.log(this.state.searchText);
			//console.log(result.data.filter);

			this.setState({contacts: result.data.contacts});
        })
		.catch(error => console.log("ERROR: "  + JSON.stringify(error) ) );

        /*
        const options = [
            { value: 'chocolate', label: 'Chocolate' },
            { value: 'strawberry', label: 'Strawberry' },
            { value: 'vanilla', label: 'Vanilla' }
        ]

        this.setState( {contacts: options} );
        */

    }

    changeEditMode = () => {
        this.setState( { editMode: !this.state.editMode } );
    }

    updateComponentValue = () => {
 
        this.setState({ editMode: false });

        if (this.state.id != this.state.updatedID) {
            
            this.setState({ id: this.state.updatedID });
            this.setState({ text: this.state.updatedText });

            setTimeout(() => {
                
                this.props.onUpdate( this.props.fieldName, this.state.id, this.state.text );
    
            },200);
        }

        
        

        
    }

    handleChange = (selectedItem) => {

        console.log(selectedItem);
        if (selectedItem) {
            this.setState( { updatedText: selectedItem.label } );
            this.setState( { updatedID: selectedItem.value } );
        }
    }

    renderEditView = () => {
        return <div className="clearfix">

                    <div className="float-left" style={{width:'250px'}}>   
                        <Select 
                            
                            /*isClearable={this.state.isClearable}*/
                            options={this.state.contacts} 
                            onChange={this.handleChange}
                            style={{width: this.props.inputWidth}}

                            value={ {value: this.state.updatedID}, {label: this.state.updatedText}}
                            /* 
                            value={this.state.updatedID}
                            defaultValue={this.state.updatedID}
                            */

                        />
                    </div>                 
                    

                    <Button 
                        className="btn btn-sm laff-green d-inline no-border"  
                        onClick={ this.updateComponentValue }
                        style={{marginLeft:'2px', marginBottom:'3px', marginTop:'5px'}}
                        
                    >
                        <FontAwesomeIcon 
                            icon={faCheckCircle} size="1x" 
                            style={{verticalAlign:'0.125em'}}
                            data-toggle="tooltip" 
                            title="Down"
                            transform="down-4"
                                                    
                        /> 
                    </Button>
                    <Button 
                        className="btn btn-sm laff-red d-inline no-border" 
                        onClick={ this.changeEditMode }
                        style={{marginLeft:'2px', marginBottom:'3px', marginTop:'5px'}}
                    >
                        <FontAwesomeIcon 
                            icon={faTimesCircle} size="1x" 
                            style={{verticalAlign:'0.125em'}}
                            data-toggle="tooltip" 
                            title="Down"
                            transform="down-4"
                                                    
                        /> 
                    </Button>
                
                
            

            
            
            

        </div>
    }

    renderDefaultView = () => {
        return <div className="clearfix" style={{fontWeight:'bold', fontSize:'16px'}}
                    onClick={ this.changeEditMode } 
                    onMouseOver={ this.mouseOverInfo } 
                    onMouseLeave={ this.mouseLeaveInfo }
                >
            <div className="float-left">{this.state.prefix}{this.state.text}</div>
            <div className="float-left">
                <FontAwesomeIcon 
                    id="pencil-edit" 
                    icon={faPencilAlt} 
                    size="lg" 
                    style={pencilStyle}                                                                  
                />
            </div>
        </div>
    }
    

    mouseOverInfo = () => {        
        var pencil = document.getElementById("pencil-edit");   
        pencil.style.display = 'block';

        window.setTimeout(function() {
            pencil.style.opacity = 1;
        },0);
    }

    mouseLeaveInfo = () => {

        var pencil = document.getElementById("pencil-edit");
        pencil.style.opacity = 0;

    }


    render () {
        return this.state.editMode ?
                    this.renderEditView()
                    :
                    this.renderDefaultView()
                
        ;
    }
}

const pencilStyle = {  
    color:'#000', 
    fontSize: '18px', 
    cursor: 'pointer',
    marginLeft:'8px',    
    opacity:'0', 
    transition: '.6s ease opacity'
}

export default EditableContactSelect;