import React, {Component} from 'react';


import NavBar from '../layout/NavBar';
import Footer from '../layout/Footer';

class SetupConfirm extends Component {

    constructor(props) {

        super(props);
        
        this.state = {

            
			
		}
            
	}

    componentDidMount() {

        

    }


    render () {

        return (

            <div>

                <NavBar />
                <br />

                <div style={{width:'100%', maxWidth:'600px', marginLeft: 'auto', marginRight:'auto', backgroundColor:'#000000', color: '#ffffff', padding:'15px', borderRadius:'20px'}}>

                    test
                
                </div>

                <Footer />

            </div>

        );
    }


}

export default SetupConfirm;