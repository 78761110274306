import React from 'react'

import NavBar from '../layout/NavBar';
import Footer from '../layout/Footer';

function Home() {

    return (
        <div>

            <NavBar />

            <h2>Welcome!</h2>
            This is Home
            <p>This is a test to see if the fonts are working</p>


            <div style={{backgroundColor:'#FFDC00', color:'#FFFFFF'}}>
                <br />
                <p>#FFDC00</p>
                <br />
            </div>
            <div style={{backgroundColor:'#7B12BB', color:'#FFFFFF'}}>
                <br />
                <p>#7B12BB</p>
                <br />
            </div>
            <div style={{backgroundColor:'#2BCB0A', color:'#FFFFFF'}}>
                <br />
                <p>#2BCB0A</p>
                <br />
            </div>
            <div style={{backgroundColor:'#0050F5', color:'#FFFFFF'}}>
                <br />
                <p>#0050F5</p>
                <br />
            </div>
            <div style={{backgroundColor:'#D42428', color:'#FFFFFF'}}>
                <br />
                <p>#D42428</p>
                <br />
            </div>         
            <div style={{backgroundColor:'#000000', color:'#FFFFFF'}}>
                <br />
                <p>#000000</p>
                <br />    
            </div>


            <Footer />
            
        </div>
    )

}


export default Home;