import React, {Component} from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

import { faPencilAlt, faBuilding, faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from 'axios';

class CreateNewContact extends Component {

    constructor(props) {

        super(props);
        
        this.state = {
            
            validFirstName: true,
            validLastName: true,
            validEmail: true,
            
		}
            
    }

    createNewContact = (e) => {

        var self = this;

        var firstName = document.getElementById("first-name").value;
        var lastName = document.getElementById("last-name").value;
        var email = document.getElementById("email").value;
        var mobile = document.getElementById("mobile-phone").value;

        var formValid = true;

        if (firstName.trim() == "") {
            this.setState( {validFirstName:false} );
            formValid = false;
        }
        
        if (lastName.trim() == "") {
            this.setState( {validLastName:false} );
            formValid = false;
        }
        
        if (email.trim() == "") {
            this.setState( {validEmail:false} );
            formValid = false;
        }


        if (formValid)
        {
            axios({
                method: 'post',
                url: 'https://laffnetdev.website/api/contacts/index.php',
                headers: { 
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': 'application/json' 
                },
                data: {
                    action: 'CREATE_NEW_CONTACT',
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    mobile: mobile,
                }
            })
            .then(result => {
                
                //console.log(this.state.searchText);
                console.log(result.data);

                self.props.created(result.data.id, result.data.name);
    
                
            })
            .catch(error => 
                console.log("ERROR: "  + JSON.stringify(error) ) 
            );
        }
        

        
    }

    handleChange = ( e ) => {
        
        if (e.target)
        {
            var fieldName = e.target.name;
            var temp = "valid"+fieldName;
            this.setState( {[temp] : true} );
        }
        

    }


    render() {


        return (
            <div>

                    <Modal 
                        isOpen={this.props.show} 
                        
                        onExit     = { this.closeNewModal }   
                        size="lg"
                        
                         

                    >
                        <ModalBody className="laff-modal">
                            <div className="row">
                                <div className="col-10">
                                    <h5>Create New Contact</h5>
                                </div>
                                <div className="col-2 text-right" style={{paddingBottom:'10px'}}>
                                    <Button className="laff-blue rounded-circle" size="sm" onClick={this.props.toggle} aria-label="Close"><span>&nbsp;&times;&nbsp;</span></Button>                                                
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6 mb-2">
                                    <input className={"form-control form-control-sm " + (this.state.validFirstName ? "": "is-invalid") } id="first-name" name="FirstName" placeholder="First Name" onChange={this.handleChange}/>
                                </div>
                                <div className="col-md-6 mb-2">
                                    <input className={"form-control form-control-sm " + (this.state.validLastName ? "": "is-invalid") } id="last-name" name="LastName" placeholder="Last Name" onChange={this.handleChange}/>
                                </div>
                                <div className="col-md-6 mb-2">
                                    <input className={"form-control form-control-sm " + (this.state.validEmail ? "": "is-invalid") } id="email" name="Email" placeholder="Email" onChange={this.handleChange}/>
                                </div>
                                <div className="col-md-6 mb-2">
                                    <input className="form-control form-control-sm" id="mobile-phone" placeholder="Mobile Phone" />
                                </div>
                            </div>

                            <div className="text-right">
                                <Button className="btn-laff-sm" onClick={this.createNewContact}>Create</Button>
                            </div>
                            

                        </ModalBody>
                    </Modal>

            </div>
        );


    }

}

export default CreateNewContact;