import React, {Component} from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

import ContactEdit from './ContactEdit';
import { faPencilAlt, faBuilding, faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class ContactDetails extends Component
{
    constructor(props) {

        super(props);
        
        this.state = {
            
            contact: null,
            editView: false,
            editSection: 0,
            
            ratingPercentage: '0%',
            showingAddress:'work',
            showingEInfo:'work',
            showingPhone:'work',
            //showModal: true
            

            //this.props.showContactID,
		}
            
    }
    /*
    handleEvent= () => {
        this.setState({ contact: this.props.contact}); 
    }
    */
    

    // VERY IMPORTANT
    // this is because the props change without re-rendering the component
    componentDidUpdate(prevProps) {

        //console.log("updating 1");

        if(prevProps.contact !== this.props.contact) {

            //console.log("updating 2");
            this.setState({contact: this.props.contact});

            var self = this;

            setTimeout(function(){ 
                self.setRating();
            }, 500);
            
            
        }
    }
    
    componentDidMount() {
        
        console.log("Mounting");
        //console.log("Showing: " + this.props.contact.id);
        //this.setState( { showModal: this.props.show } );

        
        
    }

    componentWillUnmount() {
        console.log("unmounting");
    }

    //toggle = (e) => {

       ///console.log("toggling");

    //}

    setRating = () => {

        if (this.state.contact) {

            var rating = this.state.contact.rating;
            var ratingPec = (rating/5) * 100;
            //alert(ratingPec);

            //ratingPec = Math.round(ratingPec/10) * 10;
            ratingPec = Math.round(ratingPec) ;

            ratingPec = ratingPec + "%";
            //alert(ratingPec);

            this.setState( { ratingPercentage: ratingPec } );
            
        }
        

    }
    
    editContact = (section) => {
        //console.log('hello');
        this.setState( { editSection: section } );
        this.setState( { editView: true } );
        
    }

    // Refresh after editing
    refreshContactInfo = (updatedContact) => {
        console.log(updatedContact);
        this.setState({contact: updatedContact});

        this.closeEditModal();

    }

    closeEditModal = () => {

  

        this.setState({ editView: false});

    }
    
    mouseOverInfo = (val) => {        
        var pencil = document.getElementById("pencil-edit-"+val);   
        pencil.style.display = 'block';

        var area = document.getElementById("div-edit-"+val);
        area.style.backgroundColor = '#cccccc';
        
        
        window.setTimeout(function() {
            pencil.style.opacity = 1;
            //pencil.style.transform = 'scale(1)';
        },0);
    }

    mouseLeaveInfo = (val) => {
        var pencil = document.getElementById("pencil-edit-"+val);
        pencil.style.opacity = 0;

        var area = document.getElementById("div-edit-"+val);
        area.style.backgroundColor = '#e7e7e7';


        //pencil.style.transform = 'scale(0)';
        /*
        window.setTimeout(function(){
            pencil.style.display = 'none';
        },50);
        */
    }
    
    //{this.state.contact.firstname} {this.state.contact.lastname}<br/>
    render() {

        if ( this.state.contact )
        {            

            let workSuiteNumer = '';
            if (this.state.contact.work_suite !== '')
                workSuiteNumer = this.state.contact.work_suite + "-";
                             
            let homeSuiteNumer = '';
            if (this.state.contact.home_suite !== '')
                homeSuiteNumer = this.state.contact.home_suite + "-";
               
            {/*
            const contactView = 
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            {this.state.contact.firstname} {this.state.contact.lastname}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            {this.state.contact.picture ? <img src={'./img/contacts/'+ this.state.contact.picture} style={imageStyle}/> : <img src={'./img/contacts/portrait-placeholder.jpg'} style={imageStyle}/>} 
                            <br />
                            Work<br />
                            <div>                                
                                <span style={lineWrapper}>{this.state.contact.company ? this.state.contact.company + '\n':''}</span>                                      
                                <span style={lineWrapper}>{this.state.contact.work_title ? this.state.contact.work_title + '\n':''}</span>
                                {workSuiteNumer}{this.state.contact.work_number} {this.state.contact.work_street}<br />
                                {this.state.contact.work_city} {this.state.contact.work_province}  {this.state.contact.work_postalcode}<br />
                                {this.state.contact.work_country}<br />

                                <span style={lineWrapper}>{this.state.contact.phone_work_1 ? this.state.contact.phone_work_1 + ' x ' + this.state.contact.phone_work_1_ext + '\n':''}</span>
                                <span style={lineWrapper}>{this.state.contact.phone_work_2 ? this.state.contact.phone_work_2 + ' x ' + this.state.contact.phone_work_2_ext + '\n':''}</span>
                                <span style={lineWrapper}>{this.state.contact.email_work ? this.state.contact.email_work + '\n':''}</span>
                                <span style={lineWrapper}>{this.state.contact.website_work ? this.state.contact.website_work + '\n':''}</span>
                            </div>

                            <br /><br />
                            Home<br />
                            <div>
                                {homeSuiteNumer}{this.state.contact.home_number} {this.state.contact.home_street}<br />
                                {this.state.contact.home_city} {this.state.contact.home_province}  {this.state.contact.home_postalcode}<br />
                                {this.state.contact.home_country}<br />
                                <span style={lineWrapper}>{this.state.contact.phone_home_1 ? this.state.contact.phone_home_1 + '\n':''}</span>
                                <span style={lineWrapper}>{this.state.contact.phone_home_2 ? this.state.contact.phone_home_2 + '\n':''}</span>
                            </div>
                        </div>

                        <div className="col-sm-6">

                            <span style={lineWrapper}>{this.state.contact.phone_mobile ? this.state.contact.phone_mobile + '\n':''}</span>
                            <span style={lineWrapper}>{this.state.contact.phone_other ? this.state.contact.phone_other + '\n':''}</span>
                            <span style={lineWrapper}>{this.state.contact.email_personal ? this.state.contact.email_personal + '\n':''}</span>
                            <span style={lineWrapper}>{this.state.contact.email_other ? this.state.contact.email_other + '\n':''}</span>
                            <span style={lineWrapper}>{this.state.contact.website_personal ? this.state.contact.website_personal + '\n':''}</span>
                            <span style={lineWrapper}>{this.state.contact.website_other ? this.state.contact.website_other + '\n':''}</span>

                            <span style={lineWrapper}>{this.state.contact.facebook_personal ? this.state.contact.facebook_personal + '\n':''}</span>
                            <span style={lineWrapper}>{this.state.contact.facebook_business_1 ? this.state.contact.facebook_business_1 + '\n':''}</span>
                            <span style={lineWrapper}>{this.state.contact.facebook_business_2 ? this.state.contact.facebook_business_2 + '\n':''}</span>
                            <span style={lineWrapper}>{this.state.contact.twitter_personal ? this.state.contact.twitter_personal + '\n':''}</span>
                            <span style={lineWrapper}>{this.state.contact.twitter_business_1 ? this.state.contact.twitter_business_1 + '\n':''}</span>
                            <span style={lineWrapper}>{this.state.contact.twitter_business_2 ? this.state.contact.twitter_business_2 + '\n':''}</span>
                            <span style={lineWrapper}>{this.state.contact.instagram_personal ? this.state.contact.instagram_personal + '\n':''}</span>


                            <span style={lineWrapper}>{this.state.contact.instagram_business_1 ? this.state.contact.instagram_business_1 + '\n':''}</span>
                            <span style={lineWrapper}>{this.state.contact.instagram_business_2 ? this.state.contact.instagram_business_2 + '\n':''}</span>
                            <span style={lineWrapper}>{this.state.contact.linkedin_personal ? this.state.contact.linkedin_personal + '\n':''}</span>
                            <span style={lineWrapper}>{this.state.contact.linkedin_business_1 ? this.state.contact.linkedin_business_1 + '\n':''}</span>
                            <span style={lineWrapper}>{this.state.contact.linkedin_business_2 ? this.state.contact.linkedin_business_2 + '\n':''}</span>
                            <span style={lineWrapper}>{this.state.contact.youtube_channel_1 ? this.state.contact.youtube_channel_1 + '\n':''}</span>
                            <span style={lineWrapper}>{this.state.contact.youtube_channel_2 ? this.state.contact.youtube_channel_2 + '\n':''}</span>
                            <span style={lineWrapper}>{this.state.contact.youtube_channel_3 ? this.state.contact.youtube_channel_3 + '\n':''}</span>
                            
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6 text-left">

                            <Button color="secondary" size="sm" onClick={this.props.toggle}>Close</Button >
                            
                        </div>
                        <div className="col-6 text-right">
                            {this.state.editView ? <Button color="primary" size="sm">Save</Button> : <Button color="primary" size="sm" onClick={this.editContact}>Edit</Button>}
                        </div>
                    </div>

                </div>;
            */}    

            const contactRelationship = 
                <div>
                    {this.state.contact.isBooker === "Y" ? <span className='badge badge-pill badge-secondary' style={{padding:'8px 16px', marginRight:'2px', fontWeight:'400'}}>Booker</span> : ""}
                    {this.state.contact.isPromoter === "Y" ? <span className='badge badge-pill badge-secondary' style={{padding:'8px 16px', marginRight:'2px', fontWeight:'400'}}>Promoter</span>  : ""}
                    {this.state.contact.isFan === "Y" ? <span className='badge badge-pill badge-secondary' style={{padding:'8px 16px', marginRight:'2px', fontWeight:'400'}}>Fan</span>  : ""}    
                </div>;



            return (

                
                <div id="div-contact-details">
                    
                    <Modal 
                        isOpen={this.props.show} 
                        
                         
                        /*size="xl"*/
                        
                        
                    >
                        {/*<ModalHeader toggle={this.props.toggle}>{this.state.contact.firstname} {this.state.contact.lastname}</ModalHeader>*/}
                        <ModalBody>

                            {/*{this.state.editView ? <ContactEdit contact={this.state.contact}/>:contactView}*/}


                            
                             
                            <div className="container-fluid">
                                <div className="row">

                                    {/* Left */}
                                    <div className="col-12" style={{backgroundColor:'#e7e7e7', padding:'12px 25px 25px 25px'}}>

                                        <div className="row">
                                            <div style={{width:'100%', textAlign:'right'}}>
                                                <Button className="laff-blue rounded-circle" size="sm" onClick={this.props.toggle} aria-label="Close"><span>&nbsp;&times;&nbsp;</span></Button>                                                
                                            </div>
                                        </div>

                                        <div className="row"> 
                                            <div className="col-6 col-lg-4">
                                                {this.state.contact.picture ? <img style={imageStyle} src={'./img/contacts/'+ this.state.contact.picture} /> : <img style={imageStyle} src={'./img/contacts/portrait-placeholder.jpg'} />}     
                                            </div>
                                            <div className="col-lg-8">
                                                <div className="clearfix" id="div-edit-1" style={ contactInfoArea } 
                                                    onMouseOver={() => this.mouseOverInfo('1')} 
                                                    onMouseLeave={() => this.mouseLeaveInfo('1')}
                                                    onClick={ () => this.editContact(1) }  
                                                    >
                                                    <div className="float-left">
                                                        <div style={{fontWeight:'bold', fontSize:'24px'}}>{this.state.contact.firstname} {this.state.contact.lastname}</div>
                                                        <div style={{fontSize:'16px'}}>{contactRelationship}</div>
                                                    </div>    
                                                    <div className="float-right">
                                                        <FontAwesomeIcon 
                                                            id="pencil-edit-1" 
                                                            icon={faPencilAlt} 
                                                            size="lg" 
                                                            style={pencilStyle}
                                                            onClick={ () => this.editContact(1) }                                                        
                                                        />
                                                        {/* style={{display:'none', cursor: 'pointer', position: 'absolute', bottom: '0', right: '0', opacity:'0', transform: 'scale(0)', transition: '.6s ease opacity,.6s ease transform'}} */}
                                                        {/* onClick={() => this.changeView('list')} */}
                                                    </div>
                                                </div>
                                                <div style={{fontWeight:'400', fontSize:'13px'}}><i>Contact Added: {this.state.contact.created}</i></div>
                                                <div className="stars-outer">
                                                    <abbr title={this.state.contact.rating} style={tooltipStyle}>
                                                        <div className="stars-inner" style={{width:this.state.ratingPercentage}}></div>
                                                    </abbr>
                                                </div>
                                            </div>
                                        </div>

                                   

                                        <br />

                                        {/* *************************** */}
                                        {/* Social Media ****************/}
                                        {/* *************************** */}
                                        {/* 
                                        <div className="clearfix" id="div-edit-2" style={ contactInfoArea } 
                                            onMouseOver={ () => this.mouseOverInfo('2') } 
                                            onMouseLeave={ () => this.mouseLeaveInfo('2') }
                                            onClick={ () => this.editContact(2) }   
                                            >
                                            
                                            <div className="float-left">
                                                <img src='./img/icons/facebook.png' style={iconStyle}/> 
                                                <img src='./img/icons/linkedin.png' style={iconStyle}/>
                                                <img src='./img/icons/twitter.png' style={iconStyle}/>
                                                <img src='./img/icons/youtube.png' style={iconStyle}/>
                                                <img src='./img/icons/messages.png' style={iconStyle}/>
                                                <img src='./img/icons/emails.png' style={iconStyle}/>
                                            </div>  
                                            <div className="float-right">
                                                <FontAwesomeIcon 
                                                    id="pencil-edit-2" 
                                                    icon={faPencilAlt} 
                                                    size="lg"
                                                    style={pencilStyle}
                                                    onClick={ () => this.editContact(2) }
                                                                                                         
                                                />
                                            </div>

                                        </div>
                                        */}

                                        {/* *************************** */}
                                        {/* Work ************************/}
                                        {/* *************************** */}
                                        <div className="clearfix" id="div-edit-3" style={ contactInfoArea } 
                                            onMouseOver={() => this.mouseOverInfo('3')} 
                                            onMouseLeave={() => this.mouseLeaveInfo('3')}
                                            onClick={ () => this.editContact(3) }
                                            >
                                            
                                            <div className="float-left" style={{ fontWeight:'bold', fontSize:'13px' }}  >
                                                <div style={{fontSize:'15px'}}>{this.state.contact.company}</div>
                                                <div>{this.state.contact.work_title}</div>
                                            </div>
                                            <div className="float-right">
                                                <FontAwesomeIcon 
                                                    id="pencil-edit-3" 
                                                    icon={faPencilAlt} 
                                                    size="lg" 
                                                    style={pencilStyle}
                                                    onClick={ () => this.editContact(3) }                                                             
                                                />
                                            </div>
                                        </div>
                                        

                                        {/* *************************** */}
                                        {/* Phone Numbers ***************/}
                                        {/* *************************** */}
                                        <div className="clearfix" id="div-edit-4" style={ contactInfoArea } 
                                            onMouseOver={() => this.mouseOverInfo('4')} 
                                            onMouseLeave={() => this.mouseLeaveInfo('4')}
                                            
                                            >

                                            <div style={{ fontWeight:'bold', fontSize:'13px' }}>Mobile: {this.state.contact.phone_mobile}</div> 
                                            <div className="float-left" id="work-phone" onClick={ () => this.editContact(4) } style={this.state.showingPhone=="work" ? {display:'block', fontWeight:'bold', fontSize:'13px'} : {display:'none'}}>
                                                <div>Office 1: {this.state.contact.phone_work_1} {this.state.contact.phone_work_1_ext == "" ? "": "x " + this.state.contact.phone_work_1_ext}</div>
                                                <div>Office 2: {this.state.contact.phone_work_2} {this.state.contact.phone_work_2_ext == "" ? "": "x " + this.state.contact.phone_work_2_ext}</div>
                                            </div>
                                            <div className="float-left" id="home-phone" onClick={ () => this.editContact(4) } style={this.state.showingPhone=="home" ? {display:'block', fontWeight:'bold', fontSize:'13px'} : {display:'none'}}>
                                                <div>Home 1: {this.state.contact.phone_home_1}</div>
                                                <div>Home 2: {this.state.contact.phone_home_2}</div>                                                
                                            </div>
                                            <div className="float-right">
                                                <FontAwesomeIcon 
                                                    id="pencil-edit-4" 
                                                    icon={faPencilAlt} 
                                                    size="lg" 
                                                    style={pencilStyle}  
                                                    onClick={ () => this.editContact(4) }                                               
                                                                                                                
                                                />
                                            </div>

                                            <div className="float-right" 
                                                style={{ border: 'none', borderRadius:'50%', padding:'0px 8px', marginLeft:'2px', cursor:'pointer'}}
                                                onClick={() => { this.setState( { showingPhone:'home' } ); }}
                                                >
                                                <FontAwesomeIcon 
                                                    icon={faHome} 
                                                    size="lg" 
                                                    className={this.state.showingPhone=="home" ? "laff-blue-txt" : ""}                                                                                                                
                                                />
                                            </div>

                                            <div className="float-right" 
                                                
                                                style={{ border: 'none', borderRadius:'50%', padding:'0px 8px', marginLeft:'2px', cursor:'pointer'}}
                                                onClick={() => { this.setState( { showingPhone:'work' } ); }}
                                                >
                                                <FontAwesomeIcon 
                                                    icon={faBuilding} 
                                                    size="lg" 
                                                    className={this.state.showingPhone=="work" ? "laff-blue-txt" : ""}                                                                                                                
                                                />
                                            </div>
                                            
                                        </div>
                                        

                                        {/* *************************** */}
                                        {/* Address ******************* */}
                                        {/* *************************** */}
                                        <div className="clearfix" id="div-edit-5" style={ contactInfoArea }
                                            onMouseOver={() => this.mouseOverInfo('5')} 
                                            onMouseLeave={() => this.mouseLeaveInfo('5')}
                                            
                                            >
                                            
                                            <div className="float-left" id="work-addr" onClick={ () => this.editContact(5) } style={this.state.showingAddress=="work" ? {display:'block', fontWeight:'bold', fontSize:'13px'} : {display:'none'}}>
                                                {workSuiteNumer}{this.state.contact.work_number} {this.state.contact.work_street}<br />
                                                {this.state.contact.work_city} {this.state.contact.work_province}  {this.state.contact.work_postalcode}<br />
                                                {this.state.contact.work_country}<br />
                                            </div>
                                            <div className="float-left" id="home-addr" onClick={ () => this.editContact(5) } style={this.state.showingAddress=="home" ? {display:'block', fontWeight:'bold', fontSize:'13px'} : {display:'none'}}>
                                                {homeSuiteNumer}{this.state.contact.home_number} {this.state.contact.home_street}<br />
                                                {this.state.contact.home_city} {this.state.contact.home_province}  {this.state.contact.home_postalcode}<br />
                                                {this.state.contact.home_country}<br />
                                            </div>


                                            <div className="float-right">
                                                <FontAwesomeIcon 
                                                    id="pencil-edit-5" 
                                                    icon={faPencilAlt} 
                                                    size="lg" 
                                                    style={pencilStyle}   
                                                    onClick={ () => this.editContact(5) }                                                                                                                                                                
                                                />
                                            </div>
                                            

                                            <div className="float-right" 
                                                style={{ border: 'none', borderRadius:'50%', padding:'0px 8px', marginLeft:'2px', cursor:'pointer'}}
                                                onClick={() => { this.setState( { showingAddress:'home' } ); }}
                                                >
                                                <FontAwesomeIcon 
                                                    icon={faHome} 
                                                    size="lg" 
                                                    className={this.state.showingAddress=="home" ? "laff-blue-txt" : ""}                                                                                                                
                                                />
                                            </div>

                                            <div className="float-right" 
                                                
                                                style={{ border: 'none', borderRadius:'50%', padding:'0px 8px', marginLeft:'2px', cursor:'pointer'}}
                                                onClick={() => { this.setState( { showingAddress:'work' } ); }}
                                                >
                                                <FontAwesomeIcon 
                                                    icon={faBuilding} 
                                                    size="lg" 
                                                    className={this.state.showingAddress=="work" ? "laff-blue-txt" : ""}                                                                                                                
                                                />
                                            </div>
    
                                        </div>
                                        

                                        {/* *************************** */}
                                        {/* Emails ******************** */}
                                        {/* *************************** */}
                                        <div className="clearfix" id="div-edit-6" style={ contactInfoArea } 
                                            onMouseOver={() => this.mouseOverInfo('6')} 
                                            onMouseLeave={() => this.mouseLeaveInfo('6')}
                                            
                                            >

                                            <div className="float-left" onClick={ () => this.editContact(6) } id="work-e-info" style={ this.state.showingEInfo=="work" ? {display:'block', fontWeight:'bold', fontSize:'13px'} : {display:'none'} }> 
                                                {this.state.contact.email_work}<br />
                                                {this.state.contact.website_work}<br />
                                            </div>
                                            <div className="float-left" onClick={ () => this.editContact(6) } id="home-e-info" style={this.state.showingEInfo=="home" ? {display:'block', fontWeight:'bold', fontSize:'13px'} : {display:'none'}}>
                                                {this.state.contact.email_personal}<br />
                                                {this.state.contact.website_personal}<br />
                                            </div>


                                            <div className="float-right">
                                                <FontAwesomeIcon 
                                                    id="pencil-edit-6" 
                                                    icon={faPencilAlt} 
                                                    size="lg" 
                                                    style={pencilStyle}                                                     
                                                    onClick={ () => this.editContact(6) }                                                            
                                                />
                                            </div>


                                            

                                            <div className="float-right" 
                                                style={{ border: 'none', borderRadius:'50%', padding:'0px 8px', marginLeft:'2px'}}
                                                onClick={() => { this.setState( { showingEInfo:'home' } ); }}
                                                >
                                                <FontAwesomeIcon 
                                                    icon={faHome} 
                                                    size="lg" 
                                                    className={this.state.showingEInfo=="home" ? "laff-blue-txt" : ""}                                                                                                                
                                                />
                                            </div>

                                            <div className="float-right" 
                                                
                                                style={{ border: 'none', borderRadius:'50%', padding:'0px 8px', marginLeft:'2px'}}
                                                onClick={() => { this.setState( { showingEInfo:'work' } ); }}
                                                >
                                                <FontAwesomeIcon 
                                                    icon={faBuilding} 
                                                    size="lg" 
                                                    className={this.state.showingEInfo=="work" ? "laff-blue-txt" : ""}                                                                                                                
                                                />
                                            </div>                                           

                                        </div>
                                        
                                        {/* 
                                        <div style={{fontWeight:'bold', fontSize:'13px'}}>
                                            Last In Touch: January 15 2020<br />
                                            Next In Touch: June 23 2020<br />                                            
                                        </div>                                      
                                        */}

                                        {/* 
                                        <div>
                                            <span style={lineWrapper}>{this.state.contact.email_work ? this.state.contact.email_work + '\n':''}</span>
                                            <span style={lineWrapper}>{this.state.contact.website_work ? this.state.contact.website_work + '\n':''}</span>
                                        </div>
                                        */}                                        
                                        
                                    </div>

                                    {/* Right */}
                                    {/*
                                    <div className="col-md-8">

                                        <div className="row">

                                            <div className="col-12 text-right" style={{paddingTop:'15px', paddingBottom:'10px'}}>
                                                <Button className="laff-blue rounded-circle" size="sm" onClick={this.props.toggle} aria-label="Close"><span>&nbsp;&times;&nbsp;</span></Button>                                                
                                            </div>
                                            
                                        </div>

                                        <div className="row" style={{borderBottom:'#0050F5 5px solid'}}>
                                            
                                            <div className="col-4 col-lg-2 text-center">
                                                
                                            </div>
                                            <div className="col-4 col-lg-2 text-center" >
                                                <div><strong>Proposals</strong></div>
                                                <div style={{color:'#FFF', fontSize:'20px', backgroundColor:'#FFDC00'}}><strong>3</strong></div>
                                            </div>
                                            <div className="col-4 col-lg-2 text-center">
                                                <div><strong>Booked</strong></div>
                                                <div style={{color:'#FFF', fontSize:'20px', backgroundColor:'#7B12BB'}}><strong>2</strong></div>
                                            </div>
                                            <div className="col-4 col-lg-2 text-center">
                                                <div><strong>Lost</strong></div>
                                                <div style={{color:'#FFF', fontSize:'20px', backgroundColor:'#D42428'}}><strong>1</strong></div>
                                            </div>
                                            <div className="col-4 col-lg-2 text-center">
                                                <div><strong>Book Rate</strong></div>
                                                <div style={{color:'#FFF', fontSize:'20px', backgroundColor:'#0050F5'}}><strong>67%</strong></div>
                                            </div>
                                            <div className="col-4 col-lg-2 text-center">
                                                <div><strong>Revenue</strong></div>
                                                <div style={{color:'#FFF', fontSize:'20px', backgroundColor:'#2BCB0A'}}><strong>$1,762.00</strong></div>
                                            </div>    

                                            <br /><br />&nbsp;<br />   

                                        </div>

                                        
                                    </div>
                                    */}
                                    
                                </div>
                            </div>
                            
                            

                        </ModalBody>
                        
                    </Modal>
                    







                    <Modal 
                        isOpen={this.state.editView} 
                        
                        onExit     = { this.closeEditModal }   
                        size="lg"
                        
                         

                    >
                        <ModalBody>
                            <ContactEdit contact={this.state.contact} section={this.state.editSection} onClose={this.closeEditModal} onSave={this.refreshContactInfo} />
                        </ModalBody>
                    </Modal>
                    
                </div>
    
            );
        }
        else // state.contact is not set
        {
            return (
                <div></div>
            );
        }        
    }
} 


const contactInfoArea = {

    width: '100%', 
    display: 'inlineBlock', 
    position: 'relative', 
    padding:'10px 5px 5px 10px',
    borderRadius: '2px',
    cursor:'pointer',

    transition: '.6s ease opacity'
}

const pencilStyle = {
    
    /*color:'#6c757d',*/
    color:'#7B12BB', 
    fontSize: '18px', 
    cursor: 'pointer', 

    marginLeft:'8px',

    /*
    display:'none', 
    position: 'absolute', 
    top: '0', 
    right: '0', 
    */
    opacity:'0', 
    transition: '.6s ease opacity'
}

const imageStyle = {
    width:'100%', 
    height:'auto', 
    borderRadius:'50%',
    /*paddingLeft:'20px',
    paddingRight:'20px'*/
}

const iconStyle = {
    height:'40px',
    width:'auto',
    paddingRight:'10px',
    paddingTop:'5px',
    paddingBottom:'5px',
}

const lineWrapper = {
    whiteSpace: 'pre-wrap',
}

const tooltipStyle = {
    textDecoration: 'none',
	cursor: 'default',
}



export default ContactDetails;