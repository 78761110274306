import React, {Component} from 'react';
import { Button, Input} from 'reactstrap';
import { faBoxTissue } from '@fortawesome/free-solid-svg-icons';

class Card extends Component
{
    constructor(props) {

        super(props);
        
        this.state = {
            
		}
    }

    dragStart = (e) => {
        const target = e.target;

        if (target.id.substring(0,4) == "card")
        {
            
            //target.style.transform = 'rotate(7deg)';

            //console.log("DRAG START  - card");
            e.dataTransfer.setData('card_id', target.id);
            document.getElementById( "dragging-card-id" ).value = target.id;

            //target.style.opacity="0.3";
            //console.log("Card - dragStart " + target.id);
            

            
            setTimeout(() => {
                target.style.display="none";
            },0);

        }
    }

    dragEnd = (e) => {

        
        
        //const card_id = e.dataTransfer.getData('card_id');
        const card_id = document.getElementById( "dragging-card-id" ).value;

        document.getElementById( "dragging-card-id" ).value = "";
        
        if (card_id)
        {
            //console.log("DRAG END  - card: " + card_id);
            setTimeout(() => {
                //document.getElementById(card_id).style.transform = 'rotate(0deg)';
                document.getElementById(card_id).style.display = 'block';
                document.getElementById(card_id).style.opacity = '1.0';
            },0);
            
        }
        

        
    }

    dragOver = (e) => {
        //e.stopPropagation();
          
        e.preventDefault();

        // card that is being dragged
        //const card_id = e.dataTransfer.getData('card_id');
        const card_id = document.getElementById( "dragging-card-id" ).value;


        if (card_id)
        {
            if (e.target.id)
            {

                //console.log("Card - dragOver - " + e.target.id);
                this.props.onDragOverCard(card_id, e.target.id);

                
            }
        }

        

        
        

        

    }

   
    

    // gets triggerred when
    /*
    drop = (e) => {


       
        
        const parent = null;
    
        if (e.target.parentElement.className == "sales-board")
        {
            console.log("sales-board");

            const card_id = e.dataTransfer.getData('card_id');

            const card = document.getElementById(card_id);
            card.style.display = 'block';
            console.log(e.target.parentElement);
            //e.target.parentElement.appendChild(card);
            document.getElementById("board-1").appendChild(card);
        }
        else if (e.target.parentElement.className == "sales-card")
        {
            console.log("sales-card");
        }
       
        



        -------------------
        onDrop={this.drop}
        
        
        
    }
 */
    render() {
    
         
        return (


            <div
                id={this.props.card.id}
                draggable={this.props.draggable}
                onDragStart={this.dragStart}
                onDragOver={this.dragOver}
                onDragEnd={this.dragEnd}

                onClick={ () => this.props.onClickCard(this.props.card.row_id) }

                
                className="sales-card"
                
                board={this.props.card.board}

                
            >   
                <div style={{ pointerEvents:'none' }}> 

                    { this.props.children }

                </div>
                
                
            </div>


            

        );
    }
}

export default Card;

/* 
            <div
                id={this.props.id}
                draggable={this.props.draggable}
                onDragStart={this.dragStart}
                onDragOver={this.dragOver}
                onDragEnd={this.dragEnd}

                onClick={ () => this.props.onClickCard("hi") }

                
                className="sales-card"
                
                board={this.props.board}

                
            >   
                <div style={{ pointerEvents:'none' }}> 

                    { this.props.children }

                </div>
                
                
            </div>
            */