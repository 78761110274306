import React, {Component} from 'react';

import axios from 'axios';

import { Button, CustomInput } from 'reactstrap';

import NavBar from '../layout/NavBar';
import Footer from '../layout/Footer';

import SelectEmailContacts from './SelectEmailContacts';

import { faAngleDoubleRight, faTasks } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


class SendBulkEmail extends Component {

    constructor(props) {

        super(props);
        
        this.state = {


            templateList: [],
            contactList: [],

            //batchID: this.props.match.params.batchID,
            
            currentStep: 1,

            
		}

        
    
    }

    componentDidMount() {

    }


    render() {

        

        return (
            <div>
                <NavBar />
                <br />

                {/* Templates */}
                <div className="container" style={this.state.currentStep==1 ? {display:'block'}:{display:'none'}}>


                    <div className="row">

                        <div className="col-12 col-md-3">
                            <div style={tileStyle}>
                                <div style={smallFont}>
                                    Created on: 2019-11-15 20:51:20<br />
                                    Updated on: 2020-01-15 08:25:51
                                </div>
                            </div> 
                            <div className="text-center">
                                <button className="btn btn-sm laff-purple" style={purpleButton} >
                                    <FontAwesomeIcon 
                                        icon={faAngleDoubleRight} size="1x"
                                        style={{cursor:'pointer', verticalAlign:'0.125em'}}
                                        data-toggle="tooltip" 
                                        title="Notes"
                                        transform="down-4"                                    
                                    />
                                    <span style={{paddingLeft:'5px'}}>Use this Template</span>
                                </button>
                            </div>
                        </div>

                        <div className="col-12 col-md-3">
                            <div style={tileStyle}>
                                <div style={smallFont}>
                                    Created on: 2019-11-15 20:51:20<br />
                                    Updated on: 2020-01-15 08:25:51
                                </div>
                            </div>
                            <div className="text-center">
                                <button className="btn btn-sm laff-purple" style={purpleButton} >
                                    <FontAwesomeIcon 
                                        icon={faAngleDoubleRight} size="1x"
                                        style={{cursor:'pointer', verticalAlign:'0.125em'}}
                                        data-toggle="tooltip" 
                                        title="Notes"
                                        transform="down-4"                                    
                                    />
                                    <span style={{paddingLeft:'5px'}}>Use this Template</span>
                                </button>
                            </div>                           
                        </div>

                        <div className="col-12 col-md-3">
                            <div style={tileStyle}>
                                <div style={smallFont}>
                                    Created on: 2019-11-15 20:51:20<br />
                                    Updated on: 2020-01-15 08:25:51
                                </div>
                            </div>
                            <div className="text-center">
                                <button className="btn btn-sm laff-purple" style={purpleButton} >
                                    <FontAwesomeIcon 
                                        icon={faAngleDoubleRight} size="1x"
                                        style={{cursor:'pointer', verticalAlign:'0.125em'}}
                                        data-toggle="tooltip" 
                                        title="Notes"
                                        transform="down-4"                                    
                                    />
                                    <span style={{paddingLeft:'5px'}}>Use this Template</span>
                                </button>
                            </div>
                        </div>

                        <div className="col-12 col-md-3">
                            <div style={tileStyle}>
                                <div style={smallFont}>
                                    Created on: 2019-11-15 20:51:20<br />
                                    Updated on: 2020-01-15 08:25:51
                                </div>
                            </div>
                            <div className="text-center">
                                <button className="btn btn-sm laff-purple" style={purpleButton} >
                                    <FontAwesomeIcon 
                                        icon={faAngleDoubleRight} size="1x"
                                        style={{cursor:'pointer', verticalAlign:'0.125em'}}
                                        data-toggle="tooltip" 
                                        title="Notes"
                                        transform="down-4"                                    
                                    />
                                    <span style={{paddingLeft:'5px'}}>Use this Template</span>
                                </button>
                            </div>
                        </div>

                    </div>
                </div>


                {/* Edit Template */}
                <div className="container" style={this.state.currentStep==2 ? {display:'block'}:{display:'none'}}>

                </div>

                {/* Select Contacts */}
                <div className="container" style={this.state.currentStep==3 ? {display:'block'}:{display:'none'}}>

                    <SelectEmailContacts />
                    
                </div>




                <Footer />

            </div>
        );

    }


}

const tileStyle = {
    backgroundColor:'#838C91',
    borderRadius:'2px',
    fontSize:'14px',
    padding:'10px',
    marginBottom:'5px',
    height:'150px'
}

const smallFont = {
    
    fontSize:'11px',
    fontWeight: 'bold'
}

const purpleButton = {
	borderRadius: '25px', 
	paddingLeft:'20px', 
	paddingRight:'20px',
    fontSize:'16px',
    border: '2px #ffffff solid'
}

export default SendBulkEmail;