import React, {Component, useContext} from 'react';

import { InputGroup, InputGroupText, Button, Modal, ModalHeader, ModalBody, ModalFooter, TabContent, TabPane, Nav, NavItem, NavLink, Col, Row, Input, Label, FormGroup, Card, CardTitle, CardText } from 'reactstrap';
import {  faUserPlus } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";



import axios from 'axios';

class Headshot extends Component 
{
    constructor(props) {

        super(props);

        this.state = {

            showHeadshotUpload: true,

        }

    }

    componentDidMount() {

        this.setState({showHeadshotUpload: true});

    }

    gotoWebSetup = () => {
        this.props.history.push("/websetup");
    }

    render() {

        var self = this;
        const { crop, croppedImageUrl, src } = this.state;


        return (
            <div style={backgroundStyle}>


                

                <Modal
                    isOpen={this.state.showHeadshotUpload}
                    size="lg"
                    backdrop={false}
                    centered
                >
                    <ModalHeader className="laff-blue">
                        Add a headshot to display your best side.
                    </ModalHeader>
                    <ModalBody style={{backgroundColor:'#000000', color: '#ffffff'}}> 

                        Choose an image of you that will be used as your main brand image for marketing your shows.                        

                        
                        <br /><br />
                        <div className="text-center">

                            <button type="button" className="btn btn-sm btn-laff-sm-blue" id="btn-about-section-edit"  >
                                <FontAwesomeIcon 
                                    id="pencil-edit" 
                                    icon={faUserPlus} 
                                    size="lg" 
                                    style={pencilStyle}                                                                  
                                /> Upload
                            </button>

                        </div>
                        <br />
                        
                        
                    </ModalBody>


                </Modal>

                
            </div>
        );
    }


}

const backgroundStyle = {
    width:'100%',  
    height:'100vh',
    backgroundImage: `url(./img/laffnetbg_85.png)`, /* Location of the image */
	backgroundPosition: 'center center', /* Background image is centered vertically and horizontally at all times */
	backgroundRepeat: 'no-repeat', /* Background image doesn't tile */
	backgroundAttachment: 'fixed', /* Background image is fixed in the viewport so that it doesn't move when the content's height is greater than the image's height */
	backgroundSize: 'cover', /* This is what makes the background image rescale based on the container's size */
	backgroundColor: '#464646' /* Set a background color that will be displayed while the background image is loading */
}

const pencilStyle = {  
    color:'#fff', 
    fontSize: '14px', 
    cursor: 'pointer',
    marginLeft:'4px',    
    /*opacity:'0', 
    transition: '.6s ease opacity'*/
}


export default Headshot;